/* This file is autogenerated by @oui/cli */
import Svg, { Ellipse, Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      d="M17 6.77a4.9 4.9 0 0 0-6.7-4.53.44.44 0 0 1-.5-.1A3.54 3.54 0 0 0 3.7 4.36c-.01.18-.13.34-.3.4A3.55 3.55 0 0 0 1 8.1c0 1.95 1.6 3.55 3.56 3.55.28 0 .58-.04.92-.14.19-.05.4.03.5.2a3.55 3.55 0 0 0 5.98.13c.08-.12.2-.2.35-.2A4.9 4.9 0 0 0 17 6.77Z"
      stroke="currentColor"
      strokeWidth={2}
      vectorEffect="non-scaling-stroke"
    />
    <Ellipse
      cx={3.64}
      cy={15.58}
      rx={1.26}
      ry={1.26}
      stroke="currentColor"
      strokeWidth={2}
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M10.4 4.14 8.5 6.04l1.57 1.25-1.73 2.67"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
