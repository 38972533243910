/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      d="M11.85 1.82a2.82 2.82 0 0 1 3.98 0l.34.35a2.82 2.82 0 0 1 0 3.99l-2.01 2.02a2.82 2.82 0 0 1-3.99 0l-.34-.35a2.82 2.82 0 0 1 0-3.99l2.02-2.02Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M3.85 9.82a2.82 2.82 0 0 1 3.98 0l.34.35a2.82 2.82 0 0 1 0 3.98l-2.01 2.03a2.82 2.82 0 0 1-3.99 0l-.34-.35a2.82 2.82 0 0 1 0-3.99l2.02-2.02Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="m6.2 11.8 5.6-5.6"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
