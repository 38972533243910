/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      d="M1.73 17c0-.28.08-.86.12-1.03.1-.32.62-.48 1.19-.43.94.08 1.46-.36 1.68-.94.16-.4.22-.46.34-1.39.11-.8-.1-1.63.16-2.36.13-.42.7-.36.92-.69.48-.7-.71-1.4-.96-2.17a3.73 3.73 0 0 1-.1-1.8C5.24 4.97 4.2 2.4 1 1.4"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="m11 9 6 6"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="m17 9-6 6"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
