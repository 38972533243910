/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={16} height={18} viewBox="0 0 16 18" fill="none" {...props}>
    <Path
      d="M5.47 5.34c-.24.12-.46.26-.7.36a3.91 3.91 0 0 0-2.7 3.4 7.69 7.69 0 0 0 2.84 7.19c.78.66 1.71.92 2.75.51.49-.2 1 .05 1.48.14.96.2 1.8-.12 2.46-.73 2.24-2.07 3.23-4.65 2.6-7.67A3.91 3.91 0 0 0 9.9 5.49c-.53.05-1.05.03-1.56.27-.25.12-.57 0-.77-.26"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M7.25 5.1c.05-.52.32-.99.28-1.55-.59-.39-.97-1.11-1.73-1.35-.04 0-.05-.06-.09-.08-.6-.27-1.72-.23-2 .17-.44.62.03 1.17.32 1.68.71 1.24 1.5 1.6 3.05 1.2.18-.05.15.04.2.1"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M8.77 5.34c-.25-.93.33-1.6.73-2.33.15-.26.43-.46.55-.73.16-.38.25-.84-.16-1.1-.36-.24-.73-.27-1.14.12-.6.58-.7 1.4-1.19 2.03"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
