/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      d="M5 11.5 1 17l5.5-4"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.33 7.94a4.8 4.8 0 0 0-4.73.73l6.73 6.73a4.8 4.8 0 0 0 .73-4.73l3.91-3.92c1.04.23 2.16 0 3.03-.7L11.95 1a3.6 3.6 0 0 0-.7 3.03L7.33 7.94Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
