/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      d="M1 2.84C.94 1.82 1.4 1 2.06 1H15.3c.65 0 1.12.82 1.04 1.84l-.9 12.33c-.07 1.01-.66 1.83-1.3 1.83H3.21c-.65 0-1.24-.81-1.31-1.83l-.9-12.33Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.3 14.44a.83.83 0 1 0 0-1.67.83.83 0 0 0 0 1.67Z"
      fill="currentColor"
    />
    <Path
      d="M8.3 10.68s.05-1.06.86-1.4c1.04-.46 1.65-1.35 1.65-2.03 0-1.33-1.12-2.41-2.5-2.41a2.46 2.46 0 0 0-2.51 2.4"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
