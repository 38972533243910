/* This file is autogenerated by @oui/cli */
import Svg, { Ellipse, Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={19} height={16} viewBox="0 0 19 16" fill="none" {...props}>
    <Path
      d="M14.2 7.6V11c0 1.21-2.33 2.2-5.2 2.2s-5.2-.98-5.2-2.2V7.6"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M7.84 2.22a3.23 3.23 0 0 1 2.32 0l6.36 2.86c.64.29.64.76 0 1.04l-6.36 2.86c-.64.3-1.68.3-2.32 0L1.48 6.12c-.64-.29-.64-.76 0-1.04l6.36-2.86Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M17.5 9.5s-1-2-.5-3.5c.16-.47-7.5-.5-7.5-.5"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      vectorEffect="non-scaling-stroke"
    />
    <Ellipse cx={9} cy={5.5} rx={1.5} ry={1} fill="currentColor" />
  </Svg>
);
export default SvgComponent;
