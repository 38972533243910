import * as Font from 'expo-font';
import * as FontLoader from 'expo-font/build/ExpoFontLoader';
import * as FontServer from 'expo-font/build/server';

// used b/c nextjs require returns { default: '' }
function interopDefault<T>(obj: T): string | number | undefined {
  // @ts-ignore
  return obj.default || obj;
}

const FontMap = {
  OpenSansBold: {
    uri: interopDefault(require('../assets/font/OpenSans-Bold.ttf')),
    display: Font.FontDisplay.FALLBACK,
  },
  OpenSansRegular: {
    uri: interopDefault(require('../assets/font/OpenSans-Regular.ttf')),
    display: Font.FontDisplay.FALLBACK,
  },
  OpenSansSemiBold: {
    uri: interopDefault(require('../assets/font/OpenSans-SemiBold.ttf')),
    display: Font.FontDisplay.FALLBACK,
  },
};

export function getHeadElements() {
  Object.entries(FontMap).forEach(([key, source]) => {
    FontServer.registerStaticFont(key, source);
  });

  // @ts-ignore getHeadElements is patched via yarn
  return FontLoader.default.getHeadElements() as Array<{
    $$type: string;
    [key: string]: string | undefined;
  }>;
}

export function initFonts() {
  return Font.loadAsync(FontMap);
}
