/* This file is autogenerated by @oui/cli */
import Svg, { Ellipse, G, Path, type SvgProps } from 'react-native-svg';

export const Fingerprint = (props: SvgProps) => (
  <Svg width={80} height={80} viewBox="0 0 80 80" {...props}>
    <G
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
      vectorEffect="non-scaling-stroke"
    >
      <G transform="translate(-148.000000, -378.000000)">
        <G transform="translate(148.000000, 378.000000)">
          <Ellipse fill={props.color} cx={40} cy={40} rx={40} ry={39.52} />
          <G
            transform="translate(10.602410, 13.012048)"
            stroke="#FFF"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <G transform="translate(5.923695, 4.738956)">
              <Path
                d="M35.54 40.28v-8.3a13.03 13.03 0 0 0-26.06 0v7.12"
                strokeWidth={2}
                fillRule="nonzero"
                vectorEffect="non-scaling-stroke"
              />
              <Path
                d="M45.25 10.64A29.6 29.6 0 0 0 22.5 0a29.5 29.5 0 0 0-22 9.79"
                strokeWidth={2}
                fillRule="nonzero"
                vectorEffect="non-scaling-stroke"
              />
              <Path
                d="M43.84 30.8c0-9.03-5.62-16.76-13.56-19.86m-7.77-1.46A21.33 21.33 0 0 0 1.18 30.8"
                strokeWidth={2}
                fillRule="nonzero"
                vectorEffect="non-scaling-stroke"
              />
              <Path
                d="M26.06 42.54V32a3.55 3.55 0 1 0-7.1 0s.2 4.66 0 6.96c-.49 5.68-3.56 8.44-3.56 8.44"
                strokeWidth={2}
                fillRule="nonzero"
                vectorEffect="non-scaling-stroke"
              />
            </G>
          </G>
        </G>
      </G>
    </G>
  </Svg>
);
