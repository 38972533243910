/* This file is autogenerated by @oui/cli */
import Svg, { Ellipse, Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={17} viewBox="0 0 18 17" fill="none" {...props}>
    <Path
      d="M1 7.1v5.71m0 3.05V12.8m0 0h16v3.05"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Ellipse cx={4.05} cy={10.14} rx={1.52} ry={1.52} fill="currentColor" />
    <Path
      d="M7.1 9.38v-1a1 1 0 0 0-1 1h1Zm0 3.43h-1v1h1v-1Zm8.38 0v1h.84l.14-.84-.98-.16Zm-3.43-3.43v-1 1Zm-5.95 0v3.43h2V9.38h-2Zm1 4.43h8.38v-2H7.1v2Zm9.36-.84c.07-.43-.03-.92-.16-1.33-.15-.45-.4-.95-.74-1.42a4.3 4.3 0 0 0-3.51-1.84v2c.85 0 1.47.44 1.9 1.02.21.3.36.6.45.87.1.3.08.42.09.38l1.97.32Zm-4.41-4.59H7.1v2h4.95v-2Z"
      fill="currentColor"
    />
    <Path
      d="M7.1 6.33H4.8L7.1 4.05H4.8"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M12.43 4.05H9.38L12.43 1H9.38"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
