/* This file is autogenerated by @oui/cli */
import Svg, { Circle, Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Circle
      cx={9}
      cy={9}
      r={8}
      stroke="currentColor"
      strokeWidth={2}
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M14 4 5 14"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="square"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
