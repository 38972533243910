/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={16} height={18} viewBox="0 0 16 18" fill="none" {...props}>
    <Path
      d="M11.44 3.06c-.07-.03-.1-.07-.13-.15a2.55 2.55 0 0 0-1.7-1.6C8.7 1 7.77.96 6.82 1.03c-.63.05-1.26.14-1.85.4-.79.33-1.36.86-1.47 1.76-.1.8.2 1.41.84 1.88.19.13.38.24.58.33.17.09.35.17.46.34.06.08.18.1.29.12 1.12.1 2.21.36 3.3.63.7.17 1.4.4 2 .79a2.52 2.52 0 0 1 1.21 2.4 2.19 2.19 0 0 1-1 1.81c-.27.18-.58.3-.9.36a.93.93 0 0 0-.56.3"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M5.47 5.86c-.2.18-.44.26-.7.32-.9.21-1.59.7-1.8 1.66-.24 1.08-.01 2.02.93 2.72.54.4 1.15.65 1.79.84.64.19 1.3.33 1.94.48.63.15 1.27.22 1.9.32.1 0 .17.01.22.1.13.2.34.28.53.38.52.27.99.6 1.18 1.18.26.76.14 1.45-.4 2.05-.5.55-1.15.8-1.84.93-1.08.21-2.17.23-3.25-.01-.8-.18-1.51-.53-1.97-1.26-.1-.15-.18-.31-.24-.48a.24.24 0 0 0-.12-.16"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
