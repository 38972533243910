import { useQuery } from '@apollo/client';

import { PracticeTile } from '@oui/app-core/src/components/PracticeTile';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { graphql } from '@oui/lib/src/graphql/tada';

import { useRelaxContext } from '../RelaxContext';

type RelaxPracticeTileProps = {
  lockedUntil?: number;
  practiceCount?: number;
  onPress: () => unknown;
};

export const RelaxPracticeTileQuery = graphql(`
  query RelaxPracticeTile {
    user {
      ID
      role {
        ID
        relaxDiaryWidget {
          ID
          name
        }
      }
    }
  }
`);

export const RelaxPracticeTile: React.FC<RelaxPracticeTileProps> = ({
  onPress,
  lockedUntil,
  practiceCount,
}) => {
  const { $t } = useI18n();
  const relaxTheme = useRelaxContext();
  const { data } = useQuery(RelaxPracticeTileQuery, { fetchPolicy: 'cache-first' });

  return (
    <PracticeTile
      testID="PracticeTile_relax"
      title={
        data?.user?.role?.relaxDiaryWidget?.name ??
        $t({ id: 'PracticeTile_relax_title', defaultMessage: 'Relax' })
      }
      description={$t({
        id: 'PracticeTile_relax_description',
        defaultMessage: 'Listen to calm yourself and relax',
      })}
      color={relaxTheme.backgroundColor}
      textColor="#104f51"
      icon="relax"
      onPress={onPress}
      lockedUntil={lockedUntil}
      practiceCount={practiceCount}
    />
  );
};
