/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={17} viewBox="0 0 18 17" fill="none" {...props}>
    <Path
      d="M16.5 1v11"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M1 15.59a8.99 8.99 0 0 1 9-8.97h2.37"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="m10.4 2.62 3.93 3.93-3.93 3.93"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
