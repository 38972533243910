/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={16} height={18} viewBox="0 0 16 18" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 3.46c0-.68.5-1.4 1.15-1.59 0 0 2.25-.87 5.65-.87 3.4 0 5.65.87 5.65.87.64.2 1.15.91 1.15 1.59v7.8c0 .68-.44 1.55-.98 1.94l-4.84 3.5c-.54.4-1.42.4-1.96 0l-4.84-3.5A2.69 2.69 0 0 1 1 11.26v-7.8Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M11.4 6 7 10 5 8.18"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
