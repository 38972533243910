/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
    <Path
      d="m1 14.86 2.76-2.76m10.9 2.76-2.7-2.48m-8.2-.28 1.93 1.38 1.93-1.1 2.35 1.1 2-1.1m-8.21-.28L5.69 10l4.1.14 2.18 2.24"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M7.5 9c0-.5.16-1.43-2.99-2-.56-.3-1.01-.5-1.35-.93-.07-.1-.8-1.31 1.7-1.81m-1.14.36c-.5-.67-.56-2.05-.06-2.55 1.08-1.08 2.5-1.5 4-.5s1 2.5 0 2.5M8.5 2.5s2.5-.5 4 .5 2 4 .5 4.5-3.69-.45-4 0c-.25.36-1.3 1.6-1.5 2"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
