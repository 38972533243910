/* This file is autogenerated by @oui/cli */
import Svg, { Line, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
    <Line
      x1={3}
      y1={1}
      x2={3}
      y2={15}
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      vectorEffect="non-scaling-stroke"
    />
    <Line
      x1={13}
      y1={1}
      x2={13}
      y2={15}
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      vectorEffect="non-scaling-stroke"
    />
    <Line
      x1={4}
      y1={4}
      x2={12}
      y2={4}
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="square"
      vectorEffect="non-scaling-stroke"
    />
    <Line
      x1={4}
      y1={8}
      x2={12}
      y2={8}
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="square"
      vectorEffect="non-scaling-stroke"
    />
    <Line
      x1={4}
      y1={12}
      x2={12}
      y2={12}
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="square"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
