/* This file is autogenerated by @oui/cli */
import Svg, { Path, Rect, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={16} height={18} viewBox="0 0 16 18" fill="none" {...props}>
    <Path
      d="M11.35 8.54 6.9 10.32m4.45-1.78v6.18L6.9 16.5m4.45-7.96L9.86 8M6.9 10.32 2 8.54m4.9 1.78v6.18M2 8.54v5.73l4.9 2.23M2 8.54 6.9 7"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Rect
      x={7.97}
      y={1}
      width={6.96}
      height={6.96}
      rx={1}
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
