import type { SetData } from '../../content/quizSet/quizSetData';
import { getMuxUrlFromGCSUrl } from '../../muxUrl';
import { ContentType } from '../../types/graphql.generated';

// NB: We removed what was originally session 1 and moved up other sessions by 1 position.
// That is why the video URLs dont match up exactly with session numbers. The S1/*_Revised
// videos came in after the renaming and were properly named by session number so that is
// why only those videos have the right session number in the url
const STATIC_SESSION_01: SetData = {
  ID: 'understanding suicide',
  name: 'Understanding Suicide',
  description: 'Learn more about suicide',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S1/Video_1_1_211203.mp4',
        ),
        title: 'Adults Over the Age of 45',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S1/Video_1_2_211203.mp4',
        ),
        title: 'American Indians',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S1/Video_1_3_211203.mp4',
        ),
        title: 'Alaskan Natives',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S1/Video_1_4_211203.mp4',
        ),
        title: 'Other Groups',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S1/Video_1_5_211203.mp4',
        ),
        title: 'SSRIs',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S1/Video_1_6_211203.mp4',
        ),
        title: 'SNRIs',
        compulsory: true,
      },
    },
  ],
};

const STATIC_SESSION_02: SetData = {
  ID: 'suicide statistics',
  name: 'Suicide Statistics',
  description: 'Knowing the stats helps set context',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S2/Video_2_1_220110.mp4',
        ),
        title: 'Suicide Stats by Age',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S2/Video_2_2_211206.mp4',
        ),
        title: 'Suicide Stats by Ethnicity',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S2/Video_2_3_211206.mp4',
        ),
        title: 'Suicide Stats by Method',
        compulsory: true,
      },
    },
  ],
};

const STATIC_SESSION_03: SetData = {
  ID: 'stress & suicide',
  name: 'Stress & Suicide',
  description: 'Stress is a risk factor of suicide',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S3/Video_3_1_211206.mp4',
        ),
        title: 'Examples of Stress',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S3/Video_3_2_211206.mp4',
        ),
        title: 'Stress and Genes',
        compulsory: true,
      },
    },
  ],
};

const STATIC_SESSION_04: SetData = {
  ID: 'depression & suicide',
  name: 'Depression & Suicide',
  description: 'Depression is a risk factor of suicide',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S4/Video_4_1_211206.mp4',
        ),
        title: 'Types of Major Depression',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S4/Video_4_2_211206.mp4',
        ),
        title: 'Symptoms of Depression',
        compulsory: true,
      },
    },
  ],
};

const STATIC_SESSION_05: SetData = {
  ID: 'anxiety & suicide',
  name: 'Anxiety & Suicide',
  description: 'Anxiety is a risk factor of suicide',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S5/Video_5_1_211207.mp4',
        ),
        title: 'Types of Anxiety',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S5/Video_5_2_211207.mp4',
        ),
        title: 'Symptoms of Anxiety',
        compulsory: true,
      },
    },
  ],
};

const STATIC_SESSION_06: SetData = {
  ID: 'chronic illness & suicide',
  name: 'Chronic Illness & Suicide',
  description: 'Chronic illness is a risk factor of suicide',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S6/Video_6_1_211207.mp4',
        ),
        title: 'Types of Chronic Illness',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S6/Video_6_2_211207.mp4',
        ),
        title: 'Symptoms of Chronic Illness',
        compulsory: true,
      },
    },
  ],
};

const STATIC_SESSION_07: SetData = {
  ID: 'cancer & suicide',
  name: 'Cancer & Suicide',
  description: 'Cancer is a risk factor of suicide',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S7/Video_7_1_211207.mp4',
        ),
        title: 'Types of Cancer',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S7/Video_7_2_211207.mp4',
        ),
        title: 'Symptoms of Cancer',
        compulsory: true,
      },
    },
  ],
};

const STATIC_SESSION_08: SetData = {
  ID: 'schizophrenia & suicide',
  name: 'Schizophrenia & Suicide',
  description: 'Schizophrenia is a risk factor of suicide',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S8/Video_8_1_211207.mp4',
        ),
        title: 'Delusions',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S8/Video_8_2_211207.mp4',
        ),
        title: 'Negative Symptoms of Schizophrenia',
        compulsory: true,
      },
    },
  ],
};

const STATIC_SESSION_09: SetData = {
  ID: 'addiction & suicide',
  name: 'Addiction & Suicide',
  description: 'Addiction is a risk factor of suicide',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S9/Video_9_1_211207.mp4',
        ),
        title: 'Types of Addiction',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S9/Video_9_2_211207.mp4',
        ),
        title: 'Symptoms of Addiction',
        compulsory: true,
      },
    },
  ],
};

const STATIC_SESSION_10: SetData = {
  ID: 'common warning signs',
  name: 'Common Warning Signs',
  description: 'Go over common warning signs again',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S10/Video_10_1_211207.mp4',
        ),
        title: 'SSRIs',
        compulsory: true,
      },
    },
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S10/Video_10_2_211207.mp4',
        ),
        title: 'SNRIs',
        compulsory: true,
      },
    },
  ],
};

const STATIC_SESSION_11: SetData = {
  ID: 'common risk factors',
  name: 'Common Risk Factors',
  description: 'Review common risk factors',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S11/Video_11_1_211207.mp4',
        ),
        title: 'Common Risk Factors',
        compulsory: true,
      },
    },
  ],
};

const STATIC_SESSION_12: SetData = {
  ID: 'suicide survival stats',
  name: 'Suicide Survival Stats',
  description: 'Statistics about suicide survival',
  items: [
    {
      __typename: 'LegacyQuizSetItemVideo',
      props: {
        url: getMuxUrlFromGCSUrl(
          'https://storage.googleapis.com/asset.oui.dev/voiceover/static/S12/Video_12_1_211207.mp4',
        ),
        title: 'Suicide Stats by Age',
        compulsory: true,
      },
    },
  ],
};

export const STATIC_QUIZ_SET_DATA: Record<string, SetData[] | undefined> = {
  [ContentType.STATIC_01]: [STATIC_SESSION_01],
  [ContentType.STATIC_02]: [STATIC_SESSION_02],
  [ContentType.STATIC_03]: [STATIC_SESSION_03],
  [ContentType.STATIC_04]: [STATIC_SESSION_04],
  [ContentType.STATIC_05]: [STATIC_SESSION_05],
  [ContentType.STATIC_06]: [STATIC_SESSION_06],
  [ContentType.STATIC_07]: [STATIC_SESSION_07],
  [ContentType.STATIC_08]: [STATIC_SESSION_08],
  [ContentType.STATIC_09]: [STATIC_SESSION_09],
  [ContentType.STATIC_10]: [STATIC_SESSION_10],
  [ContentType.STATIC_11]: [STATIC_SESSION_11],
  [ContentType.STATIC_12]: [STATIC_SESSION_12],
};
