import { createElement } from 'react';

import { registerChatArtifactPreviewComponent } from '@oui/app-core/src/components/ChatArtifactPreview';

import { HopeKitArtifactPreview } from './components';

registerChatArtifactPreviewComponent(
  'HopeKit',
  ({ width, height, defaultHeight }) =>
    () =>
      createElement(HopeKitArtifactPreview, {
        width,
        height,
        defaultHeight,
      }),
);

export * from './screens';
export * from './components';
export * from './hooks';
export * from './types/navigation';
export * from './register';
