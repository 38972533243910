/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={17} height={18} viewBox="0 0 17 18" fill="none" {...props}>
    <Path
      d="M15.14 2.28c.52-.7.24-1.28-.63-1.28H1.89c-.87 0-1.15.57-.63 1.28L6.2 9.4v7.03c0 .45.33.68.74.51l2.52-1.03c.4-.17.74-.67.74-1.13V9.4l4.94-7.12Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
