/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={16} viewBox="0 0 18 16" fill="none" {...props}>
    <Path
      d="M7.14 12.06c.45-.2.78-.5 1.12-.86l.53-.55c.36-.37.55-.56.95-.84.1-.04.12-.28.05-.37-.12-.13-.25-.17-.43-.23 0 0-.84-.07-1.36-.21-.95-.26-2.26-1.14-2.26-1.14-.56-.38-.55-.85-.23-1.4.38-.66 1.01-1 1.7-1.25a9.8 9.8 0 0 1 2.2-.53 8.03 8.03 0 0 1 1.67-.1 3 3 0 0 1 1.81.74c.6.51.98 1.15 1.14 1.9.13.55.87 4.3.97 4.84"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M1 12s0 .05.18.18c.28.2.63.29.96.41a35.9 35.9 0 0 0 10.08 2.22c.98.05 1.96.07 2.94-.02.34-.04.37-.08.87-.24l.72-.17"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M1 11.83c.1-.3.36-.38.61-.49a7.22 7.22 0 0 1 2-.5c.67-.08 1.33-.1 2-.08.36.02.74-.02 1.1.12"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M10.4 7.89c.7.15 1.2.75 1.02 1.6-.1.48-.14.98-.2 1.48l-.15 1.05c-.02.28-.08.55-.14.83"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="m12.5 11.5 2 1c1 .5 1.31.55 1.9.88.29.17.53.36.6.7"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M15.2 2c.8.16 1.42.6 1.7 1.39.34.97-.18 2.14-1.32 2.5a1.9 1.9 0 0 1-2.05-.54c-.55-.6-.65-1.29-.4-2.05.3-.83 1.02-1.3 2.02-1.3"
      fill="currentColor"
    />
  </Svg>
);
export default SvgComponent;
