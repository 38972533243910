import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/core';
import batchPromises from 'batch-promises';
import * as ImagePicker from 'expo-image-picker';
import { useCallback, useContext, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { graphql } from '@oui/lib/src/graphql/tada';
import { ChatInputBaseProps, ChatInputMediaPickerProps, Kind } from '@oui/lib/src/types';
import { UploadType } from '@oui/lib/src/types/graphql.generated';

import { ActivityIndicator } from '../components/ActivityIndicator';
import ChatInputChoice from '../components/ChatInputChoice';
import { Text } from '../components/Text';
import { View } from '../components/View';
import { launchMediaPickerAsync } from '../lib/launchMediaPickerAsync';
import { SessionUri } from '../lib/resumableUpload';
import { ResumableUploadManagerContext } from '../lib/resumableUploadManager';

export const ChatInputMediaPickerUploadMutation = graphql(`
  mutation ChatInputMediaPickerUpload($input: ResumableAssetCreateInput!) {
    uploadResumableAsset(input: $input) {
      resumableUploadAssetURI
    }
  }
`);

export function ChatInputMediaPicker(props: ChatInputMediaPickerProps & ChatInputBaseProps) {
  const resumableUploadManager = useContext(ResumableUploadManagerContext);
  const [uploadsRemaining, setUploadsRemaining] = useState(0);
  const [retryCount, setRetryCount] = useState(0);
  const [allowSkip, setAllowSkip] = useState(props.allowSkip ?? false);
  const [uploadAsset] = useMutation(ChatInputMediaPickerUploadMutation);

  const label: { [key: number]: string } = {
    0: 'Add from gallery',
    1: 'Take photo with camera',
  };
  if (allowSkip) {
    label[2] = 'Not now';
  }

  const processAsset = useCallback(
    async (asset: ImagePicker.ImagePickerAsset) => {
      const { fileName, type, uri } = asset;
      const ID = uuid();

      const start = Date.now();
      Sentry.addBreadcrumb({
        message: 'ChatInputMediaPicker uploading asset',
        data: asset,
      });

      return uploadAsset({
        variables: {
          input: {
            context: props.context,
            key: `${props.keyPrefix}_${ID}`,
            uploadType: type === 'video' ? UploadType.VIDEO : UploadType.IMAGE,
            fileName: asset.fileName ?? 'unknown',
          },
        },
      })
        .then(async (assetResult) => {
          const id = await resumableUploadManager.uploadFile(
            uri,
            assetResult.data!.uploadResumableAsset.resumableUploadAssetURI as SessionUri,
          );
          return new Promise<void>((resolve) => {
            const remove = resumableUploadManager.addListener(id, ({ percent }) => {
              if (percent === 100) {
                resolve();
                remove();
              }
            });
          });
        })
        .catch((e) => {
          Sentry.withScope((scope) => {
            scope.setExtras({
              duration: Date.now() - start,
              fileName,
              type,
              uri,
            });
            Sentry.captureException(e);
          });
        })
        .then(() => {
          Sentry.addBreadcrumb({
            message: 'ChatInputMediaPicker upload complete',
            data: {
              duration: Date.now() - start,
              ...asset,
            },
          });
          setUploadsRemaining((i) => i - 1);
        });
    },
    [props.context, props.keyPrefix, uploadAsset, resumableUploadManager],
  );

  const onInput = props.onInput;
  const launchImagePicker = useCallback(
    async (mode: 'gallery' | 'camera') => {
      const result = await launchMediaPickerAsync(mode, {
        allowsMultipleSelection: true,
        mediaTypes: ['images', 'videos'],
        orderedSelection: true,
      });

      if (result.canceled && 'denied' in result && result.denied) {
        onInput({
          kind: Kind.InputMediaPicker,
          props: { result: 'denied' },
        });
      }

      if (result?.canceled) {
        setRetryCount((c) => c + 1);
        return;
      }

      if (result?.assets) {
        const assets = result.assets;
        if (!assets.length) {
          setAllowSkip(true);
          setRetryCount((c) => c + 1);
          return;
        }
        setUploadsRemaining(assets.length);
        batchPromises(3, assets, processAsset)
          .then(() => {
            onInput({
              kind: Kind.InputMediaPicker,
              props: { result: 'gallery' },
            });
          })
          .catch((e) => Sentry.captureException(e));
      }
    },
    [onInput, processAsset],
  );

  return (
    <View flex={uploadsRemaining ? 1 : 0}>
      {uploadsRemaining ? (
        <View row style={{ justifyContent: 'center', flex: 1 }} spacing={8}>
          <ActivityIndicator />
          <Text text={`Uploading (${uploadsRemaining} remaining)...`} />
        </View>
      ) : (
        <ChatInputChoice
          key={retryCount.toString()}
          onInput={(action) => {
            async function perform() {
              if (action.kind === Kind.InputChoice) {
                const choice = action.props[0];
                if (choice === 0) {
                  void launchImagePicker('gallery');
                } else if (choice === 1) {
                  void launchImagePicker('camera');
                } else if (choice === 2) {
                  props.onInput({
                    kind: Kind.InputMediaPicker,
                    props: { result: 'skip' },
                  });
                }
              }
            }

            perform().catch((e) => {
              Sentry.captureException(e);
              setRetryCount((c) => c + 1);
            });
          }}
          label={label}
        />
      )}
    </View>
  );
}

ChatInputMediaPicker.defaultProps = {
  onInput: () => {},
  disabled: false,
};

export default ChatInputMediaPicker;
