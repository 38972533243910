/* This file is autogenerated by @oui/cli */
import Svg, { Circle, Path, Rect, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      d="M6 7v0a5 5 0 0 0-5 5v0a5 5 0 0 0 5 5v0a5 5 0 0 0 5-5v-.5"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.13 12.5a2.8 2.8 0 0 0-2.65 1.8s.85-.59 2.65-.59c1.8 0 2.59.6 2.59.6s-.54-1.81-2.59-1.81Z"
      fill="currentColor"
    />
    <Circle
      cx={4.49}
      cy={10.57}
      r={0.5}
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.67}
      vectorEffect="non-scaling-stroke"
    />
    <Circle
      cx={7.51}
      cy={10.57}
      r={0.5}
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.67}
      vectorEffect="non-scaling-stroke"
    />
    <Rect
      x={7}
      y={1}
      width={10}
      height={10}
      rx={5}
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Circle
      cx={10.49}
      cy={4.57}
      r={0.5}
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.67}
      vectorEffect="non-scaling-stroke"
    />
    <Circle
      cx={13.51}
      cy={4.57}
      r={0.5}
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.67}
      vectorEffect="non-scaling-stroke"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.04 7.16c1.74.04 2.6-.55 2.6-.55s-.58 1.8-2.62 1.75C9.98 8.32 9.39 6.5 9.39 6.5s.83.62 2.65.66Z"
      fill="currentColor"
    />
  </Svg>
);
export default SvgComponent;
