import { useMutation } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import batchPromises from 'batch-promises';
import * as FileSystem from 'expo-file-system';
import { Image } from 'expo-image';
import * as ImagePicker from 'expo-image-picker';
import equals from 'fast-deep-equal';
import { produce } from 'immer';
import chunk from 'lodash/chunk';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Animated, Keyboard, Platform, StyleSheet } from 'react-native';
import { useDebounce } from 'use-debounce';
import { v4 as uuid } from 'uuid';

import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import { Button } from '@oui/app-core/src/components/Button';
import { Divider } from '@oui/app-core/src/components/Divider';
import { HeaderButtons, HeaderItem } from '@oui/app-core/src/components/HeaderButtons';
import { Icon } from '@oui/app-core/src/components/Icon';
import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { TextInput } from '@oui/app-core/src/components/TextInput';
import { View } from '@oui/app-core/src/components/View';
import { IS_PRODUCTION } from '@oui/app-core/src/constants';
import { useAddAction } from '@oui/app-core/src/hooks/practices';
import { useForm } from '@oui/app-core/src/hooks/useForm';
import { useWindowDimensions } from '@oui/app-core/src/hooks/useWindowDimensions';
import { classifyAsset } from '@oui/app-core/src/lib/classifyAsset';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { launchMediaPickerAsync } from '@oui/app-core/src/lib/launchMediaPickerAsync';
import { resumableUploadManager, SessionUri } from '@oui/app-core/src/lib/resumableUploadManager';
import Sentry from '@oui/app-core/src/sentry';
import { Theme, useTheme } from '@oui/app-core/src/styles';
import { graphql } from '@oui/lib/src/graphql/tada';
import { ImageObject, searchImages } from '@oui/lib/src/searchImages';
import { times } from '@oui/lib/src/times';
import { ActionType } from '@oui/lib/src/types/graphql.generated';

import HopeKitEmpty from '../assets/hopeKitEmpty.svg';
import { ImageTile, useHopeKitContext } from '../components';
import { Quote, QuoteBox } from '../components/HopeKitItem';
import { Swiper } from '../components/Swiper';
import { StackScreenProps } from '../types/navigation';

export const AddHopeKitImageMutation = graphql(`
  mutation AddHopeKitImage($input: AddHopeKitImageInput!) {
    addHopeKitAsset: addHopeKitImage(input: $input) {
      hopeKitImage {
        __typename
        hopeKitItemID
        reason
        url
      }
      uploadSession {
        url
      }
    }
  }
`);

export const AddHopeKitVideoMutation = graphql(`
  mutation AddHopeKitVideo($input: AddHopeKitVideoInput!) {
    addHopeKitAsset: addHopeKitVideo(input: $input) {
      hopeKitVideo {
        __typename
        hopeKitItemID
        reason
        url
      }
      uploadSession {
        url
      }
    }
  }
`);

export const AddHopeKitQuoteMutation = graphql(`
  mutation AddHopeKitQuote($input: AddHopeKitQuoteInput!) {
    addHopeKitQuote(input: $input) {
      hopeKitQuote {
        __typename
        hopeKitItemID
        reason
        text
        author
      }
    }
  }
`);

const TOP_VIEW_HEIGHT = 375;

type HopeKitEntry<T> = T & { reason?: string };

function EditQuote(props: {
  value: Quote;
  onChangeValue: (value: Quote) => void;
  onRemove: () => void;
}) {
  const { clear, data, bind } = useForm(props.value);
  const onChangeValueRef = useRef(props.onChangeValue);
  onChangeValueRef.current = props.onChangeValue;
  const { $t } = useI18n();

  useEffect(() => {
    if (!equals(data, props.value)) {
      const newData = data.ID ? data : { ...data, ID: uuid() };
      onChangeValueRef.current(newData);
    }
  }, [props.value, data]);

  return (
    <View spacing={20}>
      <View>
        <TextInput
          multiline
          {...bind('text', {
            label: $t({
              id: 'AddHopeKit_editQuote_textLabel',
              defaultMessage: 'Quote',
            }),
          })}
          placeholder={$t({
            id: 'AddHopeKit_editQuote_textPlaceholder',
            defaultMessage: "What's the quote?",
          })}
          // onBlur={submit}
          inputStyle={{ minHeight: 100 }}
        />
        {props.value.ID ? (
          <View style={{ position: 'absolute', right: 0, top: 0 }}>
            <Icon
              name="close"
              size={14}
              onPress={() => {
                props.onRemove();
                clear();
              }}
              aria-label={$t({
                id: 'AddHopeKit_editQuote_removeButton',
                defaultMessage: 'Remove',
              })}
            />
          </View>
        ) : null}
      </View>
      <TextInput
        placeholder={$t({
          id: 'AddHopeKit_editQuote_authorPlaceholder',
          defaultMessage: 'Who said this quote?',
        })}
        {...bind('author', {
          label: $t({
            id: 'AddHopeKit_editQuote_authorLabel',
            defaultMessage: 'Author',
          }),
        })}
      />
    </View>
  );
}

function renderPreview({
  item,
  small,
  theme,
}: {
  item: SwiperItemType;
  small?: boolean;
  theme: Theme;
}) {
  switch (item.type) {
    case 'imageSearch': {
      return (
        <Image
          source={{ uri: item.value.thumbnailUrl }}
          style={{ width: '100%', height: '100%' }}
          contentFit="cover"
          contentPosition="center"
        />
      );
    }
    case 'asset': {
      const { isVideo } = classifyAsset(item.value.uri);
      return (
        <View style={{ width: '100%', height: '100%' }}>
          {isVideo ? (
            <View
              style={{
                zIndex: 1,
                ...StyleSheet.absoluteFillObject,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <View
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 75,
                  height: 75,
                  backgroundColor: 'rgba(255,255,255,0.8)',
                  borderRadius: 75 / 2,
                }}
              >
                <Icon color={theme.color.primary100} name="play" size={40} />
              </View>
            </View>
          ) : null}
          <Image
            source={{ uri: item.value.uri }}
            contentFit="cover"
            contentPosition="center"
            style={{ width: '100%', height: '100%' }}
          />
        </View>
      );
    }
    case 'quote': {
      return <QuoteBox quote={item.value} style={{ height: '100%' }} preview={small} />;
    }
  }
}

export type SwiperItemType =
  | { type: 'asset'; value: HopeKitEntry<ImagePicker.ImagePickerAsset> }
  | { type: 'imageSearch'; value: HopeKitEntry<ImageObject> }
  | { type: 'quote'; value: HopeKitEntry<Quote> };

export function AddHopeKit() {
  const { $t } = useI18n();
  const { width } = useWindowDimensions();
  const { navigate, goBack, setOptions } =
    useNavigation<StackScreenProps<'AddHopeKit'>['navigation']>();
  const { params } = useRoute<StackScreenProps<'AddHopeKit'>['route']>();
  const [selections, setSelections] = useState<{
    assetInfos: HopeKitEntry<ImagePicker.ImagePickerAsset>[];
    imageSearch: HopeKitEntry<ImageObject>[];
    quotes: HopeKitEntry<Quote>[];
  }>({ assetInfos: [], imageSearch: [], quotes: [] });
  const activeTab = params.method || 'gallery';
  const [currentStep, setCurrentStep] = useState<'select' | 'reasons'>('select');
  const [currentReasonsIndex, setCurrentReasonsIndex] = useState(0);
  const { theme } = useTheme();
  const [imageSearch, setImageSearch] = useState('');
  const [imageSearchResults, setImageSearchResults] = useState<ImageObject[]>([]);
  const [imageSearchDebounced] = useDebounce(imageSearch, 300);
  const [addHopeKitImage] = useMutation(AddHopeKitImageMutation);
  const [addHopeKitVideo] = useMutation(AddHopeKitVideoMutation);
  const [addHopeKitQuote] = useMutation(AddHopeKitQuoteMutation);
  const [addAction] = useAddAction();
  const hopeKitContext = useHopeKitContext();

  const [searchingImages, setSearchingImages] = useState(false);
  useEffect(() => {
    if (imageSearchDebounced) {
      setSearchingImages(true);
      searchImages(imageSearchDebounced)
        .then((result) => {
          setSearchingImages(false);
          if (result && result._type === 'Images') {
            setImageSearchResults(
              result.value.filter((res) =>
                // on iOS NSAppTransportSecurity setting prevents us from loading any http content
                // so we ensure our results are https only
                res.contentUrl
                  ? res.contentUrl.startsWith('https://')
                  : res.thumbnailUrl?.startsWith('https://'),
              ),
            );
          } else if (result?._type === 'ErrorResponse') {
            Sentry.captureMessage('searchImages error', { extra: result });
          }
        })
        .catch((e) => {
          Sentry.captureMessage('searchImages error', { extra: e });
        });
    }
  }, [imageSearchDebounced]);

  const { swiperItems, editSwiperItemReason } = useMemo(() => {
    return {
      swiperItems: [
        ...selections.assetInfos.map((value) => ({ type: 'asset', value })),
        ...selections.imageSearch.map((value) => ({ type: 'imageSearch', value })),
        ...selections.quotes.map((value) => ({ type: 'quote', value })),
      ] as Array<SwiperItemType>,
      editSwiperItemReason: (index: number, reason: string) => {
        let category: keyof typeof selections = 'assetInfos';
        if (index >= selections.assetInfos.length) {
          category = 'imageSearch';
          index = index - selections.assetInfos.length;
          if (index >= selections.imageSearch.length) {
            category = 'quotes';
            index = index - selections.imageSearch.length;
          }
        }
        const newSelections = produce(selections, (draft) => {
          draft[category][index].reason = reason;
        });
        setSelections(newSelections);
      },
    };
  }, [selections]);

  const addItems = useCallback(async () => {
    try {
      let partialFailure = false;
      await batchPromises(3, swiperItems, async (item) => {
        try {
          switch (item.type) {
            case 'asset': {
              if (item.value.type === 'image') {
                const r = await addHopeKitImage({
                  variables: {
                    input: {
                      hopeKitImage: {
                        reason: item.value.reason,
                      },
                      uploadContentType: item.value.mimeType || 'application/octet-stream',
                    },
                  },
                });
                if (r.data) {
                  const uploadSessionUri = r.data.addHopeKitAsset.uploadSession.url;
                  return resumableUploadManager.uploadFile(
                    item.value.uri,
                    uploadSessionUri as SessionUri,
                    {
                      cacheKey: r.data.addHopeKitAsset.hopeKitImage.hopeKitItemID,
                      metaData: item,
                    },
                  );
                }
                return;
              }

              const r = await addHopeKitVideo({
                variables: {
                  input: {
                    hopeKitVideo: {
                      reason: item.value.reason,
                    },
                    uploadContentType: item.value.mimeType || 'application/octet-stream',
                  },
                },
              });
              if (r.data) {
                const uploadSessionUri = r.data.addHopeKitAsset.uploadSession.url;
                return resumableUploadManager.uploadFile(
                  item.value.uri,
                  uploadSessionUri as SessionUri,
                  {
                    cacheKey: r.data.addHopeKitAsset.hopeKitVideo.hopeKitItemID,
                    metaData: item,
                  },
                );
              }
              return;
            }
            case 'imageSearch': {
              if (Platform.OS === 'web') {
                Sentry.captureException('AddHopeKit imageSearch not supported on web');
                return;
              }
              const extension = item.value.encodingFormat ?? 'jpg';

              let fallbackToThumbnail = false;
              const source = item.value.contentUrl ?? item.value.thumbnailUrl!;
              const destination =
                FileSystem.cacheDirectory +
                `hopeKitImageSearchDownload-${item.value.imageId}.${extension}`;

              let { uri } = await FileSystem.downloadAsync(source, destination);
              const preview = await FileSystem.readAsStringAsync(destination, {
                length: 1,
                position: 0,
                encoding: 'base64',
              });
              // check if the first byte of the file is angle bracket: <
              // If so, we likely got an HTML response from the server due to cloudflare captcha
              // or other server issue. In that case, fallback to downloading the thumbnailUrl
              if (preview === 'PA==') {
                fallbackToThumbnail = true;
                uri = (await FileSystem.downloadAsync(item.value.thumbnailUrl!, destination)).uri;
              }

              const r = await addHopeKitImage({
                variables: {
                  input: {
                    hopeKitImage: {
                      reason: item.value.reason,
                    },
                    uploadContentType: `image/${extension}`,
                  },
                },
              });
              if (r.data) {
                const uploadSessionUri = r.data.addHopeKitAsset.uploadSession.url;
                return resumableUploadManager.uploadFile(uri, uploadSessionUri as SessionUri, {
                  cacheKey: r.data.addHopeKitAsset.hopeKitImage.hopeKitItemID,
                  metaData: produce(item, (draft) => {
                    if (fallbackToThumbnail) {
                      draft.value.contentUrl = item.value.thumbnailUrl;
                    }
                  }),
                });
              }
              return;
            }
            case 'quote': {
              return addHopeKitQuote({
                variables: {
                  input: {
                    hopeKitQuote: {
                      text: item.value.text,
                      reason: item.value.reason,
                      author: item.value.author,
                    },
                  },
                },
              });
            }
          }
        } catch (e) {
          partialFailure = true;
          Sentry.captureException(e, {
            extra: IS_PRODUCTION ? { item: { type: item.type } } : { item },
          });
          return;
        }
      });

      void addAction({
        actionType: ActionType.HOPE_KIT_ADD,
      });
      navigate({
        name: 'HopeKit',
        params: partialFailure ? { showUploadFailure: 'true' } : {},
        merge: true, // to preserve artifact request param
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [navigate, addAction, swiperItems, addHopeKitImage, addHopeKitVideo, addHopeKitQuote]);

  const nextReason = useCallback(() => {
    const isLastItem = currentReasonsIndex === swiperItems.length - 1;
    if (isLastItem) {
      return addItems();
    } else {
      setCurrentReasonsIndex((i) => Math.min(i + 1, swiperItems.length - 1));
    }
    return;
  }, [swiperItems.length, addItems, currentReasonsIndex]);

  const prevReason = useCallback(() => {
    if (currentReasonsIndex === 0) {
      setCurrentStep('select');
    } else {
      setCurrentReasonsIndex((i) => Math.max(0, i - 1));
    }
  }, [currentReasonsIndex]);

  const hopeKitName = hopeKitContext.name;
  useLayoutEffect(() => {
    setOptions({
      title: $t(
        { id: 'AddHopeKit_title', defaultMessage: 'Add to {hopeKitName}' },
        { hopeKitName },
      ),
    });
  }, [setOptions, $t, hopeKitName]);

  const swiperItemsLength = swiperItems.length;
  useEffect(() => {
    if (currentStep === 'select') {
      setOptions({
        headerLeft: () => {
          return (
            <HeaderButtons left>
              <HeaderItem
                iconName="close"
                title=""
                onPress={goBack}
                color={theme.color.primary100}
                aria-label={$t({ id: 'AddHopeKit_backButton', defaultMessage: 'Close' })}
              />
            </HeaderButtons>
          );
        },
        headerRight: () => {
          return (
            <HeaderButtons>
              <Button
                text={$t({ id: 'AddHopeKit_nextButton', defaultMessage: 'Next' })}
                onPress={() => {
                  // Debugging info for https://sentry.io/organizations/oui/issues/2503301525/?environment=production&project=1512711&referrer=alert_email
                  Sentry.addBreadcrumb({
                    message: 'AddHopeKit advance to reasons',
                    data: { length: swiperItemsLength },
                  });
                  setCurrentStep('reasons');
                }}
                disabled={swiperItemsLength === 0}
                testID="AddHopeKit_nextButton"
                size="small"
              />
            </HeaderButtons>
          );
        },
      });
    } else {
      setOptions({
        headerLeft: () => {
          return (
            <HeaderButtons left>
              <HeaderItem
                aria-label={$t({
                  id: 'AddHopeKit_previousButton',
                  defaultMessage: 'Previous',
                })}
                iconName="caret-left"
                title=""
                onPress={prevReason}
                color={theme.color.primary100}
              />
            </HeaderButtons>
          );
        },
        headerRight: () => {
          return (
            <HeaderButtons>
              <Button
                text={$t({ id: 'AddHopeKit_nextButton', defaultMessage: 'Next' })}
                onPress={nextReason}
                testID="AddHopeKit_nextButton"
                size="small"
              />
            </HeaderButtons>
          );
        },
      });
    }
  }, [currentStep, setOptions, nextReason, prevReason, goBack, swiperItemsLength, $t, theme]);

  const renderItem = useCallback(
    (data: { item: (typeof swiperItems)[number]; index: number }) => {
      return renderPreview({ ...data, theme });
    },
    [theme],
  );

  const launchImagePicker = useCallback(async (mode: 'gallery' | 'camera') => {
    const result = await launchMediaPickerAsync(mode, {
      allowsMultipleSelection: true,
      mediaTypes: ['images', 'videos'],
      orderedSelection: true,
    });
    if (result && !result.canceled && result.assets) {
      setSelections((selections) => ({
        ...selections,
        assetInfos: [...selections.assetInfos, ...result.assets!.map((imageInfo) => imageInfo)],
      }));
    }
  }, []);

  if (currentStep === 'reasons') {
    return (
      <ScrollView
        style={{ backgroundColor: theme.color.gray800, flex: 1 }}
        contentContainerStyle={{ flexGrow: 1, padding: 20 }}
      >
        <View style={{ height: 144, width: 144, marginBottom: 20, alignSelf: 'center' }}>
          {renderPreview({ item: swiperItems[currentReasonsIndex], small: true, theme })}
        </View>
        <TextInput
          testID={`AddHopeKit_reasonInput_${currentReasonsIndex}`}
          label={hopeKitContext.question}
          value={swiperItems[currentReasonsIndex].value.reason ?? ''}
          onChangeValue={(val) => {
            editSwiperItemReason(currentReasonsIndex, val);
          }}
          multiline
          inputStyle={{ minHeight: 140 }}
          placeholder={$t({
            id: 'AddHopeKit_reasonPlaceholder',
            defaultMessage:
              'Does this inspire you? Motivate you? Give you a reason to keep living?',
          })}
        />
        <Button
          variant="text"
          text={$t({ id: 'AddHopeKit_skipReasonButton', defaultMessage: 'Skip' })}
          onPress={nextReason}
          alignSelf="center"
        />
      </ScrollView>
    );
  }

  const selectedItem = swiperItems.length ? (
    <Swiper
      indicator="overlay"
      width={width}
      style={{ height: TOP_VIEW_HEIGHT }}
      data={swiperItems}
      renderItem={renderItem}
    />
  ) : (
    <View
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        backgroundColor: 'rgba(232, 174, 235, 0.15)',
      }}
    >
      {hopeKitContext.images?.emptyStateSearch ?? (
        <Image source={HopeKitEmpty} style={{ width: 204, height: 131 }} />
      )}
    </View>
  );

  if (activeTab === 'imageSearch') {
    return (
      <ScrollView
        style={{ backgroundColor: theme.color.gray800, flex: 1 }}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <View style={{ paddingHorizontal: 20, paddingVertical: 5 }}>
          <View>
            <View
              style={{
                position: 'absolute',
                top: '50%',
                left: 12,
                transform: [{ translateY: -10 }],
                zIndex: 2,
              }}
              aria-hidden
            >
              {searchingImages ? (
                <ActivityIndicator />
              ) : (
                <Icon name="search" color={theme.color.gray600} />
              )}
            </View>
            <TextInput
              autoFocus
              value={imageSearch}
              onChangeValue={setImageSearch}
              inputStyle={{ paddingLeft: 36, paddingVertical: 12 }}
              style={{ zIndex: 1 }}
              testID="AddHopeKit_imageSearchInput"
              role="searchbox"
            />
          </View>
        </View>
        <View style={{ gap: 4 }}>
          {chunk(imageSearchResults, 3).map((row, index) => (
            <View row style={{ backgroundColor: 'white', width: '100%', gap: 3 }} key={row[0]?.id}>
              {times(3, (i) => {
                const item = row[i];
                if (!item) return <View style={{ flex: 1 }} />;

                const existingIndex = selections.imageSearch.findIndex(
                  (info) => info.imageId === item.imageId,
                );
                const isSelected = existingIndex !== -1;
                return (
                  <View style={{ flex: 1, aspectRatio: 1 }} key={item.imageId}>
                    <ImageTile
                      key={item.imageId}
                      item={{ uri: item.thumbnailUrl!, type: 'image' }}
                      selectImage={async () => {
                        const newSelections = produce(selections, (draft) => {
                          if (existingIndex === -1) {
                            draft.imageSearch.push(item);
                          } else {
                            draft.imageSearch.splice(existingIndex, 1);
                          }
                        });
                        setSelections(newSelections);
                        return { isAllowed: true };
                      }}
                      badgeColor={theme.color.primary100}
                      index={index + i}
                      selected={isSelected}
                      selectedItemCount={selections.assetInfos.length + existingIndex + 1}
                    />
                  </View>
                );
              })}
            </View>
          ))}
        </View>
      </ScrollView>
    );
  }

  if (activeTab === 'quote') {
    return (
      <ScrollView style={{ flex: 1 }} testID="AddHopeKit_scrollView">
        <Animated.View
          onStartShouldSetResponder={() => {
            Keyboard.dismiss();
            return false;
          }}
          style={{
            height: TOP_VIEW_HEIGHT,
            zIndex: 2,
          }}
        >
          {selectedItem}
        </Animated.View>
        {(selections.quotes.length ? selections.quotes : [{ ID: '', text: '' }]).map(
          (row, index) => (
            <>
              <View style={{ padding: 20 }} testID={`AddHopeKit_editQuote_${index}`} key={index}>
                <EditQuote
                  onRemove={() => {
                    const newSelections = produce(selections, (draft) => {
                      draft.quotes.splice(index, 1);
                    });
                    setSelections(newSelections);
                  }}
                  value={row}
                  onChangeValue={(quote) => {
                    const newSelections = produce(selections, (draft) => {
                      draft.quotes[index] = quote;
                    });
                    setSelections(newSelections);
                  }}
                />
              </View>
              <Divider />
            </>
          ),
        )}
        <View style={{ padding: 20 }} key={selections.quotes.length.toString()} spacing={12}>
          <Button
            alignSelf="center"
            icon="plus"
            variant="text"
            text={$t({
              id: 'AddHopeKit_addQuoteButton',
              defaultMessage: 'Add another quote',
            })}
            onPress={() => {
              const newSelections = produce(selections, (draft) => {
                draft.quotes.push({ text: '', ID: uuid() });
              });
              setSelections(newSelections);
            }}
          />
        </View>
      </ScrollView>
    );
  }

  return (
    <View style={{ flex: 1, gap: 20 }}>
      <Animated.View
        onStartShouldSetResponder={() => {
          Keyboard.dismiss();
          return false;
        }}
        style={{
          height: TOP_VIEW_HEIGHT,
          zIndex: 2,
        }}
      >
        {selectedItem}
      </Animated.View>
      <View style={{ flex: 1, padding: 20, gap: 20 }}>
        <Button
          alignSelf="center"
          text={$t({
            id: 'AddHopeKit_chooseImageFileButton',
            defaultMessage: 'Choose from gallery',
          })}
          onPress={() => launchImagePicker('gallery')}
          icon="image"
          testID="AddHopeKit_chooseImageFileButton"
          variant="solid"
        />
        <Button
          alignSelf="center"
          text={$t({
            id: 'AddHopeKit_capturePhotoButton',
            defaultMessage: 'Take photo',
          })}
          onPress={() => launchImagePicker('camera')}
          icon="camera"
          testID="AddHopeKit_capturePhotoButton"
          variant="solid"
        />
      </View>
    </View>
  );
}
