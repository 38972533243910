/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={16} height={18} viewBox="0 0 16 18" fill="none" {...props}>
    <Path
      d="M10.6 1H5a2 2 0 0 0-2 2v12c0 1.1.9 2 2 2h5.6a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.8 15.4a1.2 1.2 0 1 0 0-2.4 1.2 1.2 0 0 0 0 2.4Z"
      fill="currentColor"
    />
    <Path
      d="M7 3.4h1.6"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
