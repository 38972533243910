/* This file is autogenerated by @oui/cli */
import Svg, { Ellipse, Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={16} viewBox="0 0 18 16" fill="none" {...props}>
    <Path
      d="M8.24 6.32H2.91A1.9 1.9 0 0 0 1 8.22v0c0 1.06.85 1.91 1.9 1.91h5.34m6.1-3.8h.76c1.05 0 1.9.85 1.9 1.9v0a1.9 1.9 0 0 1-1.9 1.9h-.76"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Ellipse
      cx={11.29}
      cy={8.23}
      rx={3.43}
      ry={3.43}
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
