// Use a separate file from useCurrentUser.ts to prevent circular import with lib/auth.ts
import { graphql } from '@oui/lib/src/graphql/tada';

export const CurrentUserFragment = graphql(`
  fragment CurrentUser on User @_unmask {
    ID
    attributes {
      admin
      testUser
    }
    username
    createdAt
    email
    givenName
    familyName
    phone
    role {
      ID
      roleType
      organization {
        ID
        isTrialOrganization
      }
      product {
        ID
        slug
      }
      productStatic
      productConfig {
        ... on RoleAvivaConfig {
          onboardingVariant
          productVersion
        }
      }
    }
  }
`);

export const CurrentUserQueryName = 'CurrentUser';
export type CurrentUserQueryName = typeof CurrentUserQueryName;
export const CurrentUserQuery = graphql(
  `
    query CurrentUser {
      user {
        ...CurrentUser
      }
    }
  `,
  [CurrentUserFragment],
);
