import { useNavigation, useRoute } from '@react-navigation/native';
import { Image } from 'expo-image';
import { LinearGradient } from 'expo-linear-gradient';
import hexToRgba from 'hex-to-rgba';
import { produce } from 'immer';
import { memo, useEffect, useState } from 'react';
import { Platform, StyleSheet } from 'react-native';
import { TouchableOpacity as GHTouchableOpacity } from 'react-native-gesture-handler';

import { useAccessibilityContext } from '@oui/app-core/src/components/AccessibilityContext';
import { Button } from '@oui/app-core/src/components/Button';
import { CardStack, FlippableCard as CardStackCard } from '@oui/app-core/src/components/CardStack';
import { ConfirmationModal } from '@oui/app-core/src/components/ConfirmationModal';
import { OverflowMenu, OverflowMenuOption } from '@oui/app-core/src/components/OverflowMenu';
import { ReviewCompleteCard } from '@oui/app-core/src/components/ReviewCompleteCard';
import { RoundedSection } from '@oui/app-core/src/components/RoundedSection';
import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useAddAction, useCopingCards } from '@oui/app-core/src/hooks/practices';
import { useArtifactRequest } from '@oui/app-core/src/hooks/useArtifactResult';
import { useI18n } from '@oui/app-core/src/lib/i18n';

import Background from '@src/assets/copingCards/background.svg';
import CreateCardAccent from '@src/assets/copingCards/createCardAccent.svg';
import { ActivityIndicator } from '@src/components/ActivityIndicator';
import { ActionType, NumberParam, StackScreenProps } from '@src/types';

const TouchableOpacity = Platform.select({ default: GHTouchableOpacity, web: undefined });

const LinearGradientMemo = memo(() => (
  <LinearGradient
    colors={[hexToRgba('#2461c3', 0), '#2461c3']}
    style={[StyleSheet.absoluteFillObject, { top: 40 }]}
    start={[0, 0]}
    end={[0, 1]}
  />
));

export function CopingCards(props: { preview?: boolean }) {
  const { goBack, navigate } = useNavigation<StackScreenProps<'CopingCards'>['navigation']>();
  const [showConfirmationModal, setShowConfirmationModal] = useState<number | null>(null);
  const { loading, data: cards, saveCopingCards } = useCopingCards();
  const [cardStackKey, setCardStackKey] = useState('0');
  const [addAction] = useAddAction();
  const route = useRoute<StackScreenProps<'CopingCards'>['route']>();
  const { isScreenReaderEnabled } = useAccessibilityContext();
  const { $t } = useI18n();

  const hasData = !!cards.length;
  useEffect(() => {
    if (hasData) {
      void addAction({
        actionType: ActionType.COPING_CARD_REVIEW,
      });
    }
  }, [hasData, addAction]);

  const isEmpty = cards.length === 0;
  useArtifactRequest(route.name, !isEmpty);

  function addCards() {
    navigate('EditCopingCards', {});
  }

  function deleteCopingCard(index: number) {
    const newData = produce(cards, (draft) => {
      draft.splice(index, 1);
    });
    return saveCopingCards(newData);
  }

  return (
    <View style={{ flex: 1 }}>
      <View
        pointerEvents="none"
        style={[
          {
            position: 'absolute',
            bottom: 0,
            right: 0,
            left: 0,
            zIndex: 1,
          },
        ]}
      >
        <Image source={Background} style={{ width: '100%', aspectRatio: 354 / 112 }} />
      </View>
      <RoundedSection
        color="white"
        secondaryColor="#9db7e0"
        bottomSafeAreaColor="#2461c3"
        title={$t({ id: 'CopingCards_title', defaultMessage: 'Coping cards' })}
        preview={props.preview}
      >
        <LinearGradientMemo />
        {cards.length ? (
          <CardStack
            hideNextButtonIndexes={[cards.length]}
            pageColor="white"
            key={cardStackKey}
            hasCompleteCard
          >
            {cards.map((c, i) => {
              const menu = (
                <View style={{ position: 'absolute', right: 0 }}>
                  <OverflowMenu
                    triggerTestID="CopingCards_moreButton"
                    TriggerTouchableComponent={TouchableOpacity}
                  >
                    <OverflowMenuOption
                      text={$t({ id: 'CopingCards_editButton', defaultMessage: 'Edit' })}
                      icon="edit"
                      testID="CopingCards_editButton"
                      onPress={() => {
                        navigate('EditCopingCards', {
                          copingCardIndex: i.toString() as unknown as NumberParam,
                        });
                      }}
                    />
                    <OverflowMenuOption
                      icon="bin"
                      text={$t({ id: 'CopingCards_deleteButton', defaultMessage: 'Delete' })}
                      testID="CopingCards_deleteButton"
                      onPress={() => setShowConfirmationModal(i)}
                    />
                  </OverflowMenu>
                </View>
              );
              return (
                <CardStackCard
                  key={c.frontText}
                  frontChildren={
                    <>
                      <View spacing={12}>
                        <Text
                          text={$t({
                            id: 'CopingCards_card_frontHeader',
                            defaultMessage: 'Thought or situation',
                          })}
                          weight="semibold"
                          textAlign="center"
                          role="heading"
                        />
                        <Text text={c.frontText!} textAlign="center" size={21} weight="semibold" />
                      </View>
                      {menu}
                    </>
                  }
                  backChildren={
                    <>
                      <View spacing={12}>
                        <Text
                          text={$t({ id: 'CopingCards_card_backHeader', defaultMessage: 'Belief' })}
                          weight="semibold"
                          textAlign="center"
                          role="heading"
                        />
                        <Text text={c.backText!} textAlign="center" size={21} weight="semibold" />
                      </View>
                      {/* dont need to render two menus because both sides are displayed together when isScreenReaderEnabled */}
                      {isScreenReaderEnabled ? null : menu}
                    </>
                  }
                />
              );
            })}
            <ReviewCompleteCard onDone={goBack} onReset={() => setCardStackKey((k) => k + '1')} />
          </CardStack>
        ) : loading ? (
          <ActivityIndicator />
        ) : (
          <View
            style={{
              backgroundColor: 'white',
              borderRadius: 30,
              alignSelf: 'stretch',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 30,
            }}
          >
            <Text
              text={$t({
                id: 'CopingCards_createCardsTitle',
                defaultMessage: 'Create your coping cards',
              })}
              weight="semibold"
              size={21}
              textAlign="center"
              style={{ marginBottom: 18 }}
            />
            <Button
              text={$t({ id: 'CopingCards_addCardsButton', defaultMessage: 'Add cards' })}
              icon="plus"
              onPress={addCards}
              alignSelf="center"
              testID="CopingCards_addCardsButton"
            />
            <Image source={CreateCardAccent} style={{ width: '100%', aspectRatio: 248 / 38 }} />
          </View>
        )}
        {cards.length ? (
          <View flex={1} style={{ justifyContent: 'flex-end' }} pointerEvents="box-none">
            <Button
              testID="CopingCards_addMoreCardsButton"
              variant="text"
              color="white"
              text={$t({ id: 'CopingCards_addMoreCardsButton', defaultMessage: 'Add cards' })}
              alignSelf="center"
              icon="plus"
              onPress={addCards}
            />
          </View>
        ) : null}
        {typeof showConfirmationModal === 'number' ? (
          <ConfirmationModal
            visible={typeof showConfirmationModal === 'number'}
            onCancel={() => setShowConfirmationModal(null)}
            onConfirm={() => {
              return deleteCopingCard(showConfirmationModal).then(() => {
                setShowConfirmationModal(null);
              });
            }}
            cancelText={$t({ id: 'CopingCards_cancelDeleteButton', defaultMessage: "No, don't" })}
            confirmText={$t({
              id: 'CopingCards_confirmDeleteButton',
              defaultMessage: 'Yes, delete',
            })}
            confirmTestID="CopingCards_deleteConfirmationButton"
            title={$t({ id: 'CopingCards_deleteTitle', defaultMessage: 'Delete?' })}
            description={$t({
              id: 'CopingCards_delete',
              defaultMessage: "Please confirm you'd like to delete this coping card.",
            })}
          />
        ) : null}
      </RoundedSection>
    </View>
  );
}
