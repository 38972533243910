/* This file is autogenerated by @oui/cli */
import Svg, { Circle, Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={21} height={21} viewBox="0 0 21 21" fill="none" {...props}>
    <Circle
      cx={10.13}
      cy={10.13}
      r={9.13}
      stroke="currentColor"
      strokeWidth={2}
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M10 13.1 7.56 14.4c-.54.28-.9.02-.8-.58l.47-2.7-1.97-1.92c-.44-.42-.3-.84.3-.93l2.73-.4L9.5 5.42c.27-.55.71-.55.98 0l1.22 2.46 2.73.4c.6.08.74.5.3.92l-1.97 1.92.46 2.7c.1.6-.25.86-.79.57L10 13.12Z"
      fill="currentColor"
    />
  </Svg>
);
export default SvgComponent;
