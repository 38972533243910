/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={19} height={17} viewBox="0 0 19 17" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.46 1.64c.48-.85 1.27-.85 1.75 0l7.26 12.76c.48.86.07 1.55-.93 1.55H2.13c-1 0-1.41-.7-.93-1.55L8.46 1.64Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.34 13.65a.86.86 0 1 0 0-1.73.86.86 0 0 0 0 1.73Z"
      fill="currentColor"
    />
    <Path
      d="M9.34 5.34v4.6"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
