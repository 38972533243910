import {
  ComponentProps,
  forwardRef,
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Keyboard,
  Platform,
  TextInput as RNTextInput,
  StyleProp,
  TextInputProps,
  TextStyle,
  ViewStyle,
} from 'react-native';

import { useAccessibilityContext } from '../../components/AccessibilityContext';
import { AccessibleInput } from '../../hooks/useAccessibleInput';
import { useI18n } from '../../lib/i18n';
import { addBreadcrumb } from '../../lib/log';
import { Theme, useTheme } from '../../styles';

type Props = Omit<TextInputProps, 'value' | 'onChangeText'> & {
  component?: FunctionComponent<TextInputProps>;
  initialContentHeight?: number;
  inputStyle?: StyleProp<TextStyle>;
  label?: string | (() => ReactNode);
  onChangeValue: (value: string) => void;
  value?: string | null;
  variant?: 'contained' | 'flat';
} & Pick<
    ComponentProps<typeof AccessibleInput>,
    | 'error'
    | 'hint'
    | 'labelColor'
    | 'labelWeight'
    | 'labelHorizontal'
    | 'required'
    | 'disabled'
    | 'placeholder'
    | 'onLabelLayout'
  >;

export const getStyles = (theme: Theme) => ({
  outlined: {
    backgroundColor: 'white',
    borderColor: '#C6C6D4',
    borderRadius: 30,
    borderWidth: 1,
    color: theme.color.gray100,
    fontFamily: 'OpenSansRegular',
    fontSize: theme.textInput.outlined.fontSize,
    lineHeight: theme.textInput.outlined.lineHeight,
    marginVertical: theme.textInput.outlined.marginVertical ?? 4,
    padding: 16,
    paddingVertical: theme.textInput.outlined.paddingVertical ?? 16,
  },
  flat: {
    borderBottomWidth: 1,
    borderColor: '#C6C6D4',
    color: theme.color.gray100,
    fontFamily: 'OpenSansRegular',
    padding: 10,
    paddingVertical: 16,
  },
  focused: {
    borderColor: '#4A8EF4',
    elevation: 4,
    shadowColor: '#2060C2',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.3,
    shadowRadius: 3,
  },
});

export const TextInput = forwardRef<RNTextInput, Props>(function TextInput(
  {
    'aria-label': ariaLabel,
    component,
    hint,
    inputStyle,
    error,
    disabled,
    label,
    labelColor,
    labelWeight,
    labelHorizontal,
    variant,
    value,
    style,
    onChangeValue,
    onFocus,
    onBlur,
    onLabelLayout,
    onSubmitEditing,
    secureTextEntry,
    autoComplete,
    placeholder,
    required,
    ...props
  },
  ref,
) {
  const [isFocused, setIsFocused] = useState(Platform.OS === 'web' && props.autoFocus);
  const { theme } = useTheme();
  const [multilineInitialized, setMultilineInitialized] = useState(
    !props.multiline || Platform.OS !== 'web',
  );
  const [contentHeight, setContentHeight] = useState(props.initialContentHeight ?? 30);
  const [layoutHeight, setLayoutHeight] = useState(0);
  const { isKeyboardShowing, isScreenReaderEnabled } = useAccessibilityContext();

  useEffect(() => {
    if (!multilineInitialized) {
      setMultilineInitialized(true);
    }
  }, [multilineInitialized]);

  const Component = component ?? RNTextInput;

  return (
    <AccessibleInput
      aria-label={ariaLabel}
      placeholder={placeholder}
      error={error}
      forwardRef={ref}
      hint={hint}
      label={label as string}
      labelColor={labelColor}
      labelWeight={labelWeight}
      labelHorizontal={labelHorizontal}
      // casting is needed due to a mismatch b/t ViewStyle and TextStyle props from expo type override
      style={style as StyleProp<ViewStyle>}
      testID={props.testID}
      required={required}
      onLabelLayout={onLabelLayout}
    >
      {(accessibleProps) => (
        <>
          <Component
            {...accessibleProps}
            placeholder={
              error && value ? accessibleProps['aria-label'] : accessibleProps.placeholder
            }
            caretHidden={global.e2e}
            onContentSizeChange={
              props.multiline
                ? (event) => {
                    setContentHeight(
                      Math.max(
                        event.nativeEvent.contentSize.height,
                        props.initialContentHeight ?? 50,
                      ),
                    );
                  }
                : undefined
            }
            placeholderTextColor="#9191A3"
            // When a multiline TextInput height is larger than a single line of text, we want the
            // text/placeholder to be top aligned. If we only have one line of text height, we prefer
            // to center align so it's more aesthetically pleasing
            onLayout={(e) => {
              if (e.nativeEvent.layout.height !== layoutHeight) {
                setLayoutHeight(e.nativeEvent.layout.height);
              }
            }}
            textAlignVertical={
              props.multiline && Platform.OS === 'android' && layoutHeight > 52 ? 'top' : undefined
            }
            style={[
              props.multiline
                ? {
                    paddingTop: getStyles(theme).outlined.paddingVertical,
                    minHeight:
                      contentHeight +
                      // if we add to contentHeight on web then onContentSizeChange gets called in an
                      // infinite loop
                      Platform.select({ web: 0, default: 2 }),
                  }
                : null,
              variant === 'flat' ? getStyles(theme).flat : getStyles(theme).outlined,
              { borderColor: error ? theme.color.danger : '#C6C6D4' },
              isFocused ? getStyles(theme).focused : null,
              disabled ? { opacity: 0.7, backgroundColor: theme.color.gray700 } : null,
              inputStyle,
            ]}
            secureTextEntry={global.e2e ? undefined : secureTextEntry}
            autoComplete={global.e2e ? undefined : autoComplete}
            {...props}
            value={multilineInitialized ? value || '' : ''}
            onChangeText={(text) => {
              // https://github.com/facebook/react-native/issues/36494
              // Due to this bug, onChangeText is called an extra time on mount for multiline
              // TextInput, to avoid the additional callback, we protect against sending the event
              // if the new value is the same as the current value
              if (props.multiline && text === value) {
                return;
              }
              // use onChangeText if available b/c it's passed by TextInputMask
              (
                (props as unknown as { onChangeText?: typeof onChangeValue }).onChangeText ||
                onChangeValue
              )?.(text);
            }}
            onBlur={(e) => {
              setIsFocused(false);
              onBlur?.(e);
            }}
            onFocus={(e) => {
              setIsFocused(true);
              onFocus?.(e);
            }}
            onSubmitEditing={
              onSubmitEditing
                ? (e) => {
                    addBreadcrumb({
                      category: 'ui.submit',
                      message: props.testID ?? (typeof label === 'string' ? label : undefined),
                      data: {
                        componentType: 'TextInput',
                        testID: props.testID,
                        label,
                      },
                    });
                    onSubmitEditing(e);
                  }
                : undefined
            }
            readOnly={disabled}
            // @ts-expect-error
            dataSet={{ nofocusoutline: '' }}
          />
          {isScreenReaderEnabled && isFocused && isKeyboardShowing ? (
            <Button title="Close keyboard" onPress={Keyboard.dismiss} />
          ) : null}
        </>
      )}
    </AccessibleInput>
  );
});

export const EmailInput = forwardRef<RNTextInput, Props>(function EmailInput(props, ref) {
  const { $t } = useI18n();
  return (
    <TextInput
      autoCapitalize="none"
      autoComplete="email"
      inputMode="email"
      placeholder={$t({ id: 'EmailInput_label', defaultMessage: 'Email' })}
      ref={ref}
      {...props}
    />
  );
});

export const NumberInput = forwardRef<
  RNTextInput,
  Omit<Props, 'value' | 'onChangeValue'> & {
    onChangeValue: (value: number | null) => void;
    value?: number | null;
  }
>(function NumberInput(props, ref) {
  const [value, setValue] = useState(props.value ? props.value.toString() : '');
  const [error, setError] = useState(false);

  useEffect(() => {
    const parsedValue = Number.parseFloat(value);
    if (parsedValue !== props.value) {
      setValue(props.value?.toString() ?? '');
    }
  }, [value, props.value]);

  return (
    <TextInput
      {...props}
      ref={ref}
      value={value.toString()}
      onChangeValue={(val) => {
        const onChangeValue = props.onChangeValue;
        if (!onChangeValue) return;
        if (val === '' && props.value !== null) {
          setError(false);
          onChangeValue(null);
          return;
        }
        const parsedValue = Number.parseFloat(val);
        const nan = Number.isNaN(parsedValue);
        if (!nan && parsedValue !== props.value) {
          onChangeValue(parsedValue);
          setError(false);
        } else if (nan) {
          setError(true);
        }
      }}
      error={error ? 'must be a number' : props.error}
      inputMode="numeric"
    />
  );
});

export const RequiredNumberInput = forwardRef<
  RNTextInput,
  Omit<Props, 'value' | 'onChangeValue' | 'defaultValue'> & {
    onChangeValue: (value: number) => void;
    value?: number;
    defaultValue: number;
  }
>(function RequiredNumberInput({ defaultValue, onChangeValue, ...props }, ref) {
  const [isEmpty, setIsEmpty] = useState(false);

  return (
    <NumberInput
      {...props}
      ref={ref}
      value={isEmpty ? null : props.value}
      onChangeValue={(val) => {
        if (val === null) {
          setIsEmpty(true);
          onChangeValue(defaultValue);
        } else {
          if (isEmpty) {
            setIsEmpty(false);
          }
          onChangeValue(val);
        }
      }}
    />
  );
});
