/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      d="M1 10.5V9c0-1.67.51-3.22 1.38-4.5m14.62 6V9c0-1.67-.51-3.22-1.38-4.5M13.5 2.38a7.96 7.96 0 0 0-9 0"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M11 15a2 2 0 1 1-4 0c0-1.1.9-9 2-9s2 7.9 2 9Z"
      stroke="currentColor"
      strokeWidth={2}
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
