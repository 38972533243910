import { AudioStatus, setAudioModeAsync, useAudioPlayer, useAudioPlayerStatus } from 'expo-audio';
import { activateKeepAwakeAsync, deactivateKeepAwake } from 'expo-keep-awake';
import {
  forwardRef,
  PropsWithChildren,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Animated, Easing, Platform, TouchableOpacity as RNTouchableOpacity } from 'react-native';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import {
  Gesture,
  GestureDetector as GHGestureDetector,
  TouchableOpacity as GHTouchableOpacity,
} from 'react-native-gesture-handler';
import { runOnJS } from 'react-native-reanimated';
import { Circle } from 'react-native-svg';

import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import { Icon } from '@oui/app-core/src/components/Icon';
import { Subtitles } from '@oui/app-core/src/components/Subtitles';
import { Text } from '@oui/app-core/src/components/Text';
import { TranscriptModal } from '@oui/app-core/src/components/TranscriptModal';
import { View } from '@oui/app-core/src/components/View';
import { useFormatDuration } from '@oui/app-core/src/hooks/useFormatDuration';
import { usePersistedState } from '@oui/app-core/src/hooks/usePersistedState';
import { catchChromeAutoplayError } from '@oui/app-core/src/lib/catchChromeAutoplayError';
import { logEvent } from '@oui/app-core/src/lib/log';
import { getMinuteSecondFromMillis, getMMSSFromMillis } from '@oui/app-core/src/lib/mediaPlayer';
import { getVolume } from '@oui/app-core/src/lib/systemSetting';
import { useTheme } from '@oui/app-core/src/styles';
import { FragmentOf, graphql, readFragment } from '@oui/lib/src/graphql/tada';

import { useRelaxContext } from './RelaxContext';

export const BreathingCircleFragment = graphql(`
  fragment BreathingCircle on RelaxExercise {
    __typename
    audioUrl
    slug
    subtitleUrl
  }
`);

const useGHForNestedTouchables = Platform.OS === 'android';
const TouchableOpacity = useGHForNestedTouchables ? GHTouchableOpacity : RNTouchableOpacity;

// https://github.com/software-mansion/react-native-gesture-handler/issues/1840
const WebGestureDetector = (props: PropsWithChildren) => props.children;
const GestureDetector =
  Platform.OS === 'web'
    ? (WebGestureDetector as unknown as typeof GHGestureDetector)
    : GHGestureDetector;

void setAudioModeAsync({ playsInSilentMode: true });

type Props = {
  config: FragmentOf<typeof BreathingCircleFragment>;
  hidePlayButton?: boolean;
  onEnd?: () => void;
  onLoaded?: (isLoaded: boolean) => void;
  onPause?: (completion: number) => void;
  onStart?: () => void;
};

function BreathingCircleOrb({
  growDuration = 5000,
  opacity = 1,
  iteration,
  shrinkDuration = 5000,
  size = 100,
}: {
  growDuration?: number;
  opacity?: number;
  iteration: number;
  shrinkDuration?: number;
  size?: number;
}) {
  const animated = useRef(new Animated.Value(0));
  const relaxTheme = useRelaxContext();

  useEffect(() => {
    if (iteration && !(Platform.OS === 'android' && global.e2e)) {
      Animated.sequence([
        Animated.timing(animated.current, {
          duration: growDuration,
          toValue: 1,
          useNativeDriver: true,
        }),
        Animated.timing(animated.current, {
          duration: shrinkDuration,
          toValue: 0.5,
          useNativeDriver: true,
        }),
      ]).start();
    }
  }, [iteration, growDuration, shrinkDuration]);

  return (
    <Animated.View
      style={{
        opacity,
        position: 'absolute',
        borderRadius: size / 2,
        backgroundColor: relaxTheme.accentColor,
        width: size,
        height: size,
        transform: [
          {
            scale: animated.current.interpolate({
              inputRange: [0, 1],
              outputRange: [1, 1.5],
            }),
          },
        ],
      }}
    ></Animated.View>
  );
}

function BreathingCircleOrbs(props: {
  iteration: number;
  growDuration: number;
  shrinkDuration: number;
}) {
  return (
    <>
      <BreathingCircleOrb {...props} size={200} opacity={0.2} />
      <BreathingCircleOrb {...props} size={240} opacity={0.1} />
      <BreathingCircleOrb {...props} size={250} opacity={0.1} />
    </>
  );
}

// expo-audio returns millis on web but seconds on android/iOS
const TIME_UNIT_ADJUSTMNET = Platform.select({ default: 1000, web: 1 });

const DurationLabel = (props: { durationStr: string; audioStatus: AudioStatus }) => {
  const { formatDuration } = useFormatDuration();
  const { durationStr, audioStatus } = props;

  const duration = useMemo(() => {
    if (!audioStatus.isLoaded) return '';

    const { minutes, seconds } = getMinuteSecondFromMillis(
      (audioStatus.duration - audioStatus.currentTime) * TIME_UNIT_ADJUSTMNET,
    );

    return formatDuration({
      minutes,
      seconds,
    });
  }, [audioStatus, formatDuration]);

  return (
    <Text text={durationStr} aria-label={audioStatus.isLoaded ? `Current time: ${duration}` : ''} />
  );
};

const BREATHING_KEYFRAMES: [number, { growDuration: number; shrinkDuration: number }][] = [
  [13000, { growDuration: 12000, shrinkDuration: 8000 }],
  [37000, { growDuration: 5500, shrinkDuration: 5500 }],
  [55000, { growDuration: 3000, shrinkDuration: 3000 }],
  [61000, { growDuration: 3000, shrinkDuration: 3000 }],
  [67000, { growDuration: 3000, shrinkDuration: 3000 }],
  [73000, { growDuration: 3000, shrinkDuration: 3000 }],
  [79000, { growDuration: 3000, shrinkDuration: 3000 }],
  [85000, { growDuration: 3000, shrinkDuration: 3000 }],
  [91000, { growDuration: 3000, shrinkDuration: 3000 }],
  [97000, { growDuration: 3000, shrinkDuration: 3000 }],
  [103000, { growDuration: 3000, shrinkDuration: 3000 }],
  [109000, { growDuration: 3000, shrinkDuration: 3000 }],
  [115000, { growDuration: 3000, shrinkDuration: 3000 }],
  [121000, { growDuration: 3000, shrinkDuration: 3000 }],
  [127000, { growDuration: 3000, shrinkDuration: 3000 }],
  [133000, { growDuration: 3000, shrinkDuration: 3000 }],
  [139000, { growDuration: 3000, shrinkDuration: 3000 }],
  [145000, { growDuration: 3000, shrinkDuration: 3000 }],
  [151000, { growDuration: 3000, shrinkDuration: 3000 }],
  [157000, { growDuration: 3000, shrinkDuration: 3000 }],
  [164000, { growDuration: 5000, shrinkDuration: 4000 }],
];

const KEEP_AWAKE_TAG = 'KeepAwake:BreathingCircle';
const PROGRESS_SIZE = 180;
const PROGRESS_PADDING = 10;
const PROGRESS_WIDTH = 10;

export type Handles = {
  pauseAsync: () => Promise<unknown>;
  playAsync: () => Promise<unknown>;
};
export const BreathingCircle = forwardRef<Handles, Props>(function BreathingCircle(
  props: Props,
  ref,
) {
  const [orbState, setOrbState] = useState({ iteration: 0, growDuration: 0, shrinkDuration: 0 });
  const [showTranscript, setShowTranscript] = useState(false);
  const [isClosedCaptionEnabled, setIsClosedCaptionEnabled, isLoadingCaptionState] =
    usePersistedState('enableClosedCaptions', false);
  const progress = useRef<AnimatedCircularProgress>();
  const hasStartedRef = useRef(false);
  const config = readFragment(BreathingCircleFragment, props.config);
  const uri = config.audioUrl;
  const audioPlayer = useAudioPlayer({ uri });
  const audioPlayerStatus = useAudioPlayerStatus(audioPlayer);
  const relaxTheme = useRelaxContext();
  const { theme } = useTheme();
  const [forceMuted, setForceMuted] = useState<boolean>();
  const isMuted = typeof forceMuted === 'boolean' ? forceMuted : audioPlayerStatus.mute;

  useEffect(() => {
    if (!isLoadingCaptionState) {
      void getVolume().then((volume) => {
        if (volume === 0) {
          setIsClosedCaptionEnabled(true);
        }
      });
    }
  }, [setIsClosedCaptionEnabled, isLoadingCaptionState]);

  const onStart = props.onStart;
  const play = useCallback(() => {
    void catchChromeAutoplayError(
      () => {
        if (!hasStartedRef.current) {
          hasStartedRef.current = true;
          logEvent('breathing_relaxation_exercise');
          onStart?.();
        }
        return audioPlayer.play();
      },
      () => {
        setForceMuted(false);
        // https://github.com/expo/expo/issues/35307
        // @ts-expect-error typescript defs are wrong for iOS/android
        audioPlayer.mute = true;
        audioPlayer.muted = true;
        void audioPlayer.play();
      },
    );
  }, [onStart, audioPlayer]);

  useImperativeHandle(
    ref,
    () => ({
      playAsync: async () => play(),
      pauseAsync: async () => {
        audioPlayer.pause();
      },
    }),
    [audioPlayer, play],
  );

  const onLoadedRef = useRef(props.onLoaded);
  onLoadedRef.current = props.onLoaded;
  const onEndRef = useRef(props.onEnd);
  onEndRef.current = props.onEnd;

  useEffect(() => {
    if (audioPlayerStatus.isLoaded) {
      onLoadedRef.current?.(true);
    }
  }, [audioPlayerStatus.isLoaded]);

  useEffect(() => {
    if (audioPlayerStatus.didJustFinish) {
      onEndRef.current?.();
    }
  }, [audioPlayerStatus.didJustFinish]);

  useEffect(() => {
    if (audioPlayerStatus.didJustFinish) {
      audioPlayer.pause();
      onEndRef.current?.();
      setTimeout(() => {
        void audioPlayer.seekTo(0);
        progress.current?.animate(0, 1);
      }, 0);
    }
  }, [audioPlayer, audioPlayerStatus.didJustFinish]);

  useEffect(() => {
    if (!audioPlayerStatus.isLoaded) return;
    if (audioPlayerStatus.playing) {
      const remainingDurationMillis =
        (audioPlayerStatus.duration - audioPlayerStatus.currentTime) * TIME_UNIT_ADJUSTMNET;
      const currentPosition = audioPlayerStatus.currentTime / audioPlayerStatus.duration;
      if (!(Platform.OS === 'android' && global.e2e)) {
        progress.current!.reAnimate(currentPosition * 100, 100, remainingDurationMillis);
      }
    } else {
      if (audioPlayerStatus.currentTime === 0) return;
      const currentPosition = audioPlayerStatus.currentTime / audioPlayerStatus.duration!;
      if (!(Platform.OS === 'android' && global.e2e)) {
        progress.current!.animate(currentPosition * 100, 100000).stop();
      }
    }
    // audioPlayerStatus only needed to refresh when playing changes
    // eslint-disable-next-line
  }, [audioPlayerStatus.playing]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      if (audioPlayerStatus.playing) {
        void activateKeepAwakeAsync(KEEP_AWAKE_TAG);
      }
      return () => {
        void deactivateKeepAwake(KEEP_AWAKE_TAG);
      };
    }
    return;
  }, [audioPlayerStatus.playing]);

  useEffect(() => {
    if (audioPlayerStatus.isLoaded) {
      const currentKeyframeIndex =
        BREATHING_KEYFRAMES.findIndex(
          (frame, i) =>
            frame[0] <= audioPlayerStatus.currentTime * TIME_UNIT_ADJUSTMNET &&
            i > orbState.iteration - 1,
        ) + 1;
      if (currentKeyframeIndex !== 0 && currentKeyframeIndex !== orbState.iteration) {
        setOrbState({
          iteration: currentKeyframeIndex,
          ...BREATHING_KEYFRAMES[currentKeyframeIndex - 1][1],
        });
      }
    }
  }, [audioPlayerStatus, orbState.iteration]);

  const durationStr = audioPlayerStatus.isLoaded
    ? getMMSSFromMillis(
        (audioPlayerStatus.duration - audioPlayerStatus.currentTime) * TIME_UNIT_ADJUSTMNET,
        audioPlayerStatus.duration * TIME_UNIT_ADJUSTMNET,
      )
    : '0:00';

  const handleSeekToCoordinate = ({ x, y }: { x: number; y: number }) => {
    if (!audioPlayerStatus.isLoaded) return;

    function calcAngleDegrees(x: number, y: number) {
      return (Math.atan2(y, x) * 180) / Math.PI;
    }
    const radius = PROGRESS_SIZE / 2;
    const circleX = x - PROGRESS_PADDING - radius;
    const circleY = y - PROGRESS_PADDING - radius;
    const distanceFromCenter = Math.sqrt(circleX ** 2 + circleY ** 2);
    const isValidTouch = Math.abs(radius - PROGRESS_WIDTH - distanceFromCenter) < 15;

    if (!isValidTouch) return;

    let angle = calcAngleDegrees(circleY * -1, circleX);
    if (angle < 0) angle = 360 + angle;
    const percent = angle / 360;

    const currentIsPlaying = audioPlayerStatus.playing;
    if (audioPlayerStatus.playing) {
      audioPlayer.pause();
    }
    void audioPlayer.seekTo(audioPlayerStatus.duration * percent);
    progress.current?.animate(percent * 100, 1);
    if (currentIsPlaying) {
      setTimeout(() => {
        void audioPlayer.play();
      }, 0);
    }
  };

  const tap = Gesture.Tap().onStart((e) => {
    runOnJS(handleSeekToCoordinate)(e);
  });

  const pan = Gesture.Pan()
    .minDistance(3)
    .onEnd((e) => {
      runOnJS(handleSeekToCoordinate)(e);
    });

  const seek = Gesture.Race(tap, pan);

  return (
    <View
      style={{
        alignSelf: 'center',
        width: 200,
        height: 200,
        alignItems: 'center',
        justifyContent: 'center',
      }}
      aria-label="Breathing exercise"
    >
      <BreathingCircleOrbs {...orbState} />
      <GestureDetector gesture={seek}>
        <Animated.View>
          <AnimatedCircularProgress
            ref={(r) => {
              if (!progress.current) {
                progress.current = r!;
                if (progress.current) {
                  progress.current.animate(100, 10000).stop();
                }
              }
            }}
            rotation={0}
            duration={10000}
            lineCap="round"
            size={180}
            width={10}
            fill={100}
            tintColor={theme.color.accent100}
            backgroundColor="white"
            easing={Easing.linear}
            padding={10}
            renderCap={({ center }) => {
              return <Circle cx={center.x} cy={center.y} r="10" fill={relaxTheme.accentColor} />;
            }}
          >
            {() => (
              <View
                flex={1}
                style={{
                  justifyContent: 'space-between',
                  padding: 30,
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <DurationLabel durationStr={durationStr} audioStatus={audioPlayerStatus} />
                <TouchableOpacity
                  testID={
                    audioPlayerStatus.isLoaded
                      ? audioPlayerStatus.playing
                        ? 'BreathingCircle_pauseButton'
                        : 'BreathingCircle_playButton'
                      : undefined
                  }
                  onPress={() => {
                    if (!audioPlayerStatus.isLoaded) return;
                    if (audioPlayerStatus.playing) {
                      audioPlayer.pause();
                      props.onPause?.(audioPlayerStatus.currentTime / audioPlayerStatus.duration);
                    } else {
                      play();
                    }
                  }}
                  style={[
                    {
                      backgroundColor: 'rgba(255,255,255,0.4)',
                      borderRadius: 40,
                      padding: 10,
                      marginVertical: 6,
                    },
                    props.hidePlayButton ? { display: 'none' } : {},
                  ]}
                  role="button"
                  aria-label={audioPlayerStatus.playing ? 'pause' : 'play'}
                >
                  {audioPlayerStatus.isLoaded ? (
                    <Icon name={audioPlayerStatus.playing ? 'pause' : 'play'} size={24} />
                  ) : (
                    <ActivityIndicator />
                  )}
                </TouchableOpacity>
                {audioPlayerStatus.isLoaded ? (
                  <View row spacing={12}>
                    <Icon
                      _useGestureHandler={useGHForNestedTouchables}
                      aria-label={isMuted ? 'Unmute' : 'Mute'}
                      name={isMuted ? 'audio-mute' : 'audio'}
                      size={20}
                      onPress={() => {
                        if (typeof forceMuted === 'boolean') {
                          setForceMuted(!forceMuted);
                        } else {
                          // https://github.com/expo/expo/issues/35307
                          // @ts-expect-error typescript defs are wrong for iOS/android
                          audioPlayer.mute = !audioPlayerStatus.mute;
                          audioPlayer.muted = !audioPlayerStatus.mute;
                          if (!audioPlayerStatus.playing) {
                            // If we're not playing, the mute status doesn't update so trigger a tick
                            void audioPlayer.play()?.then(() => {
                              audioPlayer.pause();
                            });
                          }
                        }
                      }}
                    />
                    <Icon
                      _useGestureHandler={useGHForNestedTouchables}
                      aria-label={isClosedCaptionEnabled ? 'Disable captions' : 'Enable captions'}
                      name={
                        isClosedCaptionEnabled ? 'closed-captioning-selected' : 'closed-captioning'
                      }
                      size={20}
                      onPress={() => {
                        setIsClosedCaptionEnabled(!isClosedCaptionEnabled);
                      }}
                    />
                    <Icon
                      _useGestureHandler={useGHForNestedTouchables}
                      aria-label="Show transcript"
                      name="transcript"
                      size={20}
                      onPress={() => {
                        setShowTranscript(true);
                      }}
                    />
                  </View>
                ) : (
                  <Text text="" role="none" />
                )}
              </View>
            )}
          </AnimatedCircularProgress>
        </Animated.View>
      </GestureDetector>
      <View
        style={{
          position: 'absolute',
          right: 0,
          left: 0,
          bottom: -80,
        }}
      >
        <Subtitles
          enabled={isClosedCaptionEnabled}
          positionMillis={
            audioPlayerStatus.isLoaded ? audioPlayerStatus.currentTime * TIME_UNIT_ADJUSTMNET : 0
          }
          uri={config.subtitleUrl}
        />
      </View>
      {showTranscript ? (
        <TranscriptModal onRequestClose={() => setShowTranscript(false)} uri={config.subtitleUrl} />
      ) : null}
    </View>
  );
});
