/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={16} viewBox="0 0 18 16" fill="none" {...props}>
    <Path
      d="M11.88 12.76h4.54c.32 0 .58-.27.58-.59 0 0-.06-.88-.2-1.17-.16-.35-2.03-1.37-3.7-1.37-.97 0-2.01.35-2.74.7"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1 7.3a1.56 1.56 0 1 0 0-3.13 1.56 1.56 0 0 0 0 3.12Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M6.46 9.72c-2.34 0-4.96 1.37-5.19 1.84-.18.39-.27 1.58-.27 1.58 0 .43.37.79.82.79h9.29a.8.8 0 0 0 .82-.8s-.1-1.18-.28-1.57c-.22-.47-2.85-1.84-5.19-1.84Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M6.46 7.2a2.1 2.1 0 1 0 0-4.2 2.1 2.1 0 0 0 0 4.2Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
