import { useMutation, useQuery } from '@apollo/client';
import { useFocusEffect, useNavigation, useRoute } from '@react-navigation/native';
import { Image } from 'expo-image';
import { useCallback, useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';

import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import { Button } from '@oui/app-core/src/components/Button';
import { ConfirmationModal } from '@oui/app-core/src/components/ConfirmationModal';
import {
  ContextMenu,
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger,
} from '@oui/app-core/src/components/ContextMenu';
import { ErrorPresenter } from '@oui/app-core/src/components/ErrorPresenter';
import { Icon } from '@oui/app-core/src/components/Icon';
import { RoundedSection } from '@oui/app-core/src/components/RoundedSection';
import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { Label, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { Environment, environment } from '@oui/app-core/src/constants';
import { useAddAction } from '@oui/app-core/src/hooks/practices';
import { useArtifactRequest } from '@oui/app-core/src/hooks/useArtifactResult';
import { useWindowDimensions } from '@oui/app-core/src/hooks/useWindowDimensions';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import Sentry from '@oui/app-core/src/sentry';
import { useTheme } from '@oui/app-core/src/styles';
import { graphql, ResultOf } from '@oui/lib/src/graphql/tada';
import { ActionType } from '@oui/lib/src/types/graphql.generated';

import HopeKitCreate from '../assets/hopeKitCreate.svg';
import { useHopeKitContext } from '../components';
import { HopeKitItem, HopeKitItemFragment } from '../components/HopeKitItem';
import { Swiper } from '../components/Swiper';
import { useHopeKitName } from '../hooks/useHopeKitName';
import { StackScreenProps } from '../types/navigation';

const HopeKitQueryName = 'HopeKit';
export const HopeKitQuery = graphql(
  `
    query HopeKit {
      hopeKitItems {
        ...HopeKitItem
        ... on HopeKitImage {
          hopeKitItemID
        }
        ... on HopeKitVideo {
          hopeKitItemID
        }
        ... on HopeKitQuote {
          hopeKitItemID
        }
      }
    }
  `,
  [HopeKitItemFragment],
);

export const RemoveHopeKitItemMutation = graphql(`
  mutation RemoveHopeKitItem($input: RemoveHopeKitItemInput!) {
    removeHopeKitItem(input: $input) {
      hopeKitItemID
    }
  }
`);

export function HopeKit() {
  const { navigate } = useNavigation<StackScreenProps<'HopeKit'>['navigation']>();
  const route = useRoute<StackScreenProps<'HopeKit'>['route']>();
  const { theme } = useTheme();
  const { width } = useWindowDimensions();
  const [deleteConfirmationItem, setDeleteConifrmationItem] = useState<{
    hopeKitItemID: string;
  } | null>(null);
  const { data, loading, refetch } = useQuery(HopeKitQuery, {
    // Dont cache because asset urls are signed for 15 minutes and will quickly become stale
    // NB If this is not specified, the query flickers b/t loading and not loading for some reason...
    // The root cause appears to be the subscription infinitely running onNewData
    // https://github.com/apollographql/apollo-client/blob/df05ff3895afaee074702c739c84a316a40447c8/src/react/data/QueryData.ts#L279-L294
    fetchPolicy: 'no-cache',
  });
  const [removeHopeKitItem] = useMutation(RemoveHopeKitItemMutation);
  const [addAction] = useAddAction();
  const { $t } = useI18n();
  const hopeKitName = useHopeKitName();
  const hopeKitContext = useHopeKitContext();

  const swiperItems: Array<ResultOf<typeof HopeKitQuery>['hopeKitItems'][number]> = [
    ...(data?.hopeKitItems ?? []),
  ].sort((a, b) => {
    return a.hopeKitItemID < b.hopeKitItemID ? -1 : 1;
  });
  const isEmpty = swiperItems.length === 0;

  useFocusEffect(
    useCallback(() => {
      try {
        if (!loading) {
          void refetch();
        }
      } catch (e) {
        // HMR error in dev
        if (environment !== Environment.DEVELOPMENT) {
          Sentry.captureException(e);
        }
      }
    }, [loading, refetch]),
  );

  useEffect(() => {
    if (!isEmpty) {
      void addAction({
        actionType: ActionType.HOPE_KIT_REVIEW,
      });
    }
  }, [isEmpty, addAction]);

  useArtifactRequest(route.name, !isEmpty);

  return (
    <RoundedSection
      testID="HopeKit"
      color={hopeKitContext.mainColor}
      secondaryColor={theme.color.gray800}
      tintColor={theme.color.primary100}
      title={hopeKitName}
      preview={false}
      noScrollView
      applyHeaderOptions
    >
      {route.params?.showUploadFailure === 'true' ? (
        <ErrorPresenter
          style={{ marginBottom: 20 }}
          errorString="Some of your items failed to save. Please try again later."
        />
      ) : null}
      {isEmpty ? (
        <View style={{ paddingTop: 20, marginHorizontal: -20 }}>
          <View style={{ backgroundColor: 'white', aspectRatio: 1 }}>
            {hopeKitContext.images?.emptyState ?? (
              <Image
                source={HopeKitCreate}
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
            )}
          </View>
          {loading ? (
            <View
              style={[
                StyleSheet.absoluteFillObject,
                { alignItems: 'center', justifyContent: 'center' },
              ]}
            >
              <ActivityIndicator />
            </View>
          ) : (
            <View
              style={{ position: 'absolute', top: '35%', left: 0, right: 0, alignItems: 'center' }}
              spacing={12}
            >
              <Text
                text={$t(
                  { id: 'HopeKit_emptyHeader', defaultMessage: 'Create your {hopeKitName}' },
                  { hopeKitName },
                )}
                size={21}
                weight="semibold"
              />
              <Button
                text={$t({ id: 'HopeKit_addEmptyButton', defaultMessage: 'Add from gallery' })}
                icon="plus"
                onPress={() => navigate('AddHopeKit', { method: 'gallery' })}
                testID="HopeKit_addEmptyButton"
                variant="solid"
              />
              <View row style={{ gap: 30 }}>
                <Button
                  variant="text"
                  text="Search for images"
                  icon="plus"
                  onPress={() => navigate('AddHopeKit', { method: 'imageSearch' })}
                  size="small"
                  testID="HopeKit_addSearchButton"
                />
                <Button
                  variant="text"
                  text="Write a quote"
                  icon="plus"
                  onPress={() => navigate('AddHopeKit', { method: 'quote' })}
                  size="small"
                  testID="HopeKit_addQuoteButton"
                />
              </View>
            </View>
          )}
        </View>
      ) : (
        <View style={{ flex: 1, marginTop: -10, marginHorizontal: -20 }}>
          <View style={{ paddingHorizontal: 20, paddingBottom: 10 }}>
            <Menu
              renderer={ContextMenu}
              ref={(r) => {
                if (r && route.params?.showAddMenu === 'true') {
                  void r.open();
                }
              }}
            >
              <MenuTrigger testID="HopeKit_addMoreButton">
                <Button
                  variant="solid"
                  _accentColor="white"
                  alignSelf="flex-start"
                  text={$t({ id: 'HopeKit_addMoreButton', defaultMessage: 'Add' })}
                  iconRight="caret-down"
                  onPress={() => {}}
                  style={{ pointerEvents: 'none' }} // avoid conflicting with MenuTrigger event listener
                  size="small"
                />
              </MenuTrigger>
              <MenuOptions
                customStyles={{
                  optionsContainer: {
                    borderRadius: 10,
                    overflow: 'hidden',
                  },
                  optionsWrapper: {
                    backgroundColor: 'white',
                    gap: 5,
                    paddingVertical: 10,
                  },
                  optionWrapper: { padding: 10, paddingHorizontal: 20 },
                }}
              >
                <MenuOption
                  onSelect={() => navigate('AddHopeKit', { method: 'gallery' })}
                  text={
                    (
                      <View row style={{ gap: 10 }}>
                        <Icon name="plus" />
                        <Label text="Add from gallery" />
                      </View>
                    ) as unknown as string
                  }
                  customStyles={{
                    optionText: {
                      fontFamily: 'OpenSansSemiBold',
                    },
                  }}
                />
                <MenuOption
                  onSelect={() => navigate('AddHopeKit', { method: 'imageSearch' })}
                  text={
                    (
                      <View row style={{ gap: 10 }}>
                        <Icon name="search" />
                        <Label text="Search for images" />
                      </View>
                    ) as unknown as string
                  }
                  customStyles={{
                    optionText: {
                      fontFamily: 'OpenSansSemiBold',
                    },
                  }}
                />
                <MenuOption
                  onSelect={() => navigate('AddHopeKit', { method: 'quote' })}
                  text={
                    (
                      <View row style={{ gap: 10 }}>
                        <Icon name="quotemarks" />
                        <Label text="Write a quote" />
                      </View>
                    ) as unknown as string
                  }
                  customStyles={{
                    optionText: {
                      fontFamily: 'OpenSansSemiBold',
                    },
                  }}
                />
              </MenuOptions>
            </Menu>
          </View>
          <Swiper
            indicator="overlay"
            width={width}
            data={swiperItems}
            renderItem={({ item, index: i }) => {
              return (
                <ScrollView
                  style={{ paddingBottom: 20 }}
                  bottomOffset={280}
                  testID={`HopeKit_item_${i}_scrollView`}
                >
                  <HopeKitItem
                    item={item}
                    onDelete={setDeleteConifrmationItem}
                    testID={`HopeKit_item_${i}`}
                  />
                </ScrollView>
              );
            }}
          />
          {deleteConfirmationItem ? (
            <ConfirmationModal
              visible={!!deleteConfirmationItem}
              onCancel={() => setDeleteConifrmationItem(null)}
              onConfirm={async () => {
                await removeHopeKitItem({
                  variables: { input: { hopeKitItemID: deleteConfirmationItem.hopeKitItemID } },
                  refetchQueries: [HopeKitQueryName],
                });
                setDeleteConifrmationItem(null);
              }}
              cancelText={$t({
                id: 'HopeKit_deleteConfirmation_cancelButton',
                defaultMessage: "No, don't",
              })}
              confirmText={$t({
                id: 'HopeKit_deleteConfirmation_confirmButton',
                defaultMessage: 'Yes, delete',
              })}
              confirmTestID="HopeKit_confirmDeleteButton"
              title={$t({ id: 'HopeKit_deleteConfirmation_title', defaultMessage: 'Delete?' })}
              description={$t(
                {
                  id: 'HopeKit_deleteConfirmation_description',
                  defaultMessage: "Please confirm you'd like to delete this {hopeKitName} item.",
                },
                { hopeKitName },
              )}
            />
          ) : null}
        </View>
      )}
    </RoundedSection>
  );
}
