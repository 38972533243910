/* This file is autogenerated by @oui/cli */
import Svg, { Line, Path, Rect, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
    <Path
      d="M10.37 3a1 1 0 0 0-1-1H6.5a1 1 0 0 0-1 1v10.83h4.87V3Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Rect x={6.89} y={3.74} width={2.09} height={2.09} rx={1} fill="currentColor" />
    <Rect x={6.89} y={6.87} width={2.09} height={2.09} rx={1} fill="currentColor" />
    <Rect x={6.89} y={9.65} width={2.09} height={2.09} rx={1} fill="currentColor" />
    <Line
      x1={4.5}
      y1={14.02}
      x2={11.5}
      y2={14.02}
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
