import { addBreadcrumb, captureMessage, withScope } from '@sentry/core';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import { produce } from 'immer';
import get from 'lodash/get';
import set from 'lodash/set';
import { AsyncReturnType, JsonObject, JsonPrimitive } from 'type-fest';
import { v4 as uuid } from 'uuid';
import {
  actions,
  assign,
  DoneInvokeEvent,
  interpret,
  Machine,
  MachineConfig,
  send,
  State,
  StateConfig,
  StateNodeConfig,
  MachineOptions as TMachineOptions,
} from 'xstate';

import { formatHoursAndMinutes } from './formatHoursAndMinutes';
import { parseGQLDateTime } from './gqlDate';
import { changeBiteProgressExchange } from './metadata/content/exchange/changeBiteProgress';
import exit from './metadata/content/exchange/exit.json';
import lessonLearned from './metadata/content/exchange/lessonLearned.json';
import practiceReview from './metadata/content/exchange/practiceReview.json';
import setup from './metadata/content/exchange/setup.json';
import { retry } from './retry';
import { evaluateTemplate as _evaluateTemplate } from './stateMachineTemplates';
import { transformExpoContactToResponseContact } from './transformExpoContactToResponseContact';
import {
  ActionEnvelope,
  Actor,
  ChatArtifactPreviewProps,
  ChatInputArtifactProps,
  ChatInputButtonProps,
  ChatInputChoiceProps,
  ChatInputContactsPickerProps,
  ChatInputMediaPickerProps,
  ChatInputMultipleChoiceProps,
  ChatInputSignatureProps,
  ChatInputSingleChoiceProps,
  ChatInputSliderProps,
  ChatInputTextProps,
  ChatInputTimePickerProps,
  ChatTextProps,
  Envelopes,
  Kind,
} from './types';
import { InputArtifactResult } from './types/actionEnvelopes';
import { ActionType, ContentType, RoleSettingsInput } from './types/graphql.generated';
import { GQLAny, GQLDateTime } from './types/scalars';

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

const EXCHANGES = {
  exit,
  lessonLearned,
  changeBiteProgress: changeBiteProgressExchange,
  setup,
  practiceReview,
};

export const ArgNodeFunc = {
  ArgAlways: 'ArgAlways',
  ArgCopy: 'ArgCopy',
  ArgCopyFromNodeResult: 'ArgCopyFromNodeResult',
  ArgTake: 'ArgTake',
  ArgInvoke: 'ArgInvoke',
} as const;
export type ArgNodeFunc = (typeof ArgNodeFunc)[keyof typeof ArgNodeFunc];
type ReduceFunc = 'ReduceLast' | 'ReduceArrayValue' | 'ReduceMapValue' | 'ReduceIdentity';

type SequenceNode = {
  type: 'Seq';
  displayName?: string;
  children: ConvoNode[];
};
type PredicateNode = {
  type: 'Pred';
  param:
    | { func: 'PredicateIdentity'; body: ConvoNode }
    | { func: 'PredValueStringEqual'; funcValue: string; body: ConvoNode }
    | { func: 'PredDateTimeOccurredRecently'; numHours: number; body: ConvoNode };
};
type IdentityNode = { type: 'Identity'; value: unknown };
type DoWhileNode = { type: 'DoWhile'; param: { body: ConvoNode; pred: NodeWithID<PredicateNode> } };
type ForEachNode = { type: 'ForEach'; param: { body: ConvoNode; target: string } };
type ReduceNode = { type: 'Reduce'; param: ReduceFunc; children: ConvoNode[] };
type ExchangeNode = { type: 'Exchange'; param: { target: keyof typeof EXCHANGES } };
type ContextNode = {
  type: 'Arg';
  param:
    | { func: 'ArgTake'; lookup: string }
    | { func: 'ArgCopy'; lookup: string; destination: string }
    | { func: 'ArgCopyFromNodeResult'; key: string }
    | { func: 'ArgAlways'; key: string; value: Shape }
    | { func: 'ArgInvoke'; service: string; args?: Shape[] };
};

type Shape = {};
type ContextShapeNode = {
  type: 'ArgShape';
  param: {
    shape: Shape;
  };
};
type ContextDotNode = { type: 'ArgDot'; param: { dot: string } };
type ContextFromResultNode = { type: 'StoreIntoArg'; param: { arg: string; target: ConvoNode } };
type StoreFetchNode = {
  type: 'StoreFetch';
  param: {
    key: string;
    context: string;
    destinationArg: string;
  };
};
type StoreNode = {
  type: 'Store';
  param: {
    key: string;
    context: string;
    target: ConvoNode;
  };
};
type ActionsLengthNode = {
  type: 'ActionsLength';
  param: {
    actionTypes: ActionType[];
    previousSession: string;
  };
};
type ProgressCompletedAtNode = {
  type: 'ProgressCompletedAt';
  param: {
    session: string;
  };
};
type RandomNumberNode = {
  type: 'RandomNumber';
  param: {
    maxValueExclusive: number;
  };
};

type InputChoiceNode = {
  type: 'InputChoice';
  props: ChatInputChoiceProps;
  directive?: { storeType?: 'value' };
};
type InputChoiceKeyValueNode = {
  type: 'InputChoiceKeyValue';
  props: { max: number; min: number; label: { [key: string]: { key: string; value: string } } };
};
type InputSingleChoiceNode = {
  type: 'InputSingleChoice';
  props: ChatInputSingleChoiceProps;
  param?: {
    /**
     * If present, used to calculate __NODE_RESULT instead of the choice labels presented to the user
     * Keys are choices presented to the user (props.label[choiceKey]) and values are __NODE_RESULT values
     */
    valueMap?: Record<string, JsonPrimitive>;
  };
};
type InputMultipleChoiceNode = {
  type: 'InputMultipleChoice';
  props: ChatInputMultipleChoiceProps;
};
type InputTextNode = { type: 'InputText'; props: ChatInputTextProps };
type InputSliderNode = { type: 'InputSlider'; props: ChatInputSliderProps };
type InputContactsPickerNode = { type: 'InputContactsPicker'; props: ChatInputContactsPickerProps };
type InputTimePickerNode = { type: 'InputTimePicker'; props: ChatInputTimePickerProps };
type InputMediaPickerNode = { type: 'InputMediaPicker'; props: ChatInputMediaPickerProps };
type InputSignatureNode = { type: 'InputSignature'; props: ChatInputSignatureProps };
type InputButtonNode = { type: 'InputButton'; props: ChatInputButtonProps };
type InputArtifactNode = {
  type: 'InputArtifact';
  props: ChatInputArtifactProps;
  param: {
    completeNode?: ConvoNode;
    retryButton: string;
    retryText: string[];
    skipButton?: string;
    skipNode?: ConvoNode;
    previewLinkText?: string;
  };
};
type ChatTextNode = { type: 'ChatText'; props: ChatTextProps; directive?: { asUser: boolean } };
type ChatAssetNode = { type: 'ChatAsset'; props: { uri: string; title: string } };
type ChatArtifactPreviewNode = { type: 'ChatActivityPreview'; props: ChatArtifactPreviewProps };
type WriteProgressNode = {
  type: 'WriteProgress';
  props: { progress: 'completed' | 'started'; contentType?: ContentType };
};
type MuxNode = { type: 'Mux'; param: { body: ConvoNode; map: { [key: string]: ConvoNode } } };
type GoToNode = { type: 'GoTo'; param: { nextNodeID: string } };

type NodeWithID<T> = T & { ID: string };

export type ConvoNode = { ID: string } & (
  | ActionsLengthNode
  | DoWhileNode
  | ForEachNode
  | PredicateNode
  | ExchangeNode
  | ReduceNode
  | ContextNode
  | ContextShapeNode
  | ContextDotNode
  | ChatArtifactPreviewNode
  | ChatAssetNode
  | ChatTextNode
  | ContextFromResultNode
  | GoToNode
  | IdentityNode
  | InputArtifactNode
  | InputButtonNode
  | InputChoiceKeyValueNode
  | InputChoiceNode
  | InputContactsPickerNode
  | InputMediaPickerNode
  | InputMultipleChoiceNode
  | InputSignatureNode
  | InputSingleChoiceNode
  | InputSliderNode
  | InputTextNode
  | InputTimePickerNode
  | MuxNode
  | ProgressCompletedAtNode
  | RandomNumberNode
  | SequenceNode
  | StoreFetchNode
  | StoreNode
  | WriteProgressNode
  | { type: 'SideEffectNavigation'; props: { routeName: string } }
  | { type: 'GenerateUUID' }
  | { type: 'End' }
);

type ForEachLoopState = {
  collection: Array<unknown>;
  index: number;
  result: Array<unknown>;
  value: number | string | object | boolean | null;
};

type EventType =
  | 'ADVANCE'
  | 'BEGIN'
  | 'ERROR'
  | 'InputArtifact'
  | 'InputButton'
  | 'InputChoice'
  | 'InputSingleChoice'
  | 'InputMultipleChoice'
  | 'InputContactsPicker'
  | 'InputMediaPicker'
  | 'InputSignature'
  | 'InputSlider'
  | 'InputText'
  | 'InputTimePicker';
const kindToNodeType: { [key: string]: EventType | undefined } = {
  [Kind.InputArtifact]: 'InputArtifact',
  [Kind.InputButton]: 'InputButton',
  [Kind.InputChoice]: 'InputChoice',
  [Kind.InputSingleChoice]: 'InputSingleChoice',
  [Kind.InputMultipleChoice]: 'InputMultipleChoice',
  [Kind.InputContactsPicker]: 'InputContactsPicker',
  [Kind.InputMediaPicker]: 'InputMediaPicker',
  [Kind.InputSignature]: 'InputSignature',
  [Kind.InputSlider]: 'InputSlider',
  [Kind.InputText]: 'InputText',
  [Kind.InputTimePicker]: 'InputTimePicker',
};

type EventObject = { type: EventType; actionEnvelope?: ActionEnvelope };
type MachineOptions = Partial<TMachineOptions<Context, EventObject>>;
type Context = {
  [key: string]: any; // eslint-disable-line
  __CONTENT_VERSION?: number;
  __CONVO_INSTANCE_ID: string;
  __ECHO?: string[];
  __ACTIVE_LOOPS: Array<{ type: 'ForEach' | 'DoWhile'; ID: string }>;
  __DO_WHILE: {
    [loopId: string]: Pick<ForEachLoopState, 'index' | 'result'>;
  };
  __FOR_EACH: {
    [loopId: string]: ForEachLoopState;
  };
  __NODE_RESULT: {};
  __STORE: { context: string; key: string };
  __GET_ACTIONS: { actionTypes: ActionType[]; previousSession: string } | null;
  __LOCALE: string;
  __PROGRESS: WriteProgressNode['props']['progress'];
};
type StateSchema = {
  meta: Envelopes;
  context?: Partial<Context>;
  states: {
    [key: string]: StateNodeConfig<Context, StateSchema, EventObject>;
  };
};
export type Config = MachineConfig<Context, StateSchema, EventObject>;

let nodeIdSet = new Set();

type TraverseNodeOptions = { nodeIDPrefix?: string; allowDupID?: boolean };

let debug = false;

export function setDebug(val: boolean) {
  debug = val;
}

export function isSequenceNode(node: ConvoNode): node is NodeWithID<SequenceNode> {
  return node.type === 'Seq';
}

function DEBUG(...args: unknown[]) {
  if (debug) console.log(...args);
}

function fullContext(context: Context) {
  const extraContext: JsonObject = {};
  const currentLoop = context.__ACTIVE_LOOPS[0];
  if (currentLoop) {
    if (currentLoop.type === 'ForEach') {
      const currentLoopState = context.__FOR_EACH[currentLoop.ID];
      extraContext.currValue = currentLoopState?.value;
      extraContext.currIndex = currentLoopState?.index;
    } else if (currentLoop.type === 'DoWhile') {
      const currentLoopState = context.__DO_WHILE[currentLoop.ID];
      extraContext.currIndex = currentLoopState?.index;
    }
  }
  extraContext.currentIndexes = context.__ACTIVE_LOOPS.map((loop) => {
    return (context.__FOR_EACH[loop.ID] ?? context.__DO_WHILE[loop.ID])?.index;
  });

  return {
    ...context,
    ...extraContext,
  };
}

function evaluateTemplateWithContext(template: string, context: Context) {
  const result = _evaluateTemplate(template, fullContext(context));
  if (
    template.startsWith('{{json') ||
    template.startsWith('{{{json') ||
    template.startsWith('{{#json')
  ) {
    try {
      return JSON.parse(result);
    } catch (e) {
      console.warn('Invalid JSON parse from template', { template, result });
      return '';
    }
  }
  return result;
}

function fullNodeID(node: ConvoNode | undefined, options: TraverseNodeOptions) {
  if (!node) return undefined;
  return options.nodeIDPrefix ? `${options.nodeIDPrefix}::${node.ID}` : node.ID;
}

export function processShape<T extends Shape>(shape: T, context: Context): T {
  if (typeof shape === 'boolean' || typeof shape === 'number') return shape;
  if (typeof shape === 'string') {
    const result = evaluateTemplateWithContext(shape, context);
    return result as T;
  }
  if (Array.isArray(shape)) {
    return shape.map((value) => processShape(value, context)) as any; // eslint-disable-line
  }

  let result: { [key: string]: unknown } = {};
  Object.entries(shape).forEach(([key, value]) => {
    if (value) {
      result[key] = processShape(value as Shape, context);
    }
  });
  return result as T;
}

function traverseNode(
  node: ConvoNode,
  nextNodeID: string | undefined,
  states: StateSchema['states'],
  options: TraverseNodeOptions,
) {
  if (!node.ID) {
    console.warn('Missing node ID', node);
    return;
  }
  const nodeID = fullNodeID(node, options)!;
  if (nodeIdSet.has(nodeID) && !options.allowDupID) {
    captureMessage('Duplicate nodeID', { extra: { node } });
    throw new Error(`Duplicate node ID "${node.ID}"`);
  }
  nodeIdSet.add(nodeID);

  // DEBUG('traverseNode', node, { nextNodeID });
  switch (node.type) {
    case 'Exchange': {
      const exchange = getExchangeConfig(node.param.target);
      DEBUG('Exchange', node.type, nextNodeID, exchange);
      if (!exchange) {
        console.warn(`Missing exchange ${node.param.target} required by ${nodeID}`);
        return;
      }
      // If the same exchange is referenced twice, we would end up with duplicate node IDs because
      // each node internal to the exchange would be visited twice
      // To ensure our IDs are unique, we add the ExchangeNode's ID as part of the prefix
      const nodeIDPrefix = `${node.ID}::${node.param.target}`;
      states[nodeID] = {
        always: `${nodeIDPrefix}::${exchange.ID}`,
      };
      traverseNode(exchange, nextNodeID, states, { ...options, nodeIDPrefix });
      // legacy ID format which doesn't support duplicte exchanges but allows users that are
      // currently paused in the middle of an exchange to resume the session without hitting a
      // missing node ID error
      traverseNode(exchange, nextNodeID, states, {
        ...options,
        nodeIDPrefix: node.param.target,
        allowDupID: true,
      });
      break;
    }
    case 'ArgShape': {
      states[nodeID] = {
        always: nextNodeID,
        entry: assign<Context, EventObject>({
          __NODE_RESULT: (context) => {
            const result = processShape(node.param.shape, context);
            DEBUG('ArgShape result', node.param.shape, context, result);
            return result;
          },
        }),
      };
      break;
    }
    case 'ArgDot': {
      states[nodeID] = {
        entry: [
          assign<Context, EventObject>({
            __NODE_RESULT: (context) => {
              DEBUG('ArgDot', node, context, get(context, node.param.dot));
              return get(context, node.param.dot);
            },
          }),
        ],
        always: nextNodeID,
      };
      break;
    }
    case 'Arg': {
      if (
        ![
          ArgNodeFunc.ArgAlways,
          ArgNodeFunc.ArgTake,
          ArgNodeFunc.ArgCopy,
          ArgNodeFunc.ArgCopyFromNodeResult,
          ArgNodeFunc.ArgInvoke,
        ].includes(node.param.func)
      ) {
        console.warn('TODO unhandled Arg func', node);
        return;
      }
      if (node.param.func === ArgNodeFunc.ArgAlways) {
        const value = node.param.value;
        const key = node.param.key;
        states[nodeID] = {
          entry: [
            assign<Context, EventObject>({
              [key]: (context: Context) => {
                const finalValue = processShape(value, context);
                if (key.includes('.')) {
                  set(context, key, finalValue);
                }
                return finalValue;
              },
            }),
          ],
          always: nextNodeID,
        };
      } else if (node.param.func === ArgNodeFunc.ArgTake) {
        const lookup = node.param.lookup;
        states[nodeID] = {
          entry: [
            assign<Context, EventObject>({
              __NODE_RESULT: (context) => get(fullContext(context), lookup),
            }),
          ],
          always: nextNodeID,
        };
      } else if (node.param.func === ArgNodeFunc.ArgCopy) {
        const lookup = node.param.lookup;
        const destination = node.param.destination;
        states[nodeID] = {
          entry: [
            assign<Context, EventObject>({
              [destination]: (context: Context) => {
                const value = get(fullContext(context), lookup);
                if (destination.includes('.')) {
                  set(context, destination, value);
                }
                return value;
              },
            }),
            assign<Context, EventObject>({
              __NODE_RESULT: (context: Context) => get(fullContext(context), lookup),
            }),
          ],
          always: nextNodeID,
        };
      } else if (node.param.func === ArgNodeFunc.ArgCopyFromNodeResult) {
        const key = node.param.key;
        states[nodeID] = {
          entry: [
            assign<Context, EventObject>({
              [key]: (context: Context) => {
                if (key.includes('.')) {
                  set(context, key, context.__NODE_RESULT);
                }
                return context.__NODE_RESULT;
              },
            }),
          ],
          always: nextNodeID,
        };
      } else if (node.param.func === ArgNodeFunc.ArgInvoke) {
        const args = node.param.args;
        states[nodeID] = {
          invoke: {
            src: node.param.service,
            data: (context) => {
              return args?.map((arg) => processShape(arg, context));
            },
            onDone: {
              target: nextNodeID,
              actions: [
                assign<Context, DoneInvokeEvent<unknown>>({
                  __NODE_RESULT: (_context, event) => event.data,
                  [node.param.service]: (_context: any, event: any) => event.data, // eslint-disable-line
                }),
              ],
            },
            onError: 'ERROR',
          },
        };
      }
      break;
    }
    case 'Seq': {
      const children = node.children;
      states[nodeID] = {
        always: children.length ? fullNodeID(children[0], options) : nextNodeID,
      };
      children.forEach((child, i) => {
        // console.log('SEQ add child', child, children[i + 1]?.ID ?? nextNodeID);
        traverseNode(child, fullNodeID(children[i + 1], options) ?? nextNodeID, states, options);
      });
      break;
    }
    case 'Mux': {
      const bodyID = fullNodeID(node.param.body, options);
      Object.assign(states, {
        [nodeID]: {
          always: bodyID,
        },
      });

      const handleResultID = `${nodeID}-result`;

      states[handleResultID] = {
        always: [],
      };

      // Add & configure body node
      traverseNode(node.param.body, handleResultID, states, options);
      Object.entries(node.param.map).forEach(([key, sequence]: [string, ConvoNode]) => {
        // DEBUG('Mux', { key, nextID: sequence.ID });
        function muxGuard(context: Context) {
          // DEBUG('Mux guard', { key, nextID: sequence.ID, result: context.__NODE_RESULT });
          let result = false;
          try {
            result = context.__NODE_RESULT === key || JSON.stringify(context.__NODE_RESULT) === key;
          } catch (e) {}
          return result;
        }
        (states[handleResultID].always as { target: string; cond: Function }[]).push({
          target: fullNodeID(sequence, options)!,
          cond: muxGuard,
        });
        traverseNode(sequence, nextNodeID, states, options);
      });

      // Fallback if all conds fail
      (states[handleResultID].always as { target: string }[]).push({ target: nextNodeID! });

      break;
    }
    case 'InputContactsPicker': {
      states[nodeID] = {
        meta: { ID: nodeID, props: node.props, kind: Kind.InputContactsPicker, from: Actor.Bot },
        on: {
          InputContactsPicker: nextNodeID,
        },
        entry: 'sendEnvelope',
        exit: [
          // Content handles the echo
          // actions.choose<Context, EventObject>([
          //   {
          //     cond: (context, event) => {
          //       const props = event.actionEnvelope?.props;
          //       const result = props?.result === 'contacts' && props?.contacts.length;
          //       return result;
          //     },
          //     actions: [
          //       assign<Context, EventObject>({
          //         __ECHO: (context, event) => {
          //           const props = event.actionEnvelope?.props;
          //           return props?.contacts.map((c) => {
          //             const name = c.name;
          //             const phoneNumber = c.phoneNumbers?.[0]?.number;
          //             return [name, phoneNumber].filter((v) => !!v).join('\n');
          //           });
          //         },
          //       }),
          //       'echo',
          //     ],
          //   },
          // ]),
          assign<Context, EventObject>({
            __NODE_RESULT: (_context, event) => {
              if (event.actionEnvelope?.kind === Kind.InputContactsPicker) {
                const { result, contacts } = event.actionEnvelope?.props;
                return {
                  result,
                  contacts: contacts?.map(transformExpoContactToResponseContact),
                };
              }
              console.warn('unexpected envelope type in InputContactsPicker __NODE_RESULT');
              return;
            },
          }),
        ],
      };
      break;
    }
    case 'InputTimePicker': {
      states[nodeID] = {
        meta: { ID: nodeID, props: node.props, kind: Kind.InputTimePicker, from: Actor.Bot },
        on: {
          InputTimePicker: nextNodeID,
        },
        entry: 'sendEnvelope',
        exit: [
          assign<Context, EventObject>({
            __ECHO: (context, event): string[] | undefined => {
              if (event.actionEnvelope?.kind === Kind.InputTimePicker) {
                return [formatHoursAndMinutes(context.__LOCALE, event.actionEnvelope?.props.time)];
              }
              return;
            },
            __NODE_RESULT: (_context, event) => {
              if (event.actionEnvelope?.kind === Kind.InputTimePicker) {
                return event.actionEnvelope?.props.time;
              }
              return;
            },
          }),
          'echo',
        ],
      };
      break;
    }
    case 'InputButton': {
      states[nodeID] = {
        meta: { ID: nodeID, props: node.props, kind: Kind.InputButton, from: Actor.Bot },
        on: {
          InputButton: nextNodeID,
        },
        entry: 'sendEnvelope',
        exit: [
          assign<Context, EventObject>({
            __ECHO: (context, event): string[] | undefined => {
              return [node.props.label];
            },
          }),
          'echo',
        ],
      };
      break;
    }
    case 'InputSignature': {
      states[nodeID] = {
        meta: { ID: nodeID, props: node.props, kind: Kind.InputSignature, from: Actor.Bot },
        on: {
          InputSignature: nextNodeID,
        },
        entry: 'sendEnvelope',
        exit: [
          assign<Context, EventObject>({
            __NODE_RESULT: (_context, event) => {
              if (event.actionEnvelope?.kind === Kind.InputSignature) {
                return event.actionEnvelope?.props.uri;
              }
              return;
            },
          }),
        ],
      };
      break;
    }
    case 'InputMediaPicker': {
      states[nodeID] = {
        meta: { ID: nodeID, props: node.props, kind: Kind.InputMediaPicker, from: Actor.Bot },
        on: {
          InputMediaPicker: nextNodeID,
        },
        entry: 'sendEnvelope',
        exit: [
          assign<Context, EventObject>({
            __NODE_RESULT: (_context, event) => {
              if (event.actionEnvelope?.kind === Kind.InputMediaPicker) {
                return event.actionEnvelope?.props.result;
              }
              return;
            },
          }),
        ],
      };
      break;
    }
    case 'InputText': {
      states[nodeID] = {
        meta: { ID: nodeID, props: node.props, kind: Kind.InputText, from: Actor.Bot },
        on: {
          InputText: nextNodeID,
        },
        entry: 'sendEnvelope',
        exit: [
          assign<Context, EventObject>({
            __ECHO: (_context, event): string[] | undefined => {
              if (event.actionEnvelope?.kind === Kind.InputText) {
                return [event.actionEnvelope?.props];
              }
              return;
            },
            __NODE_RESULT: (_context, event) => {
              return event.actionEnvelope?.props;
            },
          }),
          'echo',
        ],
      };
      break;
    }
    case 'InputSlider': {
      states[nodeID] = {
        meta: { ID: nodeID, props: node.props, kind: Kind.InputSlider, from: Actor.Bot },
        on: {
          InputSlider: nextNodeID,
        },
        entry: 'sendEnvelope',
        exit: [
          assign<Context, EventObject>({
            __ECHO: (_context, event): string[] | undefined => {
              if (event.actionEnvelope?.kind === Kind.InputSlider) {
                const configProps = node.props;
                const numberValue = event.actionEnvelope.props;
                const value = configProps.labels?.[numberValue];
                return value ? [`${numberValue} - ${value}`] : [numberValue.toString()];
              }
              console.warn('unknown actionEnvelope in InputSlider echo');
              return;
            },
            __NODE_RESULT: (_context, event) => {
              return event.actionEnvelope?.props;
            },
          }),
          'echo',
        ],
      };
      break;
    }
    case 'InputChoice': {
      states[nodeID] = {
        meta: { ID: nodeID, props: node.props, kind: Kind.InputChoice, from: Actor.Bot },
        on: {
          InputChoice: nextNodeID,
        },
        entry: 'sendEnvelope',
        exit: [
          assign<Context, EventObject>({
            __ECHO: (_context, event): string[] | undefined => {
              const configProps = node.props;
              if (event.actionEnvelope?.kind === Kind.InputChoice) {
                const humanReadableChoices = event.actionEnvelope?.props.map((value) => {
                  return configProps.label[value];
                });

                return humanReadableChoices;
              }
              return;
            },
            __NODE_RESULT: (_context, event) => {
              if (node.directive?.storeType === 'value') {
                if (event.actionEnvelope?.kind === Kind.InputChoice) {
                  const humanReadableChoices = event.actionEnvelope?.props.map((value) => {
                    return node.props.label[value];
                  });

                  return humanReadableChoices;
                }
              }
              return event.actionEnvelope?.props;
            },
          }),
          'echo',
        ],
      };
      break;
    }
    case 'InputChoiceKeyValue': {
      const label = Object.entries(node.props.label).reduce<Record<string, string>>(
        (carry, [key, keyAndValue]) => {
          carry[key] = keyAndValue.value;
          return carry;
        },
        {},
      );

      states[nodeID] = {
        meta: {
          ID: nodeID,
          props: { min: node.props.min, max: node.props.max, label },
          kind: Kind.InputChoice,
          from: Actor.Bot,
        },
        on: {
          InputChoice: nextNodeID,
        },
        entry: 'sendEnvelope',
        exit: [
          assign<Context, EventObject>({
            __ECHO: (_context, event): string[] | undefined => {
              if (event.actionEnvelope?.kind === Kind.InputChoice) {
                const configProps = node.props;
                const humanReadableChoices = event.actionEnvelope?.props.map((key) => {
                  return configProps.label[key].value;
                });

                return humanReadableChoices;
              }
              return;
            },
            __NODE_RESULT: (_context, event) => {
              if (event.actionEnvelope?.kind === Kind.InputChoice) {
                const configProps = node.props;
                return event.actionEnvelope?.props.map((key) => configProps.label[key].key);
              }
              return;
            },
          }),
          'echo',
        ],
      };
      break;
    }
    case 'InputSingleChoice': {
      states[nodeID] = {
        meta: { ID: nodeID, props: node.props, kind: Kind.InputSingleChoice, from: Actor.Bot },
        on: {
          InputSingleChoice: nextNodeID,
        },
        entry: 'sendEnvelope',
        exit: [
          assign<Context, EventObject>({
            __ECHO: (_context, event): string[] | undefined => {
              const configProps = node.props;
              if (event.actionEnvelope?.kind === Kind.InputSingleChoice) {
                return [configProps.label[event.actionEnvelope.props]];
              }
              return;
            },
            __NODE_RESULT: (_context, event) => {
              if (event.actionEnvelope?.kind === Kind.InputSingleChoice) {
                if (node.param?.valueMap) {
                  const value = node.props.label[event.actionEnvelope.props];
                  const key = node.param.valueMap[value];
                  return {
                    key,
                    value,
                    // originalKey is used by cmsToStateMachine to properly
                    // connect the selected choice to the Mux node
                    originalKey: event.actionEnvelope.props,
                  };
                }
                return event.actionEnvelope.props;
              }
              return;
            },
          }),
          'echo',
        ],
      };
      break;
    }
    case 'InputMultipleChoice': {
      states[nodeID] = {
        meta: { ID: nodeID, props: node.props, kind: Kind.InputMultipleChoice, from: Actor.Bot },
        on: {
          InputMultipleChoice: nextNodeID,
        },
        entry: 'sendEnvelope',
        exit: [
          assign<Context, EventObject>({
            __ECHO: (context, event): string[] | undefined => {
              const configProps = node.props;
              if (event.actionEnvelope?.kind === Kind.InputMultipleChoice) {
                const humanReadableChoices = event.actionEnvelope?.props.map((value) => {
                  return configProps.label[value];
                });

                return humanReadableChoices;
              }
              console.warn('unknown actionEnvelope in InputChoice echo', event, context);
              return;
            },
            __NODE_RESULT: (_context, event) => {
              if (event.actionEnvelope?.kind === Kind.InputMultipleChoice) {
                return event.actionEnvelope.props;
              }
              return;
            },
          }),
          'echo',
        ],
      };
      break;
    }
    case 'InputArtifact': {
      const handleResultNodeID = `${nodeID}-handleResult`;
      const retryNodeID = `${nodeID}-retry`;
      const handleRetryResultNodeID = `${nodeID}-handleRetryResult`;

      function isIncomplete(context: Context) {
        DEBUG('isIncomplete', context);
        const result = context.__NODE_RESULT;
        return (result as InputArtifactResult).complete === false;
      }

      states[nodeID] = {
        meta: { ID: nodeID, props: node.props, kind: Kind.InputArtifact, from: Actor.Bot },
        on: {
          InputArtifact: handleResultNodeID,
        },
        entry: [
          assign<Context, EventObject>({
            __ACTIVE_LOOPS: (context) => {
              return [{ type: 'DoWhile', ID: nodeID }, ...context.__ACTIVE_LOOPS];
            },
            __DO_WHILE: (context) => {
              return produce(context.__DO_WHILE, (draft) => {
                draft[nodeID] = { index: 0, result: [] };
              });
            },
          }),
          'sendEnvelope',
        ],
        exit: [
          assign<Context, EventObject>({
            __ECHO: (context, event): string[] | undefined => {
              return [node.props.buttonText];
            },
            // This is a workaround to prevent the result from being overwritten by the retry InputSingleChoice
            __INPUT_ARTIFACT_CACHED_RESULT: (_context: Context, event: EventObject) => {
              if (event.actionEnvelope?.kind === Kind.InputArtifact) {
                return event.actionEnvelope?.props;
              }
              return;
            },
            __NODE_RESULT: (_context, event) => {
              if (event.actionEnvelope?.kind === Kind.InputArtifact) {
                return event.actionEnvelope?.props;
              }
              return;
            },
          }),
          'echo',
        ],
      };

      states[retryNodeID] = {
        meta: {
          ID: retryNodeID,
          props: { ...node.props, retry: true },
          kind: Kind.InputArtifact,
          from: Actor.Bot,
        },
        on: {
          InputArtifact: handleResultNodeID,
        },
        entry: [
          assign<Context, EventObject>({
            __DO_WHILE: (context) => {
              return produce(context.__DO_WHILE, (draft) => {
                draft[nodeID].index = draft[nodeID].index + 1;
              });
            },
          }),
          'sendEnvelope',
        ],
        exit: [
          assign<Context, EventObject>({
            // This is a workaround to prevent the result from being overwritten by the retry InputSingleChoice
            __INPUT_ARTIFACT_CACHED_RESULT: (_context: Context, event: EventObject) => {
              if (event.actionEnvelope?.kind === Kind.InputArtifact) {
                return event.actionEnvelope?.props;
              }
              return;
            },
            __NODE_RESULT: (_context, event) => {
              if (event.actionEnvelope?.kind === Kind.InputArtifact) {
                return event.actionEnvelope?.props;
              }
              return;
            },
          }),
        ],
      };

      states[handleResultNodeID] = {
        always: [
          { cond: isIncomplete, target: `${retryNodeID}Seq` },
          {
            target: node.param.completeNode ? node.param.completeNode.ID : nextNodeID,
            actions: [
              assign<Context, EventObject>({
                __ACTIVE_LOOPS: (context) => {
                  return context.__ACTIVE_LOOPS.filter((loop) => loop.ID !== nodeID);
                },
                __DO_WHILE: (context) => {
                  return produce(context.__DO_WHILE, (draft) => {
                    delete draft[nodeID];
                  });
                },
              }),
            ],
          },
        ],
      };

      // 'I know it can be hard to think of something at first. It doesn’t have to be perfect.';
      // 'Try again.';
      traverseNode(
        {
          ID: `${retryNodeID}Seq`,
          type: 'Seq',
          children: [
            {
              ID: `${retryNodeID}Seq-text`,
              type: 'ChatText',
              props: { text: node.param.retryText },
            },
            {
              ID: `${retryNodeID}Seq-choice`,
              type: 'InputSingleChoice',
              props: {
                variant: 'button',
                label: {
                  0: node.param.retryButton,
                  ...(node.param.skipButton ? { 1: node.param.skipButton } : null!),
                },
              },
            },
          ],
        },
        handleRetryResultNodeID,
        states,
        options,
      );

      function shouldRetry(context: Context) {
        DEBUG('shouldRetry', context);
        const result = context.__NODE_RESULT;
        return result === 0;
      }

      states[handleRetryResultNodeID] = {
        always: [
          { cond: shouldRetry, target: retryNodeID },
          {
            target: node.param.skipNode ? node.param.skipNode.ID : nextNodeID,
            actions: [
              assign<Context, EventObject>({
                __ACTIVE_LOOPS: (context) => {
                  return context.__ACTIVE_LOOPS.filter((loop) => loop.ID !== nodeID);
                },
                __DO_WHILE: (context) => {
                  return produce(context.__DO_WHILE, (draft) => {
                    delete draft[nodeID];
                  });
                },
              }),
            ],
          },
        ],
        exit: [
          assign<Context, EventObject>({
            __NODE_RESULT: (context, _event) => {
              return context.__INPUT_ARTIFACT_CACHED_RESULT;
            },
            __INPUT_ARTIFACT_CACHED_RESULT: (_context: Context, event: EventObject) => {
              return;
            },
          }),
        ],
      };

      if (node.param.completeNode) {
        traverseNode(node.param.completeNode, nextNodeID, states, options);
      }
      if (node.param.skipNode) {
        traverseNode(node.param.skipNode, nextNodeID, states, options);
      }

      break;
    }
    case 'ChatAsset': {
      states[nodeID] = {
        meta: {
          ID: nodeID,
          props: { uri: node.props.uri, linkText: node.props.title },
          kind: Kind.ChatAsset,
          from: Actor.Bot,
        },
        on: {
          ADVANCE: nextNodeID,
        },
        entry: ['sendEnvelope', send('ADVANCE')],
      };
      break;
    }
    case 'ChatActivityPreview': {
      // cmsToStateMachine relies on this code not setting a value for __NODE_RESULT.
      // Doing so would break the correct flow of the chat session.

      states[nodeID] = {
        meta: { ID: nodeID, props: node.props, kind: Kind.ChatArtifactPreview, from: Actor.Bot },
        on: {
          ADVANCE: nextNodeID,
        },
        entry: ['sendEnvelope', send('ADVANCE')],
      };
      break;
    }
    case 'ChatText': {
      states[nodeID] = {
        meta: {
          ID: nodeID,
          props: node.props,
          kind: Kind.ChatText,
          from: node.directive?.asUser ? Actor.User : Actor.Bot,
        },
        on: {
          ADVANCE: nextNodeID,
        },
        entry: ['sendEnvelope', send('ADVANCE')],
      };
      break;
    }
    case 'WriteProgress': {
      // this tmp variable pattern isn't great because it lives outside of the state machine but
      // it's ok since entry/exit of this node should happen "atomically" as seen by other nodes
      let tmp = '';
      states[nodeID] = {
        invoke: {
          src: 'writeProgress',
          onDone: nextNodeID,
          onError: 'ERROR',
        },
        entry: [
          assign<Context, EventObject>((ctx) => {
            tmp = ctx.sessionName;
            return {
              sessionName: node.props.contentType || ctx.sessionName,
            };
          }),
          assign<Context, EventObject>({
            __PROGRESS: node.props.progress,
          }),
        ],
        exit: assign<Context, EventObject>((ctx) => {
          return {
            sessionName: tmp,
          };
        }),
      };
      break;
    }
    case 'StoreIntoArg': {
      const key = node.param.arg;
      states[nodeID] = {
        always: fullNodeID(node.param.target, options),
      };
      const handleResultID = `${nodeID}-result`;
      states[handleResultID] = {
        entry: [
          assign<Context, EventObject>({ [key]: (context: Context) => context.__NODE_RESULT }),
        ],
        always: nextNodeID,
      };
      DEBUG('StoreIntoArg', { node, handleResultID });
      traverseNode(node.param.target, handleResultID, states, options);

      break;
    }
    case 'StoreFetch': {
      states[nodeID] = {
        entry: [
          assign<Context, EventObject>({
            __STORE: {
              context: node.param.context,
              key: node.param.key,
            },
          }),
        ],
        invoke: {
          src: 'getStoreData',
          onDone: {
            target: nextNodeID,
            actions: [
              assign<Context, DoneInvokeEvent<unknown>>({
                __NODE_RESULT: (_context, event) => event.data,
                [node.param.destinationArg]: (_context: Context, event: DoneInvokeEvent<unknown>) =>
                  event.data,
              }),
            ],
          },
          onError: 'ERROR',
        },
      };
      break;
    }
    case 'Store': {
      states[nodeID] = {
        always: fullNodeID(node.param.target, options),
      };
      const handleStoreID = `${nodeID}-handleStore`;
      if (node.param.context === 'sessionName') {
        console.warn(`Store node ${nodeID} should use {{sessionName}} template syntax`);
      }
      states[handleStoreID] = {
        entry: [
          assign<Context, EventObject>({
            __STORE: {
              context: node.param.context,
              key: node.param.key,
            },
          }),
        ],
        invoke: {
          src: 'storeData',
          onDone: nextNodeID,
          onError: 'ERROR',
        },
      };
      traverseNode(node.param.target, handleStoreID, states, options);
      break;
    }
    case 'ActionsLength': {
      states[nodeID] = {
        entry: [
          assign<Context, EventObject>({
            __GET_ACTIONS: {
              actionTypes: node.param.actionTypes,
              previousSession: node.param.previousSession,
            },
          }),
        ],
        invoke: {
          src: 'getActionsCount',
          onDone: {
            target: nextNodeID,
            actions: [
              assign<
                Context,
                DoneInvokeEvent<AsyncReturnType<TransitionOptions['getActionsCount']>>
              >({
                __NODE_RESULT: (_context, event) => event.data,
                __GET_ACTIONS: null,
              }),
            ],
          },
          onError: 'ERROR',
        },
      };
      break;
    }
    case 'RandomNumber': {
      states[nodeID] = {
        always: nextNodeID,
        entry: assign<Context, EventObject>({
          __NODE_RESULT: () => {
            const result = getRandomInt(node.param.maxValueExclusive);
            DEBUG('RandomNumber result', node.param.maxValueExclusive);
            return result;
          },
        }),
      };
      break;
    }
    case 'ProgressCompletedAt': {
      states[nodeID] = {
        entry: [
          assign<Context, EventObject>({
            __GET_ACTIONS: {
              actionTypes: [],
              previousSession: node.param.session,
            },
          }),
        ],
        invoke: {
          src: 'getProgressCompletedAt',
          onDone: {
            target: nextNodeID,
            actions: [
              assign<
                Context,
                DoneInvokeEvent<AsyncReturnType<TransitionOptions['getProgressCompletedAt']>>
              >({
                __NODE_RESULT: (_context, event) => event.data,
                __GET_ACTIONS: null,
              }),
            ],
          },
          onError: 'ERROR',
        },
      };
      break;
    }
    case 'Reduce': {
      if (node.param !== 'ReduceLast') {
        console.warn('Unexpected reduce param', node);
      }
      states[nodeID] = {
        always: fullNodeID(node.children[0], options),
      };
      // TODO proper store logic
      node.children.forEach((child, i) => {
        // console.log('SEQ add child', child, children[i + 1]?.ID ?? nextNodeID);
        traverseNode(
          child,
          fullNodeID(node.children[i + 1], options) ?? nextNodeID,
          states,
          options,
        );
      });
      break;
    }
    case 'SideEffectNavigation': {
      states[nodeID] = {
        meta: { ID: nodeID, props: node.props, kind: Kind.SideEffectNavigate, from: Actor.Bot },
        on: {
          ADVANCE: nextNodeID,
        },
        entry: ['sendEnvelope', send('ADVANCE')],
      };
      break;
    }
    case 'ForEach': {
      const handleIterationID = `${nodeID}-handleIteration`;
      states[nodeID] = {
        always: [
          { cond: skipForEach, target: handleIterationID },
          { target: fullNodeID(node.param.body, options) },
        ],
        entry: [
          assign<Context, EventObject>({
            __ACTIVE_LOOPS: (context) => {
              return [{ type: 'ForEach', ID: nodeID }, ...context.__ACTIVE_LOOPS];
            },
            __FOR_EACH: (context) => {
              return produce(context.__FOR_EACH, (draft) => {
                const collection = context[node.param.target] ?? [];
                draft[nodeID] = {
                  collection,
                  index: 0,
                  result: [],
                  value: collection[0],
                };
              });
            },
          }),
        ],
      };

      function skipForEach(context: Context) {
        DEBUG('skipForEach', context);
        const loopState = context.__FOR_EACH[nodeID];
        const collectionLength = loopState.collection.length;
        return collectionLength === 0;
      }

      function endForEach(context: Context) {
        DEBUG('endForEach', context);
        const loopState = context.__FOR_EACH[nodeID];
        if (loopState.index > 100) return true;
        const collectionLength = loopState.collection.length;
        return collectionLength === 0 || collectionLength - 1 === loopState.index;
      }

      states[handleIterationID] = {
        always: [
          {
            cond: endForEach,
            target: nextNodeID,
            actions: [
              assign<Context, EventObject>({
                __FOR_EACH: (context) => {
                  return produce(context.__FOR_EACH, (draft) => {
                    if (draft[nodeID].collection.length === 0) return;
                    draft[nodeID].result = [...draft[nodeID].result, context.__NODE_RESULT];
                  });
                },
              }),
              assign<Context, EventObject>({
                __NODE_RESULT: (context) => context.__FOR_EACH[nodeID].result,
              }),
              assign<Context, EventObject>({
                __ACTIVE_LOOPS: (context) => {
                  return context.__ACTIVE_LOOPS.filter((loop) => loop.ID !== nodeID);
                },
                __FOR_EACH: (context) => {
                  return produce(context.__FOR_EACH, (draft) => {
                    delete draft[nodeID];
                  });
                },
              }),
            ],
          },
          {
            target: fullNodeID(node.param.body, options),
            actions: [
              assign<Context, EventObject>({
                __FOR_EACH: (context) => {
                  return produce(context.__FOR_EACH, (draft) => {
                    draft[nodeID].index = draft[nodeID].index + 1;
                    draft[nodeID].result = [...draft[nodeID].result, context.__NODE_RESULT];
                    draft[nodeID].value = draft[nodeID].collection[draft[nodeID].index] as any; // eslint-disable-line
                  });
                },
              }),
            ],
          },
        ],
      };
      traverseNode(node.param.body, handleIterationID, states, options);
      break;
    }
    case 'DoWhile': {
      const handlePredID = `${nodeID}-handlePred`;
      const handleIterationEndID = `${nodeID}-handleIterationEnd`;

      states[nodeID] = {
        always: fullNodeID(node.param.body, options),
        entry: [
          assign<Context, EventObject>({
            __ACTIVE_LOOPS: (context) => {
              return [{ type: 'DoWhile', ID: nodeID }, ...context.__ACTIVE_LOOPS];
            },
            __DO_WHILE: (context) => {
              return produce(context.__DO_WHILE, (draft) => {
                draft[nodeID] = { index: 0, result: [] };
              });
            },
          }),
        ],
      };

      function continueDoWhile(context: Context) {
        return !!context.__NODE_RESULT;
      }

      states[handleIterationEndID] = {
        always: fullNodeID(node.param.pred, options),
        entry: [
          assign<Context, EventObject>({
            __DO_WHILE: (context) => {
              return produce(context.__DO_WHILE, (draft) => {
                draft[nodeID].result = [...draft[nodeID].result, context.__NODE_RESULT];
              });
            },
          }),
        ],
      };
      states[handlePredID] = {
        always: [
          {
            cond: continueDoWhile,
            target: fullNodeID(node.param.body, options),
            actions: [
              assign<Context, EventObject>({
                __DO_WHILE: (context) => {
                  return produce(context.__DO_WHILE, (draft) => {
                    draft[nodeID].index = draft[nodeID].index + 1;
                  });
                },
              }),
            ],
          },
          {
            target: nextNodeID,
            actions: [
              assign<Context, EventObject>({
                __NODE_RESULT: (context) => context.__DO_WHILE[nodeID].result,
              }),
              assign<Context, EventObject>({
                __ACTIVE_LOOPS: (context) => {
                  return context.__ACTIVE_LOOPS.filter((loop) => loop.ID !== nodeID);
                },
                __DO_WHILE: (context) => {
                  return produce(context.__DO_WHILE, (draft) => {
                    delete draft[nodeID];
                  });
                },
              }),
            ],
          },
        ],
      };
      traverseNode(node.param.body, handleIterationEndID, states, options);
      traverseNode(node.param.pred, handlePredID, states, options);
      break;
    }
    case 'Pred': {
      const p: PredicateNode = node;
      states[nodeID] = {
        always: fullNodeID(node.param.body, options),
      };
      function predFunc(context: Context) {
        DEBUG('predFunc', nodeID, p.param, context.__NODE_RESULT);
        if (p.param.func === 'PredicateIdentity') return !!context.__NODE_RESULT;
        if (p.param.func === 'PredValueStringEqual')
          return JSON.stringify(context.__NODE_RESULT) === p.param.funcValue;
        if (p.param.func === 'PredDateTimeOccurredRecently') {
          return (
            differenceInMinutes(
              new Date(),
              parseGQLDateTime(context.__NODE_RESULT as GQLDateTime),
            ) /
              60 <=
            p.param.numHours
          );
        }

        console.warn('Unhandled predFunc', node);
        return false;
      }
      if (
        !['PredicateIdentity', 'PredValueStringEqual', 'PredDateTimeOccurredRecently'].includes(
          node.param.func,
        )
      ) {
        console.warn('unhandled predFunc', node);
      }
      const handleResultID = `${nodeID}-handleResult`;
      states[handleResultID] = {
        on: { ADVANCE: nextNodeID },
        entry: [assign<Context, EventObject>({ __NODE_RESULT: predFunc }), send('ADVANCE')],
      };
      traverseNode(node.param.body, handleResultID, states, options);
      break;
    }
    case 'Identity': {
      states[nodeID] = {
        always: nextNodeID,
        exit: [
          assign<Context, EventObject>({
            __NODE_RESULT: (context) =>
              typeof node.value === 'string'
                ? evaluateTemplateWithContext(node.value, context)
                : node.value,
          }),
        ],
      };
      break;
    }
    case 'GenerateUUID': {
      states[nodeID] = {
        always: nextNodeID,
        exit: [
          assign<Context, EventObject>({
            __NODE_RESULT: () => uuid(),
          }),
        ],
      };
      break;
    }
    case 'GoTo': {
      states[nodeID] = {
        always: fullNodeID(
          { ID: node.param.nextNodeID, type: 'GoTo', param: { nextNodeID: '' } },
          options,
        ),
      };
      break;
    }
    case 'End': {
      states[nodeID] = {
        type: 'final',
      };
      break;
    }
    default: {
      // @ts-ignore
      if (node.type === 'StoreTemplateLocation') {
        // @ts-ignore
        console.warn(`StoreTemplateLocation should be changed to Store`, node);
      }
      // @ts-ignore
      console.warn(`Unknown node type: ${node.type}`, node);
    }
  }
}

function getExchangeConfig(target: keyof typeof EXCHANGES): ConvoNode | undefined {
  return EXCHANGES[target] as unknown as ConvoNode;
}

export function getMachineConfigFromConversation(
  convo: ConvoNode,
  options: { context?: Partial<Context> } = {},
): Config {
  nodeIdSet = new Set();
  const config: Config = {
    id: convo.ID,
    initial: '__start',
    context: (options.context ?? {}) as Context,
    states: {
      __start: {
        on: { BEGIN: convo.ID },
        exit: assign<Context, EventObject>({
          __CONVO_INSTANCE_ID: (context) => {
            return context.__CONVO_INSTANCE_ID || uuid();
          },
        }),
      },
      ERROR: {
        entry: actions.log((_context, event) => console.error(event)),
      },
      __final: {
        type: 'final',
      },
    },
  };

  traverseNode(convo, '__final', config.states!, {});

  DEBUG(JSON.stringify(config, null, 2));
  DEBUG(config);
  return config;
}

function mergeMeta(meta: { [key: string]: object }): StateSchema['meta'] {
  return Object.keys(meta).reduce((acc, key) => {
    const value = meta[key];
    // Assuming each meta value is an object
    acc = { ...acc, ...value };
    return acc;
  }, {}) as Envelopes;
}

function getEventFromActionEnvelope(action: ActionEnvelope): EventObject {
  const nodeType = kindToNodeType[action.kind];
  if (nodeType) {
    return { type: nodeType, actionEnvelope: action };
  }

  console.warn('unknown type', action);
  return { type: 'ERROR', actionEnvelope: action };
}

export type StateJson = StateConfig<Context, EventObject>;
export type StateObject = State<Context, EventObject, StateSchema>;
export type TransitionReturn = { state: StateObject; envelopes: Envelopes[] };
export type TransitionOptions = {
  saveResponse: (context: string, key: string, data: GQLAny) => Promise<unknown>;
  setProgress: (content: ContentType, value: number) => Promise<unknown>;
  getResponse: (context: string, key: string) => Promise<unknown>;
  getActionsCount: (variables: {
    actionTypes: Array<ActionType>;
    after: GQLDateTime;
  }) => Promise<number>;
  getProgressCompletedAt: (content: string) => Promise<GQLDateTime>;
  onTransitionFailed: () => Promise<void>;
  getChangeBiteProgressStatus: (content: ContentType) => Promise<{
    weight: boolean;
    fadFixes: boolean;
    binges: boolean;
    numBinges: number | null;
  }>;
  saveChangeBiteProgressEntry: (data: {
    binges: number;
    fadFixes: number;
    weight: number;
  }) => Promise<unknown>;
  updateRoleSettings: (settings: RoleSettingsInput) => Promise<unknown>;
  updateEatingCommitments: (settings: {
    increase: string;
    increaseOther?: string;
    decrease: string;
    decreaseOther?: string;
  }) => Promise<unknown>;
  createChangeBiteActivityDiaryEntries: () => Promise<unknown>;
  getStressSensitivityPercentChange: () => Promise<number>;
  getStressSensitivityResultTitle: (type: 'before' | 'after') => Promise<string>;
};

export function transition(
  config: Config,
  currentState: StateObject | null,
  action: ActionEnvelope | null,
  options: TransitionOptions,
): Promise<TransitionReturn> {
  let envelopes = [] as Envelopes[];
  let echoCount = 0;
  const now = Date.now();
  const machineOptions: MachineOptions = {
    actions: {
      echo: (context, currentAction, { state: newState }) => {
        DEBUG('echo', context, { currentAction, newState });
        const envelope: Extract<Envelopes, { kind: Kind.ChatText }> = {
          ID: `echo::${now}-${echoCount++}`,
          from: Actor.User,
          t: now,
          kind: Kind.ChatText,
          props: {
            text: context.__ECHO!,
          },
        };
        envelopes.push(envelope);
      },
      sendEnvelope: (context, _action, { state: newState }) => {
        try {
          const meta = mergeMeta(newState.meta) as Envelopes;
          DEBUG('sendEnvelope', meta, { _action, context }, newState);
          const currentIndexes = fullContext(context).currentIndexes;
          let envelope: Envelopes = {
            ...meta,
            t: Date.now(),
            ID: currentIndexes?.length ? `${meta.ID}-${currentIndexes}` : meta.ID,
          } as Envelopes;
          if (envelope.kind === Kind.ChatText) {
            const props = meta.props as ChatTextProps;
            const text = processShape(props.text, context);
            envelope.props = produce(envelope.props, (draft) => {
              draft.text = text;
            });
          } else if (envelope.kind === Kind.ChatAsset) {
            envelope.props = produce(envelope.props, (draft) => {
              draft.uri = evaluateTemplateWithContext(draft.uri, context);
            });
          } else if (envelope.kind === Kind.ChatArtifactPreview) {
            envelope.props = produce(envelope.props, (draft) => {
              if (draft.params) {
                draft.params = processShape(draft.params as Shape, context) as object;
              }
            });
          }
          envelopes.push(envelope);
        } catch (e) {
          console.error(e);
        }
      },
    },
    services: {
      getProgressCompletedAt: (context, _event) => {
        return retry(async () => {
          return options.getProgressCompletedAt(
            processShape(context.__GET_ACTIONS!.previousSession, context),
          );
        });
      },
      getActionsCount: (context, _event) => {
        return retry(async () => {
          const after = await options.getProgressCompletedAt(
            processShape(context.__GET_ACTIONS!.previousSession, context),
          );
          return options.getActionsCount({
            actionTypes: processShape(context.__GET_ACTIONS!.actionTypes, context),
            after,
          });
        });
      },
      getStoreData: (context, event, { data }) => {
        const storeContext =
          data?.[0] ?? evaluateTemplateWithContext(context.__STORE.context, context);
        const storeKey = data?.[1] ?? evaluateTemplateWithContext(context.__STORE.key, context);
        DEBUG('getStoreData', context, event, {
          storeContext,
          storeKey,
          value: context.__NODE_RESULT,
        });
        return retry(() => options.getResponse(storeContext, storeKey));
      },
      storeData: (context, event) => {
        const storeContext = evaluateTemplateWithContext(context.__STORE.context, context);
        const storeKey = evaluateTemplateWithContext(context.__STORE.key, context);
        DEBUG('storeData', context, event, {
          storeContext,
          storeKey,
          value: context.__NODE_RESULT,
        });
        return retry(() => options.saveResponse(storeContext, storeKey, context.__NODE_RESULT));
      },
      writeProgress: (context, event) => {
        DEBUG('writeProgress', event);
        const completed = context.__PROGRESS === 'completed';
        const started = context.__PROGRESS === 'started';
        return retry(() =>
          options.setProgress(context.sessionName, completed ? 1 : started ? 0.1 : 0),
        );
      },
      getChangeBiteProgressStatus: (context) => {
        return retry(() => options.getChangeBiteProgressStatus(context.sessionName));
      },
      saveChangeBiteProgressEntry: (_context, _event, { data }) => {
        return retry(() => options.saveChangeBiteProgressEntry(data[0]));
      },
      updateRoleSettings: (_context, _event, { data }) => {
        return retry(() => options.updateRoleSettings(data[0]));
      },
      updateEatingCommitments: (_context, _event, { data }) => {
        return retry(() => options.updateEatingCommitments(data[0]));
      },
      createChangeBiteActivityDiaryEntries: (_context, _event) => {
        return retry(() => options.createChangeBiteActivityDiaryEntries());
      },
      getStressSensitivityPercentChange: () => {
        return retry(() => options.getStressSensitivityPercentChange());
      },
      getStressSensitivityResultTitle: (_context, _event, { data }) => {
        return retry(() => options.getStressSensitivityResultTitle(data[0]));
      },
      testPromise: () => {
        return new Promise((resolve) => {
          setTimeout(resolve, 1000);
        });
      },
    },
  };

  const defaultContext = {
    __CONVO_INSTANCE_ID: '',
    __DO_WHILE: {},
    __ECHO: [],
    __ACTIVE_LOOPS: [],
    __FOR_EACH: {},
    __NODE_RESULT: {},
    __STORE: { context: '', key: '' },
    __GET_ACTIONS: null,
    __LOCALE: 'en-US',
    __PROGRESS: 'started' as const,
    sessionName: config.id,
  };
  const machine = Machine<Context, StateSchema, EventObject>(config, machineOptions, {
    ...defaultContext,
    ...config.context,
    ...currentState?.context,
  });
  const service = interpret(machine, { execute: true });
  addBreadcrumb({
    message: 'currentStateValue',
    data: { value: currentState?.value },
  });
  service.start(currentState ?? {});
  // We don't want envelopes for the "currentState" which is soon to be previous to be returned
  // TODO this may be the point to persist / mark past envelopes as ack'd
  if (currentState && action === null) {
    return Promise.resolve({
      state: currentState,
      envelopes,
    });
  }
  envelopes = [];
  // console.log('started', currentState);

  const event = action === null ? { type: 'BEGIN' as const } : getEventFromActionEnvelope(action);

  DEBUG('transition', currentState, action, { event });
  return new Promise<TransitionReturn>((resolve) => {
    let eventSent = false;
    function callback(newState: StateObject) {
      const willSend = newState.actions.some((a) => a.type === 'xstate.send');
      if (!eventSent || willSend) return;

      const activities = Object.values(newState.activities);
      if (activities.some((a) => a !== false)) {
        DEBUG('waiting for activities', activities, newState);
        return;
      }

      DEBUG('result', event, newState, { envelopes: [...envelopes] });
      const newStateWithoutHistory = newState;
      delete newStateWithoutHistory['history'];
      resolve({
        state: newStateWithoutHistory,
        envelopes,
      });
    }

    // @ts-expect-error upgrading xstate broke this type but it's non-trivial to resolve
    service.onTransition(callback).onDone((s) => {
      DEBUG('DONE!!!', s, service.state);
    });
    eventSent = true;
    service.send(event);
  });
}

export async function transitionWithErrorHandling(
  config: Config,
  currentStateJson: StateJson | null,
  action: ActionEnvelope | null,
  options: TransitionOptions,
): Promise<{ state: StateJson; envelopes: Envelopes[]; error: boolean }> {
  const currentState: StateObject | null = currentStateJson ? State.create(currentStateJson) : null;
  const result = await transition(config, currentState, action, options);

  let error = result.state.value === 'ERROR';
  if (error) {
    withScope((scope) => {
      scope.setExtras({
        configID: config.id,
        previousValue: currentState?.value,
      });
      captureMessage(`ERROR state in ${config.id}`, 'warning');
    });
  } else if (currentState?.value === result.state.value) {
    const newContext = fullContext(result.state.context);
    const oldContext = fullContext(currentState.context);
    const isSameLoopState =
      JSON.stringify(newContext.currentIndexes) === JSON.stringify(oldContext.currentIndexes);
    if (isSameLoopState) {
      withScope((scope) => {
        scope.setExtras({
          configID: config.id,
          previousValue: currentState?.value,
          actionKind: action?.kind,
          currentState: JSON.stringify(currentStateJson),
          ...(process.env.ENV !== 'production'
            ? {
                action,
              }
            : null),
        });
        captureMessage(
          `State value "${currentState?.value}" in "${config.id}" did not transition`,
          'warning',
        );
      });
      await options.onTransitionFailed();
      error = true;
    }
  }

  return {
    error,
    envelopes: result.envelopes,
    state: result.state,
  };
}
