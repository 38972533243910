/* This file is autogenerated by @oui/cli */
import Svg, { Line, Path, Rect, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={16} viewBox="0 0 18 16" fill="none" {...props}>
    <Path
      d="M16.62 1c-.3.2-1.07 1.48-.46 2.22.61.74.46 1.63 0 2.22-.46.6-.86.75-1.06.9"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Rect
      x={1}
      y={9}
      width={16}
      height={5.33}
      rx={1}
      stroke="currentColor"
      strokeWidth={2}
      vectorEffect="non-scaling-stroke"
    />
    <Line
      x1={14.19}
      y1={9}
      x2={14.19}
      y2={14.71}
      stroke="currentColor"
      strokeWidth={2}
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
