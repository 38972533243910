/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={16} height={18} viewBox="0 0 16 18" fill="none" {...props}>
    <Path d="M2 11.9 7.53 17l5.53-5.1" fill="currentColor" />
    <Path
      d="M2 11.9 7.53 17l5.53-5.1H2Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path d="M2 6.1 7.53 1l5.53 5.1" fill="currentColor" />
    <Path
      d="M2 6.1 7.53 1l5.53 5.1H2Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
