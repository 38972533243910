/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={16} viewBox="0 0 18 16" fill="none" {...props}>
    <Path
      d="M4.6 12.34A3.57 3.57 0 0 1 1 8.79v0a3.57 3.57 0 0 1 3.84-3.54A4.81 4.81 0 0 1 9 2.88c2.4 0 4.38 1.72 4.74 3.98A2.78 2.78 0 0 1 17 9.58a2.78 2.78 0 0 1-2.8 2.76"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M9.37 7.44v5.62"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="m6.82 9.43 2.55-2.19 2.54 2.19"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
