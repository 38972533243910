'use client';

import hexToRgba from 'hex-to-rgba';
import { ReactNode } from 'react';
import { Modal as RNModal, StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import { SystemBars } from 'react-native-edge-to-edge';
import { gestureHandlerRootHOC } from 'react-native-gesture-handler';

import { ScrollView } from './ScrollView';
import { useTheme } from '../styles';

type Props = {
  onRequestClose: () => void;
  visible: boolean;
  children: ReactNode;
  style: StyleProp<ViewStyle>;
  testID?: string;
};

const ModalInner = gestureHandlerRootHOC((props: Props) => {
  const { theme } = useTheme();

  return (
    <TouchableOpacity
      testID={props.testID}
      accessible={false}
      activeOpacity={1}
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'stretch',
        backgroundColor: hexToRgba(theme.color.gray100, '0.5'),
      }}
      onPress={props.onRequestClose}
    >
      {props.visible ? <SystemBars style="light" /> : null}
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <TouchableOpacity accessible={false} activeOpacity={1} style={props.style}>
          {props.children}
        </TouchableOpacity>
      </ScrollView>
    </TouchableOpacity>
  );
});

export function ModalContainer(props: Props) {
  return (
    <RNModal visible={props.visible} transparent onRequestClose={() => {}}>
      <ModalInner {...props} />
    </RNModal>
  );
}
