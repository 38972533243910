/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={19} height={16} viewBox="0 0 19 16" fill="none" {...props}>
    <Path
      d="M3.5 13.9A8.31 8.31 0 0 1 3.5 2m11.67 11.9a8.31 8.31 0 0 0 0-11.9"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M6.42 4a4.82 4.82 0 0 0-2.09 3.95c0 1.63.83 3.07 2.09 3.95m5.83 0a4.82 4.82 0 0 0 2.09-3.95c0-1.62-.83-3.06-2.09-3.95"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.34 12.54a.83.83 0 1 0 0-1.67.83.83 0 0 0 0 1.67Z"
      fill="currentColor"
    />
    <Path
      d="M9.33 5.04V9.2"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
