/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={16} height={18} viewBox="0 0 16 18" fill="none" {...props}>
    <Path
      d="M10.48 16.82c-.14-.28.08-.52.12-.78.1-.72.1-1.44 0-2.16a5.82 5.82 0 0 0-1.08-2.66 5.91 5.91 0 0 0-1.95-1.8c-.02-.02-.05-.01-.1-.01-.12.09-.14.23-.15.4a4.4 4.4 0 0 1-1.2 2.86l-.93 1.03a3.13 3.13 0 0 0-.74 2.06c0 .12 0 .24-.04.35l-.07.1a.4.4 0 0 1-.32-.1 5.88 5.88 0 0 1-2.01-4.4 11.59 11.59 0 0 1 1.03-5.64c.48-1.05 1.14-2 1.91-2.86A7.9 7.9 0 0 1 7.4 1.14c.1-.05.35-.17.46-.13.15.04.1.24.11.39a8.17 8.17 0 0 0 2.05 5.4c.58.64 1.2 1.25 1.75 1.93.64.8 1.1 1.67 1.24 2.7a5.55 5.55 0 0 1-.5 3.48c-.36.7-.87 1.24-1.46 1.73-.13.11-.28.18-.45.22l-.11-.04Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
