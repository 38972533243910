/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={19} height={18} viewBox="0 0 19 18" fill="none" {...props}>
    <Path
      d="M8.58 15.94a1 1 0 0 0 1.16 0l6.98-5a1 1 0 0 0 .37-1.13L14.43 1.7a1 1 0 0 0-.95-.69H4.84a1 1 0 0 0-.95.69L1.23 9.8a1 1 0 0 0 .37 1.13l6.98 5Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
