/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      d="M9.36 16.6a1 1 0 0 0 1.16 0l5.88-4.19a1 1 0 0 0 .37-1.13l-2.24-6.77a1 1 0 0 0-.95-.69H6.3a1 1 0 0 0-.95.69l-2.24 6.77a1 1 0 0 0 .37 1.13l5.88 4.18Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M3.34 9.94 1.52 8.43a1 1 0 0 1-.3-1.08L3.1 1.68A1 1 0 0 1 4.06 1h6.12a1 1 0 0 1 .95.69l.7 2.13"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="m8.65 7.59 2.7 2.53-2.7 2.54"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M3.82 10.18h6.09"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
