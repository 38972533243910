/* This file is autogenerated by @oui/cli */
import Svg, { Path, Rect, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Rect
      x={1}
      y={1}
      width={16}
      height={16}
      rx={2}
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.92 3.95a.6.6 0 0 0-.6.6v2.77H4.55a.6.6 0 0 0-.6.6v2.59c0 .33.27.6.6.6h2.77v2.34c0 .33.26.6.6.6h2.59a.6.6 0 0 0 .6-.6v-2.34h2.34a.6.6 0 0 0 .6-.6V7.9a.6.6 0 0 0-.6-.6h-2.34V4.56a.6.6 0 0 0-.6-.6H7.9Z"
      fill="currentColor"
    />
  </Svg>
);
export default SvgComponent;
