/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      d="M4.3 4.15h1a1 1 0 0 0-1-1v1Zm-.92 8.33h1a1 1 0 0 0-1-1v1Zm0 3.57h-1a1 1 0 0 0 1.7.71l-.7-.7Zm3.57-3.57v-1a1 1 0 0 0-.7.3l.7.7Zm6-.44.63.78a1 1 0 0 0 .08-1.49l-.7.71Zm-1.9-1.91.7-.7a1 1 0 0 0-.7-.3v1ZM4.3 3.15H3v2h1.3v-2Zm-1.3 0a3 3 0 0 0-3 3h2a1 1 0 0 1 1-1v-2Zm-3 3v4.33h2V6.15H0Zm0 4.33a3 3 0 0 0 3 3v-2a1 1 0 0 1-1-1H0Zm3 3h.38v-2H3v2Zm-.62-1v3.57h2v-3.57h-2Zm1.7 4.28 3.58-3.57-1.41-1.42-3.58 3.58 1.42 1.41Zm2.87-3.28h4.75v-2H6.95v2Zm4.75 0a3 3 0 0 0 1.88-.66l-1.25-1.56a1 1 0 0 1-.63.22v2Zm1.96-2.15-1.9-1.9-1.42 1.4 1.9 1.92 1.42-1.42Zm-2.61-2.2H6.3v2h4.75v-2Zm-4.75 0a1 1 0 0 1-1-1h-2a3 3 0 0 0 3 3v-2Zm-1-1V4.15h-2v3.98h2Z"
      fill="currentColor"
    />
    <Path
      d="M4.3 3c0-1.1.9-2 2-2H15a2 2 0 0 1 2 2v5.13a2 2 0 0 1-2 2h-.38v3.57l-3.57-3.57H6.3a2 2 0 0 1-2-2V3Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
