/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={16} height={18} viewBox="0 0 16 18" fill="none" {...props}>
    <Path
      d="M4.25 17h5.78m2.25-8V8H2v3.05c0 .13.01.26.04.39a5 5 0 0 0 2.76 3.34c.2.1.4.13.62.13h3.4c.24 0 .48-.04.69-.16.4-.22 1.13-.69 1.78-1.53m1-4.23v1.96c0 .2-.04.4-.1.58a6.07 6.07 0 0 1-.9 1.69m1-4.23h.55c1.13 0 1.86 1.13 1.41 2.26-.45 1.12-1.4 1.97-1.97 1.97h-1"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M9.49 1c-.28.14-.97 1.09-.42 1.63.56.54.42 1.2 0 1.63-.41.43-.78.54-.97.65M5.14 2.09c.28.14 1.66.54 1.25 1.4-.42.88-1.67.77-1.67 1.64 0 .87.97.98.97.98"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
