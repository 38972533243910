import { Image } from 'expo-image';
import { ImageSourcePropType, StyleProp, ViewStyle } from 'react-native';

import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useTheme } from '@oui/app-core/src/styles';

export function SoloOnboardingStepBadge(props: { step: string; size?: number }) {
  const { theme } = useTheme();
  const size = props.size ?? 40;
  return (
    <View
      style={{
        zIndex: 1,
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        width: size,
        height: size,
        borderRadius: size / 2,
        backgroundColor: theme.color.dark,
      }}
      accessible
      role="heading"
      aria-label={`Step ${props.step}`}
    >
      <Text
        text={props.step}
        color="white"
        weight="bold"
        lineHeight={size}
        size={size < 30 ? 15 : undefined}
      />
    </View>
  );
}

export function SoloOnboardingCard(props: {
  title: string;
  text: string;
  step: 'A' | 'B' | 'C';
  graphic?: ImageSourcePropType;
  style?: StyleProp<ViewStyle>;
}) {
  return (
    <View
      style={[
        {
          backgroundColor: 'white',
          borderRadius: 16,
          paddingHorizontal: 22,
          paddingVertical: 16,
        },
        props.style,
      ]}
    >
      <View row style={{ justifyContent: 'space-between' }}>
        <SoloOnboardingStepBadge step={props.step} />
        <View style={{ width: 100, height: 60 }}>
          <Image
            source={props.graphic}
            style={{ height: '100%', width: '100%' }}
            contentFit="contain"
          />
        </View>
      </View>
      <Text text={props.title} weight="bold" size={20} style={{ marginVertical: 8 }} />
      <Text text={props.text} />
    </View>
  );
}
