/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={17} viewBox="0 0 18 17" fill="none" {...props}>
    <Path
      d="M14.31 12.13a3 3 0 0 1-.74-1.97v-4.2a4.95 4.95 0 1 0-9.9 0v4.2a3 3 0 0 1-.75 1.97L1 14.33h15.24l-1.93-2.2Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.1 13.95a1.9 1.9 0 1 0 3.05 0H7.1Z"
      fill="currentColor"
    />
  </Svg>
);
export default SvgComponent;
