import {
  BottomTabNavigationOptions,
  createBottomTabNavigator,
} from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import { ComponentType, memo } from 'react';
import { TouchableWithoutFeedback, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Icon } from '@oui/app-core/src/components/Icon';
import { Text } from '@oui/app-core/src/components/Text';
import { useTheme } from '@oui/app-core/src/styles';

import { LearnStatic } from './LearnStatic';
import { StaticTabParamList } from '../types/navigation';

function TabLabel(props: { focused: boolean; color: string; text: string }) {
  return (
    <View
      style={{
        alignItems: 'center',
        paddingVertical: 2,
        marginTop: -4,
        width: '100%',
      }}
    >
      <Text
        text={props.text}
        color={props.color}
        size={13}
        weight="semibold"
        numberOfLines={1}
        textAlign="center"
      />
      <View
        style={{
          backgroundColor: props.focused ? props.color : 'transparent',
          height: 2,
          width: '70%',
        }}
      ></View>
    </View>
  );
}

const StaticAccountSettingsStack = createStackNavigator();
const StaticAccountSettings = ({ AccountSettings }: { AccountSettings: ComponentType }) => {
  const { theme } = useTheme();
  return (
    <StaticAccountSettingsStack.Navigator
      screenOptions={{
        headerTintColor: theme.color.primary100,
        headerTitleStyle: { color: theme.color.dark, fontFamily: 'OpenSansSemiBold' },
      }}
    >
      <StaticAccountSettingsStack.Screen name="StaticAccount" component={AccountSettings} />
    </StaticAccountSettingsStack.Navigator>
  );
};

const StaticTab = createBottomTabNavigator<StaticTabParamList>();
// use React.memo due to note about performance from https://reactnavigation.org/docs/screen#children
export const StaticTabNavigator = memo<{ AccountSettings: React.ComponentType }>(
  ({ AccountSettings }) => {
    const insets = useSafeAreaInsets();
    const { theme } = useTheme();
    return (
      <StaticTab.Navigator
        screenOptions={({ route }) => {
          // not sure why this type isn't inferred correctly but we have to manually specify otherwise
          // any object is accepted as a return value
          const options: BottomTabNavigationOptions = {
            headerShown: false,
            tabBarStyle: { height: 60 + insets.bottom },
            tabBarActiveTintColor: theme.color.primary100,
            tabBarInactiveTintColor: theme.color.gray500,
            tabBarHideOnKeyboard: true,
            // Adapted from https://github.com/react-navigation/react-navigation/blob/a484c1d7834f195ac0cfccda6a9f905218bc2274/packages/bottom-tabs/src/views/BottomTabItem.tsx#L156-L164
            // customized accessibilityRole
            tabBarButton: ({ children, style, onPress, to, accessibilityRole, ...rest }) => (
              <TouchableWithoutFeedback {...rest} role="tab" onPress={onPress}>
                <View style={style}>{children}</View>
              </TouchableWithoutFeedback>
            ),
            tabBarIcon: ({ color: tintColor }) => {
              const routeName = route.name;

              let icon = null;
              if (routeName === 'Learn') {
                icon = <Icon color={tintColor} name="sessions" />;
              } else if (routeName === 'Profile') {
                icon = <Icon color={tintColor} name="person" />;
              }

              return <View style={{ paddingTop: 4 }}>{icon}</View>;
            },
          };
          return options;
        }}
      >
        <StaticTab.Screen
          name="Learn"
          component={LearnStatic}
          options={{
            title: 'Learn',
            tabBarLabel: (p) => <TabLabel {...p} text="Learn" />,
            tabBarTestID: 'NAV_Learn',
            tabBarAccessibilityLabel: 'Learn, 1 of 2',
          }}
        />
        <StaticTab.Screen
          name="Profile"
          options={{
            title: 'Profile',
            tabBarLabel: (p) => <TabLabel {...p} text="Account" />,
            tabBarTestID: 'NAV_Account',
            tabBarAccessibilityLabel: 'Account, 2 of 2',
          }}
        >
          {() => <StaticAccountSettings AccountSettings={AccountSettings} />}
        </StaticTab.Screen>
      </StaticTab.Navigator>
    );
  },
);
