import { memo, useState } from 'react';
import { ImageBackground, Text as RNText, StyleSheet, TouchableHighlight } from 'react-native';

import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import { Icon } from '@oui/app-core/src/components/Icon';
import { View } from '@oui/app-core/src/components/View';

export const ImageTile = memo(function ImageTile({
  item,
  index,
  selected,
  selectImage,
  selectedItemCount,
  badgeColor,
}: {
  item: { uri: string; type: 'image' | 'video' };
  index: number;
  selected: boolean;
  selectImage: (i: number) => Promise<{ isAllowed: boolean }>;
  selectedItemCount: number;
  badgeColor: string;
}) {
  const [isSelecting, setIsSelecting] = useState(false);
  const [isUnavailable, setIsUnavailable] = useState(false);
  if (!item) return null;
  return (
    <TouchableHighlight
      testID={`ImageTile_${index}`}
      style={{ opacity: selected ? 0.8 : 1 }}
      underlayColor="transparent"
      onPress={() => {
        async function onPress() {
          setIsSelecting(true);
          const { isAllowed } = await selectImage(index);
          if (!isAllowed) {
            setIsUnavailable(true);
          }
          setIsSelecting(false);
        }
        void onPress();
      }}
      aria-label={`result ${index + 1}`}
      role="checkbox"
      aria-selected={selected}
    >
      <View>
        <View>
          <ImageBackground
            style={{ width: '100%', aspectRatio: 1 }}
            source={{ uri: item.uri }}
            resizeMethod="resize"
          >
            {item.type === 'video' ? (
              <View style={{ width: '100%', height: '100%', justifyContent: 'center' }} row>
                <View
                  style={{
                    width: 50,
                    height: 50,
                    borderRadius: 25,
                    backgroundColor: 'rgba(255,255,255,0.3)',
                    justifyContent: 'center',
                  }}
                  row
                >
                  <Icon name="play" />
                </View>
              </View>
            ) : null}
            {selected && (
              <View style={[styles.countBadge, { backgroundColor: badgeColor }]}>
                <RNText style={styles.countBadgeText}>{selectedItemCount}</RNText>
              </View>
            )}
          </ImageBackground>
        </View>
        {isSelecting ? (
          <View
            style={[
              {
                position: 'absolute',
                right: 5,
                bottom: 5,
                backgroundColor: 'rgba(0,0,0,0.3)',
                padding: 5,
                borderRadius: 20,
              },
            ]}
          >
            <ActivityIndicator />
          </View>
        ) : isUnavailable ? (
          <View>
            <Icon name="warning" />
          </View>
        ) : null}
      </View>
    </TouchableHighlight>
  );
});

const styles = StyleSheet.create({
  countBadge: {
    minWidth: 20,
    minHeight: 20,
    borderRadius: 50,
    position: 'absolute',
    right: 3,
    bottom: 3,
    justifyContent: 'center',
  },
  countBadgeText: {
    color: '#fff',
    fontWeight: 'bold',
    alignSelf: 'center',
    padding: 'auto',
  },
});
