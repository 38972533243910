import { useQuery } from '@apollo/client';
import { StyleProp, ViewStyle } from 'react-native';

import { Heading, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { card } from '@oui/app-core/src/styles';
import { graphql } from '@oui/lib/src/graphql/tada';

import { PatientSupporterCard } from '@src/components/PatientSupporterCard';

// @no-fast-refresh-strip
export const PatientSupportersQuery = graphql(`
  query PatientSupporters {
    user {
      ID
      role {
        ID
        supporters {
          ID
          supporter {
            ID
            user {
              ID
              givenName
              familyName
            }
          }
          claimed
          relation
          reviewed
        }
      }
    }
  }
`);

export function PatientSupporters({ style }: { style?: StyleProp<ViewStyle> }) {
  const { $t } = useI18n();

  const { data, loading } = useQuery(PatientSupportersQuery);

  if (loading || !data?.user?.role?.supporters.length) {
    return null;
  }

  return (
    <View testID="PatientSupporters" style={style}>
      <View
        spacing={12}
        style={{
          marginBottom: 24,
        }}
      >
        <Heading
          level={2}
          text={$t({
            id: 'PatientSupporters_support',
            defaultMessage: 'Support',
          })}
        />
        <Text
          size={15}
          text={$t({
            id: 'PatientSupporters_title',
            defaultMessage:
              'Support people have their own app that describes what you are learning with Aviva.',
          })}
        />
      </View>
      <View>
        {data?.user?.role?.supporters
          .filter((i) => i.claimed)
          .map(({ supporter: { ID, user }, relation }) => (
            <View
              testID={`PatientSupporters_supporter_${ID}`}
              key={ID}
              style={[card, { paddingHorizontal: 15, paddingVertical: 20, marginBottom: 20 }]}
              spacing={15}
            >
              <PatientSupporterCard
                name={`${user.givenName} ${user.familyName}`}
                relation={relation}
              />
            </View>
          ))}
      </View>
    </View>
  );
}
