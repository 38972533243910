/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      d="M9 10.85c-3.43 0-7.27 2-7.6 2.69-.27.56-.4 2.3-.4 2.3 0 .64.54 1.16 1.2 1.16h13.6c.66 0 1.2-.52 1.2-1.15 0 0-.13-1.75-.4-2.31-.33-.7-4.17-2.7-7.6-2.7Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M9 7.15A3.08 3.08 0 1 0 9 1a3.08 3.08 0 0 0 0 6.15Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
