import { registerChatArtifactPreviewComponent } from '@oui/app-core/src/components/ChatArtifactPreview';
import { registerChatInputArtifactHandler } from '@oui/app-core/src/components/ChatInputArtifact';
import { graphql } from '@oui/lib/src/graphql/tada';

import { MyPlanReview } from './screens/MyPlanReview/MyPlanReview';
import { MyPlanRounded } from './screens/MyPlanRounded';
import { SoloMyPlan } from './screens/SoloMyPlan';
import { SoloRiskCurve } from './screens/SoloRiskCurve';
import { SoloSuicideMode } from './screens/SoloSuicideMode';

registerChatArtifactPreviewComponent('MyPlan', () => MyPlanRounded);
registerChatArtifactPreviewComponent('MyPlanReview', () => MyPlanReview);
registerChatArtifactPreviewComponent('RiskCurve', () => SoloRiskCurve);
registerChatArtifactPreviewComponent('SoloMyPlan', () => SoloMyPlan);
registerChatArtifactPreviewComponent('SoloSuicideMode', () => SoloSuicideMode);
registerChatArtifactPreviewComponent('SuicideMode', () => SoloSuicideMode);
// Technically this should use productConfig.crisisFramework to determine which component to render
// but in practice the preview looks the same
registerChatArtifactPreviewComponent('CrisisFramework', () => SoloRiskCurve);

const CrisisFrameworkQuery = graphql(`
  query CrisisFramework {
    user {
      ID
      role {
        ID
        productConfig {
          __typename
          ... on RoleAvivaConfig {
            crisisFramework
          }
        }
      }
    }
  }
`);

registerChatInputArtifactHandler(
  'CrisisFramework',
  async ({ startArtifactRequest, apollo, navigate, params }) => {
    const response = await apollo.query({ query: CrisisFrameworkQuery });
    const crisisFramework =
      response.data.user?.role?.productConfig?.__typename === 'RoleAvivaConfig' &&
      response.data.user.role.productConfig.crisisFramework;

    const artifactName = crisisFramework === 'SUICIDE_MODE' ? 'SoloSuicideMode' : 'SoloRiskCurve';
    startArtifactRequest(artifactName);
    navigate(artifactName, {
      step: '',
      ...params,
    });
  },
);

export * from './types/navigation';
export * from './register';
export { ClinicianControlledMyStoryMyPlanPreview } from './screens/ControlledMyStoryMyPlan';
