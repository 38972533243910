import { useMutation } from '@apollo/client';
import {
  useFocusEffect,
  useNavigation,
  useNavigationState,
  useRoute,
} from '@react-navigation/core';
import hexToRgba from 'hex-to-rgba';
import { produce } from 'immer';
import random from 'lodash/random';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ScrollView } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { AnimatedProgressCircle } from '@oui/app-core/src/components/AnimatedProgressCircle';
import { Button } from '@oui/app-core/src/components/Button';
import { HeaderButtons, HeaderItem } from '@oui/app-core/src/components/HeaderButtons';
import { Markdown } from '@oui/app-core/src/components/Markdown';
import { Label } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { SelfPatientQuery, useCurrentPatient } from '@oui/app-core/src/hooks/useCurrentUser';
import { usePersistedState } from '@oui/app-core/src/hooks/usePersistedState';
import { logEvent } from '@oui/app-core/src/lib/log';
import { QuizSet } from '@oui/app-core/src/screens/QuizSet';
import { useTheme } from '@oui/app-core/src/styles';
import { NumberParam } from '@oui/app-core/src/types/navigation';
import { getStaticSession } from '@oui/lib/src/getStaticSession';
import { graphql } from '@oui/lib/src/graphql/tada';
import { ProductVariant } from '@oui/lib/src/types';

import { StaticSessionCover } from '../components/StaticSessionCover';
import { StaticStackScreenProps } from '../types/navigation';

function useStopwatch() {
  const timeout = useRef<NodeJS.Timeout>();
  const startMs = useRef<number>(0);
  const [durationMs, setDurationMs] = useState(0);

  useEffect(() => {
    return () => clearTimeout(timeout.current);
  }, []);

  const start = useCallback(() => {
    function advance() {
      startMs.current = Date.now();
      timeout.current = setTimeout(() => {
        setDurationMs((dur) => {
          const newDur = dur + (Date.now() - startMs.current);
          advance();
          return newDur;
        });
      }, 250);
    }
    advance();
  }, []);

  const stop = useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = undefined;
      setDurationMs((dur) => dur + (Date.now() - startMs.current));
    }
  }, []);

  return { start, stop, durationMs };
}

export const SetStaticSessionCompleteMutation = graphql(`
  mutation SetStaticSessionComplete($content: ContentType!) {
    setOuiProgress(content: $content, value: 1) {
      content
      completion
      completed
      updatedAt
    }
  }
`);

function ProgressBar({ completion }: { completion: number }) {
  const { theme } = useTheme();
  return (
    <View
      style={{
        height: 6,
        left: 0,
        right: 0,
        top: 0,
        position: 'absolute',
        backgroundColor: hexToRgba(theme.color.accentTwo100, 0.3),
      }}
    >
      <View
        style={{
          left: 0,
          width: `${completion}%`,
          height: '100%',
          backgroundColor: theme.color.accentTwo100,
        }}
      />
    </View>
  );
}

export function StaticSession() {
  const IS_TESTING = global.e2e || process.env.STORYBOOK_CLIENT;
  const STATIC_DELAY_DURATION = IS_TESTING ? 1500 : 60000;

  const { theme } = useTheme();
  const insets = useSafeAreaInsets();
  const [setStaticSessionComplete] = useMutation(SetStaticSessionCompleteMutation);
  const role = useCurrentPatient();
  const navigation = useNavigation<StaticStackScreenProps<'StaticSession'>['navigation']>();
  const route = useRoute<StaticStackScreenProps<'StaticSession'>['route']>();
  const contentType = route.params.slug;
  const pageIndex = Number.parseInt(route.params.page ?? 0);
  const [, setStaticSessionPages] = usePersistedState('staticSessionCurrentPages', {});
  const previousRoute = useNavigationState((state) => {
    const firstStaticSession = state.routes.findIndex((r) => r.name === 'StaticSession');
    if (firstStaticSession !== -1 && firstStaticSession !== 0) {
      return state.routes[firstStaticSession - 1];
    }
    return null;
  });

  const { durationMs, start, stop } = useStopwatch();

  useEffect(() => {
    start();
  }, [start]);

  useEffect(() => {
    if (durationMs > STATIC_DELAY_DURATION) {
      stop();
    }
  }, [durationMs, STATIC_DELAY_DURATION, stop]);

  useFocusEffect(
    useCallback(() => {
      setStaticSessionPages((staticSessionPages) => {
        return produce(staticSessionPages, (draft) => {
          draft[contentType] = pageIndex;
        });
      });
    }, [contentType, pageIndex, setStaticSessionPages]),
  );

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: previousRoute
        ? ({ tintColor }) => {
            return (
              <HeaderButtons>
                <HeaderItem
                  testID="StaticSession_exitButton"
                  aria-label="Leave session"
                  iconName={(route.params.previousScreenIcon as keyof AppCore.IconGlyphs) ?? 'home'}
                  title=""
                  color={tintColor}
                  onPress={() => {
                    navigation.navigate({ key: previousRoute?.key });
                  }}
                />
              </HeaderButtons>
            );
          }
        : () => null,
    });
  }, [navigation, route.params, previousRoute]);

  const nextPageTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  useEffect(() => {
    return () => clearTimeout(nextPageTimeoutRef.current);
  }, [route.params.page]);

  if (!role) return null;

  const session = getStaticSession({
    contentType,
    productSlug: role.product.slug as ProductVariant,
    roleType: role.roleType,
    productStatic: role.productStatic,
  });
  const content = session[pageIndex];
  const completion = Math.floor((pageIndex / (session.length - 1)) * 100);
  const hasNextPage = pageIndex < session.length - 1;
  const patientID = role.ID;

  async function markAsComplete() {
    setStaticSessionPages((staticSessionPages) => {
      return produce(staticSessionPages, (draft) => {
        delete draft[contentType];
      });
    });
    await setStaticSessionComplete({
      variables: { content: contentType },
      update: (cache, result) => {
        const data = cache.readQuery({
          query: SelfPatientQuery,
          variables: { patientID },
        });
        const newProgress = result.data?.setOuiProgress;
        if (newProgress && data?.user?.role) {
          const newData = produce(data, (draft) => {
            if (draft?.user?.role) {
              draft.user.role.progress = [
                ...draft.user.role.progress.filter((p) => p.content !== contentType),
                newProgress,
              ];
            }
          });
          cache.writeQuery({
            query: SelfPatientQuery,
            variables: { patientID },
            data: newData,
          });
        }
      },
    }).then(() => logEvent(`session_completed`, { session: contentType }));
  }

  async function onNext() {
    const newPage = pageIndex + 1;

    if (role?.productStatic) {
      await new Promise((res) => {
        nextPageTimeoutRef.current = setTimeout(res, IS_TESTING ? 500 : random(2, 4) * 1000);
      });
    }

    navigation.push('StaticSession', {
      ...route.params,
      page: newPage.toString() as NumberParam,
    });
  }

  if (typeof content !== 'string') {
    switch (content.type) {
      case 'QuizSet':
        return <QuizSet slug={content.slug} onDone={onNext} />;
      case 'SessionCover':
        return (
          <View style={{ flex: 1, paddingBottom: insets.bottom }}>
            <StaticSessionCover title={content.title} onBack={navigation.goBack} onNext={onNext} />
          </View>
        );
    }
  }

  return (
    <View style={{ flex: 1, paddingBottom: insets.bottom }}>
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{
          paddingHorizontal: 40,
          paddingTop: 20,
          paddingBottom: 20,
        }}
        testID={`StaticSession_${pageIndex}`}
      >
        <Markdown>{content}</Markdown>
        <View row style={{ marginTop: 20, justifyContent: 'flex-end' }}>
          {role.productStatic && durationMs < STATIC_DELAY_DURATION ? (
            <View
              style={{
                borderRadius: 100,
                paddingHorizontal: 15,
                paddingVertical: 10,
                backgroundColor: theme.color.gray800,
                gap: 10,
                flexDirection: 'row',
              }}
              // @ts-expect-error
              dataSet={{ chromatic: 'ignore' }}
            >
              <AnimatedProgressCircle
                text=""
                progress={durationMs / STATIC_DELAY_DURATION}
                size={20}
                strokeWidth={2}
                color={theme.color.gray100}
              />
              <Label small text={`${Math.floor((STATIC_DELAY_DURATION - durationMs) / 1000)}s`} />
            </View>
          ) : hasNextPage ? (
            <Button testID="StaticSession_nextButton" text="Next" onPress={onNext} />
          ) : (
            <Button
              testID="StaticSession_finishButton"
              text="Finish"
              onPress={async () => {
                await markAsComplete();
                if (previousRoute) {
                  navigation.navigate({ key: previousRoute.key });
                } else {
                  navigation.goBack();
                }
              }}
            />
          )}
        </View>
      </ScrollView>
      {role.productStatic ? null : <ProgressBar completion={completion} />}
    </View>
  );
}
