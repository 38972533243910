/* This file is autogenerated by @oui/cli */
import Svg, { Path, Rect, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={24} height={21} viewBox="0 0 24 21" fill="none" {...props}>
    <Rect
      x={1}
      y={1}
      width={21.89}
      height={18.25}
      rx={3}
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M8.98 8.63h1.93V7.3a1.3 1.3 0 0 0-1.28-1.32H5.77c-.71 0-1.29.6-1.29 1.32v5.31c0 .73.58 1.33 1.29 1.33h3.86c.7 0 1.28-.6 1.28-1.33v-1.33H8.98v.67H6.41V7.97h2.57v.66Z"
      fill="currentColor"
    />
    <Path
      d="M17.98 8.63h1.93V7.3a1.3 1.3 0 0 0-1.29-1.32h-3.85c-.72 0-1.29.6-1.29 1.32v5.31c0 .73.57 1.33 1.29 1.33h3.85c.72 0 1.29-.6 1.29-1.33v-1.33h-1.93v.67h-2.57V7.97h2.57v.66Z"
      fill="currentColor"
    />
  </Svg>
);
export default SvgComponent;
