import { useNavigation, useRoute } from '@react-navigation/native';
import { Image } from 'expo-image';
import { produce } from 'immer';
import { ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { SystemBars } from 'react-native-edge-to-edge';
import { Line, Path, Svg } from 'react-native-svg';
import { Writable } from 'type-fest';
import { v4 as uuid } from 'uuid';

import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import { Button } from '@oui/app-core/src/components/Button';
import {
  CardStack,
  Card as CardStackCard,
  CardStackRef,
} from '@oui/app-core/src/components/CardStack';
import { CheckboxListInput } from '@oui/app-core/src/components/CheckboxListInput';
import { ExplanationVideo } from '@oui/app-core/src/components/ExplanationVideo/ExplanationVideo';
import { HeaderButtons, HeaderItem } from '@oui/app-core/src/components/HeaderButtons';
import { RadioInput } from '@oui/app-core/src/components/RadioInput';
import {
  RoundedSection,
  RoundedSectionTopChild,
} from '@oui/app-core/src/components/RoundedSection';
import { ScrollToTop, ScrollViewContext } from '@oui/app-core/src/components/ScrollView';
import { Heading, Label, Text } from '@oui/app-core/src/components/Text';
import { UnorderedList } from '@oui/app-core/src/components/UnorderedList';
import { View } from '@oui/app-core/src/components/View';
import { useArtifactRequest } from '@oui/app-core/src/hooks/useArtifactResult';
import { useMyStoryMyPlanCompositionSections } from '@oui/app-core/src/hooks/useComposition';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';
import type { CrisisTimeline, PeakMethod, RiskFactors } from '@oui/lib/src/types/avro';

import SuicideModeA from '../assets/soloOnboarding/SuicideMode-A.svg';
import SuicideModeBEventsZoom from '../assets/soloOnboarding/SuicideMode-B_EventsZoom.svg';
import SuicideModeBLarge from '../assets/soloOnboarding/SuicideMode-B_Large.svg';
import SuicideModeB from '../assets/soloOnboarding/SuicideMode-B.svg';
import SuicideModeCOverview from '../assets/soloOnboarding/SuicideMode-C_Overview.svg';
import SuicideModeC from '../assets/soloOnboarding/SuicideMode-C.svg';
import { getCrisisTimelineSections } from '../components/CrisisTimelineList';
import { MobileHeaderStep } from '../components/PatientMyStoryMyPlanContainer';
import { RiskCurveBaselineGraph } from '../components/RiskCurveBaselineGraph';
import { SoloOnboardingCard, SoloOnboardingStepBadge } from '../components/SoloOnboarding';
import {
  FALLING_SECTIONS,
  formatCrisisPeakText,
  getCheckboxListInputProps,
  LETHAL_METHODS,
  RISING_SECTIONS,
  useRiskFactorsState,
} from '../lib/soloOnboarding';
import { StackScreenProps } from '../types/navigation';

function SuicideModeSection(props: {
  title: string;
  items: Array<{
    ID: string;
    text: string;
  }>;
  padding?: 'large' | 'small';
  weight?: 'semibold';
  style?: StyleProp<ViewStyle>;
  severity: 0 | 1 | 2;
}) {
  const backgroundColor = ['#fdfbfb', '#fcf6f6', '#faf1f1'][props.severity];

  return (
    <View
      style={[
        {
          borderWidth: 1,
          borderRadius: 10,
          padding: props.padding === 'large' ? 16 : 10,
          backgroundColor,
        },
        props.style,
      ]}
    >
      <Label text={props.title} />
      <UnorderedList
        items={props.items.map((i) => i.text)}
        style={{ marginTop: props.padding === 'large' ? 10 : 5 }}
        weight={props.weight === 'semibold' ? undefined : 'normal'}
      />
    </View>
  );
}

function SoloSuicideModeStepOverview(props: StackScreenProps<'SoloSuicideMode'>) {
  const { theme } = useTheme();

  return (
    <>
      <RoundedSectionTopChild backgroundColor={theme.color.gray800}>
        <Heading
          level={2}
          text="Three steps to organizing"
          textAlign="center"
          style={{ marginBottom: 20, marginTop: 12 }}
        />
        <SoloOnboardingCard
          step="A"
          title="Set your baseline"
          text="Everyone has a different risk starting point. Here, you’ll select risk factors for suicide to set your risk baseline."
          graphic={SuicideModeA}
          style={{ marginBottom: 20 }}
        />
        <SoloOnboardingCard
          step="B"
          title="Identify events"
          text="Stressful events in life can cause your suicide risk to rise. It helps to note situations that may have contributed to your crisis."
          graphic={SuicideModeB}
          style={{ marginBottom: 20 }}
        />
        <SoloOnboardingCard
          step="C"
          title="Organize what happened"
          text="It helps to group things to better understand what led up to your suicide crisis. This will be used later in MyPlan."
          graphic={SuicideModeC}
        />
      </RoundedSectionTopChild>
      <Button
        text="Start"
        onPress={() => props.navigation.setParams({ step: 'a-overview' })}
        alignSelf="center"
        style={{ marginTop: 20 }}
        testID="SoloSuicideModeStepOverview_nextButton"
      />
    </>
  );
}

function SoloSuicideModeStepAOverview(props: StackScreenProps<'SoloSuicideMode'>) {
  const { theme } = useTheme();

  return (
    <>
      <RoundedSectionTopChild backgroundColor={theme.color.gray800}>
        <Heading
          level={2}
          text="Set your baseline"
          textAlign="center"
          style={{ marginBottom: 40, marginTop: 12 }}
        />
        <RiskCurveBaselineGraph
          backgroundColor={theme.color.gray800}
          numRiskFactors={3}
          aria-label="A risk curve showing elevated baseline risk"
        />
      </RoundedSectionTopChild>
      <Text
        text={`In this first part, you'll mark your risk factors for suicide.

Baseline risk factors are things about you or things that have happened to you that increase your likelihood of becoming suicidal.

These set your baseline risk for your risk curve.`}
        style={{ marginBottom: 25 }}
      />
      <ExplanationVideo
        variant="block"
        muxUrl="https://stream.mux.com/X00JHn02v01RkGF02oLi00jcdqjVl5yHvSFcnu7DGhyPFrTM.m3u8"
      />
      <Button
        text="Next"
        onPress={() => props.navigation.setParams({ step: 'a-riskfactors' })}
        alignSelf="center"
        style={{ marginTop: 40 }}
        testID="SoloSuicideModeStepAOverview_nextButton"
      />
    </>
  );
}

function SoloSuicideModeStepARiskFactors(props: StackScreenProps<'SoloSuicideMode'>) {
  const { data, update } = useMyStoryMyPlanCompositionSections();
  const { theme } = useTheme();
  const { value, inputProps } = useRiskFactorsState(
    (data?.RISK_FACTORS as Writable<RiskFactors>) ?? [],
  );

  async function save() {
    await update({ RISK_FACTORS: value });
    props.navigation.setParams({ step: 'a-review' });
  }

  return (
    <>
      <RoundedSectionTopChild backgroundColor={theme.color.gray800}>
        <Heading
          level={2}
          text="Set your baseline"
          textAlign="center"
          style={{ marginBottom: 40, marginTop: 12 }}
        />
        <RiskCurveBaselineGraph
          baselineText="Your baseline"
          backgroundColor={theme.color.gray800}
          numRiskFactors={value.length}
        />
      </RoundedSectionTopChild>
      <CheckboxListInput
        label="What are your risk factors?"
        {...inputProps}
        testID="SoloSuicideModeStepARiskFactors_input"
      />
      <Button
        text="Set"
        onPress={save}
        alignSelf="center"
        style={{ marginTop: 40 }}
        testID="SoloSuicideModeStepARiskFactors_nextButton"
      />
    </>
  );
}

function SoloSuicideModeStepAReview(props: StackScreenProps<'SoloSuicideMode'>) {
  const { data } = useMyStoryMyPlanCompositionSections();
  const numRiskFactors = data?.RISK_FACTORS?.length ?? 0;

  return (
    <>
      <Heading text="Ok. Set" textAlign="center" style={{ marginBottom: 16 }} level={2} />
      <Text
        text={`You have ${
          numRiskFactors === 1 ? '1 risk factor' : `${numRiskFactors} risk factors`
        } for suicide that move your baseline risk up. That means you have a higher likelihood of becoming suicidal.`}
        style={{ marginBottom: 40 }}
      />
      <RiskCurveBaselineGraph baselineText="Your baseline" numRiskFactors={numRiskFactors} />
      <View style={{ marginTop: 30 }}>
        <SuicideModeSection
          title="Baseline risk factors"
          items={data?.RISK_FACTORS ?? []}
          padding="large"
          severity={0}
        />
      </View>
      <View style={{ marginTop: 40, justifyContent: 'space-between' }} row>
        <Button
          text="Back"
          variant="text"
          onPress={() => props.navigation.setParams({ step: 'a-riskfactors' })}
        />
        <Button
          text="Next"
          onPress={() => props.navigation.setParams({ step: 'b-overview' })}
          testID="SoloSuicideModeStepAReview_nextButton"
        />
      </View>
    </>
  );
}

function SoloSuicideModeStepBOverview(props: StackScreenProps<'SoloSuicideMode'>) {
  return (
    <>
      <Heading
        level={2}
        text="Identify events"
        textAlign="center"
        style={{ marginBottom: 40, marginTop: 12 }}
      />
      <Image
        source={SuicideModeBLarge}
        contentFit="contain"
        style={{ width: '100%', aspectRatio: 343 / 203 }}
      />
      <View
        style={{
          paddingTop: 40,
          paddingBottom: 25,
        }}
      >
        <Text text="Next, we’re going to focus on identifying stressful situations or problems that led up to your recent suicide crisis." />
      </View>
      <ExplanationVideo
        variant="block"
        muxUrl="https://stream.mux.com/KX02bY008FfRhaaEjZ8cNpUQVBN001dXz01202H5r00Aqnbd00.m3u8"
      />
      <Button
        text="Next"
        onPress={() => props.navigation.setParams({ step: 'b-events' })}
        alignSelf="center"
        style={{ marginTop: 30 }}
        testID="SoloSuicideModeStepBOverview_nextButton"
      />
    </>
  );
}

function SoloSuicideModeStepBEvents(props: StackScreenProps<'SoloSuicideMode'>) {
  const { data, update } = useMyStoryMyPlanCompositionSections();
  const [timeline, setTimeline] = useState<NonNullable<typeof data>['CRISIS_TIMELINE']['timeline']>(
    () => getCrisisTimelineSections(data?.CRISIS_TIMELINE!).before,
  );

  async function save() {
    if (data) {
      const { peak, after } = getCrisisTimelineSections(data.CRISIS_TIMELINE);
      await update({
        CRISIS_TIMELINE: { ...data.CRISIS_TIMELINE, timeline: [...timeline, ...peak, ...after] },
      });
    }
  }

  const checkedKeysSet = useMemo(() => {
    const keySet = new Set<string>();

    Object.values(RISING_SECTIONS).forEach((section) => {
      Object.entries(section).forEach(([key, value]) => {
        if (timeline.find((e) => e.text.startsWith(value))) {
          keySet.add(key);
        }
      });
    });

    return keySet;
  }, [timeline]);

  function getSectionProps<T extends typeof RISING_SECTIONS | typeof FALLING_SECTIONS>(
    collection: T,
    section: keyof T,
    suicideModeSection: NonNullable<CrisisTimeline['timeline'][number]['suicideModeSection']>,
  ) {
    return getCheckboxListInputProps({
      checkedKeysSet,
      collection,
      section,
      suicideModeSection,
      setTimeline,
      timeline,
    });
  }

  return (
    <>
      <Heading
        level={2}
        text="Events"
        textAlign="center"
        style={{ marginBottom: 20, marginTop: 12 }}
      />
      <CheckboxListInput
        label={`In the days and hours before the peak of your crisis...\n\nWhat events happened?`}
        defaultFollowup="Describe"
        followups={{}}
        {...getSectionProps(RISING_SECTIONS, 'beforeEvents', 'beforeEvents')}
        testID="SoloSuicideModeStepBEvents_input"
      />
      <Button
        text="Set"
        onPress={async () => {
          await save();
          props.navigation.setParams({ step: 'b-review' });
        }}
        alignSelf="center"
        style={{ marginTop: 40 }}
        testID="SoloSuicideModeStepBEvents_nextButton"
      />
    </>
  );
}

function SoloSuicideModeStepBReview(props: StackScreenProps<'SoloSuicideMode'>) {
  const { theme } = useTheme();
  const { $t } = useI18n();
  const { data } = useMyStoryMyPlanCompositionSections();
  const events =
    data?.CRISIS_TIMELINE.timeline.filter((e) => e.suicideModeSection === 'beforeEvents') ?? [];

  return (
    <>
      <Heading
        level={2}
        text="Events"
        textAlign="center"
        style={{ marginBottom: 20, marginTop: 12 }}
      />
      <Text
        text={$t(
          {
            id: 'SoloSuicideModeStepBReview_description',
            defaultMessage:
              'You have identified {numEvents, plural, one{1 event} other{# events}} that led up to your recent crisis.\n\nThese events and your baseline risk factors made you vulnerable to suicide crisis.',
          },
          {
            numEvents: events.length,
          },
        )}
        style={{ paddingHorizontal: 20 }}
      />
      <View
        style={{
          paddingHorizontal: 20,
          marginTop: 20,
          marginBottom: -10,
        }}
      >
        <Image
          source={SuicideModeBEventsZoom}
          style={{
            width: '98%',
            aspectRatio: 294 / 126,
          }}
          contentFit="contain"
        />
      </View>
      <View style={{ paddingHorizontal: 20 }}>
        <SuicideModeSection title="Events" items={events} padding="large" severity={0} />
      </View>
      <Button
        variant="text"
        text="Edit"
        icon="edit"
        onPress={() => props.navigation.setParams({ step: 'b-events' })}
        alignSelf="center"
        color={theme.color.gray400}
      />
      <Button
        text="Next"
        onPress={() => props.navigation.setParams({ step: 'c-overview' })}
        alignSelf="center"
        style={{ marginTop: 30 }}
        testID="SoloSuicideModeStepBReview_nextButton"
      />
    </>
  );
}

function SoloSuicideModeStepCOverview(props: StackScreenProps<'SoloSuicideMode'>) {
  return (
    <>
      <Heading
        level={2}
        text="Crisis"
        textAlign="center"
        style={{ marginBottom: 40, marginTop: 12 }}
      />
      <Image
        source={SuicideModeCOverview}
        style={{ width: 147, aspectRatio: 147 / 117, alignSelf: 'center' }}
      />
      <View
        style={{
          paddingTop: 40,
          paddingBottom: 25,
        }}
      >
        <Text text="Lastly, sort your feelings, thoughts, behaviors, and physical sensations in the days and hours leading up peak of your crisis. Then what happened at the moment you wanted to take your own life." />
      </View>
      <ExplanationVideo
        variant="block"
        muxUrl="https://stream.mux.com/sqGhBFcnDKjoab16BorY01MNH5F1dMJR2bjP00FlsLRN4.m3u8"
      />
      <Button
        text="Next"
        onPress={() => props.navigation.setParams({ step: 'c-inputs' })}
        alignSelf="center"
        style={{ marginTop: 30 }}
        testID="SoloSuicideModeStepCOverview_nextButton"
      />
    </>
  );
}

function SoloSuicideModeStepCInputs(props: StackScreenProps<'SoloSuicideMode'>) {
  const { scrollTo } = useContext(ScrollViewContext);
  const cardsRef = useRef<CardStackRef>(null);
  const { data, update } = useMyStoryMyPlanCompositionSections();
  const [timeline, setTimeline] = useState<NonNullable<typeof data>['CRISIS_TIMELINE']['timeline']>(
    () => getCrisisTimelineSections(data?.CRISIS_TIMELINE!).before,
  );

  const checkedKeysSet = useMemo(() => {
    const keySet = new Set<string>();

    Object.values(RISING_SECTIONS).forEach((section) => {
      Object.entries(section).forEach(([key, value]) => {
        if (timeline.find((e) => e.text.startsWith(value))) {
          keySet.add(key);
        }
      });
    });

    return keySet;
  }, [timeline]);

  function getSectionProps<T extends typeof RISING_SECTIONS | typeof FALLING_SECTIONS>(
    collection: T,
    section: keyof T,
    suicideModeSection: NonNullable<CrisisTimeline['timeline'][number]['suicideModeSection']>,
  ) {
    return getCheckboxListInputProps({
      checkedKeysSet,
      collection,
      section,
      suicideModeSection,
      setTimeline,
      timeline,
    });
  }

  const [type, setType] = useState<'attempt' | 'ideation'>();
  const [checked, setChecked] = useState<PeakMethod[]>([]);
  const [followupsValue, setFollowupsValue] = useState<Record<string, string>>({});
  const { formatList } = useI18n();

  async function save() {
    if (data) {
      const crisisPeakText = formatCrisisPeakText({
        followupsValue,
        checked,
        type,
        formatList,
      });

      const crisisPeakID = data.CRISIS_TIMELINE.crisisPeakID;
      let crisisPeak = data.CRISIS_TIMELINE.timeline.find((event) => event.ID === crisisPeakID);
      if (crisisPeak) {
        crisisPeak = produce(crisisPeak, (draft) => {
          draft.text = crisisPeakText;
          draft.peakMethods = checked;
        });
      } else {
        crisisPeak = {
          ID: uuid(),
          text: crisisPeakText,
          isWarningSign: false,
          peakMethods: checked,
        };
      }

      await update({
        CRISIS_TIMELINE: { crisisPeakID: crisisPeak.ID, timeline: [...timeline, crisisPeak] },
      });
    }
  }

  return (
    <>
      <CardStack
        onIndexChanged={() => {
          scrollTo({ y: 0 });
          void save();
        }}
        hideNextButtonIndexes={[4, 5]}
        ref={cardsRef}
      >
        <CardStackCard header={<Heading level={2} text="Feelings" textAlign="center" />}>
          <CheckboxListInput
            label={`In the hours before the peak of your crisis...

What feelings did you have?`}
            defaultFollowup="Describe"
            followups={{}}
            {...getSectionProps(RISING_SECTIONS, 'feelings', 'feelings')}
            testID="SoloSuicideModeStepCInputs_feelingsInput"
          />
        </CardStackCard>
        <CardStackCard header={<Heading level={2} text="Thoughts" textAlign="center" />}>
          <CheckboxListInput
            label={`In the hours before the peak of your crisis...

What thoughts did you have?`}
            followups={{ other_thought: 'Describe' }}
            {...getSectionProps(RISING_SECTIONS, 'thoughts', 'thoughts')}
            testID="SoloSuicideModeStepCInputs_thoughtsInput"
          />
        </CardStackCard>
        <CardStackCard header={<Heading level={2} text="Behaviors" textAlign="center" />}>
          <CheckboxListInput
            label={`In the hours before the peak of your crisis...

What behaviors or things did you do?`}
            defaultFollowup="Describe"
            followups={{}}
            {...getSectionProps(RISING_SECTIONS, 'behaviors', 'behaviors')}
            testID="SoloSuicideModeStepCInputs_behaviorsInput"
          />
        </CardStackCard>
        <CardStackCard header={<Heading level={2} text="Sensations" textAlign="center" />}>
          <CheckboxListInput
            label={`In the hours before the peak of your crisis...

What bodily responses did you have?`}
            defaultFollowup="Describe"
            followups={{}}
            {...getSectionProps(RISING_SECTIONS, 'physiological', 'physiological')}
            testID="SoloSuicideModeStepCInputs_physiologicalInput"
          />
        </CardStackCard>
        <CardStackCard header={<Heading level={2} text="Suicide crisis" textAlign="center" />}>
          <RadioInput<'attempt' | 'ideation'>
            testID="SoloSuicideModeStepCInputs_eventInput"
            label="What happened at the height of your suicide crisis?"
            value={type}
            onChangeValue={(value) => {
              setType(value);
              setTimeout(() => {
                cardsRef.current?.next();
              }, 300);
            }}
            items={[
              { label: 'I made an attempt', value: 'attempt' },
              { label: 'I had strong thoughts and/or a plan', value: 'ideation' },
            ]}
          />
        </CardStackCard>
        <CardStackCard header={<Heading level={2} text="Suicide crisis" textAlign="center" />}>
          <CheckboxListInput
            testID="SoloSuicideModeStepCInputs_methodInput"
            label={
              type === 'attempt'
                ? 'How did you try to kill yourself?'
                : 'How did you think about killing yourself?'
            }
            defaultFollowup="Describe"
            followups={{}}
            onChangeFollowupsValue={setFollowupsValue}
            followupsValue={followupsValue}
            value={checked}
            onChangeValue={setChecked}
            items={LETHAL_METHODS}
          />
          <Button
            text="Next"
            onPress={async () => {
              await save();
              props.navigation.setParams({ step: 'c-review' });
            }}
            alignSelf="center"
            testID="SoloSuicideModeStepCInputs_nextButton"
          />
        </CardStackCard>
      </CardStack>
    </>
  );
}

function SuicideModeGraphStep(props: { step: 'A' | 'B' | 'C'; children: ReactNode }) {
  return (
    <View row spacing={10} style={{ alignItems: 'flex-start' }}>
      <View>
        <SoloOnboardingStepBadge step={props.step} size={25} />
      </View>
      <View flex={1}>{props.children}</View>
    </View>
  );
}

function CrisisArrow() {
  const { theme } = useTheme();
  return (
    <Svg width="18" height="45" viewBox="0 0 18 45" fill="none">
      <Path
        d="M2.5 33.2963L8.99639 42.7363L15.4928 33.2963"
        stroke={theme.color.danger}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Line
        x1="9"
        y1="2"
        x2="9"
        y2="40"
        stroke="#932A2A"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

function SuicideModeChartCInner() {
  const { data } = useMyStoryMyPlanCompositionSections();

  return data ? (
    <View>
      <View>
        <View row childFlex={1} style={{ alignItems: 'stretch' }}>
          <SuicideModeSection
            title="Feelings"
            items={
              data.CRISIS_TIMELINE.timeline.filter((i) => i.suicideModeSection === 'feelings') ?? []
            }
            style={{ borderRadius: 0, borderTopStartRadius: 10 }}
            severity={1}
          />
          <SuicideModeSection
            title="Thoughts"
            items={
              data.CRISIS_TIMELINE.timeline.filter((i) => i.suicideModeSection === 'thoughts') ?? []
            }
            style={{ borderRadius: 0, borderTopEndRadius: 10, borderStartWidth: 0 }}
            severity={1}
          />
        </View>
        <View row childFlex={1} style={{ alignItems: 'stretch' }}>
          <SuicideModeSection
            title="Behaviors"
            items={
              data.CRISIS_TIMELINE.timeline.filter((i) => i.suicideModeSection === 'behaviors') ??
              []
            }
            style={{ borderRadius: 0, borderBottomStartRadius: 10, borderTopWidth: 0 }}
            severity={1}
          />
          <SuicideModeSection
            title="Sensations"
            items={
              data.CRISIS_TIMELINE.timeline.filter(
                (i) => i.suicideModeSection === 'physiological',
              ) ?? []
            }
            style={{
              borderRadius: 0,
              borderBottomEndRadius: 10,
              borderTopWidth: 0,
              borderStartWidth: 0,
            }}
            severity={1}
          />
        </View>
      </View>
      <View style={{ alignSelf: 'center' }}>
        <CrisisArrow />
      </View>
      <SuicideModeSection
        title="Suicide crisis"
        items={
          data.CRISIS_TIMELINE.timeline.filter((i) => i.ID === data.CRISIS_TIMELINE.crisisPeakID) ??
          []
        }
        weight="semibold"
        severity={2}
      />
    </View>
  ) : null;
}

function SoloSuicideModeStepCReview(props: StackScreenProps<'SoloSuicideMode' | 'SuicideMode'>) {
  const { theme } = useTheme();
  const { data } = useMyStoryMyPlanCompositionSections();

  return data ? (
    <View>
      <View spacing={20}>
        <Heading level={2} text="You’ve organized what happened" textAlign="center" />
        <Text
          text="Take a look. Is this right? Is anything missing? Tap edit at the bottom to adjust anything."
          style={{ maxWidth: 290, alignSelf: 'center' }}
        />
        <SuicideModeChartCInner />
      </View>
      <View spacing={40}>
        <Button
          alignSelf="center"
          text="Edit"
          icon="edit"
          variant="text"
          onPress={() => {
            props.navigation.setParams({ step: 'c-inputs' });
          }}
          color={theme.color.gray400}
          testID="SoloSuicideModeStepCReview_editButton"
        />
        <Button
          alignSelf="center"
          text="Next"
          onPress={() => {
            props.navigation.setParams({ step: 'review' });
          }}
          testID="SoloSuicideModeStepCReview_nextButton"
        />
      </View>
    </View>
  ) : null;
}

function SoloSuicideModeReview(props: StackScreenProps<'SoloSuicideMode' | 'SuicideMode'>) {
  const navigation = useNavigation<StackScreenProps<'SoloSuicideMode'>['navigation']>();
  const { data } = useMyStoryMyPlanCompositionSections();

  useArtifactRequest(props.route.name, true);

  useEffect(() => {
    navigation.setOptions({
      headerRight: ({ tintColor }) => {
        return (
          <HeaderButtons>
            <HeaderItem
              title=""
              iconName="edit"
              color={tintColor}
              aria-label="Edit suicide mode"
              onPress={() => navigation.push('EditSuicideMode')}
              testID="SoloSuicideModeReview_editButton"
            />
          </HeaderButtons>
        );
      },
    });
  }, [navigation]);

  return data ? (
    <View spacing={20} testID="SoloSuicideModeReview">
      <Heading level={2} text="Your story organized" textAlign="center" />
      <SuicideModeGraphStep step="A">
        <SuicideModeSection title="Baseline" items={data.RISK_FACTORS ?? []} severity={0} />
      </SuicideModeGraphStep>
      <SuicideModeGraphStep step="B">
        <SuicideModeSection
          title="Events"
          items={
            data.CRISIS_TIMELINE.timeline.filter((i) => i.suicideModeSection === 'beforeEvents') ??
            []
          }
          severity={0}
        />
      </SuicideModeGraphStep>
      <SuicideModeGraphStep step="C">
        <SuicideModeChartCInner />
      </SuicideModeGraphStep>
      <View>
        <Button
          alignSelf="center"
          variant="solid"
          text="Done"
          onPress={() => {
            props.navigation.goBack();
          }}
          testID="SoloSuicideModeReview_doneButton"
        />
      </View>
    </View>
  ) : null;
}

export const SoloSuicideMode = () => {
  const navigation = useNavigation<StackScreenProps<'SoloSuicideMode'>['navigation']>();
  const route = useRoute<StackScreenProps<'SoloSuicideMode'>['route']>();
  const props = { navigation, route };
  const { theme } = useTheme();
  const step = props.route.params?.step ?? '';
  const showHeaderSteps = step !== 'overview' && step !== 'review';
  const { data } = useMyStoryMyPlanCompositionSections({ createIfUndefined: true });

  const setParams = props.navigation.setParams;
  useEffect(() => {
    // @ts-expect-error when this component is nested inside ChatArtifactPreview we may not be on the SoloSuicideMode screen
    if (route.name === 'Conversation') return;
    if (data) {
      const { peak } = getCrisisTimelineSections(data.CRISIS_TIMELINE);
      const timeline = data.CRISIS_TIMELINE ?? [];
      if (peak.length) {
        setParams({ step: 'review' });
      } else if (
        timeline.timeline.find((e) => ['beforeEvents'].includes(e.suicideModeSection ?? ''))
      ) {
        setParams({ step: 'c-overview' });
      } else if (data.RISK_FACTORS?.length) {
        setParams({ step: 'b-overview' });
      } else {
        setParams({ step: 'overview' });
      }
    }
    // eslint-disable-next-line
  }, [!!data, setParams, route.name]);

  return (
    <>
      <SystemBars style="light" />
      <RoundedSection
        applyHeaderOptions
        testID="SoloSuicideMode_scrollView"
        subtitle="Risk curve"
        title={
          <View style={showHeaderSteps ? { marginTop: -10 } : null}>
            <Text
              text="Risk curve"
              weight="bold"
              color="white"
              size={28}
              style={{
                lineHeight: 35,
                marginBottom: 20,
                textAlign: 'center',
              }}
            />
            {showHeaderSteps ? (
              <View row style={{ paddingBottom: 20 }}>
                <MobileHeaderStep
                  backgroundColor={theme.color.primary100}
                  num={1}
                  numText="A"
                  label="Baseline"
                  active={step.startsWith('a')}
                  complete={step.startsWith('b') || step.startsWith('c')}
                />
                <MobileHeaderStep
                  backgroundColor={theme.color.primary100}
                  num={2}
                  numText="B"
                  label="Events"
                  active={step.startsWith('b')}
                  complete={step.startsWith('c')}
                />
                <MobileHeaderStep
                  backgroundColor={theme.color.primary100}
                  num={3}
                  numText="C"
                  label="Crisis"
                  active={step.startsWith('c')}
                  complete={false}
                />
              </View>
            ) : null}
          </View>
        }
        color={theme.color.primary100}
      >
        <ScrollToTop key={step} />
        {!data || !step ? (
          <ActivityIndicator />
        ) : step === 'review' ? (
          <SoloSuicideModeReview {...props} />
        ) : step === 'overview' ? (
          <SoloSuicideModeStepOverview {...props} />
        ) : step === 'a-overview' ? (
          <SoloSuicideModeStepAOverview {...props} />
        ) : step === 'a-riskfactors' ? (
          <SoloSuicideModeStepARiskFactors {...props} />
        ) : step === 'a-review' ? (
          <SoloSuicideModeStepAReview {...props} />
        ) : step === 'b-overview' ? (
          <SoloSuicideModeStepBOverview {...props} />
        ) : step === 'b-events' ? (
          <SoloSuicideModeStepBEvents {...props} />
        ) : step === 'b-review' ? (
          <SoloSuicideModeStepBReview {...props} />
        ) : step === 'c-overview' ? (
          <SoloSuicideModeStepCOverview {...props} />
        ) : step === 'c-inputs' ? (
          <SoloSuicideModeStepCInputs {...props} />
        ) : step === 'c-review' ? (
          <SoloSuicideModeStepCReview {...props} />
        ) : null}
      </RoundedSection>
    </>
  );
};
