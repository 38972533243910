/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m11.5 2.97-1.6-1.6c-.5-.5-1.3-.5-1.8 0l-1.6 1.6H4.25c-.7 0-1.28.57-1.28 1.28V6.5l-1.6 1.6c-.5.5-.5 1.3 0 1.8l1.6 1.6v2.25c0 .7.57 1.28 1.28 1.28H6.5l1.6 1.6c.5.5 1.3.5 1.8 0l1.6-1.6h2.25c.7 0 1.28-.57 1.28-1.28V11.5l1.6-1.6c.5-.5.5-1.3 0-1.8l-1.6-1.6V4.25c0-.7-.57-1.28-1.28-1.28H11.5v0Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="m11 8-2.4 3L7 9.74"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
