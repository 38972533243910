/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      d="M17 2.2c0-.66-.54-1.2-1.2-1.2H7.4L1 7.4v8.4c0 .66.54 1.2 1.2 1.2h13.6c.66 0 1.2-.54 1.2-1.2V2.2Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2 14.2a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6Z"
      fill="currentColor"
    />
    <Path
      d="M10.2 10.6s.04-1.02.82-1.35c1-.44 1.58-1.29 1.58-1.94C12.6 6.03 11.53 5 10.2 5a2.35 2.35 0 0 0-2.4 2.3"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M7.4 1 1 7.4h6.4V1Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
