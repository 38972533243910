/* This file is autogenerated by @oui/cli */
import Svg, { G, Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg fill="none" height={18} viewBox="0 0 18 18" width={18} {...props}>
    <G
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      vectorEffect="non-scaling-stroke"
    >
      <Path d="M16.5 8.88 9 16.38l-7.5-7.5" />
      <Path d="M16.5 2.63 9 10.13l-7.5-7.5" />
    </G>
  </Svg>
);
export default SvgComponent;
