import { useQuery } from '@apollo/client';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import * as ScreenOrientation from 'expo-screen-orientation';
import { useCallback } from 'react';
import { Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { ArtifactButton } from '@oui/app-core/src/components/ArtifactButton';
import { LessonsLearned } from '@oui/app-core/src/components/LessonsLearned/LessonsLearned';
import { ProfileHeader } from '@oui/app-core/src/components/ProfileHeader/ProfileHeader';
import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { Heading } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useMyStoryMyPlanCompositionSections } from '@oui/app-core/src/hooks/useComposition';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { graphql } from '@oui/lib/src/graphql/tada';

import { PatientSupporters } from '@src/components/PatientSupporters';

import { TabScreenProps } from '../types';

export const RecordsQuery = graphql(`
  query UserProfileRecords {
    user {
      ID
      role {
        ID
        productConfig {
          __typename
          ... on RoleAvivaConfig {
            crisisFramework
          }
        }
        sessions(contentType: "MYPLAN") {
          status
        }
      }
    }
  }
`);

function Records() {
  const { $t } = useI18n();
  const { data: user } = useQuery(RecordsQuery);
  const { navigate } = useNavigation<TabScreenProps<'Profile'>['navigation']>();
  const { data } = useMyStoryMyPlanCompositionSections();
  const hasData = user?.user?.role?.sessions[0]?.status === 'COMPLETED' && !!data;

  const doneText = $t({ id: 'UserProfile_recordComplete', defaultMessage: 'Done' });
  const lockedText = $t({ id: 'UserProfile_recordLocked', defaultMessage: 'Complete session 1' });

  if (
    user?.user?.role?.productConfig &&
    'crisisFramework' in user.user.role.productConfig &&
    user.user.role.productConfig.crisisFramework === 'SUICIDE_MODE'
  ) {
    const suicideMode = $t({
      id: 'UserProfile_suicideModeTitle',
      defaultMessage: 'Risk curve',
    });
    return hasData ? (
      <ArtifactButton
        testID="ArtifactButton_SuicideMode"
        badgeText={doneText}
        assetName="SuicideMode"
        title={suicideMode}
        locked={false}
        onPress={() => navigate('SuicideMode', { step: 'review' })}
      />
    ) : (
      <ArtifactButton
        testID="ArtifactButton_SuicideMode__locked"
        bannerText={lockedText}
        assetName="SuicideMode"
        title={suicideMode}
        locked
      />
    );
  }

  const riskCurve = $t({ id: 'UserProfile_riskCurveTitle', defaultMessage: 'Risk curve' });

  return hasData ? (
    <ArtifactButton
      testID="ArtifactButton_RiskCurve"
      badgeText={doneText}
      assetName="RiskCurve"
      title={riskCurve}
      locked={false}
      onPress={() => navigate('RiskCurve', { step: 'review' })}
    />
  ) : (
    <ArtifactButton
      testID="ArtifactButton_RiskCurve__locked"
      bannerText={lockedText}
      assetName="RiskCurve"
      title={riskCurve}
      locked
    />
  );
}

export function UserProfile() {
  const { $t } = useI18n();
  const insets = useSafeAreaInsets();

  useFocusEffect(
    useCallback(() => {
      if (Platform.OS !== 'web') {
        void ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
        return () => ScreenOrientation.unlockAsync();
      }

      return;
    }, []),
  );

  return (
    <View style={{ flex: 1 }} testID="Profile">
      <ScrollView
        bottomOverflowColor="white"
        style={{ flex: 1 }}
        contentContainerStyle={{
          paddingTop: insets.top,
          backgroundColor: 'white',
          paddingBottom: 20,
        }}
        testID="Profile_scrollView"
      >
        <ProfileHeader />
        <LessonsLearned />
        <View>
          <Heading
            level={2}
            text={$t({
              id: 'UserProfile_recordsHeading',
              defaultMessage: 'Records',
            })}
            style={{
              paddingHorizontal: 20,
              marginBottom: 12,
            }}
          />
          <View style={{ paddingHorizontal: 20 }}>
            <Records />
            <PatientSupporters
              style={{
                marginTop: 25,
              }}
            />
          </View>
        </View>
      </ScrollView>
    </View>
  );
}
