import { NavigationContainerRef } from '@react-navigation/native';
import { locale } from 'expo-localization';
import { createContext, useContext } from 'react';
import { ImageSourcePropType } from 'react-native';

import { FLAGS } from '../constants';
import { DeeplinkConfigShape } from '../types/navigation';

export type DeeplinkOptions = DeeplinkConfigShape<string>;

export function useAppContext() {
  return useContext(AppContext);
}

const AppContext = createContext<{
  Logo: ImageSourcePropType | null;
  onboardingGraphic?: React.ReactNode;
  deeplinkConfig?: { screens: DeeplinkOptions };
  flags: AppCore.Flags;
  locale: string;
  navigationContainer: NavigationContainerRef<{}> | null;
  setNavigationContainer?: (ref: NavigationContainerRef<{}>) => void;
}>({
  Logo: null,
  deeplinkConfig: undefined,
  flags: FLAGS,
  // We don't currently have a good way to access nextjs libs in app-core, so we just assume
  // en if no locale is available via expo
  // https://github.com/ouihealth/oui-aviva/pull/477#discussion_r1112090711
  locale: locale || 'en',
  navigationContainer: null,
});

export default AppContext;
