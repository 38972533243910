/* This file is autogenerated by @oui/cli */
import Svg, { Path, Rect, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      d="M7.31 6.6s-.35.85-1.1 1.43c-.76.58-1.61.57-1.61.57h-.4v7.6h9.07c.44 0 .98-.3 1.2-.69 0 0 .83-1.29 1.63-3.71.8-2.42.88-3.62.88-3.62a1.32 1.32 0 0 0-1.36-1.58H11.4l.8-4.4c0-.66-.54-1.2-1.2-1.2H9.8L7.31 6.6Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Rect
      x={1}
      y={7.8}
      width={3.2}
      height={8.4}
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
