/* This file is autogenerated by @oui/cli */
import Svg, { Circle, Line, Rect, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Rect
      x={1}
      y={1}
      width={16}
      height={16}
      rx={3}
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Circle cx={4.98} cy={6.71} r={0.84} fill="currentColor" stroke="currentColor" />
    <Circle cx={13.01} cy={6.71} r={0.84} fill="currentColor" stroke="currentColor" />
    <Line
      x1={5.54}
      y1={13.3}
      x2={12.46}
      y2={13.3}
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
