import { useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { Image } from 'expo-image';
import { ReactNode, useEffect, useState } from 'react';

import { TextAccordion } from '@oui/app-core/src/components/Accordion';
import { useAppContext } from '@oui/app-core/src/components/AppContext';
import { Button } from '@oui/app-core/src/components/Button';
import { ConfirmationModal } from '@oui/app-core/src/components/ConfirmationModal';
import { HeaderButtons } from '@oui/app-core/src/components/HeaderButtons';
import { Icon } from '@oui/app-core/src/components/Icon';
import { OverflowMenu, OverflowMenuOption } from '@oui/app-core/src/components/OverflowMenu';
import { RoundedSection } from '@oui/app-core/src/components/RoundedSection';
import { SessionIncompleteLockCard } from '@oui/app-core/src/components/SessionCard';
import { OldHeading, Text } from '@oui/app-core/src/components/Text';
import { UnorderedList } from '@oui/app-core/src/components/UnorderedList';
import { View } from '@oui/app-core/src/components/View';
import {
  shouldSwitchThought,
  shouldTestThought,
  useThoughtDiaryEntryPractice,
} from '@oui/app-core/src/hooks/practices';
import { useProgressByContent } from '@oui/app-core/src/hooks/useCurrentUser';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';
import { parseGQLDateTime } from '@oui/lib/src/gqlDate';
import { graphql } from '@oui/lib/src/graphql/tada';
import { RatingType } from '@oui/lib/src/types/graphql.generated';

import ThoughtBubble from '@src/assets/thoughtDiary/thoughtBubble.svg';
import ThoughtBubbleAfter from '@src/assets/thoughtDiary/thoughtBubbleAfter.svg';
import Divider from '@src/components/Divider';
import { type ThoughtDiaryEntriesQueryName } from '@src/screens/ThoughtDiary';
import { StackScreenProps } from '@src/types';

const DeleteThoughtDiaryEntryMutation = graphql(`
  mutation DeleteThoughtDiaryEntry($practiceID: UUID!) {
    deleteThoughtDiaryEntry(practiceID: $practiceID)
  }
`);
export function SectionHeading({ icon, text }: { icon: ReactNode; text: string }) {
  return (
    <View row spacing={8}>
      {icon}
      <OldHeading text={text} />
    </View>
  );
}

export function BeliefBubbleTopChild(props: { after?: boolean; children?: ReactNode }) {
  return (
    <View
      style={{
        backgroundColor: props.after ? '#f1fcf4' : '#eee',
        borderBottomStartRadius: 0,
        borderBottomEndRadius: 0,
        borderTopStartRadius: 16,
        borderTopEndRadius: 16,
        padding: 10,
        marginTop: -22,
        marginHorizontal: -20,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {props.children}
    </View>
  );
}

function BubbleRating({ rating }: { rating: number }) {
  const { theme } = useTheme();
  const { $t } = useI18n();
  return (
    <>
      <View
        row
        spacing={15}
        accessible
        aria-label={$t(
          {
            id: 'ThoughtDiaryEntry_beliefRatingAccessibilityLabel',
            defaultMessage: 'Helpfulness rating: {rating} of 5',
          },
          { rating: rating },
        )}
      >
        <Text text={`${rating}/5`} size={17} weight="semibold" color={theme.color.gray200} />
        <Text
          text={$t({ id: 'ThoughtDiaryEntry_beliefRating', defaultMessage: 'Helpfulness rating' })}
          weight="semibold"
          color={theme.color.gray300}
        />
      </View>
    </>
  );
}

export function BeliefBubble({
  after,
  text,
  accordion,
  rating,
}: {
  after?: boolean;
  accordion?: boolean;
  text: string;
  rating: number;
}) {
  return (
    <View
      style={{
        padding: 14,
        backgroundColor: after ? '#f1fcf4' : '#eeeeee',
        alignItems: 'flex-start',
        borderRadius: 10,
      }}
      row
      spacing={15}
    >
      <View
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          width: 44,
          height: 44,
          borderRadius: 22,
          backgroundColor: after ? '#ccf4d7' : 'white',
        }}
      >
        {after ? (
          <Image
            source={ThoughtBubbleAfter}
            style={{ width: 22, aspectRatio: 1 }}
            contentFit="contain"
          />
        ) : (
          <Image
            source={ThoughtBubble}
            style={{ width: 22, aspectRatio: 1 }}
            contentFit="contain"
          />
        )}
      </View>
      <View style={{ flex: 1 }}>
        {accordion ? (
          <TextAccordion text={text}>
            <BubbleRating rating={rating} />
          </TextAccordion>
        ) : (
          <View spacing={15} style={{ flex: 1 }}>
            <Text text={`"${text}"`} />
            <BubbleRating rating={rating} />
          </View>
        )}
      </View>
    </View>
  );
}

function SpotIt() {
  const { theme } = useTheme();
  const { practiceValues, thoughtDiaryEntry } = useThoughtDiaryEntryPractice();
  const { $t } = useI18n();

  return (
    <View spacing={24}>
      <SectionHeading
        icon={<Icon name="eye" color={theme.color.dark} aria-label={undefined} />}
        text={$t({ id: 'ThoughtDiaryEntry_spot_heading', defaultMessage: 'Spot it' })}
      />
      <View spacing={10}>
        <Text
          weight="semibold"
          text={$t({
            id: 'ThoughtDiaryEntry_spot_oldThoughtHeading',
            defaultMessage: 'Old thought',
          })}
          size={17}
        />
        <BeliefBubble
          rating={
            practiceValues?.ratings.find((v) => v.type === RatingType.RATING_BEFORE)?.value ?? 0
          }
          text={thoughtDiaryEntry?.thoughtBefore ?? ''}
        />
      </View>
      <View row childFlex={1} style={{ alignItems: 'flex-start' }}>
        <View accessible>
          <Text
            weight="semibold"
            text={$t({ id: 'ThoughtDiaryEntry_spot_feeling', defaultMessage: 'Feelings' })}
            size={17}
          />
          <UnorderedList
            items={thoughtDiaryEntry?.feelingsBefore?.map((e) => e.text) ?? []}
            color={theme.color.gray400}
            weight="normal"
            size="small"
          />
        </View>
        <View accessible>
          <Text
            weight="semibold"
            text={$t({ id: 'ThoughtDiaryEntry_spotBehavior', defaultMessage: 'Behavior' })}
            size={17}
          />
          <Text text={thoughtDiaryEntry?.behavior ?? ''} />
        </View>
      </View>
    </View>
  );
}

function TestIt() {
  const { navigate } = useNavigation<StackScreenProps<'ThoughtDiaryEntry'>['navigation']>();
  const { practiceID, thoughtDiaryEntry } = useThoughtDiaryEntryPractice();
  const { data: progress } = useProgressByContent();
  const startedSession07 = !!progress.TEST_IT?.completion;
  const isIncompleteAndUnlocked = shouldTestThought({
    completedSession07: startedSession07,
    thoughtDiaryEntry,
  });
  const locked = !startedSession07;
  const { theme } = useTheme();
  const { $t } = useI18n();

  return (
    <View spacing={12}>
      <SectionHeading
        icon={<Icon name="test" color={theme.color.dark} aria-label={undefined} />}
        text={$t({ id: 'ThoughtDiaryEntry_testHeading', defaultMessage: 'Test it' })}
      />
      {locked ? (
        <SessionIncompleteLockCard num={7} testID="ThoughtDiaryEntry_testItLockedCard" />
      ) : isIncompleteAndUnlocked ? (
        <Button
          text={$t({ id: 'ThoughtDiaryEntry_testButton', defaultMessage: 'Test your thought' })}
          onPress={() =>
            navigate('EditThoughtDiaryEntry', { practiceID, step: 'test', cardStack: 'true' })
          }
          alignSelf="center"
          testID="ThoughtDiaryEntry_testItButton"
        />
      ) : (
        <View row childFlex={1} style={{ alignItems: 'flex-start' }}>
          <View>
            <Text
              weight="semibold"
              text={$t({ id: 'ThoughtDiaryEntry_evidenceFor', defaultMessage: 'Evidence for' })}
              size={17}
            />
            <UnorderedList
              items={thoughtDiaryEntry?.evidenceFor?.map((e) => e.text) ?? []}
              color={theme.color.gray400}
              weight="normal"
              size="small"
            />
          </View>
          <View>
            <Text
              weight="semibold"
              text={$t({
                id: 'ThoughtDiaryEntry_evidenceAgainst',
                defaultMessage: 'Evidence against',
              })}
              size={17}
            />
            <UnorderedList
              items={thoughtDiaryEntry?.evidenceAgainst?.map((e) => e.text) ?? []}
              color={theme.color.gray400}
              weight="normal"
              size="small"
            />
          </View>
        </View>
      )}
    </View>
  );
}

function SwitchIt() {
  const { navigate } = useNavigation<StackScreenProps<'ThoughtDiaryEntry'>['navigation']>();
  const { practiceID, practiceValues, thoughtDiaryEntry } = useThoughtDiaryEntryPractice();
  const { data: progress } = useProgressByContent();
  const startedSession08 = !!progress.SWITCH_IT?.completion;
  const locked = !startedSession08;
  const isIncompleteAndUnlocked = shouldSwitchThought({
    completedSession08: startedSession08,
    thoughtDiaryEntry,
  });
  const { theme } = useTheme();
  const { $t } = useI18n();

  return (
    <View spacing={12}>
      <SectionHeading
        icon={<Icon name="switch" color={theme.color.dark} aria-label={undefined} />}
        text={$t({ id: 'ThoughtDiaryEntry_switchHeading', defaultMessage: 'Switch it' })}
      />
      {locked ? (
        <SessionIncompleteLockCard num={8} testID="ThoughtDiaryEntry_switchItLockedCard" />
      ) : isIncompleteAndUnlocked ? (
        <Button
          text={$t({ id: 'ThoughtDiaryEntry_switchButton', defaultMessage: 'Switch your thought' })}
          onPress={() =>
            navigate('EditThoughtDiaryEntry', { practiceID, step: 'switch', cardStack: 'true' })
          }
          alignSelf="center"
          testID="ThoughtDiaryEntry_switchItButton"
        />
      ) : (
        <View spacing={24}>
          <View spacing={10}>
            <Text
              weight="semibold"
              text={$t({ id: 'ThoughtDiaryEntry_switchNewThought', defaultMessage: 'New thought' })}
              size={17}
            />
            <BeliefBubble
              rating={
                practiceValues?.ratings.find((v) => v.type === RatingType.RATING_AFTER)?.value ?? 0
              }
              text={thoughtDiaryEntry?.thoughtAfter ?? ''}
              after
            />
          </View>
          <View row childFlex={1} style={{ alignItems: 'flex-start' }}>
            <View>
              <Text
                weight="semibold"
                text={$t({
                  id: 'ThoughtDiaryEntry_switchNewFeeling',
                  defaultMessage: 'New feelings',
                })}
                size={17}
              />
              <UnorderedList
                items={thoughtDiaryEntry?.feelingsAfter?.map((e) => e.text) ?? []}
                color={theme.color.gray400}
                weight="normal"
                size="small"
              />
            </View>
            <View></View>
          </View>
        </View>
      )}
    </View>
  );
}

export function ThoughtDiaryEntry() {
  const { practiceID, practiceValues, thoughtDiaryEntry } = useThoughtDiaryEntryPractice();
  const { locale } = useAppContext();
  const [dateFormatter] = useState(
    () =>
      new Intl.DateTimeFormat(locale, {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
      }),
  );
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [deleteThoughtDiaryEntry] = useMutation(DeleteThoughtDiaryEntryMutation);
  const { $t } = useI18n();

  const { goBack, navigate, setOptions } =
    useNavigation<StackScreenProps<'ThoughtDiaryEntry'>['navigation']>();
  useEffect(() => {
    setOptions({
      headerLeft: undefined,
      headerRight: () => {
        return (
          <HeaderButtons>
            <OverflowMenu triggerTestID="ThoughtDiaryEntry_overflowMenuTrigger" headerItem>
              <OverflowMenuOption
                text={$t({ id: 'ThoughtDiaryEntry_editButton', defaultMessage: 'Edit' })}
                icon="edit"
                testID="ThoughtDiaryEntry_editButton"
                onPress={() => {
                  navigate('EditThoughtDiaryEntry', {
                    practiceID,
                    step: 'spot',
                    cardStack: 'false',
                  });
                }}
              />
              <OverflowMenuOption
                icon="bin"
                text={$t({ id: 'ThoughtDiaryEntry_deleteButton', defaultMessage: 'Delete' })}
                testID="ThoughtDiaryEntry_deleteButton"
                onPress={() => setShowDeleteConfirmationModal(true)}
              />
            </OverflowMenu>
          </HeaderButtons>
        );
      },
    });
  }, [setOptions, practiceID, navigate, $t]);

  return thoughtDiaryEntry ? (
    <RoundedSection
      color={'#c1e3c9'}
      secondaryColor={'white'}
      title={
        practiceValues?.date
          ? dateFormatter.format(parseGQLDateTime(practiceValues.date))
          : $t({ id: 'ThoughtDiaryEntry_title', defaultMessage: 'Thought Diary' })
      }
      preview={false}
      testID="ThoughtDiaryEntry_scrollView"
    >
      <View
        spacing={20}
        style={{
          marginTop: -20,
          borderTopStartRadius: 30,
          borderTopEndRadius: 30,
          marginHorizontal: -20,
          paddingVertical: 20,
          paddingHorizontal: 20,
          backgroundColor: '#f4f8f5',
        }}
      >
        <Text
          text={$t({ id: 'ThoughtDiaryEntry_eventLabel', defaultMessage: 'Event' })}
          weight="semibold"
        />
        <Text text={thoughtDiaryEntry.event} />
      </View>
      <View
        style={{
          paddingTop: 20,
          backgroundColor: 'white',
        }}
        spacing={18}
      >
        <SpotIt />
        <Divider />
        <TestIt />
        <Divider />
        <SwitchIt />
      </View>
      <ConfirmationModal
        visible={!!showDeleteConfirmationModal}
        onCancel={() => setShowDeleteConfirmationModal(false)}
        onConfirm={async () => {
          await deleteThoughtDiaryEntry({
            variables: { practiceID },
            refetchQueries: ['ThoughtDiaryEntries' satisfies ThoughtDiaryEntriesQueryName],
          });
          goBack();
        }}
        cancelText={$t({
          id: 'ThoughtDiaryEntry_confirmDeleteCancel',
          defaultMessage: "No, don't",
        })}
        confirmText={$t({
          id: 'ThoughtDiaryEntry_confirmDeleteConfirm',
          defaultMessage: 'Yes, delete',
        })}
        confirmTestID="ThoughtDiaryEntry_confirmDeleteButton"
        title={$t({ id: 'ThoughtDiaryEntry_confirmDeleteTitle', defaultMessage: 'Delete?' })}
        description={$t({
          id: 'ThoughtDiaryEntry_confirmDeleteDescription',
          defaultMessage: "Please confirm you'd like to delete this thought diary entry.",
        })}
      />
    </RoundedSection>
  ) : null;
}
