/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      d="m9 13.97-3.9 2.05c-.87.45-1.44.04-1.28-.92l.75-4.33-3.16-3.06c-.7-.68-.48-1.35.49-1.49l4.36-.63 1.96-3.94c.43-.87 1.13-.87 1.56 0l1.96 3.94 4.36.63c.97.14 1.19.8.49 1.49l-3.16 3.06.75 4.33c.16.96-.4 1.37-1.27.92L9 13.97Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
