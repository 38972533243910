/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={17} height={18} viewBox="0 0 17 18" fill="none" {...props}>
    <Path
      d="M1 3.29A2.29 2.29 0 0 1 3.29 1v0a2.29 2.29 0 0 1 2.28 2.29V14.7A2.29 2.29 0 0 1 3.3 17v0A2.29 2.29 0 0 1 1 14.71V3.3Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M11.29 3.29A2.29 2.29 0 0 1 13.57 1v0a2.29 2.29 0 0 1 2.29 2.29V14.7A2.29 2.29 0 0 1 13.57 17v0a2.29 2.29 0 0 1-2.28-2.29V3.3Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
