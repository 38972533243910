/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={17} viewBox="0 0 18 17" fill="none" {...props}>
    <Path
      d="M4.6 3.46V2.7c0-.43.35-.78.78-.78h1.05c.44 0 .79.35.79.78v.71c.48.16.91.4 1.29.71l.6-.34a.8.8 0 0 1 1.08.28l.52.9c.22.37.1.84-.29 1.06l-.58.33a3.84 3.84 0 0 1 0 1.49l.58.33c.38.21.51.69.3 1.06l-.53.9a.8.8 0 0 1-1.08.28l-.6-.35c-.38.31-.81.56-1.29.71v.71c0 .43-.35.78-.79.78H5.38a.78.78 0 0 1-.78-.78v-.76c-.45-.16-.85-.4-1.2-.7l-.7.39a.8.8 0 0 1-1.07-.29l-.52-.9a.77.77 0 0 1 .28-1.05l.72-.4a3.84 3.84 0 0 1 0-1.35l-.72-.4a.77.77 0 0 1-.28-1.06l.52-.9a.8.8 0 0 1 1.08-.28l.69.4c.35-.31.75-.55 1.2-.72Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M5.9 8.38a1.3 1.3 0 1 0 0-2.58 1.3 1.3 0 0 0 0 2.58Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M6.94 14.86c1.33.6 2.79.52 2.79.52 1.36 0 2.64-.38 3.74-1.03.27-.17 2.76 1.22 3.28.7.51-.52-.95-2.94-.8-3.17A7.28 7.28 0 0 0 11.28 1"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
