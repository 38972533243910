import { useMutation, useQuery } from '@apollo/client';
import { useFocusEffect } from '@react-navigation/core';
import { Route, useRoute } from '@react-navigation/native';
import add from 'date-fns/add';
import addDays from 'date-fns/addDays';
import differenceInWeeks from 'date-fns/differenceInWeeks';
import endOfMonth from 'date-fns/endOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import isAfter from 'date-fns/isAfter';
import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import startOfYear from 'date-fns/startOfYear';
import { useCallback, useMemo } from 'react';

import { formatGQLDate, parseGQLDateTime } from '@oui/lib/src/gqlDate';
import { graphql, ResultOf, VariablesOf } from '@oui/lib/src/graphql/tada';
import { parseHoursAndMinutes } from '@oui/lib/src/parseHoursAndMinutes';
import { times } from '@oui/lib/src/times';
import {
  ActionType,
  PracticeType,
  RatingDuration,
  RatingType,
  SleepDiaryEntryDuration,
  SleepDiaryEntryInput,
} from '@oui/lib/src/types/graphql.generated';
import { GQLDate, GQLDateTime, GQLTime, GQLUUID } from '@oui/lib/src/types/scalars';

import { useCurrentPatientID } from '../hooks/useCurrentUser';
import { IntlShape, useI18n } from '../lib/i18n';
import { logEvent } from '../lib/log';

export enum SleepDiaryConfigInfluencerFrequency {
  Regularly = 0,
  Sometimes = 1,
  Rarely = 2,
  Never = 3,
}

export const PracticeFragment = graphql(`
  fragment PracticeFragment on Practice @_unmask {
    __typename
    practiceID
    practiceValues {
      role {
        ID
        streak
      }
      date
      ratings {
        type
        value
      }
    }
  }
`);

export type SleepDiaryConfig = {
  startHHMM?: GQLTime;
  endHHMM?: GQLTime;
  sleepDelay?: SleepDiaryEntryDuration;
  wakeupCount?: number;
  caffeine?: SleepDiaryConfigInfluencerFrequency;
  alcohol?: SleepDiaryConfigInfluencerFrequency;
  tobacco?: SleepDiaryConfigInfluencerFrequency;
  napping?: SleepDiaryConfigInfluencerFrequency;
  exercise?: SleepDiaryConfigInfluencerFrequency;
  medicine?: SleepDiaryConfigInfluencerFrequency;
  deviceInBed?: SleepDiaryConfigInfluencerFrequency;
};

export function getDefaultSleepDiaryEntry(
  sleepConfig: SleepDiaryConfig | undefined,
  baseDate: Date,
): SleepDiaryEntryInput {
  let startDateTime = parseHoursAndMinutes(sleepConfig?.startHHMM ?? '0:00', baseDate);
  const endDateTime = parseHoursAndMinutes(sleepConfig?.endHHMM ?? '8:00', baseDate);

  if (isAfter(startDateTime, endDateTime)) {
    startDateTime = addDays(startDateTime, -1);
  }

  return {
    startTime: startDateTime.toISOString() as GQLDateTime,
    endTime: endDateTime.toISOString() as GQLDateTime,
    sleepDelay: undefined,
    wakeupCount: -1,
    wakeupDuration: undefined,
    caffeine: undefined,
    alcohol: undefined,
    tobacco: undefined,
    medicine: undefined,
    exercise: undefined,
    napping: undefined,
    deviceInBed: undefined,
  };
}

export const SleepDiaryConfigQuery = graphql(`
  query SleepDiaryConfig {
    kvResponse(context: "sleepDiary", key: "config") {
      __typename
      context
      key
      value
    }
  }
`);

export function useSleepDiaryConfig() {
  const { data: persistedData, ...rest } = useQuery<
    {
      kvResponse: {
        context: string;
        key: string;
        value?: SleepDiaryConfig;
      };
    },
    VariablesOf<typeof SleepDiaryConfigQuery>
  >(SleepDiaryConfigQuery);

  const isComplete = useMemo(() => {
    if (!persistedData?.kvResponse.value) return null;
    return (
      Object.keys(persistedData.kvResponse.value).length === 11 &&
      !Object.values(persistedData.kvResponse.value).find((v) => typeof v === 'undefined')
    );
  }, [persistedData?.kvResponse]);

  return { data: persistedData?.kvResponse?.value, isComplete, ...rest };
}

export const CopingCardsQuery = graphql(`
  query CopingCards {
    kvResponse(context: "session-10", key: "copingCard") {
      __typename
      context
      key
      value
    }
  }
`);
const SaveCopingCardsMutation = graphql(`
  mutation SaveCopingCards($data: Any!) {
    kvRespond(context: "session-10", key: "copingCard", data: $data) {
      __typename
      context
      key
      value
    }
  }
`);
type CopingCard = { frontText: string; backText: string };
export function useCopingCards() {
  const {
    data: persistedData,
    refetch,
    ...rest
  } = useQuery<
    {
      kvResponse: {
        context: string;
        key: string;
        value?: CopingCard[];
      };
    },
    VariablesOf<typeof CopingCardsQuery>
  >(CopingCardsQuery);

  const [saveCopingCardsMutation] = useMutation<
    ResultOf<typeof SaveCopingCardsMutation>,
    {
      context: string;
      key: string;
      data: CopingCard[];
    }
  >(SaveCopingCardsMutation);

  const saveCopingCards = useCallback(
    async (data: CopingCard[]) => {
      await saveCopingCardsMutation({
        variables: {
          context: 'session-10',
          key: 'copingCard',
          data,
        },
      });
    },
    [saveCopingCardsMutation],
  );

  return { data: persistedData?.kvResponse?.value ?? [], ...rest, saveCopingCards };
}

export const getRatingsLabels = ($t: IntlShape['$t']) => {
  return {
    1: $t({ id: 'PracticeRating_label_1', defaultMessage: 'Awful' }),
    2: $t({ id: 'PracticeRating_label_2', defaultMessage: 'Bad' }),
    3: $t({ id: 'PracticeRating_label_3', defaultMessage: 'Ok' }),
    4: $t({ id: 'PracticeRating_label_4', defaultMessage: 'Good' }),
    5: $t({ id: 'PracticeRating_label_5', defaultMessage: 'Great' }),
  };
};

export const PracticeRatingsQuery = graphql(`
  query PracticeRatings(
    $practiceType: PracticeType!
    $ratingType: RatingType!
    $before: Date
    $after: Date
    $ratingInterval: RatingDuration!
  ) {
    practiceRatings(
      practiceType: $practiceType
      ratingType: $ratingType
      duration: $ratingInterval
      before: $before
      after: $after
    ) {
      startDate
      practiceRating {
        type
        value
      }
    }
  }
`);

export function usePracticeRatings({
  practiceType,
  ratingType,
  timeScale,
}: {
  practiceType: PracticeType;
  ratingType: RatingType;
  timeScale: 'WEEK' | 'MONTH' | 'YEAR';
}) {
  const variables: VariablesOf<typeof PracticeRatingsQuery> = {
    practiceType,
    ratingType,
    ratingInterval: RatingDuration.WEEK,
  };
  let xGQLDates: GQLDate[] = [];
  let xLabels: string[] = [];
  const { $t, formatDate } = useI18n();

  if (timeScale === 'WEEK') {
    const start = startOfWeek(new Date());
    variables.after = formatGQLDate(start);
    variables.ratingInterval = RatingDuration.DAY;
    xGQLDates = times(7, (i) => {
      return formatGQLDate(add(start, { days: i }));
    });
    xLabels = xGQLDates.map((d) => formatDate(parseGQLDateTime(d), { weekday: 'short' }));
  } else if (timeScale === 'MONTH') {
    const start = startOfWeek(startOfMonth(new Date()));
    const end = add(endOfWeek(endOfMonth(new Date())), { seconds: 1 });
    const numberOfWeeks = differenceInWeeks(end, start);
    variables.after = formatGQLDate(start);
    variables.ratingInterval = RatingDuration.WEEK;

    xGQLDates = times(numberOfWeeks, (i) => {
      return formatGQLDate(add(start, { weeks: i }));
    });
    xLabels = xGQLDates.map((d) =>
      formatDate(parseGQLDateTime(d), { month: 'numeric', day: 'numeric' }),
    );
  } else if (timeScale === 'YEAR') {
    const start = startOfYear(new Date());
    variables.after = formatGQLDate(start);
    variables.ratingInterval = RatingDuration.MONTH;

    xGQLDates = times(12, (i) => {
      return formatGQLDate(add(start, { months: i }));
    });
    xLabels = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  }

  const { loading, data, refetch } = useQuery(PracticeRatingsQuery, { variables });
  const practiceRatings = data?.practiceRatings ?? [];

  const inflatedRatingsData = xGQLDates.map((d) => {
    return practiceRatings.find((r) => r.startDate === d)?.practiceRating?.value;
  });

  useFocusEffect(
    useCallback(() => {
      void refetch();
    }, [refetch]),
  );

  return {
    loading,
    xAxisLabel:
      timeScale === 'WEEK'
        ? $t({ id: 'Practices_xAxisWeekLabel', defaultMessage: 'Days' })
        : timeScale === 'MONTH'
          ? $t({ id: 'Practices_xAxisMonthLabel', defaultMessage: 'Week beginning' })
          : $t({ id: 'Practices_xAxisYearLabel', defaultMessage: 'Months' }),
    xLabels,
    data: { type: ratingType, data: inflatedRatingsData },
    refetch,
  };
}

const ActionCountsQueryName = 'ActionCounts';
export const ActionCountsQuery = graphql(`
  query ActionCounts {
    actionCounts {
      actionType
      count
    }
  }
`);
export function useActionCounts() {
  const { data: counts, ...rest } = useQuery(ActionCountsQuery);

  const countByActionType = useMemo(() => {
    let total = 0;
    const collection = (counts?.actionCounts ?? []).reduce<
      Partial<Record<ActionType | 'TOTAL', number>>
    >((carry, { actionType, count }) => {
      carry[actionType] = count;
      total += count;
      return carry;
    }, {});
    collection.TOTAL = total;
    return collection;
  }, [counts]);

  return { data: countByActionType, ...rest };
}

export const AddActionMutation = graphql(`
  mutation AddAction($input: AddActionInput!) {
    addAction(input: $input) {
      action {
        actionID
        actionValues {
          role {
            ID
            streak
          }
        }
      }
    }
  }
`);

export function useAddAction() {
  const patientID = useCurrentPatientID()!;
  const [mutate, data] = useMutation(AddActionMutation);

  const mutation = useCallback(
    (variables: Omit<VariablesOf<typeof AddActionMutation>['input'], 'actionValues'>) => {
      logEvent('add_action', {
        actionType: variables.actionType,
        practiceID: variables.practiceID ?? null,
      });
      return mutate({
        variables: {
          input: {
            actionValues: {
              patientID,
            },
            ...variables,
          },
        },
        refetchQueries: [ActionCountsQueryName],
      });
    },
    [patientID, mutate],
  );

  return [mutation, data] as [typeof mutation, typeof data];
}

export const ActivityFragment = graphql(`
  fragment ActivityFragment on Activity @_unmask {
    __typename
    startTime
    endTime
    title
    attendee
    location
    notes
  }
`);
export const ActivityDiaryEntryQuery = graphql(
  `
    query ActivityDiaryEntry($practiceID: UUID!) {
      activityPracticeByID(practiceID: $practiceID) {
        ...PracticeFragment
        activity {
          ...ActivityFragment
        }
      }
    }
  `,
  [PracticeFragment, ActivityFragment],
);
export function useActivityPractice() {
  const route = useRoute<Route<string, { practiceID: GQLUUID } | undefined>>();
  const practiceID = route.params && 'practiceID' in route.params ? route.params.practiceID : null;
  const { data, ...rest } = useQuery(ActivityDiaryEntryQuery, {
    variables: {
      practiceID: practiceID!,
    },
    skip: !practiceID,
  });
  return {
    data,
    ...rest,
    activityPractice: data?.activityPracticeByID,
    practiceID,
  };
}

export const ThoughtDiaryEntryFragment = graphql(`
  fragment ThoughtDiaryEntryFragment on ThoughtDiaryEntry @_unmask {
    __typename
    event
    thoughtBefore
    feelingsBefore {
      ID
      text
    }
    behavior
    evidenceFor {
      ID
      text
    }
    evidenceAgainst {
      ID
      text
    }
    thoughtAfter
    feelingsAfter {
      ID
      text
    }
  }
`);

export const ThoughtDiaryEntryQueryName = 'ThoughtDiaryEntry';
export type ThoughtDiaryEntryQueryName = typeof ThoughtDiaryEntryQueryName;
export const ThoughtDiaryEntryQuery = graphql(
  `
    query ThoughtDiaryEntry($practiceID: UUID!) {
      thoughtDiaryEntryPracticeByID(practiceID: $practiceID) {
        ...PracticeFragment
        ... on ThoughtDiaryEntryPractice {
          thoughtDiaryEntry {
            ...ThoughtDiaryEntryFragment
          }
        }
      }
    }
  `,
  [PracticeFragment, ThoughtDiaryEntryFragment],
);
export function shouldTestThought({
  completedSession07,
  thoughtDiaryEntry,
}: {
  completedSession07: boolean;
  thoughtDiaryEntry?: NonNullable<
    ResultOf<typeof ThoughtDiaryEntryQuery>['thoughtDiaryEntryPracticeByID']
  >['thoughtDiaryEntry'];
}) {
  const locked = !completedSession07;
  return (
    !locked &&
    thoughtDiaryEntry?.evidenceFor.length === 0 &&
    thoughtDiaryEntry?.evidenceAgainst.length === 0
  );
}

export function shouldSwitchThought({
  completedSession08,
  thoughtDiaryEntry,
}: {
  completedSession08: boolean;
  thoughtDiaryEntry?: NonNullable<
    ResultOf<typeof ThoughtDiaryEntryQuery>['thoughtDiaryEntryPracticeByID']
  >['thoughtDiaryEntry'];
}) {
  const locked = !completedSession08;
  return !locked && !thoughtDiaryEntry?.thoughtAfter;
}

export function useThoughtDiaryEntryPractice() {
  const route = useRoute<Route<string, { practiceID: GQLUUID }>>();
  const practiceID = route.params?.practiceID!;
  const { data, ...rest } = useQuery(ThoughtDiaryEntryQuery, {
    variables: {
      practiceID,
    },
    skip: !practiceID,
  });

  const practiceValues = data?.thoughtDiaryEntryPracticeByID?.practiceValues;
  const thoughtDiaryEntry = data?.thoughtDiaryEntryPracticeByID?.thoughtDiaryEntry;
  return { data, ...rest, practiceID, practiceValues, thoughtDiaryEntry };
}

// NB fragments don't work if __typename isn't queried
// https://github.com/apollographql/apollo-client/issues/7006#issuecomment-691248339
// fragment SleepDiaryEntryInfluencerFragment on SleepDiaryEntryInfluencer {
//   occurred
//   count
//   duration
//   kinds
//   timesOfDay
// }
export const SleepDiaryEntryFragment = graphql(`
  fragment SleepDiaryEntry on SleepDiaryEntry @_unmask {
    __typename
    startTime
    endTime
    sleepDelay
    wakeupCount
    wakeupDuration
    caffeine {
      occurred
      count
      duration
      kinds
      timesOfDay
    }
    alcohol {
      occurred
      count
      duration
      kinds
      timesOfDay
    }
    tobacco {
      occurred
      count
      duration
      kinds
      timesOfDay
    }
    medicine {
      occurred
      count
      duration
      kinds
      timesOfDay
    }
    exercise {
      occurred
      count
      duration
      kinds
      timesOfDay
    }
    napping {
      occurred
      count
      duration
      kinds
      timesOfDay
    }
    deviceInBed {
      occurred
      count
      duration
      kinds
      timesOfDay
    }
  }
`);

const SleepDiaryEntryQuery = graphql(
  `
    query SleepDiaryEntry($practiceID: UUID!) {
      sleepDiaryEntryPracticeByID(practiceID: $practiceID) {
        ...PracticeFragment
        ... on SleepDiaryEntryPractice {
          sleepDiaryEntry {
            ...SleepDiaryEntry
          }
        }
      }
    }
  `,
  [PracticeFragment, SleepDiaryEntryFragment],
);
export function useSleepDiaryEntryPractice() {
  const route = useRoute<Route<string, { practiceID: GQLUUID }>>();
  const practiceID = route.params?.practiceID!;
  const { data, ...rest } = useQuery(SleepDiaryEntryQuery, {
    variables: {
      practiceID,
    },
    skip: !practiceID,
  });

  const practiceValues = data?.sleepDiaryEntryPracticeByID?.practiceValues;
  const sleepDiaryEntry = data?.sleepDiaryEntryPracticeByID?.sleepDiaryEntry;
  return { data, ...rest, practiceID, practiceValues, sleepDiaryEntry };
}
