/* This file is autogenerated by @oui/cli */
import Svg, { Path, Rect, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={17} viewBox="0 0 18 17" fill="none" {...props}>
    <Rect
      x={1}
      y={8.24}
      width={2.68}
      height={7.04}
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M3.68 9.25c.84.39 2.52.47 5.87.33 1.51-.06 2.07 1.29 1 2.02m0 0c-.5.22-1.74.33-2.67.33m2.68-.33c1.12-.12 4.53-1.01 4.53-1.01 1.67-.67 2.68 1.34 1.17 2.18 0 0-2.85 1.68-3.35 1.84-.5.17-3.7.68-9.23 0"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M9.24 1.86S8.84 1 7.88 1C7 1 6.22 1.68 6.22 2.84c0 1.1 1.03 1.87 1.59 2.4.56.52 1.43 1.13 1.43 1.13s.87-.6 1.43-1.13c.56-.53 1.59-1.3 1.59-2.4 0-1.16-.8-1.84-1.66-1.84-.97 0-1.36.86-1.36.86Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
