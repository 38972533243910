import { useApolloClient } from '@apollo/client';
import * as Sentry from '@sentry/core';
import hexToRgba from 'hex-to-rgba';
import { useState } from 'react';
import { Image, Linking, TouchableOpacity } from 'react-native';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { validate } from 'uuid';

import { Button } from '@oui/app-core/src/components/Button';
import { Icon } from '@oui/app-core/src/components/Icon';
import { SvgUri } from '@oui/app-core/src/components/SvgUri';
import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { resumableUploadManager } from '@oui/app-core/src/lib/resumableUploadManager';
import { useTheme } from '@oui/app-core/src/styles';
import { graphql } from '@oui/lib/src/graphql/tada';

const MyPlanAssetQuery = graphql(`
  query MyPlanAsset($context: String!, $key: String!) {
    asset(context: $context, key: $key)
  }
`);

type Props = {
  name: { readonly first?: string | null | undefined; readonly last?: string | null | undefined };
  /**
   * string for a direct URL, context/key object to reference an API Asset
   */
  image?: string | { context: string; key: string } | null;
  imageBorderRadius?: boolean;
  phone?: string;
  imageOnly?: boolean;
  imageSize?: number;
};

export function ContactImage({
  image,
  imageBorderRadius,
  nameStr,
  imageSize = 80,
  inverted,
}: Pick<Props, 'image' | 'imageBorderRadius' | 'imageSize'> & {
  nameStr: string;
  inverted?: boolean;
}) {
  const { theme } = useTheme();
  const size = imageSize;
  const contactBubbleStr = nameStr
    .replace(/[^A-Za-z ]/g, '')
    .split(' ')
    .map((segment) => segment[0] || '')
    .join('');
  const apollo = useApolloClient();
  const [imageUri, setImageUri] = useState(() => {
    if (image && typeof image === 'object') {
      if (validate(image.key)) {
        const result = apollo.readQuery({
          query: MyPlanAssetQuery,
          variables: image,
        });
        return result?.asset;
      } else {
        return image.key;
      }
    }
    return image;
  });

  useDeepCompareEffect(() => {
    if (image && typeof image === 'object' && validate(image.key)) {
      const pendingItem = resumableUploadManager.getPendingUploadByCacheKey<null>(image.key);
      if (pendingItem) {
        setImageUri(pendingItem.sourceUri);
      } else {
        apollo
          .query({ query: MyPlanAssetQuery, variables: image, fetchPolicy: 'cache-first' })
          .then((res) => {
            if (res.data.asset) {
              setImageUri(res.data.asset);
            }
          })
          .catch(Sentry.captureException);
      }
    }
  }, [apollo, image]);

  return imageUri ? (
    imageUri.endsWith('svg') ? (
      <View
        aria-label="Contact"
        role="img"
        style={{
          width: size,
          height: size,
          borderRadius: imageBorderRadius ? size / 2 : undefined,
        }}
      >
        <SvgUri uri={imageUri} width="100%" height="100%" />
      </View>
    ) : (
      <View
        aria-label="Contact"
        role="img"
        style={{
          width: size,
          height: size,
          borderRadius: imageBorderRadius ? size / 2 : undefined,
          overflow: 'hidden',
        }}
      >
        <Image
          style={{ width: '100%', height: '100%' }}
          resizeMode="cover"
          source={{ uri: imageUri }}
          onError={(err) => {
            Sentry.captureException('ContactImage failed to load', {
              extra: { originalError: err, image },
            });
          }}
        />
      </View>
    )
  ) : (
    <View
      style={{
        backgroundColor: hexToRgba(
          inverted ? theme.color.primary100 : theme.color.accentThree100,
          0.4,
        ),
        width: size,
        height: size,
        borderRadius: size / 2,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
      }}
      aria-label="Contact"
      role="img"
    >
      <Text
        color={inverted ? theme.color.primary100 : theme.color.accentThree100}
        text={contactBubbleStr}
        size={
          contactBubbleStr.length <= 2
            ? size / 2.4
            : contactBubbleStr.length <= 4
              ? size / 3
              : size / contactBubbleStr.length // Scales initial sizing to fit well for names with 4-7 initials, Assumes no names with more than 7 initials
        }
        weight="bold"
        style={{ lineHeight: (size * 3) / 4 }}
      />
    </View>
  );
}

export function getNameStr({ name }: Pick<Props, 'name'>) {
  return [name.first, name.last].filter((part) => !!part).join(' ');
}

export function Contact({ name, image, imageBorderRadius, phone, imageOnly, imageSize }: Props) {
  const { theme } = useTheme();
  const nameStr = getNameStr({ name });
  return (
    <View
      style={{
        flexBasis: '33%',
        marginVertical: 20,
        alignItems: 'center',
      }}
      spacing={8}
    >
      <View>
        <ContactImage
          image={image}
          imageBorderRadius={imageBorderRadius}
          nameStr={nameStr}
          imageSize={imageSize}
        />
        {phone && !imageOnly ? (
          <TouchableOpacity
            style={{
              padding: 8,
              borderRadius: 50,
              backgroundColor: 'rgba(255,255,255, 0.8)',
              position: 'absolute',
              bottom: 0,
              right: 0,
            }}
            onPress={() => void Linking.openURL(`tel:${phone}`)}
          >
            <Icon name="phone" color={theme.color.primary100} size={20} />
          </TouchableOpacity>
        ) : null}
      </View>
      {!imageOnly ? <Text text={nameStr} weight="bold" style={{ textAlign: 'center' }} /> : null}
    </View>
  );
}

export function ContactListItem({
  name,
  nameWeight,
  image,
  imageBorderRadius,
  phone,
  relationship,
  imageSize,
  testID,
}: Omit<Props, 'imageOnly'> & {
  nameWeight?: 'normal' | 'semibold';
  relationship?: string;
  testID?: string;
}) {
  const nameStr = getNameStr({ name });
  return (
    <View row testID={testID}>
      <View style={{ marginRight: 8 }}>
        <ContactImage
          image={image}
          imageBorderRadius={imageBorderRadius}
          nameStr={nameStr}
          imageSize={imageSize}
        />
      </View>
      <View style={{ justifyContent: 'center', flex: 1 }}>
        <Text text={nameStr} weight={nameWeight ?? 'bold'} />
        {relationship ? <Text text={relationship} /> : null}
      </View>
      {phone ? (
        <Button
          icon="phone"
          onPress={() => Linking.openURL(`tel:${phone}`)}
          variant="text"
          aria-label={`Call ${nameStr}`}
        />
      ) : null}
    </View>
  );
}
