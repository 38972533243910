import { useQuery } from '@apollo/client';
import { useRoute } from '@react-navigation/native';
import { useState } from 'react';

import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import { DiaryTabs } from '@oui/app-core/src/components/DiaryTabs';
import {
  RoundedSection,
  RoundedSectionTopChild,
} from '@oui/app-core/src/components/RoundedSection';
import { View } from '@oui/app-core/src/components/View';
import { useArtifactRequest } from '@oui/app-core/src/hooks/useArtifactResult';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { graphql } from '@oui/lib/src/graphql/tada';

import { useRelaxContext } from '../components/RelaxContext';
import { RelaxExercises, RelaxExercisesFragment } from '../components/RelaxExercises';
import { RelaxMoodRatings } from '../components/RelaxMoodRatings/RelaxMoodRatings';
import { StackScreenProps } from '../types/navigation';

export const RelaxQuery = graphql(
  `
    query Relax {
      user {
        ID
        role {
          ID
          relaxDiaryWidget {
            ID
            name
            ...RelaxExercises
          }
        }
      }
    }
  `,
  [RelaxExercisesFragment],
);

export function Relax() {
  const route = useRoute<StackScreenProps<'Relaxation'>['route']>();
  const [hasStartedExercise, setHasStartedExercise] = useState(false);
  const [activeTab, setActiveTab] = useState<'exercise' | 'rating'>('exercise');
  const { $t } = useI18n();
  const { data, loading } = useQuery(RelaxQuery);
  const theme = useRelaxContext();

  useArtifactRequest(route.name, hasStartedExercise);

  return (
    <RoundedSection
      color={theme.backgroundColor}
      secondaryColor="white"
      title={data?.user?.role?.relaxDiaryWidget?.name ?? 'Relax'}
      testID="Relax_scrollView"
      applyHeaderOptions
    >
      <DiaryTabs
        testID="Relax_tabs"
        value={activeTab}
        onChangeValue={setActiveTab}
        items={[
          {
            value: 'exercise',
            text: $t({ id: 'Relax_exercisesTab', defaultMessage: 'Exercises' }),
            icon: 'relax',
          },
          {
            value: 'rating',
            text: $t({ id: 'Relax_ratingsTab', defaultMessage: 'Mood Ratings' }),
            icon: 'stats',
          },
        ]}
        topOfRoundedSection
      />
      {loading || !data?.user?.role?.relaxDiaryWidget ? (
        <ActivityIndicator />
      ) : activeTab === 'exercise' ? (
        <View style={{ marginHorizontal: -20 }}>
          <RelaxExercises
            onStartExercise={() => setHasStartedExercise(true)}
            config={data?.user?.role?.relaxDiaryWidget}
            initialExerciseSlug={route.params?.initialExerciseSlug}
          />
        </View>
      ) : (
        <RoundedSectionTopChild backgroundColor="transparent">
          <RelaxMoodRatings />
        </RoundedSectionTopChild>
      )}
    </RoundedSection>
  );
}
