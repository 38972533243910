import { Image } from 'expo-image';

import { View } from '@oui/app-core/src/components/View';

import HopeKitArtifactPreviewAsset from '../assets/hopeKitArtifactPreview.svg';

export function HopeKitArtifactPreview({
  width,
  height,
  defaultHeight,
}: {
  width: number;
  height: number;
  defaultHeight?: number;
}) {
  return (
    <View
      style={{
        height: height * 2,
        width: width * 2,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Image
        source={HopeKitArtifactPreviewAsset}
        style={{ width: '100%', aspectRatio: 210 / 125 }}
        contentPosition="center"
      />
    </View>
  );
}
