/* This file is autogenerated by @oui/cli */
import Svg, { Circle, Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={17} height={16} viewBox="0 0 17 16" fill="none" {...props}>
    <Circle
      cx={9.89}
      cy={2.48}
      r={1.48}
      stroke="currentColor"
      strokeWidth={2}
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M1 13.89s3.2-.38 4.3-1.93L7 9.74m6.89-5.48s-.94 2.88-2.37 2.81C10.64 7.04 10.47 6 9.59 5.9 8.72 5.79 7 9.74 7 9.74m0 0L11.52 11l3.78 2.89m-8.08-8.3s-2.3.12-2.66.3c-.89.43-1.63 1.92-1.63 1.92"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
