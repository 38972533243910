/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={17} height={20} viewBox="0 0 17 20" fill="none" {...props}>
    <Path
      d="M7.58 6.84a.93.93 0 0 1 0-1.17l3.6-4.55a1.06 1.06 0 0 1 1.65 0l3.58 4.56c.27.34.27.81 0 1.15l-3.58 4.55c-.4.53-1.23.53-1.65 0l-3.6-4.54Z"
      fill="currentColor"
    />
    <Path
      d="M12.52 10.56v5.76a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V7.84c0-1.1.9-2 2-2h5.8"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
