/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      d="m12 4-.59 1.92a8.28 8.28 0 0 1-5.5 5.5L4 12l1.92.59c2.63.8 4.69 2.86 5.5 5.5L12 20l.59-1.92a8.28 8.28 0 0 1 5.5-5.5L20 12l-1.92-.59a8.28 8.28 0 0 1-5.5-5.5L12 4Z"
      stroke="currentColor"
      strokeWidth={2}
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
