import { useEffect, useRef } from 'react';
import { Animated, StyleSheet, useWindowDimensions } from 'react-native';
import { Circle, CircleProps, Svg } from 'react-native-svg';

import { Text } from './Text';
import { View } from './View';
import { useTheme } from '../styles';

const OFFWHITE = 'rgba(255,255,255,0.6)';

const AnimatedCircle = Animated.createAnimatedComponent<React.ComponentType<CircleProps>>(Circle);

export function AnimatedProgressCircle({
  color,
  backgroundColor = '#c5c5d3',
  testID,
  'aria-label': ariaLabel,
  text,
  progress,
  strokeWidth = 3,
  size = 34,
}: {
  color?: string;
  backgroundColor?: string;
  testID?: string;
  'aria-label'?: string;
  text: string;
  strokeWidth?: number;
  size?: number;
  progress: number;
}) {
  const { fontScale } = useWindowDimensions();
  const complete = progress === 1;
  const strokeDashoffsetRef = useRef(new Animated.Value(progress ?? 1));
  const { theme } = useTheme();

  color ||= theme.color.accent100;

  const svgProgress = 100 - (progress ?? 1) * 100;
  const percent = Math.floor(progress * 100);

  const radius = (size - strokeWidth) / 2;
  const circum = radius * 2 * Math.PI;

  useEffect(() => {
    const strokeDashoffset = radius * Math.PI * 2 * (svgProgress / 100);
    Animated.timing(strokeDashoffsetRef.current, {
      toValue: strokeDashoffset,
      duration: 200,
      useNativeDriver: true,
    }).start();
  }, [svgProgress, radius]);

  return (
    <View
      testID={testID}
      aria-label={ariaLabel ? `${ariaLabel}. ${percent} percent` : undefined}
      accessible={!!ariaLabel}
    >
      <View testID={`AnimatedProgressCircle_progress_${percent}`}>
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            width: size * fontScale,
            height: size * fontScale,
            borderRadius: 18,
          }}
        >
          <View style={StyleSheet.absoluteFillObject}>
            <Svg viewBox={`0 0 ${size} ${size}`}>
              <AnimatedCircle
                stroke={backgroundColor}
                fill="none"
                cx={size / 2}
                cy={size / 2}
                r={radius}
                strokeDasharray={`${circum} ${circum}`}
                strokeDashoffset={0}
                strokeLinecap="round"
                strokeWidth="2"
              />
            </Svg>
          </View>
          <View style={StyleSheet.absoluteFillObject}>
            <Svg viewBox={`0 0 ${size} ${size}`}>
              <AnimatedCircle
                stroke={complete || progress ? color : OFFWHITE}
                fill={complete ? color : 'none'}
                cx={size / 2}
                cy={size / 2}
                r={radius}
                strokeDasharray={`${circum} ${circum}`}
                strokeDashoffset={strokeDashoffsetRef.current}
                strokeLinecap="round"
                transform={`rotate(-90, ${size / 2}, ${size / 2})`}
                strokeWidth={strokeWidth}
              />
            </Svg>
          </View>
          {text ? (
            <Text
              text={text}
              color={complete ? 'white' : theme.color.gray300}
              weight={complete ? 'bold' : undefined}
              style={{ lineHeight: size }}
            />
          ) : null}
        </View>
      </View>
    </View>
  );
}
