export type ChatInputTextProps = {
  autoFocus?: boolean;
  keyboardType?: 'default' | 'email-address' | 'numeric' | 'phone-pad';
  placeholder?: string;
};

export type ChatInputDatePickerProps = {};
export type ChatInputTimePickerProps = {
  defaultTime?: string;
};
export type ChatInputContactsPickerProps = { allowSkip?: boolean };
export type ChatInputMediaPickerProps = {
  context: string;
  keyPrefix: string;
  allowSkip?: boolean;
};

export type ChatInputCarouselPropsItem = {
  ID: string;
  label: string;
  thumbnail: string;
};
export type ChatInputCarouselProps = {
  allowSkip?: boolean;
  items: ChatInputCarouselPropsItem[];
};

export type ChatInputChoiceProps = {
  min: number;
  max: number;
  label: { [key: number]: string };
  variant?: 'radio' | 'button';
};

export type ChatInputSingleChoiceProps = {
  label: { [key: number]: string };
  variant?: 'radio' | 'button';
};

export type ChatInputMultipleChoiceProps = {
  min: number;
  max: number;
  label: { [key: number]: string };
};

export type ChatInputButtonProps = {
  label: string;
};

export type ChatInputSliderProps = {
  maxLabel?: string;
  maxValue: number;
  minLabel?: string;
  minValue: number;
  labels?: { [key: string]: string };
  step?: number;
  value?: number;
  displayStyle?: 'value' | 'label' | 'both';
};

export type ChatInputSignatureProps = {};

export type ChatInputArtifactProps = {
  artifactName:
    | 'ActivityDiary'
    | 'BingeEatingMyPlan'
    | 'BreathHolding'
    | 'CopingCards'
    | 'CrisisFramework'
    | 'EatingLog'
    | 'EditEatingSchedule'
    | 'HopeKit'
    | 'Hyperventilation'
    | 'MyPlanReview'
    | 'QuizSet'
    | 'Relaxation'
    | 'RiskCurve'
    | 'SleepDiary'
    | 'SoloMyPlan'
    | 'SoloRiskCurve'
    | 'SoloSuicideMode'
    | 'StressSensitivityAssessment'
    | 'SuicideMode'
    | 'TestWidget'
    | 'ThoughtDiary'
    | 'WordPairing';
  buttonText: string;
  params?: object;
  retry?: true;
};
