/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      d="M6.1 1c-.34.06-.57.32-.8.52A12.47 12.47 0 0 0 1 11.28c.03 1.25.25 2.45.9 3.54a4.6 4.6 0 0 0 1.82 1.7l.17.1c.37.23.82-.13 1.2.16.33.25.74.21 1.14.21h.04c.47.07.84-.4 1.27-.32.51.1.85-.17 1.24-.35.4-.18.66-.54 1.06-.7.22-.63.7-1.13.91-1.78.46-1.36.46-2.74.37-4.14-.05-.67-.17-1.34-.3-2-.08-.46-.36-.94-.55-1.41a12.5 12.5 0 0 0-3.02-4.43A5.6 5.6 0 0 0 6.15 1"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M7.95 5.52c.48 2.18.65 4.39.56 6.61-.04 1.14-.2 2.26-.62 3.33-.15.4-.51.7-.51 1.15"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M4.83 3.3c-.02.46-.23.86-.35 1.28a20.56 20.56 0 0 0-.63 3.2l-.17 1.45"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M3.7 12.86c.1.8.22 1.58.49 2.34.16.47.5.86.55 1.37"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M10.62 14.78c.18.34.5.37.83.4a3.54 3.54 0 0 0 2.75-1.03 8.95 8.95 0 0 0 2.53-4.55c.43-1.7.33-3.39-.17-5.05-.17-.55-.18-.6-.78-.52a9.27 9.27 0 0 0-5.2 2.3c-.16.14-.34.25-.55.3"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M14.45 9.2a17.72 17.72 0 0 1-2.75 4.9 5.72 5.72 0 0 1-1.73 1.58c-.24.12-.37.44-.71.36-.42-.09-.8-.24-1.1-.55L8 15.33"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
