import { useMutation } from '@apollo/client';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import * as Localization from 'expo-localization';
import { Fragment, useEffect, useState } from 'react';
import { SystemBars } from 'react-native-edge-to-edge';

import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { SessionCard } from '@oui/app-core/src/components/SessionCard';
import { TabHeader } from '@oui/app-core/src/components/TabHeader';
import { View } from '@oui/app-core/src/components/View';
import { graphql } from '@oui/lib/src/graphql/tada';
import { ContentType } from '@oui/lib/src/types';

import { useNavigateToStaticSessionHook } from '../hooks/useNavigateToStaticSessionHook';

export const SetLocaleAndTimezoneMutation = graphql(`
  mutation SetLocaleAndTimezone($locale: Any!, $timezone: Any!) {
    locale: kvRespond(context: "user", key: "locale", data: $locale) {
      context
      key
    }
    timezone: kvRespond(context: "user", key: "timezone", data: $timezone) {
      context
      key
    }
  }
`);

const SESSIONS = [
  {
    ordinal: 1,
    title: `Session 1`,
    subtitle: null,
    description: '',
    slug: ContentType.STATIC_01,
  },
  {
    ordinal: 2,
    title: `Session 2`,
    subtitle: null,
    description: '',
    slug: ContentType.STATIC_02,
  },
  {
    ordinal: 3,
    title: `Session 3`,
    subtitle: null,
    description: '',
    slug: ContentType.STATIC_03,
  },
  {
    ordinal: 4,
    title: `Session 4`,
    subtitle: null,
    description: '',
    slug: ContentType.STATIC_04,
  },
  {
    ordinal: 5,
    title: `Session 5`,
    subtitle: null,
    description: '',
    slug: ContentType.STATIC_05,
  },
  {
    ordinal: 6,
    title: `Session 6`,
    subtitle: null,
    description: '',
    slug: ContentType.STATIC_06,
  },
  {
    ordinal: 7,
    title: `Session 7`,
    subtitle: null,
    description: '',
    slug: ContentType.STATIC_07,
  },
  {
    ordinal: 8,
    title: `Session 8`,
    subtitle: null,
    description: '',
    slug: ContentType.STATIC_08,
  },
  {
    ordinal: 9,
    title: `Session 9`,
    subtitle: null,
    description: '',
    slug: ContentType.STATIC_09,
  },
  {
    ordinal: 10,
    title: `Session 10`,
    subtitle: null,
    description: '',
    slug: ContentType.STATIC_10,
  },
  {
    ordinal: 11,
    title: `Session 11`,
    subtitle: null,
    description: '',
    slug: ContentType.STATIC_11,
  },
  {
    ordinal: 12,
    title: `Session 12`,
    subtitle: null,
    description: '',
    slug: ContentType.STATIC_12,
  },
];

export function LearnStatic(_: {}) {
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (isFocused) {
      timeout = setTimeout(() => {
        setLoading(false);
      }, 500);
    } else {
      setLoading(true);
    }

    return () => clearTimeout(timeout);
  }, [isFocused]);

  const [setLocaleAndTimezone] = useMutation(SetLocaleAndTimezoneMutation);
  useEffect(() => {
    void setLocaleAndTimezone({
      variables: {
        locale: Localization.getLocales()[0].languageTag,
        timezone: Localization.getCalendars()[0].timeZone,
      },
    });
  }, [setLocaleAndTimezone]);

  const { navigateToStaticSession } = useNavigateToStaticSessionHook({
    navigation: navigation,
  });

  return (
    <View testID="LearnStatic" style={{ flex: 1 }}>
      {isFocused ? <SystemBars style="dark" /> : null}
      <ScrollView
        testID="LearnStatic_scrollView"
        style={{ flex: 1 }}
        contentContainerStyle={{
          paddingHorizontal: 20,
          paddingBottom: 20,
        }}
      >
        <TabHeader heading="Learn" />
        {!loading ? (
          <View style={{ marginTop: 20, gap: 10 }}>
            {SESSIONS.map((item, i) => {
              return (
                <Fragment key={i}>
                  <SessionCard
                    hideHeader
                    static
                    testID={`SessionCard_${item.slug}`}
                    state="unlocked"
                    session={{
                      num: item.ordinal,
                      title: item.title,
                      subtitle: item.subtitle ?? undefined,
                      details: [],
                      illustrationUrl: undefined,
                    }}
                    onPressTop={() => {
                      navigateToStaticSession(
                        { contentType: item.slug, name: item.title },
                        'sessions',
                      );
                    }}
                  />
                </Fragment>
              );
            })}
          </View>
        ) : (
          <ActivityIndicator />
        )}
      </ScrollView>
    </View>
  );
}
