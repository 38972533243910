import { Image } from 'expo-image';
import { useEffect, useState } from 'react';

import { ContentType } from '@oui/lib/src/types';

import Biometric from '../assets/biometric.svg';
import FacialRecognition from '../assets/facialRecognition.svg';
import { AuthScreenContainer } from '../components/AuthScreenContainer';
import { Button } from '../components/Button';
import { Fingerprint } from '../components/Fingerprint';
import { SetPINModal } from '../components/SetPINModal';
import { Text } from '../components/Text';
import { View } from '../components/View';
import { useProgressComplete } from '../hooks/useCurrentUser';
import { usePersistedState } from '../hooks/usePersistedState';
import {
  AuthenticationType,
  AuthenticationTypes,
  useReauthenticationState,
} from '../hooks/useReauthenticationState';
import { IntlShape, useI18n } from '../lib/i18n';
import { useTheme } from '../styles';
import { StackScreenProps } from '../types/navigation';

function getMethodName($t: IntlShape['$t'], type: AuthenticationType) {
  switch (type) {
    case AuthenticationTypes.FINGERPRINT:
      return $t({
        id: 'LocalAuthenticationPrompt_fingerprintMethod',
        defaultMessage: 'Fingerprint',
      });
    case AuthenticationTypes.FACIAL_RECOGNITION:
      return $t({ id: 'LocalAuthenticationPrompt_faceMethod', defaultMessage: 'FaceID' });
    case AuthenticationTypes.PIN:
      return $t({ id: 'LocalAuthenticationPrompt_pinMethod', defaultMessage: 'PIN' });
    case AuthenticationTypes.BIOMETRIC:
      return $t({
        id: 'LocalAuthenticationPrompt_biometricMethod',
        defaultMessage: 'fingerprint or face unlock',
      });
    case AuthenticationTypes.IRIS:
      return $t({ id: 'LocalAuthenticationPrompt_irisMethod', defaultMessage: 'Iris unlock' });
  }
}

export default function LocalAuthenticationPrompt(
  props: StackScreenProps<'LocalAuthenticationPrompt'>,
) {
  const [, setHasSeenLocalAuthenticationPrompt] = usePersistedState(
    'SeenLocalAuthenticationPrompt',
    false,
  );
  const { completeResult: myStoryMyPlanNotComplete } = useProgressComplete(
    ContentType.MYPLAN,
    false,
  );
  const [showSetPINModal, setShowSetPINModal] = useState(false);
  const { $t } = useI18n();
  const { addEnrollment, isLoading, supportedTypes } = useReauthenticationState();
  const { theme } = useTheme();
  const onDone = () => {
    if (myStoryMyPlanNotComplete) {
      props.navigation.navigate('FinishPatientRegistration');
      setHasSeenLocalAuthenticationPrompt(true);
      return;
    }
    setHasSeenLocalAuthenticationPrompt(true);
  };
  const supportedType = supportedTypes[supportedTypes.length - 1];
  const method = getMethodName($t, supportedType);
  const [disableLocalAuthentication, , loadingDisableLocalAuthentication] = usePersistedState(
    'disableLocalAuthentication',
    false,
  );

  useEffect(() => {
    if (!loadingDisableLocalAuthentication && disableLocalAuthentication) {
      onDone();
    }
    // we want this effect to run once when disableLocalAuthentication has loaded. At that time,
    // the closure will update to the latest values of onDone/disableLocalAuthentication
    // eslint-disable-next-line
  }, [loadingDisableLocalAuthentication]);

  return isLoading ? (
    <AuthScreenContainer heading="">
      <View />
    </AuthScreenContainer>
  ) : (
    <AuthScreenContainer
      heading={$t(
        { id: 'LocalAuthenticationPrompt_heading', defaultMessage: 'Enable {method}?' },
        { method },
      )}
    >
      <View spacing={16} style={{ alignItems: 'center' }}>
        {supportedType === AuthenticationTypes.FINGERPRINT ? (
          <Fingerprint color={theme.color.accentTwo100} />
        ) : null}
        {supportedType === AuthenticationTypes.BIOMETRIC ? (
          <Image source={Biometric} style={{ width: 175, height: 80 }} />
        ) : null}
        {supportedType === AuthenticationTypes.FACIAL_RECOGNITION ? (
          <Image source={FacialRecognition} style={{ width: 62, height: 62 }} />
        ) : null}
        <Text
          testID={`LocalAuthenticationPrompt_${supportedType}`}
          text={$t(
            {
              id: 'LocalAuthenticationPrompt_description',
              defaultMessage:
                'Use {method} to log in securely and easily. You can enable or disable it in your account settings at any time.',
            },
            { method },
          )}
          weight="semibold"
          size={16}
          style={{ marginBottom: 20, textAlign: 'center' }}
        />
        <Button
          onPress={() => {
            if (supportedType === AuthenticationTypes.PIN) {
              setShowSetPINModal(true);
              return;
            }
            addEnrollment(supportedType);
            onDone();
          }}
          alignSelf="center"
          text={$t({ id: 'LocalAuthenticationPrompt_acceptButton', defaultMessage: 'Yes, enable' })}
          testID="LocalAuthenticationPrompt_acceptButton"
        />
        <Button
          onPress={onDone}
          alignSelf="center"
          variant="text"
          text={$t({ id: 'LocalAuthenticationPrompt_denyButton', defaultMessage: 'Not now' })}
          testID="LocalAuthenticationPrompt_denyButton"
        />
      </View>
      {showSetPINModal ? (
        <SetPINModal
          onRequestClose={() => {
            setShowSetPINModal(false);
            onDone();
          }}
          onSetPIN={() => {
            addEnrollment(AuthenticationTypes.PIN);
            setShowSetPINModal(false);
            onDone();
          }}
        />
      ) : null}
    </AuthScreenContainer>
  );
}
