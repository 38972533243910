/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={16} height={19} viewBox="0 0 16 19" fill="none" {...props}>
    <Path d="m4.3 17.53 7.4-8-7.4-8" fill="currentColor" />
    <Path
      d="m4.3 17.53 7.4-8-7.4-8v16Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
