import {
  ImagePickerOptions,
  ImagePickerResult,
  launchCameraAsync,
  launchImageLibraryAsync,
  requestCameraPermissionsAsync,
  UIImagePickerPreferredAssetRepresentationMode,
} from 'expo-image-picker';
import { Platform } from 'react-native';

import { askAsync } from './permissionsManager';

export async function launchMediaPickerAsync(
  mode: 'gallery' | 'camera',
  options?: ImagePickerOptions,
): Promise<ImagePickerResult | { canceled: true; assets: null; denied: true }> {
  options = {
    mediaTypes: 'images' as const,
    allowsEditing: false,
    quality: 1,
    preferredAssetRepresentationMode: UIImagePickerPreferredAssetRepresentationMode.Compatible,
    ...options,
  } satisfies ImagePickerOptions;

  if (global.e2e) {
    return Promise.resolve<ImagePickerResult>({
      canceled: false,
      assets: [
        {
          uri:
            Platform.OS === 'ios'
              ? // For some reason iOS can't fetch picsum.photos, but android can
                // Web can't fetch placedog.net b/c of a CORS issue
                'https://placedog.net/500/500'
              : 'https://picsum.photos/500',
          width: 500,
          height: 500,
        },
      ],
    });
  }

  if (mode === 'gallery') {
    return launchImageLibraryAsync(options);
  }

  const permissionResult = await askAsync('Camera', () => requestCameraPermissionsAsync(), {
    retryIfDenied: true,
  });

  if (!permissionResult.granted) return { canceled: true, assets: null, denied: true };

  return launchCameraAsync(options);
}
