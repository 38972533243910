/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      d="M1 2.84C.94 1.82 1.4 1 2.06 1H15.3c.65 0 1.12.82 1.04 1.84l-.9 12.33c-.07 1.01-.66 1.83-1.3 1.83H3.21c-.65 0-1.24-.81-1.31-1.83l-.9-12.33Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M5.8 5.25c0-.38.26-.52.57-.32l5.91 3.86c.32.2.32.54 0 .74l-5.91 3.86c-.32.2-.57.06-.57-.32V5.25Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
