import type { ApolloClient } from '@apollo/client';

import { setUserProperties } from '@oui/app-core/src/lib/log';
import { graphql } from '@oui/lib/src/graphql/tada';

const CurrentUserOrganizationsQuery = graphql(`
  query CurrentUserOrganizations($query: String) {
    organizations(query: $query) {
      ID
      name
      isTrialOrganization
    }
  }
`);

export async function setOrganizationUserProperties(client: ApolloClient<unknown>) {
  const { data } = await client.query({ query: CurrentUserOrganizationsQuery });
  const organization = data?.organizations?.[0];
  if (organization) {
    setUserProperties({
      organizationID: organization.ID,
      organizationName: organization.name ?? 'Unknown',
    });
  }
}
