import { registerChatArtifactPreviewComponent } from '@oui/app-core/src/components/ChatArtifactPreview';
import { registerChatInputArtifactHandler } from '@oui/app-core/src/components/ChatInputArtifact';

import { SleepDiary } from './screens';

registerChatArtifactPreviewComponent('SleepDiary', () => SleepDiary);
registerChatInputArtifactHandler('SleepDiary', async ({ startArtifactRequest, navigate }) => {
  startArtifactRequest('SleepDiary');
  navigate('SetupSleepDiary', {});
});

export * from './screens';
export * from './components';
export * from './hooks';
export * from './types/navigation';
export * from './register';
