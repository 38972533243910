import { ApolloError, useApolloClient, useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import addDays from 'date-fns/addDays';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import isAfter from 'date-fns/isAfter';
import { Image } from 'expo-image';
import equals from 'fast-deep-equal';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import { ComponentProps, useEffect, useMemo, useRef, useState } from 'react';
import { Platform } from 'react-native';
import { useDebounce } from 'use-debounce';

import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import { Button } from '@oui/app-core/src/components/Button';
import {
  CardStack,
  Card as CardStackCard,
  CardStackRef,
} from '@oui/app-core/src/components/CardStack';
import { DateTimeInput } from '@oui/app-core/src/components/DateTimeInput';
import { DiaryTabs } from '@oui/app-core/src/components/DiaryTabs';
import { Icon } from '@oui/app-core/src/components/Icon';
import { PickerInput } from '@oui/app-core/src/components/PickerInput';
import { PracticeRatingsInput } from '@oui/app-core/src/components/PracticeRatingInput';
import {
  RoundedSection,
  RoundedSectionTopChild,
} from '@oui/app-core/src/components/RoundedSection';
import { SegmentedControl } from '@oui/app-core/src/components/SegmentedControl';
import { Heading, Label, Lead, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import {
  getDefaultSleepDiaryEntry,
  PracticeFragment,
  SleepDiaryConfigInfluencerFrequency,
  SleepDiaryEntryFragment,
  useSleepDiaryConfig,
} from '@oui/app-core/src/hooks/practices';
import { useCurrentUser } from '@oui/app-core/src/hooks/useCurrentUser';
import { useForm } from '@oui/app-core/src/hooks/useForm';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';
import { formatGQLDate, parseGQLDateTime } from '@oui/lib/src/gqlDate';
import { graphql, VariablesOf } from '@oui/lib/src/graphql/tada';
import { omitDeep } from '@oui/lib/src/omitDeep';
import { parseHoursAndMinutes } from '@oui/lib/src/parseHoursAndMinutes';
import {
  AddSleepDiaryEntryPracticeInput,
  RatingType,
  SleepDiaryEntryDuration,
  SleepDiaryEntryInfluencerInput,
  UpdateSleepDiaryEntryPracticeInput,
} from '@oui/lib/src/types/graphql.generated';
import { GQLDateTime, GQLTime } from '@oui/lib/src/types/scalars';

import { type SleepDiaryEntriesQueryName } from './SleepDiary';
import {
  getAlcoholItems,
  getCaffeineItems,
  getDeviceInBedItems,
  getDurationItems,
  getExerciseItems,
  getMedicineItems,
  getNappingItems,
  getQualityItems,
  getTimeOfDayItems,
  getTobaccoItems,
  getWakeupCountItems,
  useSleepDiaryEntryPracticeByDate,
  type SleepDiaryEntryByDateQueryName,
} from './SleepDiaryEntry';
import MorningBackground from '../assets/sleepDiary/morning.svg';
import NightBackground from '../assets/sleepDiary/night.svg';
import { useSleepDiaryContext } from '../components';
import { StarRating } from '../components/StarRating';
import { StackScreenProps } from '../types/navigation';

export const AddSleepDiaryEntryMutation = graphql(
  `
    mutation AddSleepDiaryEntry($input: AddSleepDiaryEntryPracticeInput!) {
      saveSleepDiaryEntry: addSleepDiaryEntry(input: $input) {
        sleepDiaryEntryPractice {
          ...PracticeFragment
          sleepDiaryEntry {
            ...SleepDiaryEntry
          }
        }
      }
    }
  `,
  [PracticeFragment, SleepDiaryEntryFragment],
);

export const UpdateSleepDiaryEntryMutation = graphql(
  `
    mutation UpdateSleepDiaryEntry($input: UpdateSleepDiaryEntryPracticeInput!) {
      saveSleepDiaryEntry: updateSleepDiaryEntry(input: $input) {
        sleepDiaryEntryPractice {
          ...PracticeFragment
          sleepDiaryEntry {
            ...SleepDiaryEntry
          }
        }
      }
    }
  `,
  [PracticeFragment, SleepDiaryEntryFragment],
);

// eslint-disable-next-line
const formFoo = () => useForm<AddSleepDiaryEntryPracticeInput>({});
type FormType = ReturnType<typeof formFoo>;
type Props = {
  form: FormType;
  isToday: boolean;
};

function MorningChecklist(props: Props) {
  const navigation =
    useNavigation<StackScreenProps<'EditSleepDiaryEntry' | 'SleepDiaryEntry'>['navigation']>();
  const { theme } = useTheme();
  const { bind } = props.form;
  const { $t, formatDate } = useI18n();

  const {
    value: startTime,
    onChangeValue: changeStartTime,
    ...startTimeRest
  } = bind(['sleepDiaryEntry', 'startTime'], {
    'aria-label': 'In bed at',
  });

  const {
    value: endTime,
    onChangeValue: changeEndTime,
    ...endTimeRest
  } = bind(['sleepDiaryEntry', 'endTime'], {
    'aria-label': 'Out of bed at',
  });

  function sanitizeTimes({ newStartTime, newEndTime }: { newStartTime: Date; newEndTime: Date }) {
    if (isAfter(newStartTime, newEndTime)) {
      newStartTime = addDays(newStartTime, -1);
    } else if (differenceInHours(newEndTime, newStartTime) >= 24) {
      newStartTime = addDays(newStartTime, 1);
    }

    if (newStartTime.toISOString() !== startTime) {
      changeStartTime(newStartTime.toISOString() as GQLDateTime);
    }
    if (newEndTime.toISOString() !== endTime) {
      changeEndTime(newEndTime.toISOString() as GQLDateTime);
    }
  }

  const differenceInMin = differenceInMinutes(
    parseGQLDateTime(endTime),
    parseGQLDateTime(startTime),
  );

  return (
    <View>
      <CardStack hideNextButtonIndexes={[2]}>
        <CardStackCard>
          <View spacing={30}>
            <View row spacing={12} style={{ alignSelf: 'center' }}>
              <Icon name="sleep-bed" color={theme.color.dark} />
              <Heading
                text={$t({
                  id: 'EditSleepDiaryEntry_heading',
                  defaultMessage: 'Sleep',
                })}
                level={2}
              />
            </View>
            <View>
              <View row style={{ justifyContent: 'space-between' }} spacing={20}>
                <View aria-hidden row flex={2}>
                  <Text
                    weight="semibold"
                    text={$t({
                      id: 'EditSleepDiaryEntry_startTimeLabel',
                      defaultMessage: 'In bed at',
                    })}
                    style={{ flex: 1 }}
                  />
                </View>
                <DateTimeInput
                  style={{ flex: 3 }}
                  mode="time"
                  minuteInterval={Platform.select({ default: 15, android: 5 })}
                  aria-label={$t({
                    id: 'EditSleepDiaryEntry_startTimeAccessibilityLabel',
                    defaultMessage: 'In bed at',
                  })}
                  {...startTimeRest}
                  value={
                    formatDate(parseGQLDateTime(startTime), {
                      hour: 'numeric',
                      minute: '2-digit',
                      hourCycle: 'h23',
                    }) as GQLTime
                  }
                  onChangeValue={(hhmm) => {
                    sanitizeTimes({
                      newStartTime: parseHoursAndMinutes(hhmm, parseGQLDateTime(startTime)),
                      newEndTime: parseGQLDateTime(endTime),
                    });
                  }}
                />
              </View>
            </View>
            <View row style={{ justifyContent: 'space-between', marginVertical: -20 }} spacing={20}>
              <View style={{ flex: 2 }} />
              <Text
                text={$t(
                  {
                    id: 'EditSleepDiaryEntry_timeInBedLabel',
                    defaultMessage: '{hoursInBed}h {minsInBed}m in bed',
                  },
                  {
                    hoursInBed: `${Math.floor(differenceInMin / 60)}`,
                    minsInBed: `${differenceInMin % 60}`,
                  },
                )}
                textAlign="center"
                style={{ flex: 3 }}
                weight="semibold"
                color={theme.color.gray300}
              />
            </View>
            <View row style={{ justifyContent: 'space-between' }} spacing={20}>
              <View aria-hidden row flex={2}>
                <Text
                  weight="semibold"
                  text={$t({
                    id: 'EditSleepDiaryEntry_endTimeLabel',
                    defaultMessage: 'Out of bed at',
                  })}
                  style={{ flex: 1 }}
                />
              </View>
              <DateTimeInput
                style={{ flex: 3 }}
                mode="time"
                aria-label={$t({
                  id: 'EditSleepDiaryEntry_endTimeAccessibilityLabel',
                  defaultMessage: 'Out of bed at',
                })}
                minuteInterval={Platform.select({ default: 15, android: 5 })}
                {...endTimeRest}
                value={
                  formatDate(parseGQLDateTime(endTime), {
                    hour: 'numeric',
                    minute: '2-digit',
                    hourCycle: 'h23',
                  }) as GQLTime
                }
                onChangeValue={(hhmm) => {
                  sanitizeTimes({
                    newStartTime: parseGQLDateTime(startTime),
                    newEndTime: parseHoursAndMinutes(hhmm, parseGQLDateTime(endTime)),
                  });
                }}
              />
            </View>
            <View spacing={12}>
              <Text
                weight="semibold"
                text={$t({
                  id: 'EditSleepDiaryEntry_ratingLabel',
                  defaultMessage: "I'd rate my sleep",
                })}
                textAlign="center"
                role="none"
              />
              <PracticeRatingsInput
                ratingType={RatingType.RATING}
                {...bind(['practiceValues', 'ratings'], {
                  'aria-label': $t({
                    id: 'EditSleepDiaryEntry_ratingAccessibilityLabel',
                    defaultMessage: "I'd rate my sleep",
                  }),
                })}
                testID="EditSleepDiaryEntry_rating"
              >
                {(renderProps) => <StarRating {...renderProps} />}
              </PracticeRatingsInput>
            </View>
          </View>
        </CardStackCard>
        <CardStackCard>
          <View spacing={30}>
            <View row spacing={12} style={{ alignSelf: 'center' }}>
              <Icon name="moon" color={theme.color.dark} />
              <Heading
                text={$t({
                  id: 'EditSleepDiaryEntry_lastNightHeading',
                  defaultMessage: 'Last night...',
                })}
                level={2}
              />
            </View>
            <View row style={{ justifyContent: 'space-between' }} spacing={10}>
              <View style={{ flex: 2 }}>
                <Label
                  text={$t({
                    id: 'EditSleepDiaryEntry_sleepDelayLabel',
                    defaultMessage: 'I fell asleep',
                  })}
                  role="none"
                />
              </View>
              <PickerInput
                placeholder={$t({
                  id: 'EditSleepDiaryEntry_sleepDelayPlaceholder',
                  defaultMessage: 'Within how long?',
                })}
                items={getDurationItems($t, [
                  SleepDiaryEntryDuration.DURATION_LESS_THAN_15,
                  SleepDiaryEntryDuration.DURATION_15_TO_30,
                  SleepDiaryEntryDuration.DURATION_30_TO_60,
                  SleepDiaryEntryDuration.DURATION_MORE_THAN_60,
                ])}
                style={{ flex: 3 }}
                {...bind(['sleepDiaryEntry', 'sleepDelay'], {
                  'aria-label': $t({
                    id: 'EditSleepDiaryEntry_sleepDelayAccessibilityLabel',
                    defaultMessage: 'I fell asleep within how long',
                  }),
                })}
              />
            </View>
            <View row style={{ justifyContent: 'space-between' }} spacing={10}>
              <View style={{ flex: 2 }}>
                <Label
                  text={$t({
                    id: 'EditSleepDiaryEntry_wakeupCountLabel',
                    defaultMessage: 'I woke up during the night',
                  })}
                  role="none"
                />
              </View>
              <PickerInput
                placeholder={$t({
                  id: 'EditSleepDiaryEntry_wakeupCountPlaceholder',
                  defaultMessage: 'How many times?',
                })}
                items={getWakeupCountItems($t)}
                style={{ flex: 3 }}
                {...bind(['sleepDiaryEntry', 'wakeupCount'], {
                  'aria-label': $t({
                    id: 'EditSleepDiaryEntry_wakeupCountAccessibilityLabel',
                    defaultMessage: 'I woke up during the night how many times',
                  }),
                })}
              />
            </View>
            {props.form.data.sleepDiaryEntry.wakeupCount > 0 ? (
              <View row style={{ justifyContent: 'space-between' }} spacing={20}>
                <View style={{ flex: 2 }}>
                  <Label
                    text={$t({
                      id: 'EditSleepDiaryEntry_wakeupDurationLabel',
                      defaultMessage: 'I was awake for',
                    })}
                    role="none"
                  />
                </View>
                <PickerInput
                  placeholder={$t({
                    id: 'EditSleepDiaryEntry_wakeupDurationPlaceholder',
                    defaultMessage: 'How long in total?',
                  })}
                  items={getDurationItems($t, [
                    SleepDiaryEntryDuration.DURATION_LESS_THAN_15,
                    SleepDiaryEntryDuration.DURATION_15_TO_30,
                    SleepDiaryEntryDuration.DURATION_30_TO_60,
                    SleepDiaryEntryDuration.DURATION_60_TO_120,
                    SleepDiaryEntryDuration.DURATION_MORE_THAN_120,
                  ])}
                  style={{ flex: 3 }}
                  {...bind(['sleepDiaryEntry', 'wakeupDuration'], {
                    'aria-label': $t({
                      id: 'EditSleepDiaryEntry_wakeupDurationAccessibilityLabel',
                      defaultMessage: 'I was awake for how long in total',
                    }),
                  })}
                />
              </View>
            ) : null}
          </View>
        </CardStackCard>
        <CardStackCard>
          <View spacing={30}>
            <View row spacing={12} style={{ alignSelf: 'center' }}>
              <Icon name="sun" color="#ecbf2d" />
              <Heading
                text={$t({
                  id: 'EditSleepDiaryEntry_qualityHeading',
                  defaultMessage: 'This morning...',
                })}
                level={2}
              />
            </View>
            <View row style={{ justifyContent: 'space-between' }} spacing={20}>
              <View style={{ flex: 2 }}>
                <Label
                  text={$t({
                    id: 'EditSleepDiaryEntry_qualityLabel',
                    defaultMessage: 'When I woke up, I felt',
                  })}
                  role="none"
                />
              </View>
              <PracticeRatingsInput
                ratingType={RatingType.QUALITY}
                {...bind(['practiceValues', 'ratings'], {
                  'aria-label': $t({
                    id: 'EditSleepDiaryEntry_qualityAccessibilityLabel',
                    defaultMessage: 'When I woke up I felt how rested',
                  }),
                })}
                testID="EditSleepDiaryEntry_quality"
              >
                {(renderProps) => (
                  <PickerInput
                    placeholder={$t({
                      id: 'EditSleepDiaryEntry_qualityPlaceholder',
                      defaultMessage: 'How refreshed?',
                    })}
                    items={getQualityItems($t)}
                    {...renderProps}
                    style={{ flex: 3 }}
                  />
                )}
              </PracticeRatingsInput>
            </View>
            <Button
              text={$t({ id: 'EditSleepDiaryEntry_doneButton', defaultMessage: 'Done' })}
              onPress={() => {
                navigation.goBack();
              }}
              alignSelf="center"
              testID="EditSleepDiaryEntry_doneButton"
            />
          </View>
        </CardStackCard>
      </CardStack>
    </View>
  );
}

function SleepInfluencerCard<T extends number | string>(props: {
  icon: ComponentProps<typeof Icon>['name'];
  title: string;
  items: Array<{ label: string; value: T }>;
  kindItems?: ComponentProps<typeof PickerInput>['items'];
  type: 'duration' | 'amount';
  value?: SleepDiaryEntryInfluencerInput | null;
  onChangeValue: (newValue: SleepDiaryEntryInfluencerInput) => void;
  onDone: () => void;
  doneText?: string;
  skipTimeOfDay?: boolean;
  testID: string;
}) {
  const { theme } = useTheme();
  const { $t } = useI18n();

  function changeValue(partial: Partial<SleepDiaryEntryInfluencerInput>) {
    const newValue = { ...DEFAULT_SLEEP_INFLUENCER, ...props.value, ...partial };
    props.onChangeValue(newValue);
  }

  return (
    <View spacing={20} testID={props.testID}>
      <View spacing={12} style={{ alignItems: 'center', alignSelf: 'center' }}>
        <View
          style={{
            padding: 10,
            backgroundColor: '#d2dfff',
            borderRadius: 50,
          }}
        >
          <Icon name={props.icon} size={25} color={theme.color.dark} />
        </View>
        <Lead text={props.title} textAlign="center" />
      </View>
      <View row style={{ justifyContent: 'center' }} spacing={20}>
        <Button
          text={$t({ id: 'EditSleepDiaryEntry_influencerYesButton', defaultMessage: 'Yes' })}
          onPress={() => changeValue({ occurred: true })}
          variant={props.value?.occurred === true ? 'solid' : 'contained'}
          testID={`${props.testID}_yesButton`}
        />
        <Button
          text={$t({ id: 'EditSleepDiaryEntry_influencerNoButton', defaultMessage: 'No' })}
          onPress={() => {
            changeValue({ occurred: false });
            props.onDone();
          }}
          variant={props.value?.occurred === false ? 'solid' : 'contained'}
          testID={`${props.testID}_noButton`}
        />
      </View>
      {props.value?.occurred ? (
        <View spacing={20}>
          {props.type === 'amount' ? (
            <View row style={{ justifyContent: 'space-between' }} spacing={20}>
              <View style={{ flex: 2 }}>
                <Label
                  text={$t({
                    id: 'EditSleepDiaryEntry_influencerAmountLabel',
                    defaultMessage: 'How much?',
                  })}
                  role="none"
                />
              </View>
              <PickerInput<T>
                aria-label={$t({
                  id: 'EditSleepDiaryEntry_influencerAmountAccessibilityLabel',
                  defaultMessage: 'How much?',
                })}
                placeholder={$t({
                  id: 'EditSleepDiaryEntry_influencerAmountPlaceholder',
                  defaultMessage: 'Choose amount',
                })}
                items={props.items}
                style={{ flex: 3 }}
                value={props.value.count}
                onChangeValue={(v) => changeValue({ count: v as unknown as number })}
                testID={`${props.testID}_amountPicker`}
              />
            </View>
          ) : (
            <View row style={{ justifyContent: 'space-between' }} spacing={20}>
              <View style={{ flex: 2 }}>
                <Label
                  text={$t({
                    id: 'EditSleepDiaryEntry_influencerDurationLabel',
                    defaultMessage: 'How long?',
                  })}
                  role="none"
                />
              </View>
              <PickerInput<T>
                aria-label={$t({
                  id: 'EditSleepDiaryEntry_influencerDurationAccessibilityLabel',
                  defaultMessage: 'How long?',
                })}
                placeholder={$t({
                  id: 'EditSleepDiaryEntry_influencerDurationPlaceholder',
                  defaultMessage: 'Choose duration',
                })}
                items={props.items}
                style={{ flex: 3 }}
                value={props.value.duration}
                onChangeValue={(v) =>
                  changeValue({ duration: v as unknown as SleepDiaryEntryDuration })
                }
                testID={`${props.testID}_durationPicker`}
              />
            </View>
          )}
          {props.kindItems ? (
            <View row style={{ justifyContent: 'space-between' }} spacing={20}>
              <View style={{ flex: 2 }}>
                <Label
                  text={$t({
                    id: 'EditSleepDiaryEntry_influencerKindLabel',
                    defaultMessage: 'What kind?',
                  })}
                  role="none"
                />
              </View>
              <PickerInput
                aria-label={$t({
                  id: 'EditSleepDiaryEntry_influencerKindAccessibilityLabel',
                  defaultMessage: 'What kind?',
                })}
                placeholder={$t({
                  id: 'EditSleepDiaryEntry_influencerKindPlaceholder',
                  defaultMessage: 'Name of meds',
                })}
                items={props.kindItems}
                style={{ flex: 3 }}
                value={props.value.kinds?.[0]}
                onChangeValue={(v) =>
                  typeof v === 'undefined'
                    ? undefined
                    : changeValue({ kinds: [v as unknown as string] })
                }
                testID={`${props.testID}_kindPicker`}
              />
            </View>
          ) : null}
          {props.skipTimeOfDay ? null : (
            <View style={{ justifyContent: 'space-between' }} spacing={20}>
              <Label
                text={$t({
                  id: 'EditSleepDiaryEntry_influencerTimeOfDayLabel',
                  defaultMessage: 'When?',
                })}
                role="none"
              />
              <SegmentedControl
                aria-label={$t({
                  id: 'EditSleepDiaryEntry_influencerTimeOfDayAccessibilityLabel',
                  defaultMessage: 'When?',
                })}
                value={props.value.timesOfDay}
                onChangeValue={(timesOfDay) => {
                  changeValue({ timesOfDay });
                }}
                items={getTimeOfDayItems($t)}
                testID={`${props.testID}_timeOfDayInput`}
              />
            </View>
          )}
          <Button
            text={
              props.doneText ??
              $t({ id: 'EditSleepDiaryEntry_influencerNextButton', defaultMessage: 'Next' })
            }
            alignSelf="center"
            onPress={props.onDone}
            testID={`${props.testID}_nextButton`}
          />
        </View>
      ) : null}
    </View>
  );
}

function NightChecklist(props: Props) {
  const navigation =
    useNavigation<StackScreenProps<'EditSleepDiaryEntry' | 'SleepDiaryEntry'>['navigation']>();
  const { data: sleepConfig } = useSleepDiaryConfig();
  const { bind } = props.form;
  const ref = useRef<CardStackRef>(null);
  const { $t } = useI18n();

  function onNext() {
    ref.current?.next();
  }

  return (
    <View>
      <Heading
        level={2}
        text={
          props.isToday
            ? $t({ id: 'EditSleepDiaryEntry_nightTodayHeading', defaultMessage: 'Today, I...' })
            : $t({
                id: 'EditSleepDiaryEntry_nightNotTodayHeading',
                defaultMessage: 'This day, I...',
              })
        }
        textAlign="center"
        style={{ marginTop: props.isToday ? 0 : -15, marginBottom: 30 }}
      />
      <CardStack hideNextButtonIndexes={[0, 1, 2, 3, 4, 5, 6, 7]} ref={ref}>
        {sleepConfig?.caffeine === SleepDiaryConfigInfluencerFrequency.Never ? null : (
          <CardStackCard>
            <SleepInfluencerCard
              onDone={onNext}
              icon="coffee"
              title={$t({
                id: 'EditSleepDiaryEntry_caffeineTitle',
                defaultMessage: 'Had caffeine?',
              })}
              type="amount"
              items={getCaffeineItems($t)}
              {...bind(['sleepDiaryEntry', 'caffeine'], {
                'aria-label': $t({
                  id: 'EditSleepDiaryEntry_caffeineAcessibilityLabel',
                  defaultMessage: 'Had caffeine?',
                }),
              })}
            />
          </CardStackCard>
        )}
        {sleepConfig?.alcohol === SleepDiaryConfigInfluencerFrequency.Never ? null : (
          <CardStackCard>
            <SleepInfluencerCard
              onDone={onNext}
              icon="alcohol"
              title={$t({ id: 'EditSleepDiaryEntry_alcoholTitle', defaultMessage: 'Had alcohol?' })}
              type="amount"
              items={getAlcoholItems($t)}
              {...bind(['sleepDiaryEntry', 'alcohol'], {
                'aria-label': $t({
                  id: 'EditSleepDiaryEntry_alcoholAcessibilityLabel',
                  defaultMessage: 'Had alcohol?',
                }),
              })}
            />
          </CardStackCard>
        )}
        {sleepConfig?.tobacco === SleepDiaryConfigInfluencerFrequency.Never ? null : (
          <CardStackCard>
            <SleepInfluencerCard
              onDone={onNext}
              icon="smoking"
              title={$t({
                id: 'EditSleepDiaryEntry_tobaccoTitle',
                defaultMessage: 'Smoked tobacco?',
              })}
              type="amount"
              items={getTobaccoItems($t)}
              {...bind(['sleepDiaryEntry', 'tobacco'], {
                'aria-label': $t({
                  id: 'EditSleepDiaryEntry_tobaccoAcessibilityLabel',
                  defaultMessage: 'Had tobacco?',
                }),
              })}
            />
          </CardStackCard>
        )}
        {sleepConfig?.napping === SleepDiaryConfigInfluencerFrequency.Never ? null : (
          <CardStackCard>
            <SleepInfluencerCard
              onDone={onNext}
              icon="sleep-bed"
              title={$t({
                id: 'EditSleepDiaryEntry_nappingTitle',
                defaultMessage: 'Napped during the day?',
              })}
              type="duration"
              items={getNappingItems($t)}
              {...bind(['sleepDiaryEntry', 'napping'], {
                'aria-label': $t({
                  id: 'EditSleepDiaryEntry_nappingAcessibilityLabel',
                  defaultMessage: 'Napped during the day?',
                }),
              })}
            />
          </CardStackCard>
        )}
        <CardStackCard>
          <SleepInfluencerCard
            onDone={onNext}
            icon="exercise"
            title={$t({
              id: 'EditSleepDiaryEntry_exerciseTitle',
              defaultMessage: 'Exercised 20+ min?',
            })}
            type="duration"
            items={getExerciseItems($t)}
            {...bind(['sleepDiaryEntry', 'exercise'], {
              'aria-label': $t({
                id: 'EditSleepDiaryEntry_exerciseAcessibilityLabel',
                defaultMessage: 'Exercised during the day?',
              }),
            })}
          />
        </CardStackCard>
        {sleepConfig?.medicine === SleepDiaryConfigInfluencerFrequency.Never ? null : (
          <CardStackCard>
            <SleepInfluencerCard
              onDone={onNext}
              icon="pill"
              title={$t({
                id: 'EditSleepDiaryEntry_medicineTitle',
                defaultMessage: 'Took sleep medication?',
              })}
              type="amount"
              items={getMedicineItems($t)}
              {...bind(['sleepDiaryEntry', 'medicine'], {
                'aria-label': $t({
                  id: 'EditSleepDiaryEntry_medicineAcessibilityLabel',
                  defaultMessage: 'Took sleep medication?',
                }),
              })}
              kindItems={[
                { label: 'Ambien®', value: 'Ambien®' },
                { label: 'Dalmane®', value: 'Dalmane®' },
                { label: 'Halcion®', value: 'Halcion®' },
                { label: 'Lunesta®', value: 'Lunesta®' },
                { label: 'Prosom®', value: 'Prosom®' },
                { label: 'Restoril®', value: 'Restoril®' },
                { label: 'Rozerem®', value: 'Rozerem®' },
                { label: 'Silenor®', value: 'Silenor®' },
                { label: 'Sonata®', value: 'Sonata®' },
                { label: 'Desyrel®', value: 'Desyrel®' },
                { label: 'Belsomra®', value: 'Belsomra®' },
                { label: 'Antihistamine', value: 'Antihistamine' },
                { label: 'Melatonin', value: 'Melatonin' },
                { label: 'Herbal formulations', value: 'Herbal formulations' },
              ]}
            />
          </CardStackCard>
        )}
        <CardStackCard>
          <SleepInfluencerCard
            onDone={() => {
              // Give time for onChangeValue to process
              setTimeout(() => {
                navigation.goBack();
              }, 400);
            }}
            doneText={$t({
              id: 'EditSleepDiaryEntry_nightCheckinDoneButton',
              defaultMessage: 'Done',
            })}
            icon="phone"
            title={$t({
              id: 'EditSleepDiaryEntry_deviceInBedTitle',
              defaultMessage: 'Used a device (cell, laptop, TV) in bed',
            })}
            type="duration"
            items={getDeviceInBedItems($t)}
            {...bind(['sleepDiaryEntry', 'deviceInBed'], {
              'aria-label': $t({
                id: 'EditSleepDiaryEntry_deviceInBedAcessibilityLabel',
                defaultMessage: 'Used a device (cell, laptop, TV) in bed',
              }),
            })}
            skipTimeOfDay
          />
        </CardStackCard>
      </CardStack>
    </View>
  );
}

const DEFAULT_SLEEP_INFLUENCER = {
  occurred: false,
  count: undefined,
  duration: undefined,
  kinds: undefined,
  timesOfDay: [],
};

export function EditSleepDiaryEntry(props: StackScreenProps<'EditSleepDiaryEntry'>) {
  const { theme } = useTheme();
  const { data: user } = useCurrentUser();
  const activeTab = props.route.params?.step ?? 'morning';
  const setActiveTab = (step: 'morning' | 'night') => props.navigation.setParams({ step: step });
  const { data: sleepConfig, loading: sleepConfigLoading } = useSleepDiaryConfig();
  const [addSleepDiaryEntry] = useMutation(AddSleepDiaryEntryMutation);
  const [updateSleepDiaryEntry] = useMutation(UpdateSleepDiaryEntryMutation);
  const {
    loading: entryLoading,
    practiceID,
    practiceValues,
    sleepDiaryEntry,
  } = useSleepDiaryEntryPracticeByDate();
  const loading = entryLoading || sleepConfigLoading;
  const [today] = useState(formatGQLDate);
  const date = props.route.params.date;
  const isToday = today === date;
  const { $t, formatDate } = useI18n();
  const sleepDiaryContext = useSleepDiaryContext();
  const apollo = useApolloClient();

  const patientID = user?.user?.role?.ID!;

  const defaultFormData = useMemo(() => {
    const baseDate = parseGQLDateTime(date);
    return getDefaultSleepDiaryEntry(sleepConfig, baseDate);
  }, [sleepConfig, date]);

  const form = useForm<
    VariablesOf<typeof AddSleepDiaryEntryMutation | typeof UpdateSleepDiaryEntryMutation>['input']
  >(
    {
      practiceID,
      practiceValues: {
        patientID,
        date,
        ...omit(practiceValues, ['__typename', 'role']),
        ratings: practiceValues?.ratings.length ? practiceValues.ratings : [],
      },
      sleepDiaryEntry: merge(defaultFormData, omitDeep(sleepDiaryEntry, ['__typename'])),
    },
    {
      // For timesOfDay always taking latest value
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      merger: (originalValue: any, formValue: any, key: string) => {
        if (Array.isArray(originalValue)) {
          if (key === 'ratings') {
            return merge(originalValue, formValue);
          }
          return formValue;
        }
      },
    },
  );

  useEffect(() => {
    if (!practiceID && !loading && patientID) {
      addSleepDiaryEntry({
        variables: {
          input: form.data,
        },
        refetchQueries: [
          'SleepDiaryEntries' satisfies SleepDiaryEntriesQueryName,
          // https://github.com/apollographql/apollo-client/issues/5963
          'SleepDiaryEntryByDate' satisfies SleepDiaryEntryByDateQueryName,
        ],
      }).catch((e) => {
        if (
          e instanceof ApolloError &&
          e.graphQLErrors[0]?.message === 'Sleep Diary Entry already exists'
        ) {
          return apollo.refetchQueries({
            include: ['SleepDiaryEntryByDate' satisfies SleepDiaryEntryByDateQueryName],
          });
        }

        throw e;
      });
    }
    // eslint-disable-next-line
  }, [practiceID, loading, patientID, addSleepDiaryEntry, apollo]);

  const [debouncedValue] = useDebounce(form.data, 200, { maxWait: 5000, equalityFn: equals });
  useEffect(() => {
    if (
      // TODO avoid update on mount
      (debouncedValue as UpdateSleepDiaryEntryPracticeInput).practiceID &&
      debouncedValue.practiceValues.patientID &&
      !loading
    ) {
      void updateSleepDiaryEntry({
        variables: {
          input: {
            ...debouncedValue,
            practiceID: (debouncedValue as UpdateSleepDiaryEntryPracticeInput).practiceID!,
          },
        },
      });
    }
  }, [updateSleepDiaryEntry, practiceID, patientID, debouncedValue, loading]);

  return (
    <View style={{ flex: 1, backgroundColor: theme.color.gray800 }}>
      <View
        style={[
          {
            position: 'absolute',
            bottom: 0,
            right: 0,
            left: 0,
            top: 0,
          },
        ]}
      >
        {activeTab === 'morning'
          ? sleepDiaryContext.morningBackground ?? (
              <View style={{ position: 'absolute', bottom: 0, right: 0, left: 0 }}>
                <Image
                  source={MorningBackground}
                  style={{ width: '100%', aspectRatio: 375 / 411 }}
                />
              </View>
            )
          : sleepDiaryContext.nightBackground ?? (
              <View style={{ position: 'absolute', bottom: 0, right: 0, left: 0 }}>
                <Image source={NightBackground} style={{ width: '100%', aspectRatio: 375 / 438 }} />
              </View>
            )}
      </View>
      <RoundedSection
        color={sleepDiaryContext.mainColor}
        secondaryColor="transparent"
        overflowColor="transparent"
        applyHeaderOptions
        title={
          isToday
            ? $t({ id: 'EditSleepDiaryEntry_todayTitle', defaultMessage: 'Today' })
            : form.data.practiceValues.date
              ? formatDate(parseGQLDateTime(form.data.practiceValues.date), {
                  weekday: 'short',
                  month: 'short',
                  day: 'numeric',
                })
              : sleepDiaryContext.name
        }
        preview={false}
        testID="EditSleepDiaryEntry_scrollView"
      >
        {isToday ? (
          <DiaryTabs
            value={activeTab}
            onChangeValue={setActiveTab}
            items={[
              {
                text: $t({ id: 'EditSleepDiaryEntry_morningDiaryTab', defaultMessage: 'Morning' }),
                value: 'morning',
              },
              {
                text: $t({ id: 'EditSleepDiaryEntry_nightDiaryTab', defaultMessage: 'Night' }),
                value: 'night',
              },
            ]}
            topOfRoundedSection
          />
        ) : (
          <RoundedSectionTopChild backgroundColor={theme.color.gray800} />
        )}
        {!practiceID ? (
          <ActivityIndicator />
        ) : activeTab === 'morning' ? (
          <MorningChecklist form={form} isToday={isToday} />
        ) : (
          <NightChecklist form={form} isToday={isToday} />
        )}
      </RoundedSection>
    </View>
  );
}
