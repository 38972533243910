import { useMutation, useQuery } from '@apollo/client';
import { useFocusEffect, useNavigation, useRoute } from '@react-navigation/native';
import * as Sentry from '@sentry/core';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ViewShot from 'react-native-view-shot';

import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import { Button } from '@oui/app-core/src/components/Button';
import { ExplanationVideo } from '@oui/app-core/src/components/ExplanationVideo/ExplanationVideo';
import {
  RoundedSection,
  RoundedSectionTopChild,
} from '@oui/app-core/src/components/RoundedSection';
import { ScrollToTop } from '@oui/app-core/src/components/ScrollView';
import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import {
  useArtifactRequest,
  useConversationRoute,
} from '@oui/app-core/src/hooks/useArtifactResult';
import { MyStoryMyPlanCompositionDataHash } from '@oui/app-core/src/hooks/useComposition';
import { useCurrentPatient } from '@oui/app-core/src/hooks/useCurrentUser';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { card, useTheme } from '@oui/app-core/src/styles';
import { graphql } from '@oui/lib/src/graphql/tada';
import { CompositionTemplates } from '@oui/lib/src/types/compositionTemplates';
import { ProductVariant } from '@oui/lib/src/types/graphql.generated';

import { MyPlanExport } from '../components/MyPlanExport';
import {
  BLANK_LOCATION,
  EnvironmentSafety,
  getMyPlanSteps,
  PatientMyPlan,
  PROFESSIONAL_SERVICES,
} from '../components/PatientMyPlan';
import { RiskCurveGraph } from '../components/RiskCurve';
import {
  IsEnvironmentSafetyLockedFragment,
  useIsEnvironmentSafetyLocked,
} from '../hooks/useIsEnvironmentSafetyLocked';
import { StackScreenProps } from '../types/navigation';

export const SoloMyPlanQuery = graphql(
  `
    query SoloMyPlan {
      user {
        ID
        role {
          ID
          composition(template: "MYSTORYMYPLAN") {
            ID
            json
          }
          ...IsEnvironmentSafetyLocked
        }
      }
    }
  `,
  [IsEnvironmentSafetyLockedFragment],
);

export const SoloMyPlanMutation = graphql(`
  mutation SoloMyPlan($json: Map!) {
    setComposition(template: MYSTORYMYPLAN, json: $json) {
      ID
      json
    }
  }
`);

function SoloMyPlanStepOverview() {
  const navigation = useNavigation<StackScreenProps<'SoloMyPlan'>['navigation']>();
  const route = useRoute<StackScreenProps<'SoloMyPlan'>['route']>();
  const props = { navigation, route };
  const { theme } = useTheme();
  const { data: queryData, loading } = useQuery(SoloMyPlanQuery, { fetchPolicy: 'cache-only' });
  const data = useMemo(() => {
    return loading
      ? undefined
      : CompositionTemplates.MYSTORYMYPLAN.parse(queryData?.user?.role?.composition?.json);
  }, [queryData, loading]);

  return (
    <>
      <RoundedSectionTopChild backgroundColor={theme.color.gray800}>
        <View style={{ backgroundColor: 'white', paddingHorizontal: 12, paddingVertical: 8 }}>
          <RiskCurveGraph
            preview
            crisisPeakID=""
            timeline={[]}
            aria-label="Stopping the risk curve"
            numRiskFactors={data?.RISK_FACTORS?.length}
          />
        </View>
      </RoundedSectionTopChild>
      <View style={{ padding: 20 }}>
        <Text
          text={`MyPlan aims to give you steps to follow to stop an escalating crisis.

You can update MyPlan at anytime. Don’t worry about filling everything out perfectly. Just give it go.

The important thing is that you try to fill in as much as you can now so you’ll know what to do if your risk starts rising.`}
        />
      </View>
      <ExplanationVideo
        variant="block"
        muxUrl="https://stream.mux.com/8RDVtZbwGY8bzs7XnHf01rjIexNWV0062e6LJfHqCAadY.m3u8"
      />
      <Button
        text="Next"
        onPress={() => props.navigation.setParams({ step: 'edit' })}
        alignSelf="center"
        style={{ marginTop: 20 }}
        testID="SoloMyPlanStepOverview_nextButton"
      />
    </>
  );
}

function SoloMyPlanStepEdit() {
  const navigation = useNavigation<StackScreenProps<'SoloMyPlan'>['navigation']>();
  const route = useRoute<StackScreenProps<'SoloMyPlan'>['route']>();
  const props = { navigation, route };

  const viewShotRef = useRef<ViewShot>(null);
  const role = useCurrentPatient();
  const { theme } = useTheme();
  const { data: queryData, loading } = useQuery(SoloMyPlanQuery, { fetchPolicy: 'cache-only' });
  const [update] = useMutation(SoloMyPlanMutation);
  const [editingSection, setEditingSection] = useState<keyof MyStoryMyPlanCompositionDataHash>(
    (props.route.params?.initialEditingSection as keyof MyStoryMyPlanCompositionDataHash) ??
      'WARNING_SIGNS',
  );
  const currentEditingSectionRef = useRef<keyof MyStoryMyPlanCompositionDataHash>();
  const conversationRoute = useConversationRoute();
  const [artifactComplete, setArtifactComplete] = useState(false);
  const isEnvironmentSafetyLocked = useIsEnvironmentSafetyLocked(queryData?.user?.role);
  const isShortMyPlan = (
    [ProductVariant.AVIVA_MILITARY, ProductVariant.AVIVA_INPATIENT] as string[]
  ).includes(role?.product.slug!);
  const { $t } = useI18n();

  useArtifactRequest(props.route.name, artifactComplete);

  const data = useMemo(() => {
    return loading
      ? undefined
      : CompositionTemplates.MYSTORYMYPLAN.parse(queryData?.user?.role?.composition?.json);
  }, [queryData, loading]);
  const hasData = !!data;

  useEffect(() => {
    const name = props.route.name;
    // @ts-expect-error when this component is nested inside ChatArtifactPreview we may not be on the SoloSuicideMode screen
    if (name === 'Conversation') return;

    if (props.route.params.initialEditingSection) {
      startEditingSection(
        props.route.params.initialEditingSection as keyof MyStoryMyPlanCompositionDataHash,
      );
      return;
    }
    if (data) {
      if (data.WARNING_SIGNS.length === 0) {
        setEditingSection('WARNING_SIGNS');
      } else if (data.COPING_STRATEGIES.length === 0) {
        setEditingSection('COPING_STRATEGIES');
      } else if (data.REASONS_FOR_LIVING && data.REASONS_FOR_LIVING.length === 0) {
        setEditingSection('REASONS_FOR_LIVING');
      } else if (
        data.SOCIAL_DISTRACTIONS.places.length === 0 &&
        data.SOCIAL_DISTRACTIONS.contacts.length === 0
      ) {
        setEditingSection('SOCIAL_DISTRACTIONS');
      } else if (data.HELP_CONTACTS.length === 0) {
        setEditingSection('HELP_CONTACTS');
      } else if (data.PROFESSIONAL_HELP_CONTACTS.length === 0) {
        setEditingSection('PROFESSIONAL_HELP_CONTACTS');
      } else {
        setEditingSection('ENVIRONMENT_SAFETY');
      }
    }
    // eslint-disable-next-line
  }, [loading, hasData, props.route]);

  useEffect(() => {
    if (
      data &&
      !data.PROFESSIONAL_HELP_CONTACTS.find(
        (c) => c.contact.ID === 'national-suicide-prevention-lifeline',
      )
    ) {
      update({
        variables: {
          json: {
            ...data,
            PROFESSIONAL_HELP_CONTACTS: [
              ...data.PROFESSIONAL_HELP_CONTACTS,
              {
                contact: PROFESSIONAL_SERVICES[0],
                location: BLANK_LOCATION,
              },
            ],
          },
        },
      }).catch(Sentry.captureException);
    }
  }, [update, data]);

  useEffect(() => {
    const finalSections: Array<keyof MyStoryMyPlanCompositionDataHash> = isShortMyPlan
      ? ['PROFESSIONAL_HELP_CONTACTS', 'ENVIRONMENT_SAFETY']
      : ['ENVIRONMENT_SAFETY'];
    if (finalSections.includes(editingSection)) {
      setArtifactComplete(true);
    }
  }, [isShortMyPlan, editingSection]);

  const myPlanSteps = data ? getMyPlanSteps(data) : undefined;
  useFocusEffect(
    useCallback(() => {
      const section = currentEditingSectionRef.current;
      if (section && myPlanSteps) {
        setEditingSection(myPlanSteps[myPlanSteps.indexOf(section) + 1]);
        currentEditingSectionRef.current = undefined;
      }
    }, [myPlanSteps]),
  );

  function startEditingSection(section: keyof MyStoryMyPlanCompositionDataHash) {
    currentEditingSectionRef.current = section;
    props.navigation.navigate('EditMyPlan', { editingSection: section });
  }

  return data ? (
    <>
      <RoundedSectionTopChild backgroundColor={theme.color.gray800}>
        <ViewShot ref={viewShotRef}>
          <View style={[card, { padding: 20 }]}>
            <View
              style={{
                paddingHorizontal: 20,
                paddingBottom: 10,
                marginBottom: 10,
                marginTop: -10,
                marginHorizontal: -20,
                borderBottomWidth: 1,
                borderColor: theme.color.gray600,
              }}
            >
              <Text
                text={$t({ id: 'SoloMyPlan_stepsHeading', defaultMessage: 'Safety steps' })}
                color={theme.color.gray300}
                weight="semibold"
                role="heading"
              />
            </View>
            <PatientMyPlan
              data={data}
              onStartEditingSection={startEditingSection}
              focusedSection={editingSection}
              onEdit={() => {}}
              isEditing
            />
          </View>
          {isEnvironmentSafetyLocked ? null : (
            <View
              style={[
                card,
                { marginTop: 30, padding: 20 },
                editingSection === 'ENVIRONMENT_SAFETY'
                  ? { borderColor: theme.color.primary100, borderWidth: 2 }
                  : null,
              ]}
            >
              <EnvironmentSafety
                data={data}
                isEditing
                focusedSection={editingSection}
                onStartEditingSection={startEditingSection}
                onEdit={() => {}}
              />
            </View>
          )}
        </ViewShot>
        <MyPlanExport
          getViewShot={() => {
            return viewShotRef.current;
          }}
          style={{
            marginTop: 20,
            borderTopWidth: 1,
            borderBottomWidth: 1,
            borderColor: theme.color.gray600,
            marginHorizontal: -20,
            marginBottom: -20,
            padding: 20,
          }}
        />
      </RoundedSectionTopChild>
      <Button
        text="Done"
        onPress={() => {
          props.navigation.navigate('Conversation', {
            ...conversationRoute.params,
            _artifactResult: { complete: true },
          });
        }}
        alignSelf="center"
        style={{ marginTop: 20 }}
      />
    </>
  ) : loading ? (
    <ActivityIndicator />
  ) : null;
}

export const SoloMyPlan = () => {
  const navigation = useNavigation<StackScreenProps<'SoloMyPlan'>['navigation']>();
  const route = useRoute<StackScreenProps<'SoloMyPlan'>['route']>();
  const props = { navigation, route };
  const { theme } = useTheme();
  const step = props.route.params?.step || 'overview';
  const { data } = useQuery(SoloMyPlanQuery);
  const { $t } = useI18n();

  return (
    <>
      <RoundedSection
        applyHeaderOptions
        title={$t({ id: 'SoloMyPlan_title', defaultMessage: 'MyPlan' })}
        color={theme.color.accentThree100}
        testID="SoloMyPlan_scrollView"
      >
        <ScrollToTop key={step} />
        {!data ? (
          <ActivityIndicator />
        ) : step === 'overview' ? (
          <SoloMyPlanStepOverview />
        ) : (
          <SoloMyPlanStepEdit />
        )}
      </RoundedSection>
    </>
  );
};
