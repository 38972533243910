import { NavigationContext, useScrollToTop } from '@react-navigation/native';
import * as Sentry from '@sentry/core';
import noop from 'lodash/noop';
import { createContext, ReactNode, useContext, useEffect, useRef } from 'react';
import { Platform, ScrollView as RNScrollView, View as RNView, StyleSheet } from 'react-native';
import {
  KeyboardAwareScrollView,
  KeyboardAwareScrollViewProps,
} from 'react-native-keyboard-controller';
import Reanimated, { useAnimatedRef, useScrollViewOffset } from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { View } from '../components/View';

type ElementLayout = {
  x: number;
  y: number;
  width: number;
  height: number;
};
type ContentOffset = {
  x: number;
  y: number;
};
type ScrollPosition = {
  x: number;
  y: number;
  animated: boolean;
};

export type ScrollIntoViewOptions = {
  getScrollPosition?: (
    parentLayout: ElementLayout,
    childLayout: ElementLayout,
    contentOffset: ContentOffset,
  ) => ScrollPosition;
};

type ContextValueType = {
  scrollTo: RNScrollView['scrollTo'];
  scrollToElement: (el: RNView | null, options?: ScrollIntoViewOptions) => number;
};
export const ScrollViewContext = createContext<ContextValueType>({
  scrollTo: noop as any, // eslint-disable-line
  scrollToElement: noop as any, // eslint-disable-line
});

export function ScrollToTop(props: { animated?: boolean }) {
  const { scrollTo } = useContext(ScrollViewContext);

  useEffect(() => {
    scrollTo({ y: 0, animated: props.animated });
  }, [scrollTo, props.animated]);

  return null;
}

export function ScrollHere(props: { animated?: boolean; offsetY?: number }) {
  const ref = useRef(null);
  const { scrollToElement } = useContext(ScrollViewContext);
  const insets = useSafeAreaInsets();

  useEffect(() => {
    if (ref.current) {
      const timeout = scrollToElement(ref.current, {
        getScrollPosition: (parentLayout, childLayout, contentOffset) => {
          return {
            x: 0,
            y: Math.max(
              0,
              childLayout.y - parentLayout.y - insets.top - (props.offsetY ?? contentOffset.y ?? 0),
            ),
            animated: props.animated ?? true,
          };
        },
      });
      return () => clearTimeout(timeout);
    }
    return;
  }, [scrollToElement, props.animated, props.offsetY, insets.top]);

  // NB android requires size + backgroundColor to measure the layout properly
  return (
    <View
      ref={ref}
      style={{ width: '100%', height: 1, backgroundColor: 'transparent', marginBottom: -1 }}
    />
  );
}

/**
 * useScrollToTop is not typically safe to use outside of a ReactNavigation tree. However,
 * we do make use of our ScrollView in some situtations where we may not
 * be inside the navigation tree.
 * e.g. Reauthenticate / AppError rendered from AppContainer
 */
const useScrollToTopSafe: typeof useScrollToTop = (ref) => {
  const context = useContext(NavigationContext);
  if (Platform.OS === 'web' || !context) return;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useScrollToTop(ref);
};

const useSafeAreaInsetsSafe: typeof useSafeAreaInsets = () => {
  if (Platform.OS === 'web') return { bottom: 0, left: 0, right: 0, top: 0 };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useSafeAreaInsets();
};

function measureInWindowAsync(view: RNView) {
  return new Promise<{ x: number; y: number; height: number; width: number }>((res) => {
    view.measureInWindow((x, y, width, height) => {
      res({ x, y, width, height });
    });
  });
}

export function ScrollView({
  topOverflowColor,
  bottomOverflowColor,
  disableBottomSafeAreaInset,
  ...props
}: KeyboardAwareScrollViewProps & {
  children?: ReactNode;
  topOverflowColor?: string;
  bottomOverflowColor?: string;
  disableBottomSafeAreaInset?: boolean;
}) {
  const scrollViewAnimatedRef = useAnimatedRef<Reanimated.ScrollView>();
  const ref = useRef<RNScrollView | null>(null);
  const position = useScrollViewOffset(scrollViewAnimatedRef);
  const timeout = useRef(0);
  const insets = useSafeAreaInsetsSafe();

  const valueRef = useRef<ContextValueType>({
    scrollTo: (...args) => {
      ref.current?.scrollTo(...args);
    },
    scrollToElement: (el, options) => {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        async function scroll() {
          if (ref.current && el) {
            const scrollMeasure = await measureInWindowAsync(
              ref.current as RNScrollView as unknown as RNView,
            );
            const elMeasure = await measureInWindowAsync(el);
            const scrollPosition = options?.getScrollPosition?.(
              scrollMeasure,
              elMeasure,
              props.horizontal
                ? {
                    x: position.value,
                    y: 0,
                  }
                : {
                    x: 0,
                    y: position.value,
                  },
            ) || {
              x: 0,
              y: Math.max(0, elMeasure.y - scrollMeasure.y + position.value),
              animated: true,
            };

            ref.current.scrollTo(scrollPosition);
          }
        }
        scroll().catch(Sentry.captureException);
      }, 200) as unknown as number;
      return timeout.current;
    },
  });

  useScrollToTopSafe(ref);

  // ScrollViews typically extend to the bottom of the screen where navigation
  // UI is. This logic ensures we always have sufficient padding to avoid being covered by that UI
  const flatStyle = StyleSheet.flatten(props.contentContainerStyle) || {};
  const providedPaddingBottom =
    flatStyle.paddingBottom ?? flatStyle.paddingVertical ?? flatStyle.padding ?? 0;
  const bottomInset = disableBottomSafeAreaInset ? 0 : insets.bottom;
  const paddingBottom =
    typeof providedPaddingBottom === 'number' ? bottomInset + providedPaddingBottom : bottomInset;

  return (
    <ScrollViewContext.Provider value={valueRef.current}>
      {topOverflowColor || bottomOverflowColor ? (
        <View style={StyleSheet.absoluteFillObject}>
          <View style={{ flex: 1, backgroundColor: topOverflowColor || 'transparent' }} />
          <View style={{ flex: 1, backgroundColor: bottomOverflowColor || 'transparent' }} />
        </View>
      ) : null}
      <KeyboardAwareScrollView
        ref={(r) => {
          ref.current = r;
          // @ts-expect-error types aren't correct this is valid
          scrollViewAnimatedRef(r || undefined);
        }}
        // hide scroll indicators in e2e tests for image snapshot stability
        showsHorizontalScrollIndicator={!global.e2e}
        showsVerticalScrollIndicator={!global.e2e}
        scrollIndicatorInsets={props.horizontal !== false ? { right: Number.MIN_VALUE } : undefined}
        // give extra padding / cushion so inputs aren't crammed against the keyboard
        bottomOffset={200}
        {...props}
        contentContainerStyle={[props.contentContainerStyle, { paddingBottom }]}
        keyboardShouldPersistTaps="handled"
      />
    </ScrollViewContext.Provider>
  );
}
