/* This file is autogenerated by @oui/cli */
import Svg, { Path, Rect, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={24} height={21} viewBox="0 0 24 21" fill="none" {...props}>
    <Rect
      x={1}
      y={1}
      width={21.89}
      height={18.25}
      rx={3}
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M5 14.11V7h2.06l2.07 2.61L11.19 7h2.07v7.11h-2.07v-4.08l-2.06 2.62-2.07-2.62v4.08H5Zm12.9 0-3.1-3.45h2.07V7h2.07v3.66H21l-3.1 3.45Z"
      fill="currentColor"
    />
  </Svg>
);
export default SvgComponent;
