import { ApolloClient, ApolloProvider, useApolloClient } from '@apollo/client';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  DarkTheme,
  DefaultTheme,
  getActionFromState,
  getPathFromState,
  getStateFromPath,
  InitialState,
  CommonActions as NavigationActions,
  NavigationContainer,
  NavigationContainerRef,
  NavigationState,
} from '@react-navigation/native';
import setDefaultOptions from 'date-fns/setDefaultOptions';
import { applicationId } from 'expo-application';
import * as Localization from 'expo-localization';
import * as Notifications from 'expo-notifications';
import * as SplashScreen from 'expo-splash-screen';
import * as Updates from 'expo-updates';
import { produce } from 'immer';
import { stringify } from 'query-string';
import {
  Component,
  ComponentProps,
  ElementType,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { AppState, Platform, Linking as RNLinking } from 'react-native';
import { SystemBars } from 'react-native-edge-to-edge';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { KeyboardProvider } from 'react-native-keyboard-controller';
import { Menu, MenuProvider, renderers } from 'react-native-popup-menu';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import { getIntlFirstWeekdayAsDateDay } from '@oui/lib/src/getIntlFirstWeekday';
import { ResultOf } from '@oui/lib/src/graphql/tada';
import { DetoxNotificationData } from '@oui/lib/src/types';
import { clearFrescoMemoryCache } from '@oui/native-utils';

import { Reauthenticate } from './Reauthenticate';
import { AccessibilityProvider } from '../components/AccessibilityContext';
import AppContext, { DeeplinkOptions } from '../components/AppContext';
import { AppError } from '../components/AppError';
import { Button } from '../components/Button';
import { CurrentUserProvider } from '../components/CurrentUserContext';
import { DevMenu } from '../components/DevMenu';
import { Icon } from '../components/Icon';
import { InternetConnectivityProvider } from '../components/InternetConnectivityProvider';
import { MinimumAppVersion } from '../components/MinimumAppVersion';
import { OrientationProvider } from '../components/OrientationContext';
import { SnackbarProvider } from '../components/SnackbarProvider';
import { Text } from '../components/Text';
import { View } from '../components/View';
import { APP_SLUG, manifest } from '../constants';
import { useAppState } from '../hooks/useAppState';
import { CurrentUserQuery } from '../hooks/useCurrentUser';
import { LogoutProvider } from '../hooks/useLogout';
import { setPersistedState } from '../hooks/usePersistedState';
import { checkForUpdateAsync } from '../lib/checkForUpdateAsync';
import { I18nProvider } from '../lib/i18n';
import { initApp, shouldShowWebBlocker } from '../lib/initApp';
import { addBreadcrumb } from '../lib/log';
import LogBox from '../lib/LogBox';
import { parseUrl } from '../lib/parseUrl';
import { PermissionsManagerProvider } from '../lib/permissionsManager';
import { resumableUploadManager } from '../lib/resumableUploadManager';
import { setDeviceInfo } from '../lib/setDeviceInfo';
import Sentry, { routingInstrumentation } from '../sentry';
import { Shadow, useTheme } from '../styles';

const LATEST_NAVIGATION_STATE_KEY = `${APP_SLUG}:lastNavigationState`;

Menu.setDefaultRenderer(renderers.Popover);
Menu.setDefaultRendererProps({ placement: 'bottom', anchorStyle: [Shadow.default] });
DefaultTheme.colors.background = 'white';
LogBox.ignoreLogs([
  'Accessing view manager configs',
  'componentWillMount',
  'componentWillReceiveProps',
  '[react-native-gesture-handler] Seems', // https://github.com/software-mansion/react-native-gesture-handler/pull/1817
]);

export function useDetoxSideEffects() {
  const [forceOffline, setForceOffline] = useState(false);
  useEffect(() => {
    function handleDetoxNotification(data: DetoxNotificationData) {
      if (data.type === 'NETWORK') {
        setForceOffline(!data.payload.isInternetReachable);
      } else if (data.type === 'ASYNC_STORAGE') {
        AsyncStorage.setItem(data.payload.key, data.payload.value).catch(Sentry.captureException);
      } else if (data.type === 'EXPO_CHANNEL') {
        Updates.setUpdateURLAndRequestHeadersOverride({
          updateUrl: `https://u.expo.dev/${data.payload.expoProjectID}`,
          requestHeaders: { 'expo-channel-name': data.payload.channel },
        });
      }
    }

    const handleOpenURL = ({ url }: { url: string }) => {
      const parsedUrl = parseUrl(url);
      if (global.e2e && parsedUrl?.path === 'DETOX_NOTIFICATION') {
        const data: DetoxNotificationData = JSON.parse(parsedUrl.params.data);
        handleDetoxNotification(data);
        return;
      }

      if (parsedUrl?.params.skipLocalAuthenticationPrompt) {
        setPersistedState(
          'SeenLocalAuthenticationPrompt',
          JSON.parse(parsedUrl.params.skipLocalAuthenticationPrompt),
        );
      }
    };

    if (Platform.OS === 'web') {
      // @ts-ignore
      global.window.__detoxNotificationCallback = (data: string) => {
        handleDetoxNotification(JSON.parse(data) as DetoxNotificationData);
      };
    } else {
      const urlSubscription = RNLinking.addEventListener('url', handleOpenURL);

      return () => {
        urlSubscription.remove();
      };
    }

    return;
  }, []);
  return { forceOffline };
}

function useLowMemoryWarning() {
  useEffect(() => {
    if (Platform.OS === 'web') return;

    const onMemoryWarningListener = AppState.addEventListener('memoryWarning', (data) => {
      addBreadcrumb({ category: 'app-state-memory', data: { data }, level: 'debug' });
      clearFrescoMemoryCache();
    });

    return () => {
      onMemoryWarningListener?.remove();
    };
  }, []);
}

/**
 * Ensures we always have a route to navigate back to if opening app from a deeplink / notification
 */
function ensureInitialRoute(
  defaultState: ReturnType<typeof getStateFromPath>,
  targetState: NonNullable<ReturnType<typeof getStateFromPath>>,
) {
  // If we don't have a default state in this app, then abort
  if (!defaultState) return targetState;
  // If targetState is already linking to the same screen as the defaultState, then just use targetState
  if (defaultState.routes[0].name === targetState.routes[0].name) return targetState;

  return {
    ...targetState,
    index: defaultState.routes.length,
    routes: [...defaultState.routes, ...targetState.routes],
  };
}

type AppInnerProps = {
  app: ElementType;
  initialPath: string | ((data: { user: ResultOf<typeof CurrentUserQuery>['user'] }) => string);
  deeplinkConfig: { screens: DeeplinkOptions };
};
const AppContainerInner = forwardRef<NavigationContainerRef<{}>, AppInnerProps>((props, ref) => {
  const [initialNavigationState, setInitialNavigationState] = useState<InitialState>();
  const { flags } = useContext(AppContext);
  const { scheme } = useTheme();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigator = useRef<NavigationContainerRef<{}>>();
  const [navigatorState, setNavigatorState] = useState<NavigationContainerRef<{}> | null>(null);
  const handleNotificationRef = useRef<typeof handleNotification>();
  const Main = props.app;
  const deeplinkConfigRef = useRef<{ screens: DeeplinkOptions }>(props.deeplinkConfig);
  const latestStateRef = useRef<NavigationState>();
  const apollo = useApolloClient();
  const initialPath = props.initialPath;
  const [reauthenticateCallback, setReauthenticateCallback] = useState<{
    callback?: Function;
  }>({ callback: undefined });

  navigator.current = navigatorState ?? undefined;
  useImperativeHandle(ref, () => navigatorState!, [navigatorState]);

  const handleNotification = useCallback(
    (
      notification: Notifications.NotificationResponse,
      skipLoadingCheck = false,
      defaultState: ReturnType<typeof getStateFromPath> = undefined,
    ): boolean => {
      // console.log({
      //   notification: JSON.stringify(notification, null, 2),
      //   skipLoadingCheck,
      //   navigator: !!navigator.current,
      //   loading,
      // });
      if (!skipLoadingCheck && loading) {
        return false;
      }

      addBreadcrumb({ category: 'notification', data: notification });

      // Ignore fake notification present in latest SDK version
      // https://github.com/expo/expo/issues/28656#issuecomment-2198200751
      if (
        notification.notification.date === 0 &&
        notification.notification.request.identifier === null
      ) {
        return false;
      }

      Notifications.dismissNotificationAsync(notification.notification.request.identifier).catch(
        Sentry.captureException,
      );
      const content = notification.notification.request.content;
      const data = content.data as AppCore.NotificationPayload;
      if (data.type === 'navigate') {
        let path;
        if ('path' in data.payload) {
          path = data.payload.path;
        } else {
          const payload = {
            name: data.payload.name ?? data.payload.routeName,
            params: data.payload.params,
          };
          const paramsStr = payload.params ? stringify(payload.params) : '';
          path = `${payload.name}${paramsStr ? `?${paramsStr}` : ''}`;
        }
        const state = getStateFromPath(path, deeplinkConfigRef.current)!;
        if (navigator.current) {
          navigator.current?.dispatch(getActionFromState(state)!);
        } else {
          setInitialNavigationState(ensureInitialRoute(defaultState, state));
        }
        return true;
      }
      return false;
    },
    [loading],
  );
  handleNotificationRef.current = handleNotification;

  useAppState((status) => {
    addBreadcrumb({ category: 'app-state', data: { status }, level: 'debug' });
  });

  useAppState((status) => {
    if (status === 'active') {
      const locale = Localization.getLocales()[0].languageTag;
      setDefaultOptions({
        weekStartsOn: getIntlFirstWeekdayAsDateDay(locale),
      });
    }
  });

  useEffect(() => {
    resumableUploadManager.startPendingUploads().catch((e) => Sentry.captureException(e));
  }, []);

  const { forceOffline } = useDetoxSideEffects();
  useLowMemoryWarning();

  useEffect(() => {
    if (Platform.OS === 'web') return;

    const handleOpenURL = ({ url }: { url: string }) => {
      const parsedUrl = parseUrl(url);
      const initialURLState = parsedUrl?.path
        ? getStateFromPath(parsedUrl.pathWithQuery, deeplinkConfigRef.current)
        : undefined;

      addBreadcrumb({
        category: 'navigation',
        message: 'handleOpenURL',
        data: { url, parsedUrl, initialURLState },
      });
    };

    const handleNotificationSubscription =
      Notifications.addNotificationResponseReceivedListener(handleNotification);
    Notifications.setNotificationHandler({
      handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: false,
        shouldSetBadge: false,
      }),
    });
    const urlSubscription = RNLinking.addEventListener('url', handleOpenURL);

    return () => {
      urlSubscription.remove();
      if (handleNotificationSubscription) {
        handleNotificationSubscription.remove();
      }
      Notifications.setNotificationHandler(null);
    };
  }, [handleNotification]);

  const init = useCallback(async () => {
    try {
      const initialURL = await RNLinking.getInitialURL();
      const { isLoggedIn, reauthenticate } = await initApp(apollo);
      let productUser: ResultOf<typeof CurrentUserQuery>['user'] | null = null;

      if (isLoggedIn) {
        try {
          const r = await apollo.query({ query: CurrentUserQuery, fetchPolicy: 'cache-first' });
          productUser = r.data.user;
        } catch (e) {
          Sentry.captureException(e, (scope) => {
            scope.setExtras({ location: 'preload productUser' });
            return scope;
          });
        }
        void setDeviceInfo(apollo);
      }

      const parsedUrl = parseUrl(initialURL);
      const initialURLState = parsedUrl?.path
        ? getStateFromPath(parsedUrl.pathWithQuery, deeplinkConfigRef.current)
        : undefined;

      Sentry.addBreadcrumb({
        message: 'init',
        data: {
          reauthenticate,
          initialURLState,
          parsedUrl,
          initialURL,
        },
      });

      const primaryNavigation = async () => {
        const defaultState = getStateFromPath(
          typeof initialPath === 'function' ? initialPath({ user: productUser }) : initialPath,
          deeplinkConfigRef.current,
        )!;

        // react-navigation handles the initial url on web by default so if we're planning to
        // set initial state to the initial url ourselves, just mark the action as already handled
        // https://reactnavigation.org/docs/navigation-container/#initialstate
        let handled = false;
        if (Platform.OS === 'web' && parsedUrl?.isDeeplink) {
          handled = true;
        } else if (Platform.OS !== 'web') {
          const initialNotification = await Notifications.getLastNotificationResponseAsync();
          if (initialNotification) {
            handled =
              handled || handleNotificationRef.current!(initialNotification, true, defaultState);
          }
        }

        if (!handled) {
          const getPrimaryNavigationState = async (): Promise<InitialState> => {
            const persistedStateStr = await AsyncStorage.getItem(LATEST_NAVIGATION_STATE_KEY);

            if (persistedStateStr) {
              AsyncStorage.removeItem(LATEST_NAVIGATION_STATE_KEY).catch(Sentry.captureException);
              const persistedState: NavigationState = JSON.parse(persistedStateStr);
              return persistedState;
            } else if (initialURLState) {
              return ensureInitialRoute(defaultState, initialURLState);
            } else if (isLoggedIn && shouldShowWebBlocker()) {
              return getStateFromPath('WebBlocker', deeplinkConfigRef.current)!;
            }

            return defaultState;
          };

          setInitialNavigationState((await getPrimaryNavigationState())!);
        }
      };

      // if existing credentials, go to App Home / deeplink
      // if previously logged in, but currently logged out show Login instead of Welcome
      // if app was opened normally and no user present, show "Welcome"
      if (reauthenticate) {
        setReauthenticateCallback({ callback: primaryNavigation });
      } else {
        await primaryNavigation();
      }

      setLoading(false);
      setError(false);
    } catch (e) {
      Sentry.captureException(e);
      setError(true);
    } finally {
      if (Platform.OS !== 'web') {
        SplashScreen.hideAsync().catch(Sentry.captureException);
      }
    }
  }, [initialPath, apollo]);

  useEffect(() => {
    init().catch(Sentry.captureException);
  }, [init]);

  return reauthenticateCallback.callback ? (
    <Reauthenticate
      onSuccess={async () => {
        await reauthenticateCallback.callback?.();
        setReauthenticateCallback({ callback: undefined });
      }}
      onLogout={async (forgotPassword) => {
        setLoading(true);
        setReauthenticateCallback({ callback: undefined });
        await init();
        if (forgotPassword) {
          setTimeout(() => {
            // @ts-expect-error
            navigator.current?.navigate('RequestResetPassword', {});
          }, 100);
        }
      }}
    />
  ) : error ? (
    <AppError retry={init} />
  ) : loading ? null : (
    <>
      <SystemBars style={flags.allowDarkTheme && scheme === 'dark' ? 'light' : 'dark'} />
      <PermissionsManagerProvider
        onOpenSettings={() => {
          if (!latestStateRef.current) return Promise.resolve();
          return AsyncStorage.setItem(
            LATEST_NAVIGATION_STATE_KEY,
            JSON.stringify(latestStateRef.current),
          );
        }}
        onCloseSettings={() => {
          return AsyncStorage.removeItem(LATEST_NAVIGATION_STATE_KEY);
        }}
      >
        <InternetConnectivityProvider
          forceOffline={forceOffline}
          onReconnect={() => {
            resumableUploadManager.startPendingUploads().catch((e) => Sentry.captureException(e));
          }}
        >
          <MinimumAppVersion>
            <SnackbarProvider>
              <NavigationContainer
                linking={{
                  prefixes: [
                    `${manifest.scheme}://`,
                    `${applicationId || 'com.ouitherapeutics.app'}://`,
                    'https://oui.health',
                    'https://oui.dev',
                    'https://*.oui.dev',
                    'https://*.oui.health',
                  ],
                  config: deeplinkConfigRef.current,
                }}
                documentTitle={{
                  // we disable document title handling for two reasons
                  // 1) it messes up screen capture in detox-puppeteer (b/c we select the video source by doc title)
                  // 2) we need to create a better custom formatter and it's not immediately obvious what the logic is
                  enabled: false,
                }}
                initialState={initialNavigationState}
                theme={flags.allowDarkTheme && scheme === 'dark' ? DarkTheme : DefaultTheme}
                onReady={() => {
                  // eslint-disable-next-line
                  routingInstrumentation.registerNavigationContainer(navigator as any);
                }}
                onStateChange={(state) => {
                  latestStateRef.current = state;
                  // console.log('onStateChange', state);
                  // if (state) console.log(getPathFromState(state, deeplinkConfigRef.current));
                  if (!state) return;
                  const currentRoute = navigator.current?.getCurrentRoute();
                  const breadcrumbState = produce(state, (draft) => {
                    draft.routeNames = []; // don't need this list for debugging purposes
                  });
                  addBreadcrumb({
                    category: 'navigation',
                    data: {
                      state: breadcrumbState,
                      path: getPathFromState(state, deeplinkConfigRef.current),
                      name: currentRoute?.name,
                      params: currentRoute?.params,
                    },
                    level: 'debug',
                  });
                }}
                ref={(r) => {
                  setNavigatorState(r);
                }}
              >
                <Main />
              </NavigationContainer>
            </SnackbarProvider>
          </MinimumAppVersion>
        </InternetConnectivityProvider>
      </PermissionsManagerProvider>
    </>
  );
});

function WebUpdateAvailableToast({ onDismiss }: { onDismiss: () => void }) {
  const { theme } = useTheme();
  return (
    <View
      style={[
        {
          position: 'absolute',
          bottom: 20,
          right: 20,
          width: 300,
          padding: 20,
          borderRadius: 20,
          backgroundColor: theme.color.gray800,
        },
        Shadow.default,
      ]}
      spacing={10}
    >
      <View row style={{ justifyContent: 'space-between' }}>
        <Text text="Update available" weight="semibold" />
        <Icon aria-label="Dismiss" name="close" size={14} onPress={onDismiss} />
      </View>
      <Text
        text="A new version of the website is available. Please refresh to get the latest features and improvements."
        size={13}
      />
      <Button
        variant="text"
        text="Update"
        alignSelf="flex-end"
        size="small"
        onPress={() => {
          global.window.location.reload();
        }}
      />
    </View>
  );
}

export class AppContainer extends Component<
  AppInnerProps & {
    apollo: ApolloClient<unknown>;
    flags: AppCore.Flags;
    getMessages: ComponentProps<typeof I18nProvider>['getMessages'];
  } & Pick<ComponentProps<(typeof AppContext)['Provider']>['value'], 'Logo' | 'onboardingGraphic'>,
  { hasError: boolean; updateAvailable: boolean; navigator: NavigationContainerRef<{}> | null }
> {
  state = { hasError: false, updateAvailable: false, navigator: null };
  lastUpdateCheckAt = Date.now();

  static getDerivedStateFromError() {
    return { navigator: null, hasError: true };
  }

  componentDidMount() {
    if (Platform.OS === 'web' && APP_SLUG !== 'oui-aviva') {
      AppState.addEventListener('change', (nextStatus) => {
        if (nextStatus === 'active' && Date.now() - this.lastUpdateCheckAt > 60 * 60 * 1000) {
          this.lastUpdateCheckAt = Date.now();
          void checkForUpdateAsync().then(({ isAvailable }) => {
            if (isAvailable) {
              this.setState({ updateAvailable: true });
            }
          });
        }
      });
    }
  }

  componentDidCatch(e: Error, info: object) {
    Sentry.withScope((scope) => {
      scope.setExtra('info', info);
      Sentry.captureException(e);
    });
  }

  dispatch = (action: NavigationActions.Action) => {
    const navigator: NavigationContainerRef<{}> = this.state.navigator!;
    navigator?.dispatch(action);
  };

  render() {
    return (
      <View testID="AppContainer" style={{ flex: 1 }}>
        <AppContext.Provider
          value={{
            navigationContainer: this.state.navigator,
            flags: this.props.flags,
            locale: Localization.getLocales()[0].languageTag,
            deeplinkConfig: this.props.deeplinkConfig,
            Logo: this.props.Logo,
            onboardingGraphic: this.props.onboardingGraphic,
          }}
        >
          <GestureHandlerRootView>
            <AccessibilityProvider>
              <KeyboardProvider>
                <SafeAreaProvider>
                  <ActionSheetProvider>
                    <ApolloProvider client={this.props.apollo}>
                      <OrientationProvider>
                        <LogoutProvider>
                          <CurrentUserProvider>
                            <MenuProvider>
                              <I18nProvider getMessages={this.props.getMessages}>
                                <DevMenu onNavigate={this.dispatch}>
                                  {this.state.hasError ? (
                                    <AppError
                                      retry={() => this.setState({ hasError: false })}
                                      type="runtime"
                                    />
                                  ) : (
                                    <AppContainerInner
                                      app={this.props.app}
                                      ref={(r) => {
                                        if (!this.state.navigator && r) {
                                          this.setState({ navigator: r });
                                        }
                                      }}
                                      initialPath={this.props.initialPath}
                                      deeplinkConfig={this.props.deeplinkConfig}
                                    />
                                  )}
                                  {this.state.updateAvailable ? (
                                    <WebUpdateAvailableToast
                                      onDismiss={() => this.setState({ updateAvailable: false })}
                                    />
                                  ) : null}
                                </DevMenu>
                              </I18nProvider>
                            </MenuProvider>
                          </CurrentUserProvider>
                        </LogoutProvider>
                      </OrientationProvider>
                    </ApolloProvider>
                  </ActionSheetProvider>
                </SafeAreaProvider>
              </KeyboardProvider>
            </AccessibilityProvider>
          </GestureHandlerRootView>
        </AppContext.Provider>
      </View>
    );
  }
}
