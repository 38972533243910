/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      d="M1 2.84C.94 1.82 1.4 1 2.06 1H15.3c.65 0 1.12.82 1.04 1.84l-.9 12.33c-.07 1.01-.66 1.83-1.3 1.83H3.21c-.65 0-1.24-.81-1.31-1.83l-.9-12.33Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M8.38 13.32a.2.2 0 0 1-.2.16H6.67a.2.2 0 0 1-.2-.24l1.5-6.63a.2.2 0 0 0-.2-.25H6.05a.2.2 0 0 1-.2-.24L6.12 5a.2.2 0 0 1 .2-.16h5.96a.2.2 0 0 1 .2.24l-.26 1.13a.2.2 0 0 1-.2.15h-1.9a.2.2 0 0 0-.2.16l-1.53 6.8Z"
      fill="currentColor"
    />
  </Svg>
);
export default SvgComponent;
