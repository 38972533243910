/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={16} height={18} viewBox="0 0 16 18" fill="none" {...props}>
    <Path
      d="M12.17 2.24v13.88"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M9.7 1.43h4.76"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M9.7 16.57h4.76"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="m6.83 13.77-1.52-.19 1.42-8.9h2.18v2.1h1.04l.28-2.94h-8.6L.95 6.77h.95l.66-2.08h2.27l-1.51 8.9-1.61.18-.1.76h5.11l.1-.76Z"
      fill="currentColor"
    />
  </Svg>
);
export default SvgComponent;
