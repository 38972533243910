/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      d="M9.43 13.3c-.11-.17-.25-.3-.38-.44L4.99 8.83c-.28-.28-.32-.28-.6 0l-2.87 2.85-.4.4a.39.39 0 0 0-.02.55l.14.13 4 3.98c.07.07.15.15.23.2.15.09.34.09.45-.04.25-.26.57-.46.72-.8.01-.03.03-.05.05-.06.2-.06.33-.22.47-.36l.98-.98c.1-.09.19-.17.22-.3.01-.03.05-.06.08-.08.4-.26.73-.58.99-.98a.08.08 0 0 1 .05-.04c.36-.1.65-.34.96-.53.86-.51 1.73-1.02 2.56-1.58.82-.55 1.62-1.13 2.3-1.85a5.5 5.5 0 0 0 1.22-1.8c.35-.93.24-1.81-.23-2.67a10.67 10.67 0 0 0-2.94-3.1 4.21 4.21 0 0 0-1.65-.73c-.62-.1-1.2 0-1.74.33-.3.18-.54.45-.8.67-.37.3-.57.7-.86 1.04-.3.35-.54.73-.77 1.12"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M8.84 2.26a1.32 1.32 0 0 0-1.05-.42c-.56 0-1.02.26-1.43.6a5.6 5.6 0 0 0-1.59 1.98c-.23.5-.3 1.01-.27 1.56.03.5.12.98.17 1.47.04.38.05.77.02 1.15"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
