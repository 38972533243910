/* This file is autogenerated by @oui/cli */
import Svg, { Circle, Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={16} viewBox="0 0 18 16" fill="none" {...props}>
    <Circle
      cx={9.5}
      cy={7.5}
      r={6.5}
      stroke="currentColor"
      strokeWidth={2}
      vectorEffect="non-scaling-stroke"
    />
    <Circle
      cx={9.5}
      cy={7.5}
      r={3.5}
      stroke="currentColor"
      strokeWidth={2}
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M15.93 8.09c.18 1.6.02 3.21.08 4.81.02.43-.23 1.1.48 1.1s.47-.68.47-1.1c.02-3.64.01-7.28 0-10.92 0-.32.1-.67-.18-.98-.86.24-1.5.88-1.62 1.76a22.08 22.08 0 0 0-.04 4.99"
      fill="#fff"
    />
    <Path
      d="M15.93 8.09c.18 1.6.02 3.21.08 4.81.02.43-.23 1.1.48 1.1s.47-.68.47-1.1c.02-3.64.01-7.28 0-10.92 0-.32.1-.67-.18-.98-.86.24-1.5.88-1.62 1.76a22.08 22.08 0 0 0-.04 4.99l.8.34Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M1.17 1.17c.19 1.2.05 2.4.08 3.59.01.46-.01.94.44 1.18.41.22.8.5.8.83L2.5 14"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M3.99 1.17c0 1.03-.05 2.05.01 3.08.05.83-.18 1.48-.87 1.96"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M2.55 1.17V4.6"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
