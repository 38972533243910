/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      d="M11.4 17h4.4c.66 0 1.2-.53 1.2-1.2V9c0-.66-.4-1.55-.91-1.98L9 1 1.91 7.02C1.41 7.45 1 8.34 1 9v6.8c0 .67.54 1.2 1.2 1.2h4.4v-5.6h4.8V17Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
