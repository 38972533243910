/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.76 2a2 2 0 1 0 4 0 2 2 0 0 0-4 0Z"
      fill="currentColor"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.76 8a2 2 0 1 0 4 0 2 2 0 0 0-4 0Z"
      fill="currentColor"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.76 14a2 2 0 1 0 4 0 2 2 0 0 0-4 0Z"
      fill="currentColor"
    />
  </Svg>
);
export default SvgComponent;
