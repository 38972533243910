/* This file is autogenerated by @oui/cli */
import Svg, { Circle, Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
    <Path
      d="M5.86 1.14H15"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M5.86 8H15"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M5.86 14.86H15"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Circle cx={1.14} cy={1.14} r={1.14} fill="currentColor" />
    <Circle cx={1.14} cy={8} r={1.14} fill="currentColor" />
    <Circle cx={1.14} cy={14.86} r={1.14} fill="currentColor" />
  </Svg>
);
export default SvgComponent;
