/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      d="M3 5a2 2 0 0 0-2 2v4.34c0 1.1.9 2 2 2h.38v3.57l3.57-3.57h3.38"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M16.62 10.97c.5.42.5 1.1 0 1.52l-1.26 1.02c-.5.42-1.44.62-2.06.4 0 0-2.51-.47-5.63-3.59C4.56 7.22 4.08 4.7 4.08 4.7c-.2-.63-.02-1.55.4-2.07L5.5 1.4a.95.95 0 0 1 1.53-.01l1.54 1.85c.28.34.31.9.07 1.28l-.69 1.02a4.81 4.81 0 0 0 4.51 4.51l1.03-.68c.37-.25.94-.21 1.28.07l1.85 1.54Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
