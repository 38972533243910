import sum from 'lodash/sum';
import { z } from 'zod';

const rating = z.number().int().min(1).max(5);

const Assessment = z.object({
  feelingShortOfBreath: rating,
  racingHeart: rating,
  feelingDizzy: rating,
  troubleConcentrating: rating,
});

export const StressSensitivityAssessment = z
  .object({
    before: Assessment.optional(),
    after: Assessment.optional(),
  })
  .default({});
export type StressSensitivityAssessment = z.infer<typeof StressSensitivityAssessment>;

export function getStressSensitivityResult(data: z.output<typeof Assessment>) {
  const values = Object.values(data);
  const score = sum(values) / values.length;

  if (score >= 3.6) {
    return {
      score,
      result: 'extreme' as const,
      title: 'You have a significant problem with stress sensitivity',
      description:
        'Your answers suggest that stress sensitivity is a significant problem area for you, and this program may provide you with better strategies for dealing with stress in the future.',
    };
  } else if (score >= 2.6) {
    return {
      score,
      result: 'moderate' as const,
      title: 'You have moderate stress sensitivity',
      description:
        'Your answers suggest that you have a definite problem with stress sensitivity, and this program may provide you with healthier ways to cope with stress.',
    };
  } else if (score >= 1.6) {
    return {
      score,
      result: 'low' as const,
      title: 'You have some stress sensitivity',
      description:
        'Your answers suggest that you have a little stress sensitivity, and this program may provide some helpful tools for managing your stress.',
    };
  }

  return {
    score,
    result: 'none' as const,
    title: 'You minimal or no stress sensitivity',
    description:
      'Your answers suggest that you don’t have a problem with stress sensitivity, but this program may still be beneficial in preventing the development of it.',
  };
}

export function getStressSensitivityChange(data: StressSensitivityAssessment) {
  if (!data.before || !data.after) return 0;
  const before = getStressSensitivityResult(data.before).score;
  const after = getStressSensitivityResult(data.after).score;
  const reduction = (before - after) / before;
  return reduction;
}
