/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      d="M13.14 12.1h1.9c.63 0 1.14-.5 1.14-1.13V5.65c0-.63-.52-1.13-1.15-1.13H2.14c-.63 0-1.14.5-1.14 1.13v5.32c0 .62.51 1.13 1.14 1.13h1.9v-1.13c0-.63.5-1.14 1.13-1.14h6.84c.62 0 1.13.5 1.13 1.14v1.13Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M3.54 4.52h-.5c0 .27.22.5.5.5v-.5Zm1 0v.5a.5.5 0 0 0 .5-.5h-.5Zm8.1 0h-.5c0 .27.22.5.5.5v-.5Zm1 0v.5a.5.5 0 0 0 .5-.5h-.5Zm-.5 7.58h-.5c0 .28.22.5.5.5v-.5Zm.5 0h.5a.5.5 0 0 0-.5-.5v.5Zm-1-2.08.28-.41a.5.5 0 0 0-.78.41h.5Zm-8.1 0h.5a.5.5 0 0 0-.79-.41l.29.41Zm-.5 2.08v.5a.5.5 0 0 0 .5-.5h-.5Zm-.5 0v-.5a.5.5 0 0 0-.5.5h.5Zm.5-7.62a3 3 0 0 1 3-3v-1a4 4 0 0 0-4 4h1Zm0 .04v-.04h-1v.04h1Zm.5-.5h-1v1h1v-1Zm-.5.46v.04h1v-.04h-1Zm3-3a3 3 0 0 0-3 3h1c0-1.1.9-2 2-2v-1Zm3.1 0h-3.1v1h3.1v-1Zm3 3a3 3 0 0 0-3-3v1a2 2 0 0 1 2 2h1Zm0 .04v-.04h-1v.04h1Zm.5-.5h-1v1h1v-1Zm-.5.46v.04h1v-.04h-1Zm-3-3a3 3 0 0 1 3 3h1a4 4 0 0 0-4-4v1Zm-3.1 0h3.1v-1h-3.1v1Zm6.1 11.12h.5v-1h-.5v1Zm-.5-1.63v1.13h1v-1.13h-1Zm-.28-.54c.17.12.28.31.28.54h1c0-.57-.29-1.07-.72-1.36l-.56.82Zm.78 3.23v-3.64h-1v3.64h1Zm-3 3a3 3 0 0 0 3-3h-1a2 2 0 0 1-2 2v1Zm-3.1 0h3.1v-1h-3.1v1Zm-3-3a3 3 0 0 0 3 3v-1a2 2 0 0 1-2-2h-1Zm0-3.64v3.64h1v-3.64h-1Zm.5.95c0-.22.1-.42.28-.53l-.57-.83c-.43.3-.71.8-.71 1.36h1Zm0 1.13v-1.13h-1v1.13h1Zm-1 .5h.5v-1h-.5v1Zm.5 1.06V12.1h-1v1.56h1Zm3 3a3 3 0 0 1-3-3h-1a4 4 0 0 0 4 4v-1Zm3.1 0h-3.1v1h3.1v-1Zm3-3a3 3 0 0 1-3 3v1a4 4 0 0 0 4-4h-1Zm0-1.56v1.56h1V12.1h-1Z"
      fill="currentColor"
    />
  </Svg>
);
export default SvgComponent;
