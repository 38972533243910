/* eslint-disable */
/* This file is autogenerated by @oui/cli */
import Icon_ai from './ai';
import Icon_alcohol from './alcohol';
import Icon_almonds from './almonds';
import Icon_apple from './apple';
import Icon_approve from './approve';
import Icon_apps from './apps';
import Icon_arrowDown from './arrow-down';
import Icon_arrowLeft from './arrow-left';
import Icon_arrowRight from './arrow-right';
import Icon_arrowUp from './arrow-up';
import Icon_audioMute from './audio-mute';
import Icon_audio from './audio';
import Icon_badThought from './bad-thought';
import Icon_banana from './banana';
import Icon_beaker from './beaker';
import Icon_bin from './bin';
import Icon_blindFold from './blind-fold';
import Icon_bookHead from './book-head';
import Icon_book from './book';
import Icon_boxingGlove from './boxing-glove';
import Icon_breathHold from './breath-hold';
import Icon_breathe from './breathe';
import Icon_buildingDouble from './building-double';
import Icon_buildingSingle from './building-single';
import Icon_bulletedList from './bulleted-list';
import Icon_calendar from './calendar';
import Icon_camera from './camera';
import Icon_cards from './cards';
import Icon_caretDown from './caret-down';
import Icon_caretFilledDown from './caret-filled-down';
import Icon_caretFilledRight from './caret-filled-right';
import Icon_caretFilledUpDown from './caret-filled-up-down';
import Icon_caretLeft from './caret-left';
import Icon_caretRight from './caret-right';
import Icon_caretUp from './caret-up';
import Icon_chatDouble from './chat-double';
import Icon_checkIn from './check-in';
import Icon_check from './check';
import Icon_choice from './choice';
import Icon_clickCheck from './click-check';
import Icon_clock from './clock';
import Icon_close from './close';
import Icon_closedCaptioningSelected from './closed-captioning-selected';
import Icon_closedCaptioning from './closed-captioning';
import Icon_cloudOffline from './cloud-offline';
import Icon_cloudUpload from './cloud-upload';
import Icon_coffee from './coffee';
import Icon_collapse from './collapse';
import Icon_colors from './colors';
import Icon_comment from './comment';
import Icon_compulsion from './compulsion';
import Icon_config from './config';
import Icon_connect from './connect';
import Icon_content from './content';
import Icon_copy from './copy';
import Icon_crisisPeak from './crisis-peak';
import Icon_curveFalling from './curve-falling';
import Icon_curvePeak from './curve-peak';
import Icon_curveRising from './curve-rising';
import Icon_dashboard from './dashboard';
import Icon_decrease from './decrease';
import Icon_diary from './diary';
import Icon_distress from './distress';
import Icon_download from './download';
import Icon_earth from './earth';
import Icon_easier from './easier';
import Icon_eatingLog from './eating-log';
import Icon_edit from './edit';
import Icon_email from './email';
import Icon_exchange from './exchange';
import Icon_exercise from './exercise';
import Icon_expand from './expand';
import Icon_external from './external';
import Icon_eye from './eye';
import Icon_faceBad from './face-bad';
import Icon_faceGood from './face-good';
import Icon_faceGreat from './face-great';
import Icon_faceOkay from './face-okay';
import Icon_faceTerrible from './face-terrible';
import Icon_filter from './filter';
import Icon_flagMountain from './flag-mountain';
import Icon_flame from './flame';
import Icon_flip from './flip';
import Icon_fullSize from './full-size';
import Icon_goal from './goal';
import Icon_goodThought from './good-thought';
import Icon_graduation from './graduation';
import Icon_grid from './grid';
import Icon_group from './group';
import Icon_hamburger from './hamburger';
import Icon_handStop from './hand-stop';
import Icon_history from './history';
import Icon_home from './home';
import Icon_image from './image';
import Icon_inProgress from './in-progress';
import Icon_increase from './increase';
import Icon_info from './info';
import Icon_input from './input';
import Icon_jumping from './jumping';
import Icon_kit from './kit';
import Icon_ladder from './ladder';
import Icon_later from './later';
import Icon_lightBlub from './light-blub';
import Icon_link from './link';
import Icon_location from './location';
import Icon_lock from './lock';
import Icon_markdown from './markdown';
import Icon_meal from './meal';
import Icon_mic from './mic';
import Icon_minus from './minus';
import Icon_mobilePhone from './mobile-phone';
import Icon_moodAfter from './mood-after';
import Icon_moodBefore from './mood-before';
import Icon_mood from './mood';
import Icon_moon from './moon';
import Icon_more from './more';
import Icon_moveExchanges from './move-exchanges';
import Icon_myPlan from './my-plan';
import Icon_next from './next';
import Icon_notification from './notification';
import Icon_obsession from './obsession';
import Icon_paste from './paste';
import Icon_pause from './pause';
import Icon_people from './people';
import Icon_person from './person';
import Icon_phone from './phone';
import Icon_pill from './pill';
import Icon_pin from './pin';
import Icon_play from './play';
import Icon_plus from './plus';
import Icon_point from './point';
import Icon_postOpen from './post-open';
import Icon_postReady from './post-ready';
import Icon_practice from './practice';
import Icon_print from './print';
import Icon_publish from './publish';
import Icon_pushNotifications from './push-notifications';
import Icon_quiz from './quiz';
import Icon_quotemarks from './quotemarks';
import Icon_refresh from './refresh';
import Icon_relax from './relax';
import Icon_reorder1 from './reorder-1';
import Icon_reorder from './reorder';
import Icon_reply from './reply';
import Icon_retry from './retry';
import Icon_riskHigh from './risk-high';
import Icon_riskLow from './risk-low';
import Icon_riskMed from './risk-med';
import Icon_sandwich from './sandwich';
import Icon_script from './script';
import Icon_search from './search';
import Icon_section from './section';
import Icon_send from './send';
import Icon_sessions from './sessions';
import Icon_settings from './settings';
import Icon_share from './share';
import Icon_shield from './shield';
import Icon_skipInSession from './skip-in-session';
import Icon_sleepBed from './sleep-bed';
import Icon_slider from './slider';
import Icon_smoking from './smoking';
import Icon_specializedExchange from './specialized-exchange';
import Icon_squareCube from './square-cube';
import Icon_starSelected from './star-selected';
import Icon_star from './star';
import Icon_start from './start';
import Icon_stats from './stats';
import Icon_stop1 from './stop-1';
import Icon_stop from './stop';
import Icon_sun from './sun';
import Icon_sunrise from './sunrise';
import Icon_sunset from './sunset';
import Icon_surf from './surf';
import Icon_switch from './switch';
import Icon_tag from './tag';
import Icon_teen from './teen';
import Icon_test from './test';
import Icon_textCard from './text-card';
import Icon_textPage from './text-page';
import Icon_text from './text';
import Icon_thumbDown from './thumb-down';
import Icon_thumbUp from './thumb-up';
import Icon_transcript from './transcript';
import Icon_trigger from './trigger';
import Icon_troubleshooting from './troubleshooting';
import Icon_ungroup from './ungroup';
import Icon_upload from './upload';
import Icon_variable from './variable';
import Icon_videoCard from './video-card';
import Icon_video from './video';
import Icon_volcano from './volcano';
import Icon_warning from './warning';
import Icon_weightScale from './weight-scale';
import Icon_widget from './widget';
import Icon_zz from './zz';

export default {
  'ai': Icon_ai,
'alcohol': Icon_alcohol,
'almonds': Icon_almonds,
'apple': Icon_apple,
'approve': Icon_approve,
'apps': Icon_apps,
'arrow-down': Icon_arrowDown,
'arrow-left': Icon_arrowLeft,
'arrow-right': Icon_arrowRight,
'arrow-up': Icon_arrowUp,
'audio-mute': Icon_audioMute,
'audio': Icon_audio,
'bad-thought': Icon_badThought,
'banana': Icon_banana,
'beaker': Icon_beaker,
'bin': Icon_bin,
'blind-fold': Icon_blindFold,
'book-head': Icon_bookHead,
'book': Icon_book,
'boxing-glove': Icon_boxingGlove,
'breath-hold': Icon_breathHold,
'breathe': Icon_breathe,
'building-double': Icon_buildingDouble,
'building-single': Icon_buildingSingle,
'bulleted-list': Icon_bulletedList,
'calendar': Icon_calendar,
'camera': Icon_camera,
'cards': Icon_cards,
'caret-down': Icon_caretDown,
'caret-filled-down': Icon_caretFilledDown,
'caret-filled-right': Icon_caretFilledRight,
'caret-filled-up-down': Icon_caretFilledUpDown,
'caret-left': Icon_caretLeft,
'caret-right': Icon_caretRight,
'caret-up': Icon_caretUp,
'chat-double': Icon_chatDouble,
'check-in': Icon_checkIn,
'check': Icon_check,
'choice': Icon_choice,
'click-check': Icon_clickCheck,
'clock': Icon_clock,
'close': Icon_close,
'closed-captioning-selected': Icon_closedCaptioningSelected,
'closed-captioning': Icon_closedCaptioning,
'cloud-offline': Icon_cloudOffline,
'cloud-upload': Icon_cloudUpload,
'coffee': Icon_coffee,
'collapse': Icon_collapse,
'colors': Icon_colors,
'comment': Icon_comment,
'compulsion': Icon_compulsion,
'config': Icon_config,
'connect': Icon_connect,
'content': Icon_content,
'copy': Icon_copy,
'crisis-peak': Icon_crisisPeak,
'curve-falling': Icon_curveFalling,
'curve-peak': Icon_curvePeak,
'curve-rising': Icon_curveRising,
'dashboard': Icon_dashboard,
'decrease': Icon_decrease,
'diary': Icon_diary,
'distress': Icon_distress,
'download': Icon_download,
'earth': Icon_earth,
'easier': Icon_easier,
'eating-log': Icon_eatingLog,
'edit': Icon_edit,
'email': Icon_email,
'exchange': Icon_exchange,
'exercise': Icon_exercise,
'expand': Icon_expand,
'external': Icon_external,
'eye': Icon_eye,
'face-bad': Icon_faceBad,
'face-good': Icon_faceGood,
'face-great': Icon_faceGreat,
'face-okay': Icon_faceOkay,
'face-terrible': Icon_faceTerrible,
'filter': Icon_filter,
'flag-mountain': Icon_flagMountain,
'flame': Icon_flame,
'flip': Icon_flip,
'full-size': Icon_fullSize,
'goal': Icon_goal,
'good-thought': Icon_goodThought,
'graduation': Icon_graduation,
'grid': Icon_grid,
'group': Icon_group,
'hamburger': Icon_hamburger,
'hand-stop': Icon_handStop,
'history': Icon_history,
'home': Icon_home,
'image': Icon_image,
'in-progress': Icon_inProgress,
'increase': Icon_increase,
'info': Icon_info,
'input': Icon_input,
'jumping': Icon_jumping,
'kit': Icon_kit,
'ladder': Icon_ladder,
'later': Icon_later,
'light-blub': Icon_lightBlub,
'link': Icon_link,
'location': Icon_location,
'lock': Icon_lock,
'markdown': Icon_markdown,
'meal': Icon_meal,
'mic': Icon_mic,
'minus': Icon_minus,
'mobile-phone': Icon_mobilePhone,
'mood-after': Icon_moodAfter,
'mood-before': Icon_moodBefore,
'mood': Icon_mood,
'moon': Icon_moon,
'more': Icon_more,
'move-exchanges': Icon_moveExchanges,
'my-plan': Icon_myPlan,
'next': Icon_next,
'notification': Icon_notification,
'obsession': Icon_obsession,
'paste': Icon_paste,
'pause': Icon_pause,
'people': Icon_people,
'person': Icon_person,
'phone': Icon_phone,
'pill': Icon_pill,
'pin': Icon_pin,
'play': Icon_play,
'plus': Icon_plus,
'point': Icon_point,
'post-open': Icon_postOpen,
'post-ready': Icon_postReady,
'practice': Icon_practice,
'print': Icon_print,
'publish': Icon_publish,
'push-notifications': Icon_pushNotifications,
'quiz': Icon_quiz,
'quotemarks': Icon_quotemarks,
'refresh': Icon_refresh,
'relax': Icon_relax,
'reorder-1': Icon_reorder1,
'reorder': Icon_reorder,
'reply': Icon_reply,
'retry': Icon_retry,
'risk-high': Icon_riskHigh,
'risk-low': Icon_riskLow,
'risk-med': Icon_riskMed,
'sandwich': Icon_sandwich,
'script': Icon_script,
'search': Icon_search,
'section': Icon_section,
'send': Icon_send,
'sessions': Icon_sessions,
'settings': Icon_settings,
'share': Icon_share,
'shield': Icon_shield,
'skip-in-session': Icon_skipInSession,
'sleep-bed': Icon_sleepBed,
'slider': Icon_slider,
'smoking': Icon_smoking,
'specialized-exchange': Icon_specializedExchange,
'square-cube': Icon_squareCube,
'star-selected': Icon_starSelected,
'star': Icon_star,
'start': Icon_start,
'stats': Icon_stats,
'stop-1': Icon_stop1,
'stop': Icon_stop,
'sun': Icon_sun,
'sunrise': Icon_sunrise,
'sunset': Icon_sunset,
'surf': Icon_surf,
'switch': Icon_switch,
'tag': Icon_tag,
'teen': Icon_teen,
'test': Icon_test,
'text-card': Icon_textCard,
'text-page': Icon_textPage,
'text': Icon_text,
'thumb-down': Icon_thumbDown,
'thumb-up': Icon_thumbUp,
'transcript': Icon_transcript,
'trigger': Icon_trigger,
'troubleshooting': Icon_troubleshooting,
'ungroup': Icon_ungroup,
'upload': Icon_upload,
'variable': Icon_variable,
'video-card': Icon_videoCard,
'video': Icon_video,
'volcano': Icon_volcano,
'warning': Icon_warning,
'weight-scale': Icon_weightScale,
'widget': Icon_widget,
'zz': Icon_zz,
}