/* This file is autogenerated by @oui/cli */
import Svg, { Path, Rect, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      d="M10.69 10.6s.35-.85 1.1-1.43c.76-.58 1.61-.57 1.61-.57h.4V1H4.73c-.44 0-.98.3-1.2.69 0 0-.83 1.3-1.63 3.71-.8 2.42-.88 3.62-.88 3.62-.14.87.47 1.58 1.36 1.58H6.6L5.8 15c0 .66.54 1.2 1.2 1.2h1.2l2.49-5.6Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Rect
      x={17}
      y={9.4}
      width={3.2}
      height={8.4}
      transform="rotate(180 17 9.40012)"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
