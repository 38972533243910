import { useFocusEffect } from '@react-navigation/native';
import * as Sentry from '@sentry/core';
import { ReactNode, useCallback, useRef, useState } from 'react';
import { Image, Modal, ScrollView, StyleSheet, TouchableOpacity } from 'react-native';
import ImageViewer from 'react-native-image-zoom-viewer';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { ChatAssetProps } from '@oui/lib/src/types';

import { ActivityIndicator } from '../components/ActivityIndicator';
import { Button } from '../components/Button';
import { Icon } from '../components/Icon';
import MediaPlayer, { FullscreenMediaPlayer } from '../components/MediaPlayer';
import { Text } from '../components/Text';
import { View } from '../components/View';
import { classifyAsset } from '../lib/classifyAsset';
import { getResizedImageUrl } from '../lib/getResizedImageUrl';

const MAX_WIDTH = 170;

function ChatVideo({
  caption,
  initialPositionMillis,
  isAudio,
  uri,
  linkText,
}: ChatAssetProps & {
  initialPositionMillis?: number;
  isAudio?: boolean;
  caption?: string | ReactNode;
}) {
  const player = useRef<MediaPlayer>(null);

  const play = useCallback(() => {
    if (player.current) {
      void player.current.presentFullscreenPlayer();
    }
  }, []);

  const pause = useCallback(() => {
    if (player.current) {
      void player.current.dismissFullscreenPlayer();
    }
  }, []);

  useFocusEffect(
    useCallback(() => {
      return () => pause();
    }, [pause]),
  );

  return (
    <View
      accessible
      aria-label={isAudio ? `Listen to ${linkText ?? 'audio'}` : `Watch ${linkText ?? 'video'}`}
      role="button"
    >
      <View aria-hidden>
        <FullscreenMediaPlayer
          previewAspectRatio={1}
          mediaAspectRatio={null}
          uri={uri}
          ref={player}
          initialPositionMillis={initialPositionMillis ?? 5000}
          caption={caption}
          borderRadius={10}
        />
        <Button
          text={linkText ?? (isAudio ? 'Play audio' : 'Play video')}
          variant="text"
          alignSelf="center"
          onPress={play}
        />
      </View>
    </View>
  );
}

function ChatImage({ uri, linkText, caption }: ChatAssetProps & { caption?: ReactNode }) {
  const [fullscreen, setFullscreen] = useState(false);
  const [loading, setLoading] = useState(true);
  const insets = useSafeAreaInsets();

  return (
    <>
      <TouchableOpacity
        onPress={() => setFullscreen(true)}
        aria-label={linkText ?? 'Show image'}
        role="button"
      >
        <View style={{ width: '100%', aspectRatio: 1 }}>
          <Image
            source={{
              uri: uri.startsWith('https://storage.googleapis.com')
                ? getResizedImageUrl(uri, { width: MAX_WIDTH })
                : uri,
            }}
            onError={(e) => {
              Sentry.withScope((scope) => {
                scope.setExtras({ error: e.nativeEvent });
                Sentry.captureException('ChatAsset image load error');
              });
            }}
            onLoadEnd={() => setLoading(false)}
            resizeMode="cover"
            style={{
              height: '100%',
              width: '100%',
              borderRadius: 10,
            }}
          />
          {loading ? <ActivityIndicator style={StyleSheet.absoluteFillObject} /> : null}
        </View>
        {linkText === '' ? null : (
          <Button
            text={linkText ?? 'Show image'}
            variant="text"
            alignSelf="center"
            onPress={() => setFullscreen(true)}
          />
        )}
      </TouchableOpacity>
      {fullscreen ? (
        <Modal visible={fullscreen} transparent={true}>
          <ImageViewer
            useNativeDriver
            backgroundColor="rgba(0,0,0,0.95)"
            enableSwipeDown
            renderIndicator={() => <View />}
            footerContainerStyle={{ width: '100%' }}
            renderHeader={() => (
              <View
                style={{
                  position: 'absolute',
                  top: 0,
                  width: '100%',
                  backgroundColor: 'rgba(0,0,0,0.3)',
                  zIndex: 1,
                  paddingTop: insets.top,
                  paddingBottom: insets.bottom,
                }}
              >
                <View style={{ padding: 10, alignItems: 'flex-start' }}>
                  <Icon
                    name="close"
                    onPress={() => setFullscreen(false)}
                    color="white"
                    aria-label="Exit fullscreen"
                  />
                </View>
              </View>
            )}
            renderFooter={
              caption
                ? () => (
                    <ScrollView
                      style={{ maxHeight: 150, backgroundColor: 'rgba(0,0,0,0.6)' }}
                      contentContainerStyle={{
                        padding: 10,
                        paddingBottom: insets.bottom + 10,
                      }}
                    >
                      {typeof caption === 'string' ? (
                        <Text text={caption!} color="white" textAlign="center" weight="semibold" />
                      ) : (
                        caption
                      )}
                    </ScrollView>
                  )
                : undefined
            }
            imageUrls={[{ url: uri }]}
            loadingRender={() => <ActivityIndicator />}
            onCancel={() => setFullscreen(false)}
            onClick={() => setFullscreen(false)}
          />
        </Modal>
      ) : null}
    </>
  );
}

export function getAccessibilityTextForChatAsset(props: ChatAssetProps) {
  const { isVideo, isAudio, isImage } = classifyAsset(props.uri);
  if (isVideo) {
    return `A video: ${props.linkText ?? ''}`;
  } else if (isAudio) {
    return `An audio recording: ${props.linkText ?? ''}`;
  } else if (isImage) {
    return `An image`;
  }

  return 'An asset';
}

export function ChatAsset({
  caption,
  initialPositionMillis,
  linkText,
  padding = true,
  uri,
}: ChatAssetProps & {
  padding?: boolean;
  caption?: string | ReactNode;
  initialPositionMillis?: number;
}) {
  const { isVideo, isAudio, isImage } = classifyAsset(uri);

  return (
    <View style={{ padding: padding ? 10 : 0 }}>
      <View style={{ maxWidth: MAX_WIDTH }}>
        {isVideo ? (
          <ChatVideo
            uri={uri}
            linkText={linkText}
            initialPositionMillis={initialPositionMillis}
            caption={caption}
          />
        ) : null}
        {isAudio ? <ChatVideo uri={uri} linkText={linkText} isAudio caption={caption} /> : null}
        {isImage ? <ChatImage uri={uri} linkText={linkText} caption={caption} /> : null}
      </View>
    </View>
  );
}

export default ChatAsset;
