import type { ApolloClient } from '@apollo/client';
import * as Sentry from '@sentry/core';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';
import addDays from 'date-fns/addDays';
import addWeeks from 'date-fns/addWeeks';
import endOfWeek from 'date-fns/endOfWeek';
import isBefore from 'date-fns/isBefore';
import isSameWeek from 'date-fns/isSameWeek';
import startOfWeek from 'date-fns/startOfWeek';

import { loadConfig } from './botContent';
import { getIntlFirstWeekdayAsDateDay } from './getIntlFirstWeekday';
import { formatGQLDate, parseGQLDate, parseGQLDateAndTime, parseGQLDateTime } from './gqlDate';
import { graphql, ResultOf } from './graphql/tada';
import { StateJson, TransitionOptions, transitionWithErrorHandling } from './stateMachine';
import { ActionEnvelope, Envelopes } from './types';
import {
  getStressSensitivityChange,
  getStressSensitivityResult,
} from './types/avro/stressSensitivityAssessment';
import { CompositionTemplates } from './types/compositionTemplates';
import { AddActivityPracticeInput, ContentType } from './types/graphql.generated';
import { GQLAny, GQLDate, GQLDateTime } from './types/scalars';

const BotProgressQuery = graphql(`
  query BotProgress {
    user {
      ID
      __typename
      role {
        ID
        progress {
          content
          completion
          completed
          updatedAt
        }
      }
    }
  }
`);

const SetOuiProgressBotMutation = graphql(`
  mutation SetOuiProgressBot($content: ContentType!, $value: Float!) {
    setOuiProgress(content: $content, value: $value) {
      completed
    }
  }
`);

const BotResponseQuery = graphql(`
  query BotResponse($context: String!, $key: String!) {
    kvResponse(context: $context, key: $key) {
      context
      key
      value
    }
  }
`);

const BotActionsCountQuery = graphql(`
  query BotActionsCount($actionTypes: [ActionType!]!, $after: DateTime!) {
    actionCounts(actionTypes: $actionTypes, after: $after) {
      actionType
      count
    }
  }
`);

const SaveBotResponseMutation = graphql(`
  mutation SaveBotResponse($context: String!, $key: String!, $data: Any!) {
    kvRespond(context: $context, key: $key, data: $data) {
      context
      key
      value
    }
  }
`);

export const LoadBotQuery = graphql(`
  query LoadBot($configID: String!, $hash: String) {
    user {
      __typename
      ID
      timezone
      role {
        ID
        product {
          ID
          slug
        }
        productStatic
      }
    }
    botConfigState(configID: $configID, hash: $hash) {
      config
      botStateID
      state
      occurredAt
    }
  }
`);

const BotHistoryQuery = graphql(`
  query BotHistory($configID: String!, $lastValidOccurredAt: DateTime!) {
    botHistory(configID: $configID, lastValidOccurredAt: $lastValidOccurredAt) {
      config
      envelope
      occurredAt
    }
  }
`);

const PersistBotStateMutation = graphql(`
  mutation PersistBotState($input: MutationPersistBotStateInput!) {
    persistBotState(input: $input) {
      __typename
      ... on BaseError {
        message
      }
      ... on OutdatedBotStateError {
        message
      }
      ... on BotState {
        botStateID
      }
    }
  }
`);

const RewindBotStateMutation = graphql(`
  mutation RewindBotState($configID: String!) {
    rewindBotState(configID: $configID) {
      config
    }
  }
`);

export function setProgress(apollo: ApolloClient<unknown>, content: ContentType, value: number) {
  return apollo.mutate({
    mutation: SetOuiProgressBotMutation,
    variables: {
      content: content as ContentType,
      value,
    },
  });
}

export async function getResponse(apollo: ApolloClient<unknown>, context: string, key: string) {
  return apollo
    .query({
      query: BotResponseQuery,
      variables: {
        context,
        key,
      },
    })
    .then((result) => result.data?.kvResponse.value);
}

export async function saveResponse(
  apollo: ApolloClient<unknown>,
  context: string,
  key: string,
  data: GQLAny,
) {
  await apollo.mutate({ mutation: SaveBotResponseMutation, variables: { context, key, data } });
  return true;
}

export async function transitionBot(
  config: string,
  currentStateStr: string | null,
  action: ActionEnvelope | null,
  options: Omit<
    TransitionOptions,
    | 'saveResponse'
    | 'getResponse'
    | 'setProgress'
    | 'getActionsCount'
    | 'getProgressCompletedAt'
    | 'getChangeBiteProgressStatus'
    | 'saveChangeBiteProgressEntry'
    | 'updateRoleSettings'
    | 'updateEatingCommitments'
    | 'createChangeBiteActivityDiaryEntries'
    | 'onTransitionFailed'
    | 'getStressSensitivityPercentChange'
    | 'getStressSensitivityResultTitle'
  > & {
    apolloClient: ApolloClient<unknown>;
    logEvent: (
      eventName: string,
      properties?: Record<string, string | number | boolean | null>,
    ) => void;
    forceLegacyStaticContent: boolean;
  },
): Promise<{ state: string; envelopes: Envelopes[]; error: boolean }> {
  const { apolloClient } = options;
  let latestBotState: ResultOf<typeof LoadBotQuery>['botConfigState'] | undefined;
  const res = await apolloClient.query({
    query: LoadBotQuery,
    variables: { configID: config, hash: currentStateStr },
  });
  latestBotState = res?.data?.botConfigState;

  // console.log({ latestBotState, action, currentStateStr });

  if (action === null && latestBotState) {
    const history = await apolloClient.query({
      query: BotHistoryQuery,
      variables: {
        configID: config,
        lastValidOccurredAt: latestBotState.occurredAt,
      },
    });
    const envelopes = history.data?.botHistory?.map((h) => h.envelope as Envelopes) ?? [];
    return {
      error: false,
      envelopes: envelopes
        .map((e) => ({ ...e, history: true }))
        .sort((a, b) => (a.t! < b.t! ? -1 : 1)),
      state: latestBotState.botStateID,
    };
  }

  const patient = res.data.user?.role;
  const productStatic = patient?.productStatic ?? false;

  const botStateObj = latestBotState ? (JSON.parse(latestBotState.state) as StateJson) : null;
  const configObject = await loadConfig(apolloClient, {
    useLegacyStaticContent:
      options.forceLegacyStaticContent ||
      // if user has a botStateObj and __CONTENT_VERSION isn't set, that means the botStateObj was created
      // using legacy static content
      !!(botStateObj && typeof botStateObj.context.__CONTENT_VERSION !== 'number'),
    version: botStateObj?.context.__CONTENT_VERSION ?? undefined,
    content: config,
    productVariant: patient?.product.slug,
    productStatic,
  });
  if (!configObject) {
    throw new Error('Invalid config specified: ' + config);
  }

  const result = await transitionWithErrorHandling(configObject, botStateObj, action, {
    ...options,
    getResponse: (context, key) => {
      return getResponse(options.apolloClient, context, key);
    },
    saveResponse: (context, key, value) => {
      return saveResponse(options.apolloClient, context, key, value);
    },
    setProgress: (content, value) => {
      return Promise.all([
        options.logEvent(value === 1 ? 'session_completed' : 'session_started', {
          session: content,
        }),
        setProgress(options.apolloClient, content, value),
      ]);
    },
    getProgressCompletedAt: async (content) => {
      const progressResult = await options.apolloClient.query({ query: BotProgressQuery });
      return (
        progressResult.data.user?.role?.progress.find((p) => p.content === content)?.updatedAt ??
        (new Date().toISOString() as GQLDateTime)
      );
    },
    getActionsCount: async (variables) => {
      const r = await options.apolloClient.query({ query: BotActionsCountQuery, variables });
      return r.data?.actionCounts.reduce((sum, { count }) => sum + count, 0) ?? 0;
    },
    getChangeBiteProgressStatus: async (session) => {
      const changeBite = await options.apolloClient.query({
        query: graphql(`
          query BotChangeBiteProgressStatus($after: Date!, $before: Date!) {
            user {
              ID
              locale
              role {
                ID
                eatingProgressEntries {
                  practiceID
                  practiceValues {
                    date
                  }
                  eatingProgressEntry {
                    binges
                    fadFixes
                    weight
                    weightUnit
                  }
                }
                eatingLogEntries(after: $after, before: $before) {
                  practiceID
                  eatingLogEntry {
                    timestamp
                    binged
                  }
                }
              }
            }
          }
        `),
        variables: { after: formatGQLDate(addWeeks(new Date(), -1)), before: formatGQLDate() },
      });
      const role = changeBite.data!.user!.role!;

      const lastWeek = addWeeks(new Date(), -1);
      const lastWeekRecordedBinges = role.eatingLogEntries.filter(
        (e) =>
          e.eatingLogEntry.binged &&
          isSameWeek(lastWeek, parseGQLDateTime(e.eatingLogEntry.timestamp)),
      ).length;

      const locale = changeBite.data?.user?.locale ?? 'en-US';
      const weekStartsOn = getIntlFirstWeekdayAsDateDay(locale);
      const formatter = new Intl.DateTimeFormat(locale, {
        // e.g. "Mon, Sep 9"
        month: 'short',
        weekday: 'short',
        day: 'numeric',
      });
      const lastWeekDateRange = `${formatter.format(startOfWeek(lastWeek, { weekStartsOn }))} – ${formatter.format(endOfWeek(lastWeek, { weekStartsOn }))}`;

      // Last session requires we prompt to compare before / after
      if (session === ContentType.RELAPSE_PREVENTION) {
        const endProgress = role.eatingProgressEntries[0];
        const startProgress = role.eatingProgressEntries[role.eatingProgressEntries.length - 1];

        return {
          // used in final display
          startBinges: startProgress.eatingProgressEntry.binges ?? 0,
          endBinges: endProgress.eatingProgressEntry.binges ?? 0,
          startFadFixes: startProgress.eatingProgressEntry.fadFixes ?? 0,
          endFadFixes: endProgress.eatingProgressEntry.fadFixes ?? 0,
          startWeight: startProgress.eatingProgressEntry.weight ?? 0,
          endWeight: endProgress.eatingProgressEntry.weight ?? 0,
          startWeightUnit: startProgress.eatingProgressEntry.weightUnit?.toLowerCase() ?? 'lbs',
          endWeightUnit: endProgress.eatingProgressEntry.weightUnit?.toLowerCase() ?? 'lbs',

          binges: true,
          fadFixes: true,
          weight: true,
          numBinges: lastWeekRecordedBinges,
          numEntries: role.eatingLogEntries.length,
          lastWeekDateRange,
        };
      }

      const entriesFromThisWeek = role.eatingProgressEntries.filter((e) =>
        isSameWeek(new Date(), parseGQLDate(e.practiceValues.date)),
      );
      const entriesFromLast4Weeks = role.eatingProgressEntries.filter((e) =>
        isBefore(addWeeks(new Date(), -4), parseGQLDate(e.practiceValues.date)),
      );

      // If user has recorded progress for category this same calendar week, then don't prompt again
      const binges = !entriesFromThisWeek.some(
        (e) => typeof e.eatingProgressEntry.binges === 'number',
      );
      const fadFixes = !entriesFromThisWeek.some(
        (e) => typeof e.eatingProgressEntry.fadFixes === 'number',
      );
      // If user has recorded progress for category in last 4 weeks, then don't prompt again
      const weight = !entriesFromLast4Weeks.some(
        (e) => typeof e.eatingProgressEntry.weight === 'number',
      );

      return {
        binges,
        fadFixes,
        weight,
        numBinges: role.eatingLogEntries.length ? lastWeekRecordedBinges : null,
        numEntries: role.eatingLogEntries.length,
        lastWeekDateRange,
      };
    },
    async getStressSensitivityPercentChange() {
      const response = await options.apolloClient.query({
        query: graphql(`
          query BotStressSensitivityPercentChange {
            user {
              ID
              role {
                ID
                composition(template: "STRESS_SENSITIVITY_ASSESSMENT") {
                  ID
                  json
                }
              }
            }
          }
        `),
      });

      const json = response.data.user?.role?.composition?.json;
      if (!json) return 0;

      const assessment = CompositionTemplates.STRESS_SENSITIVITY_ASSESSMENT.parse(
        response.data.user?.role?.composition?.json,
      ).STRESS_SENSITIVITY_ASSESSMENT;

      return Math.floor(getStressSensitivityChange(assessment) * 100);
    },
    async getStressSensitivityResultTitle(type) {
      const response = await options.apolloClient.query({
        query: graphql(`
          query BotStressSensitivityResult {
            user {
              ID
              role {
                ID
                composition(template: "STRESS_SENSITIVITY_ASSESSMENT") {
                  ID
                  json
                }
              }
            }
          }
        `),
      });

      const json = response.data.user?.role?.composition?.json;

      if (!json) {
        throw new Error('Tried to getStressSensitivityResult without data');
      }

      const assessment = CompositionTemplates.STRESS_SENSITIVITY_ASSESSMENT.parse(
        response.data.user?.role?.composition?.json,
      ).STRESS_SENSITIVITY_ASSESSMENT;

      if (!assessment[type]) {
        throw new Error(`Tried to getStressSensitivityResult without ${type} data`);
      }

      return getStressSensitivityResult(assessment[type]).title;
    },
    async saveChangeBiteProgressEntry(data) {
      const response = await options.apolloClient.query({
        query: graphql(`
          mutation BotSaveChangeBiteProgressEntry($input: MutationAddEatingProgressEntryInput!) {
            addEatingProgressEntry(input: $input) {
              ... on EatingProgressEntryPractice {
                practiceID
              }
            }
          }
        `),
        variables: {
          input: {
            practiceValues: { patientID: patient!.ID, ratings: [], date: formatGQLDate() },
            eatingProgressEntry: {
              weightUnit: 'LBS',
              timestamp: new Date().toISOString() as GQLDateTime,
              ...data,
            },
          },
        },
      });

      return response.data;
    },
    async updateRoleSettings(settings) {
      const response = await options.apolloClient.query({
        query: graphql(`
          mutation BotUpdateRoleSettings($input: MutationUpdateRoleSettingsInput!) {
            updateRoleSettings(input: $input) {
              __typename
            }
          }
        `),
        variables: { input: { roleID: patient!.ID, settings } },
      });

      return response.data;
    },
    async updateEatingCommitments(data) {
      const updateResult = await options.apolloClient.mutate({
        mutation: graphql(`
          mutation BotUpdateEatingCommitments($json: Map!) {
            setComposition(template: EATING_COMMITMENT, json: $json) {
              ID
              json
            }
          }
        `),
        variables: {
          json: CompositionTemplates.EATING_COMMITMENT.parse({
            EATING_COMMITMENT: {
              increase: data.increase,
              increaseOther: data.increaseOther,
              decrease: data.decrease,
              decreaseOther: data.decreaseOther,
            },
          }),
        },
      });

      if (updateResult.errors?.[0]) {
        Sentry.captureException(updateResult.errors?.[0]);
        throw updateResult.errors?.[0];
      }

      return updateResult.data?.setComposition;
    },
    async createChangeBiteActivityDiaryEntries() {
      const timezone = res.data.user!.timezone ?? 'America/New_York';
      function getEntryForDate(date: GQLDate): AddActivityPracticeInput {
        const startTime = zonedTimeToUtc(
          parseGQLDateAndTime(date, '00:00'),
          timezone,
        ).toISOString() as GQLDateTime;
        const endTime = zonedTimeToUtc(
          parseGQLDateAndTime(date, '23:59'),
          res.data.user!.timezone ?? 'America/New_York',
        ).toISOString() as GQLDateTime;

        return {
          practiceValues: {
            patientID: patient!.ID,
            ratings: [],
            date,
          },
          activity: {
            startTime,
            endTime,
            title: 'Interrupt weight stigma',
            location: 'Weight stigma in the media',
            notes: `Reminder - three steps:
1. Identify social pressures create your body dissatisfaction
2. Interrupt the belief these social pressures are true
3. Accept what you can change and what you can’t`,
          },
        };
      }

      const nowInUserTime = utcToZonedTime(new Date(), timezone);
      const result = await options.apolloClient.mutate({
        mutation: graphql(`
          mutation BotCreateChangeBiteActivityDiaryEntries(
            $one: AddActivityPracticeInput!
            $two: AddActivityPracticeInput!
            $three: AddActivityPracticeInput!
          ) {
            one: addActivityPractice(input: $one) {
              activityPractice {
                practiceID
              }
            }
            two: addActivityPractice(input: $two) {
              activityPractice {
                practiceID
              }
            }
            three: addActivityPractice(input: $three) {
              activityPractice {
                practiceID
              }
            }
          }
        `),
        variables: {
          one: getEntryForDate(formatGQLDate(nowInUserTime)),
          two: getEntryForDate(formatGQLDate(addDays(nowInUserTime, 2))),
          three: getEntryForDate(formatGQLDate(addDays(nowInUserTime, 4))),
        },
      });

      if (result.errors?.[0]) throw result.errors[0];
      return result.data;
    },
    onTransitionFailed: async () => {
      await apolloClient.mutate({
        mutation: RewindBotStateMutation,
        variables: { configID: config },
      });
    },
  });

  if (result.error) {
    return { error: true, envelopes: [], state: '' };
  }

  const persistedResult = await apolloClient.mutate({
    mutation: PersistBotStateMutation,
    variables: {
      input: {
        configID: config,
        envelopes: result.envelopes,
        state: JSON.stringify(result.state),
        previousBotStateID: latestBotState?.botStateID,
      },
    },
  });

  if (persistedResult.errors?.length) {
    // error captured by sentry error link
    return {
      error: true,
      envelopes: [],
      state: '',
    };
  }

  if (persistedResult.data?.persistBotState?.__typename === 'BotState') {
    return {
      error: false,
      envelopes: result.envelopes,
      state: persistedResult.data.persistBotState.botStateID,
    };
  } else {
    Sentry.captureException(
      `persistBotState returned an error: ${persistedResult.data?.persistBotState?.__typename}`,
      {
        extra: {
          config,
          currentBotStateID: currentStateStr,
          latestBotState: latestBotState?.botStateID,
        },
      },
    );

    return {
      error: true,
      envelopes: [],
      state: '',
    };
  }
}
