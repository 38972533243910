import * as Application from 'expo-application';
import Constants from 'expo-constants';
import * as Updates from 'expo-updates';
import { Platform } from 'react-native';
import semverCompare from 'semver-compare';
import { JsonObject } from 'type-fest';
import Url from 'url-parse';

import type { AppClientSlug } from '@oui/lib/src/appClientDirectory';

export function getNativeBuildVersionAsSemver() {
  const version = Platform.select({
    android: () => Application.nativeApplicationVersion,
    web: () => Constants.expoConfig?.version ?? '99.99.99', // fallback for non-expo builds (e.g storybook, nextjs)
    default: () => Application.nativeApplicationVersion,
  })();

  if (!version) {
    throw new Error('Missing native build version');
  }

  return version;
}

export const isHermes = () => !!(global as unknown as { HermesInternal: boolean }).HermesInternal;

export enum Environment {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
  STAGING = 'staging',
}

export const manifest: { version: string; name: string; slug: string; scheme: string } = process.env
  .NEXT_PUBLIC_API_CLIENT
  ? {
      name: process.env.NEXT_PUBLIC_API_CLIENT!,
      slug: process.env.NEXT_PUBLIC_API_CLIENT!,
      version: process.env.VERSION!,
      // location can be null in some nextjs jest tests
      scheme: global.window?.location?.protocol ?? 'https',
    }
  : Constants.expoConfig
    ? {
        name: Constants.expoConfig.name || 'Aviva',
        slug: Constants.expoConfig.slug,
        version: getNativeBuildVersionAsSemver(),
        scheme: Constants.expoConfig.scheme! as string,
      }
    : {
        name: Constants.manifest2?.extra?.expoClient?.name || 'Aviva',
        slug: Constants.manifest2?.extra?.expoClient?.slug || 'oui-aviva',
        version: getNativeBuildVersionAsSemver(),
        scheme: Constants.manifest2?.extra?.expoClient?.scheme! as string,
      };

export const APP_SLUG = manifest.slug as AppClientSlug;

export const IS_STAFF_APP = APP_SLUG === 'oui-aviva-staff';

const releaseChannel = Updates.channel || 'development';

export const environment: Environment = Platform.select({
  // CAPS hosts in prod on more TLDs than 'health' so it's easier to match on 'dev'
  web: () => {
    if (typeof window === 'undefined') {
      if (process.env['NEXT_PUBLIC_ENV']) {
        return process.env['NEXT_PUBLIC_ENV'];
      }
      return Environment.DEVELOPMENT;
    }
    // @ts-expect-error defined by web-core/getDynamicEnv
    if (global.window?.env?.['NEXT_PUBLIC_ENV']) {
      // @ts-expect-error defined by web-core/getDynamicEnv
      return global.window?.env?.['NEXT_PUBLIC_ENV'];
    }

    return window.location.hostname === 'localhost'
      ? Environment.DEVELOPMENT
      : window.location.host.split('.').reverse()[0] === 'dev' ||
          // For firebase hosting preview channels
          window.location.hostname.includes('-dev-') ||
          window.location.hostname.endsWith('.chromatic.com')
        ? Environment.STAGING
        : Environment.PRODUCTION;
  },
  default: () => {
    const applicationId = Application.applicationId;
    if (
      applicationId?.endsWith('.debug') ||
      process.env.NODE_ENV === 'test' ||
      ['development'].includes(releaseChannel)
    ) {
      return Environment.DEVELOPMENT;
    } else if (
      applicationId?.endsWith('.staging') ||
      ['test', 'staging'].includes(releaseChannel)
    ) {
      return Environment.STAGING;
    }

    return Environment.PRODUCTION;
  },
})();
export const IS_PRODUCTION = environment === Environment.PRODUCTION;

export const ENABLE_TTS = false;

export const CLINICIAN_WIZARD_STEPS = 6;

const Config = (Constants.expoConfig?.extra ?? Constants.manifest2?.extra ?? {}) as JsonObject;

function getConfigValue(key: string) {
  const nextKey = `NEXT_PUBLIC_${key}`;
  // @ts-expect-error window.env is defined in nextjs layout
  const value = Config[key] || global.window?.env?.[nextKey] || process?.env?.[nextKey];
  if (typeof value !== 'string' && process.env.NODE_ENV !== 'test') {
    // NEXTJS and storybook builds don't have Config set, and also don't rely on these values so it's safe
    // not to warn
    if (!process.env.NEXT_PUBLIC_API_CLIENT && !process.env.STORYBOOK_CLIENT) {
      console.log(new Error(`Config object not properly initialized in build: missing ${key}`));
    }
    return '';
  }
  return value as string;
}

export const API_URL = getConfigValue('API_URL');
export const REST_API_URL = getConfigValue('REST_API_URL');
export const BOT_TRANSITION_URL = getConfigValue('BOT_TRANSITION_URL');
export const CLOUD_FUNCTION_URL = getConfigValue('CLOUD_FUNCTION_URL');
export const AMPLITUDE_API_KEY = getConfigValue('AMPLITUDE_API_KEY');
const AMPLITUDE_URL_OBJ = new Url(API_URL || 'https://oui.dev');
AMPLITUDE_URL_OBJ.set(
  'hostname',
  AMPLITUDE_URL_OBJ.hostname
    .split('.')
    .slice(-2, AMPLITUDE_URL_OBJ.hostname.split('.').length)
    .join('.'),
);
AMPLITUDE_URL_OBJ.pathname = '/pupil';
export const AMPLITUDE_URL = AMPLITUDE_URL_OBJ.toString();

export const SENTRY_DSN = getConfigValue('SENTRY_DSN');
export const MUX_ENV_KEY = getConfigValue('MUX_ENV_KEY');

export const remoteConfigDefaults = {
  apiUrl: API_URL,
  subscriptionUri: getConfigValue('SUBSCRIPTION_API_URL'),
  restApiUrl: getConfigValue('REST_API_URL'),
};

export const REGEX = {
  email:
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i,
  // https://www.regextester.com/17
  phone:
    /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/,
};

export const SESSION_TIMEOUT =
  environment === Environment.DEVELOPMENT
    ? 40 * 60000
    : manifest.slug === 'oui-aviva'
      ? 3 * 60000
      : 15 * 60000;

// use a non-round number to more easily distiguish this timeout from other 10s timeouts
export const LOADING_TIMEOUT = 10001;

export const DETOX_SIGNATURE = `data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAA0NDQ0ODQ8REQ8VFxQXFR8dGhodHy8iJCIkIi9HLTQtLTQtRz9MPjo+TD9xWU9PWXGDbmhug5+Ojp/Ivsj///8BDQ0NDQ4NDxERDxUXFBcVHx0aGh0fLyIkIiQiL0ctNC0tNC1HP0w+Oj5MP3FZT09ZcYNuaG6Dn46On8i+yP/////CABEIAKoBLAMBIgACEQEDEQH/xAAbAAEBAAMBAQEAAAAAAAAAAAAABAIDBQYBB//aAAgBAQAAAAD9OAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAB84ej0WQAAAAAhtQdAAAAAAQ3I7AAAAABHYlqAAAAAHP6DVjvAAAAAPP8AoCSsAAAAA43ZJagAAAACG4iryDDXvAAAAByesTad1IAAAAGmDdjXuAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAH/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAgBAhAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAH//xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oACAEDEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAf//EADoQAAIBAgMCCQcNAAAAAAAAAAECAwQRAAVBElAQFCAhMVJxgbETIlFTgpGhFSQzNEBicHWAo7LC0v/aAAgBAQABPwD9CjEKDc2AFycR0j5mPLVcswhJvDAjlAE0LkWJY4npnyRuN00kxpF+swu5lsvrELYUqwDKQQRcEag7jzC5pzEpsZXWK/oDmxPuvhQFAsLACwGCAwIIuCLEYyy0EL0Zv83bYS/q+lNxyjylbSppGjyH+A8TwtaLMInHRLEyN2odpdxx3auq26gjj9wLf24a6ypHOBzRSo/dfZbcdDKsk2Z6FKvYPaIweGaITRSxnodGU94tilmMtNCx6gv267iyA7T53+Zv8EHIpBsNPDqkxPc/n+JO4smgERzU6nMZT8FHIF1r0Gk0du+M38DuKijkj40HQreqkZb6g2seRXqyxeWRSWgkEotqB0gdoJwjK6K6kFWAII1B5TyRxKWdgq+k4ileTaJiZUFtknmLd2g+3wyfJ1TxKWwie5pT4x9q6ciStpoG2ZJRt9RfOc+yLnAkrJuaOIQr15edu5RiOnRTtsWkk67m57tBuCeCGoieKaJJEYWZWFxg5U0agU2Y1kK6LtCQfuBjbHE64E3zWX2Y0/zhaAn6esqZhoCwjHujAxDTwwC0caoPui34o//EABQRAQAAAAAAAAAAAAAAAAAAAHD/2gAIAQIBAT8AUf/EABQRAQAAAAAAAAAAAAAAAAAAAHD/2gAIAQMBAT8AUf/Z`;

export const DEFAULT_HEADER_MODE = 'float' as const;

export const IOS_14_OR_GREATER =
  Platform.OS === 'ios' &&
  Platform.Version &&
  semverCompare(Platform.Version.toString(), '14.0') >= 0;

export const FLAGS: AppCore.Flags = {
  allowDarkTheme: false && environment === Environment.DEVELOPMENT && __DEV__,
  showEnvelopeIDInChat: false,
  showI18NFlags: false,
  showI18NKeys: false,
  useIOS14DatePicker: false,
  useMediaLibraryAlbums: environment !== Environment.PRODUCTION,
};
