import { ApolloClient, useApolloClient } from '@apollo/client';
import { NavigationProp, useFocusEffect, useNavigation } from '@react-navigation/native';
import * as Sentry from '@sentry/core';
import { useCallback, useEffect, useRef } from 'react';

import { ChatInputArtifactProps, ChatInputBaseProps, Kind } from '@oui/lib/src/types';

import ChatInputButton from '../components/ChatInputButton';
import { View } from '../components/View';
import { startArtifactRequest, useArtifactResult } from '../hooks/useArtifactResult';
import { StackScreenProps } from '../types/navigation';

export const ARTIFACT_HANDLERS: Partial<
  Record<
    ChatInputArtifactProps['artifactName'],
    (
      payload: {
        startArtifactRequest: typeof startArtifactRequest;
        navigate: NavigationProp<ReactNavigation.RootParamList>['navigate'];
        apollo: ApolloClient<unknown>;
      } & Pick<ChatInputArtifactProps, 'params'>,
    ) => Promise<void>
  >
> = {};

export function registerChatInputArtifactHandler(
  key: ChatInputArtifactProps['artifactName'],
  callback: (typeof ARTIFACT_HANDLERS)[typeof key],
) {
  ARTIFACT_HANDLERS[key] = callback;
}

export function ChatInputArtifact(props: ChatInputArtifactProps & ChatInputBaseProps) {
  const { navigate } = useNavigation<StackScreenProps<'Conversation'>['navigation']>();
  const apollo = useApolloClient();
  const artifactStartedRef = useRef(false);
  const onInputRef = useRef(props.onInput);
  onInputRef.current = props.onInput;

  useArtifactResult((result) => {
    artifactStartedRef.current = false;
    props.onInput({ kind: Kind.InputArtifact, props: result });
  });

  const onStartArtifact = useCallback(async () => {
    artifactStartedRef.current = true;

    if (ARTIFACT_HANDLERS[props.artifactName]) {
      let started = false;
      const ensureStartArtifactRequest: typeof startArtifactRequest = (name) => {
        started = true;
        startArtifactRequest(name);
      };

      await ARTIFACT_HANDLERS[props.artifactName]!({
        startArtifactRequest: ensureStartArtifactRequest,
        apollo,
        navigate,
        params: props.params,
      });

      if (!started) {
        onInputRef.current({ kind: Kind.InputArtifact, props: { complete: false } });
        Sentry.captureException('Failed to startArtifactRequest in custom artifact handler', {
          extra: { artifactName: props.artifactName },
        });
      }
    } else {
      startArtifactRequest(props.artifactName);
      // @ts-expect-error we don't have precise enough types for routeName/params so we just have to trust
      // the artifact props
      navigate(props.artifactName, { ...props.params });
    }
  }, [props.artifactName, navigate, props.params, apollo]);

  useFocusEffect(
    useCallback(() => {
      if (artifactStartedRef.current) {
        onInputRef.current({ kind: Kind.InputArtifact, props: { complete: false } });
      }
    }, []),
  );

  useEffect(() => {
    if (props.retry) {
      onStartArtifact().catch(Sentry.captureException);
    }
  }, [props.retry, onStartArtifact]);

  return props.retry ? null : (
    <View>
      <ChatInputButton
        onInput={(action) => {
          if (action.kind === Kind.InputButton) {
            void onStartArtifact();
          }
        }}
        label={props.buttonText}
      />
    </View>
  );
}

ChatInputArtifact.defaultProps = {
  onInput: () => {},
  disabled: false,
};
