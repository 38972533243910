/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      d="M16.53 13.27c.62.52.62 1.36 0 1.88l-1.55 1.25c-.63.52-1.77.76-2.53.5 0 0-3.1-.59-6.93-4.42C1.68 8.65 1.1 5.55 1.1 5.55c-.24-.77-.03-1.9.5-2.54l1.25-1.54a1.17 1.17 0 0 1 1.88 0l1.9 2.27c.34.42.38 1.12.08 1.58l-.85 1.26s.01 2.1 1.74 3.82a5.92 5.92 0 0 0 3.82 1.73l1.26-.84a1.4 1.4 0 0 1 1.57.08l2.28 1.9Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
