/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={19} height={18} viewBox="0 0 19 18" fill="none" {...props}>
    <Path
      d="M10.27 6.07A2.37 2.37 0 0 0 6 6.76"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M10.43 5v1.19H9.24v0"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M8.15 8.64A2.37 2.37 0 0 0 12.41 8"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M8 9.7V8.53h1.19v0"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M1.34 9.3a3.6 3.6 0 0 1 2.94-4.36 4.8 4.8 0 0 1 9.04-.4l.44-.14c1.5-.34 3 .6 3.35 2.1.35 1.52-.53 4.18-2.04 4.52 0 0-3.42.84-4.94 2.19-1.11.98-.96 2.43-2.34 3-.51.21-1.4.29-1.4.29L5.65 12a3.6 3.6 0 0 1-4.31-2.7Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
