/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={16} height={18} viewBox="0 0 16 18" fill="none" {...props}>
    <Path
      d="M4.38 3.4v0A2.38 2.38 0 0 0 2 5.78V14a3 3 0 0 0 3 3h6a3 3 0 0 0 3-3V5.78a2.38 2.38 0 0 0-2.38-2.38v0"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M6.4 2.2v1a1 1 0 0 0 1-1h-1Zm-.6 0v1-1Zm4.4 0v1-1Zm-.6 0h-1a1 1 0 0 0 1 1v-1Zm-2.2 0c0-.11.09-.2.2-.2V0a2.2 2.2 0 0 0-2.2 2.2h2Zm-1.6 1h.6v-2h-.6v2Zm-.4.4c0-.22.18-.4.4-.4v-2a2.4 2.4 0 0 0-2.4 2.4h2Zm.4.4a.4.4 0 0 1-.4-.4h-2A2.4 2.4 0 0 0 5.8 6V4Zm4.4 0H5.8v2h4.4V4Zm.4-.4a.4.4 0 0 1-.4.4v2a2.4 2.4 0 0 0 2.4-2.4h-2Zm-.4-.4c.22 0 .4.18.4.4h2a2.4 2.4 0 0 0-2.4-2.4v2Zm-.6 0h.6v-2h-.6v2ZM8.4 2c.11 0 .2.09.2.2h2A2.2 2.2 0 0 0 8.4 0v2Zm-.8 0h.8V0h-.8v2Z"
      fill="currentColor"
    />
    <Path
      d="M5.31 7.46h5.38"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M5.31 10.54h5.38"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M5.31 13.6h5.38"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
