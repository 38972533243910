/* This file is autogenerated by @oui/cli */
import Svg, { Path, Rect, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={16} viewBox="0 0 18 16" fill="none" {...props}>
    <Rect
      x={1}
      y={1.92}
      width={16}
      height={11.3}
      rx={2}
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M9 9.45 2.36 5.5A2.79 2.79 0 0 1 1 3.1v0c0-.65.54-1.18 1.2-1.18h13.6c.67 0 1.2.53 1.2 1.19v0c0 .98-.51 1.9-1.36 2.4L9 9.44Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
