/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={19} height={16} viewBox="0 0 19 16" fill="none" {...props}>
    <Path
      d="M5.52 4.6c.1-.09.19-.38.38-.38.86-.19 1.73-.28 2.69-.38.1 0 .38.2.48.29.29.67.48 1.44.67 2.2.38-.47.77-1.05 1.25-1.43a3.8 3.8 0 0 1 1.44-.96c.48-.2 1.15 0 1.34.48.2.48.2.96-.1 1.34-.47.38-.95.29-1.43.1-.68-.39-.87-.3-1.35.28-.77.77-1.15 1.64-.67 2.79.29.67.29 1.34.48 2.01.2.68.58.77 1.15.3.29-.3.48-.58.87-.97 0 .29.1.58.1.67-.58.58-1.06 1.25-1.74 1.73-.96.67-1.82.39-2.3-.67a11.3 11.3 0 0 1-.67-1.92c-.39.67-.87 1.34-1.25 1.92-.38.58-.96.96-1.73.96-.48 0-.86-.29-1.15-.77-.2-.29-.1-.77.2-1.15.38-.29.76-.29 1.24-.1 1.06.39 1.06.3 1.73-.57.67-.87.86-1.73.48-2.79-.2-.48-.2-.96-.29-1.34-.2-1.25-.38-1.44-1.82-1.63Z"
      fill="currentColor"
    />
    <Path
      d="M4.13 2.18a7.97 7.97 0 0 0-.93 11.38"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M14.2 13.3a7.97 7.97 0 0 0 .92-11.38"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
