/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      d="M6.57 3.38V2.2c0-.66.55-1.2 1.22-1.2H9.4c.67 0 1.22.54 1.22 1.2v1.1a6.1 6.1 0 0 1 2 1.1l.93-.53a1.23 1.23 0 0 1 1.66.44l.82 1.38c.33.58.13 1.31-.45 1.64l-.9.52a5.95 5.95 0 0 1 0 2.3l.9.52c.58.33.78 1.06.45 1.64l-.82 1.38c-.33.57-1.08.77-1.66.44l-.94-.53a6.1 6.1 0 0 1-1.99 1.1v1.1c0 .66-.55 1.2-1.22 1.2H7.8a1.2 1.2 0 0 1-1.22-1.2v-1.18a6.1 6.1 0 0 1-1.86-1.1l-1.07.61c-.58.33-1.33.13-1.66-.44l-.82-1.38a1.2 1.2 0 0 1 .45-1.64l1.1-.63a5.95 5.95 0 0 1 0-2.08l-1.1-.63a1.2 1.2 0 0 1-.45-1.64l.82-1.38a1.23 1.23 0 0 1 1.66-.44l1.07.6a6.1 6.1 0 0 1 1.86-1.09v0Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M8.6 11a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
