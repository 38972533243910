import { Image } from 'expo-image';
import { useState } from 'react';
import { Modal, PixelRatio, Platform } from 'react-native';
import { SystemBars } from 'react-native-edge-to-edge';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { getMuxThumbnail, isMuxUrl } from '@oui/lib/src/muxUrl';

import { useTheme } from '../../styles';
import { Button } from '../Button';
import { Card, CardStack } from '../CardStack';
import { HeaderButtons, HeaderItem } from '../HeaderButtons';
import MediaPlayer from '../MediaPlayer';
import { Label, Text } from '../Text';
import { View } from '../View';

/**
 * Shows the user an explanatory video for the product.
 */
export const ExplanationVideo = ({
  muxUrl,
  variant,
}: {
  muxUrl: string;
  variant: 'block' | 'inline';
}) => {
  const { theme } = useTheme();
  const insets = useSafeAreaInsets();
  const [showVideo, setShowVideo] = useState(false);

  if (!isMuxUrl(muxUrl)) throw new Error('Invalid muxUrl for ExplanationVideo');

  let ui = (
    <Button
      text="Watch a clinician explain more"
      icon="play"
      onPress={() => setShowVideo(true)}
      size="small"
      variant="text"
    />
  );

  if (variant === 'block') {
    ui = (
      <View
        style={{
          borderRadius: 10,
          paddingHorizontal: 15,
          paddingVertical: 7,
          backgroundColor: theme.color.gray800,
          gap: 15,
        }}
        row
      >
        <Image
          source={getMuxThumbnail(muxUrl, {
            format: 'jpg',
            height: 46,
            width: 46,
            getPixelSizeForLayoutSize: PixelRatio.getPixelSizeForLayoutSize,
          })}
          style={{ width: 46, height: 46, borderRadius: 5 }}
        />
        <View>
          <Text text="Want to learn more?" size={15} color={theme.color.gray300} />
          <Button
            text="Watch a clinician explain"
            icon="play"
            onPress={() => setShowVideo(true)}
            size="small"
            variant="text"
          />
        </View>
      </View>
    );
  }

  return (
    <>
      {ui}
      <Modal visible={showVideo} onRequestClose={() => setShowVideo(false)}>
        <SystemBars style="light" />
        <View
          style={{
            paddingTop: insets.top,
            backgroundColor: theme.color.gray100,
            flex: 1,
          }}
        >
          <View row style={{ justifyContent: 'space-between' }}>
            <HeaderButtons left>
              <HeaderItem
                aria-label="Close"
                iconName="close"
                title=""
                color="white"
                onPress={() => setShowVideo(false)}
              />
            </HeaderButtons>
            <Label text="Dr. Bryan" color="white" />
            <View style={{ opacity: 0 }} aria-hidden>
              <HeaderButtons>
                <HeaderItem aria-label="Close" iconName="close" title="" color="white" />
              </HeaderButtons>
            </View>
          </View>
          <View
            style={{
              paddingTop: 48,
              paddingBottom: insets.bottom,
              paddingHorizontal: 20,
            }}
          >
            <CardStack
              hideNextButtonIndexes
              // hide pagination UI
              hasCompleteCard
            >
              <Card>
                <View style={{ margin: -25, marginTop: -26, borderRadius: 15, overflow: 'hidden' }}>
                  {/* Don't mount video until the modal is showing otherwise it will autoplay when not visible */}
                  {showVideo ? (
                    <MediaPlayer
                      _enableSkipToEnd={global.e2e}
                      // detox has trouble synchronizing on android when video autoplays... so a workaround is to
                      // disable autoplay in this case
                      autoPlay={!(global.e2e && Platform.OS === 'android')}
                      uri={muxUrl}
                      onClose={undefined}
                      aspectRatio={9 / 16}
                      resizeMode="cover"
                    />
                  ) : null}
                </View>
              </Card>
            </CardStack>
          </View>
        </View>
      </Modal>
    </>
  );
};
