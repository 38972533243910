/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={16} height={18} viewBox="0 0 16 18" fill="none" {...props}>
    <Path
      d="M12.5 8.75V4.93M8.08 2.97C8.04 2.32 7.36 1.8 6.7 1.8h-.1c-.65 0-.78.54-.78 1.19l-.15 7.54-2.45-2.29c-.61 0-.9.47-1.1 1.05-.13.4-.19.84 0 1.22.7 1.4 1.97 2.78 2.53 3.06.69.35 2.02 1.02 2.56 2.28.22.5.71 1.16 1.36 1.16h5.11c.65 0 1.18-.53 1.18-1.18V5.34c0-.66-.34-1.19-.99-1.19-.43 0-1.32.35-1.37.78M8.08 2.97v5.19m0-5.19V1.8c.05-.43.63-.84 1.18-.78.6.06.98.46.98 1.11v1.04m2.26 1.77V3.56c0-.66-.43-1.18-1.08-1.18-.6 0-1.13.35-1.18.78m0 0v4.6"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
