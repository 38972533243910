/* This file is autogenerated by @oui/cli */
import Svg, { Ellipse, Path, Rect, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Rect
      x={1}
      y={1}
      width={16}
      height={16}
      rx={2}
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="m1 13.8 3.2-3.2L7.4 13l4.8-5.6 4.8 4.8V14a3 3 0 0 1-3 3H4a3 3 0 0 1-3-3v-.2Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Ellipse cx={5.8} cy={5.8} rx={1.6} ry={1.6} fill="currentColor" />
  </Svg>
);
export default SvgComponent;
