/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={24} height={21} viewBox="0 0 24 21" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 0a3 3 0 0 0-3 3v14.25a3 3 0 0 0 3 3h17.89a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3Zm7.91 8.63H8.98v-.66H6.41v3.98h2.57v-.67h1.93v1.33a1.3 1.3 0 0 1-1.28 1.33H5.77a1.3 1.3 0 0 1-1.29-1.33v-5.3c0-.74.58-1.33 1.29-1.33h3.86c.7 0 1.28.6 1.28 1.32v1.33Zm9 0h-1.93v-.66h-2.57v3.98h2.57v-.67h1.93v1.33a1.3 1.3 0 0 1-1.29 1.33h-3.85a1.3 1.3 0 0 1-1.29-1.33v-5.3c0-.74.57-1.33 1.29-1.33h3.85c.72 0 1.29.6 1.29 1.32v1.33Z"
      fill="currentColor"
    />
  </Svg>
);
export default SvgComponent;
