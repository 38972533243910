import type { RouteProp } from '@react-navigation/core';
import type { StackNavigationProp } from '@react-navigation/stack';

import {
  BooleanParam,
  CoreRootStackParamList,
  DeeplinkConfigShape,
} from '@oui/app-core/src/types/navigation';

export type HopeKitStackParamList = {
  AddHopeKit: { method: 'gallery' | 'imageSearch' | 'quote' };
  HopeKit: { showAddMenu?: BooleanParam; showUploadFailure?: BooleanParam } | undefined;
};

export const HOPE_KIT_DEEPLINK_CONFIG = {
  AddHopeKit: 'hope-kit/add',
  HopeKit: 'hope-kit',
} as const satisfies DeeplinkConfigShape<keyof HopeKitStackParamList>;

type FullStackParamList = HopeKitStackParamList & CoreRootStackParamList;

export type StackScreenProps<T extends keyof HopeKitStackParamList, Other extends object = {}> = {
  navigation: StackNavigationProp<FullStackParamList, T>;
  route: RouteProp<FullStackParamList, T>;
} & Other;

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace ReactNavigation {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface RootParamList extends FullStackParamList {}
  }
}
