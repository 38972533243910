/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={17} height={18} viewBox="0 0 17 18" fill="none" {...props}>
    <Path
      d="M5.01 17s.1-2.31-1.88-4.63C1.87 10.88.92 8.84 1.01 6.87v-.15c0-2.46 1.7-4.36 3.9-5.16 1.07-.4 1.86-.51 2.5-.55.28-.02.53 0 .72.01 4.8.4 6.27 3.72 6.08 5.17-.12.86-.05 1.33.1 1.8.25.78 1.44 1.48.96 2.17-.22.33-.79.27-.92.69-.25.73-.05 1.56-.16 2.36-.12.93-.18.98-.34 1.39-.22.58-.74 1.02-1.68.94-.58-.05-1.09.1-1.19.43-.04.17-.12.75-.12 1.03"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 5.51c0-.37.3-.59.65-.48l2.74.82v5.25l-2.74-.82a.97.97 0 0 1-.65-.87V5.5Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.28 5.51c0-.37-.3-.59-.65-.48l-2.74.82v5.25l2.74-.82c.36-.1.65-.5.65-.87V5.5Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
