/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={16} viewBox="0 0 18 16" fill="none" {...props}>
    <Path
      d="M1.82 7.27c.28 0 1.48.41 2.96 1.14 1.48.74 3.36 1.92 5.8 1.15 2.08-.55 4.16-3.11 4.16-3.11s.38-1.06.26-3.67c.52-1.37 1.64-.57 1.9-.31.1.1.28.64-.44 1.6-.72.96-.12 2.74-.12 2.74s1.28 2.88-2.12 5.58c-2.58 2.05-5.64 1.88-5.64 1.88s-4.97.52-7.28-5.08c-.64-1.55-.16-1.92.52-1.92Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
