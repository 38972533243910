// This file is automatically generated. See gqlcodegen.yml for details
import * as CustomScalars from "./scalars";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  Any: { input: CustomScalars.GQLAny; output: CustomScalars.GQLAny; }
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  ArtifactID: { input: CustomScalars.GQLArtifactID; output: CustomScalars.GQLArtifactID; }
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  AssetID: { input: CustomScalars.GQLAssetID; output: CustomScalars.GQLAssetID; }
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: CustomScalars.GQLDate; output: CustomScalars.GQLDate; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: CustomScalars.GQLDateTime; output: CustomScalars.GQLDateTime; }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: CustomScalars.GQLJSONObject; output: CustomScalars.GQLJSONObject; }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  Map: { input: CustomScalars.GQLMap; output: CustomScalars.GQLMap; }
  QuizSetItemQuestionDirectiveFeedback: { input: CustomScalars.GQLQuizSetItemQuestionDirectiveFeedback; output: CustomScalars.GQLQuizSetItemQuestionDirectiveFeedback; }
  QuizSetItemQuestionLabel: { input: CustomScalars.GQLQuizSetItemQuestionLabel; output: CustomScalars.GQLQuizSetItemQuestionLabel; }
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: { input: CustomScalars.GQLUUID; output: CustomScalars.GQLUUID; }
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UserID: { input: CustomScalars.GQLUserID; output: CustomScalars.GQLUserID; }
  YstMentalHealthConcerns: { input: CustomScalars.YstMentalHealthConcerns; output: CustomScalars.YstMentalHealthConcerns; }
  YstOnboardingAvailability: { input: CustomScalars.YstOnboardingAvailability; output: CustomScalars.YstOnboardingAvailability; }
  YstRecurringAvailability: { input: CustomScalars.YstRecurringAvailability; output: CustomScalars.YstRecurringAvailability; }
};

export type AccessLog = {
  accessLogID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['JSONObject']['output'];
  entityIDs: Array<Scalars['UUID']['output']>;
  entityType: EntityType;
  requestID: Scalars['String']['output'];
  user: Maybe<Person>;
};

export type Action = {
  actionID: Scalars['UUID']['output'];
  actionValues: ActionValues;
};

export type ActionCount = {
  actionType: ActionType;
  count: Scalars['Int']['output'];
};

export type ActionProgress = {
  completed: Scalars['Int']['output'];
  date: Scalars['Date']['output'];
  total: Scalars['Int']['output'];
};

export type ActionTodos = {
  completed: Array<Action>;
  todo: Array<PendingAction>;
};

export const ActionType = {
  ACTIVITY_ADD: 'ACTIVITY_ADD',
  ACTIVITY_RATE: 'ACTIVITY_RATE',
  BREATH_HOLDING: 'BREATH_HOLDING',
  COPING_CARD_ADD: 'COPING_CARD_ADD',
  COPING_CARD_REVIEW: 'COPING_CARD_REVIEW',
  EATING_LOG_ADD: 'EATING_LOG_ADD',
  HOPE_KIT_ADD: 'HOPE_KIT_ADD',
  HOPE_KIT_REVIEW: 'HOPE_KIT_REVIEW',
  HYPERVENTILATION: 'HYPERVENTILATION',
  MY_PLAN_REVIEW: 'MY_PLAN_REVIEW',
  RELAX: 'RELAX',
  SLEEP_DIARY_ENTRY_MORNING: 'SLEEP_DIARY_ENTRY_MORNING',
  SLEEP_DIARY_ENTRY_NIGHT: 'SLEEP_DIARY_ENTRY_NIGHT',
  STATIC_01: 'STATIC_01',
  STATIC_02: 'STATIC_02',
  STATIC_03: 'STATIC_03',
  STATIC_04: 'STATIC_04',
  STATIC_05: 'STATIC_05',
  STATIC_06: 'STATIC_06',
  STATIC_07: 'STATIC_07',
  STATIC_08: 'STATIC_08',
  STATIC_09: 'STATIC_09',
  STATIC_10: 'STATIC_10',
  STATIC_11: 'STATIC_11',
  STATIC_12: 'STATIC_12',
  THOUGHT_DIARY_SPOT: 'THOUGHT_DIARY_SPOT',
  THOUGHT_DIARY_SWITCH: 'THOUGHT_DIARY_SWITCH',
  THOUGHT_DIARY_TEST: 'THOUGHT_DIARY_TEST',
  WORD_PAIRING: 'WORD_PAIRING'
} as const;

export type ActionType = typeof ActionType[keyof typeof ActionType];
export type ActionValues = {
  role: Role;
  timestamp: Scalars['DateTime']['output'];
};

export type ActionValuesInput = {
  patientID: Scalars['UUID']['input'];
};

export type Activity = {
  attendee: Maybe<Scalars['String']['output']>;
  endTime: Scalars['DateTime']['output'];
  location: Maybe<Scalars['String']['output']>;
  notes: Maybe<Scalars['String']['output']>;
  startTime: Scalars['DateTime']['output'];
  title: Scalars['String']['output'];
};

export type ActivityAddAction = Action & {
  actionID: Scalars['UUID']['output'];
  actionValues: ActionValues;
  activityPractice: ActivityPractice;
};

export type ActivityDiaryWidget = Widget & {
  ID: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  iconName: Scalars['String']['output'];
  locked: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  practiceCount: Scalars['Int']['output'];
  practiceTileColor: Scalars['String']['output'];
  practiceTileDeeplink: Scalars['String']['output'];
  unlockSession: Maybe<RoleSession>;
};

export type ActivityInput = {
  attendee?: InputMaybe<Scalars['String']['input']>;
  endTime: Scalars['DateTime']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  startTime: Scalars['DateTime']['input'];
  title: Scalars['String']['input'];
};

export type ActivityPractice = Practice & {
  activity: Activity;
  practiceID: Scalars['UUID']['output'];
  practiceValues: PracticeValues;
};

export type ActivityRateAction = Action & {
  actionID: Scalars['UUID']['output'];
  actionValues: ActionValues;
  activityPractice: ActivityPractice;
};

export type AddActionInput = {
  actionType: ActionType;
  actionValues: ActionValuesInput;
  practiceID?: InputMaybe<Scalars['UUID']['input']>;
};

export type AddActionPayload = {
  action: Action;
};

export type AddActivityPracticeInput = {
  activity: ActivityInput;
  practiceValues: PracticeValuesInput;
};

export type AddActivityPracticePayload = {
  activityPractice: ActivityPractice;
};

export type AddEatingLogEntryInput = {
  eatingLogEntry: EatingLogEntryInput;
  practiceValues: PracticeValuesInput;
};

export type AddHopeKitImageInput = {
  hopeKitImage: HopeKitImageInput;
  uploadContentType: Scalars['String']['input'];
};

export type AddHopeKitImagePayload = {
  hopeKitImage: HopeKitImage;
  uploadSession: UploadSession;
};

export type AddHopeKitQuoteInput = {
  hopeKitQuote: HopeKitQuoteInput;
};

export type AddHopeKitQuotePayload = {
  hopeKitQuote: HopeKitQuote;
};

export type AddHopeKitVideoInput = {
  hopeKitVideo: HopeKitVideoInput;
  uploadContentType: Scalars['String']['input'];
};

export type AddHopeKitVideoPayload = {
  hopeKitVideo: HopeKitVideo;
  uploadSession: UploadSession;
};

export type AddRelaxDiaryEntryPracticeInput = {
  practiceValues: PracticeValuesInput;
  relaxDiaryEntry: RelaxDiaryEntryInput;
};

export type AddRelaxDiaryEntryPracticePayload = {
  relaxDiaryEntryPractice: RelaxDiaryEntryPractice;
};

export type AddSleepDiaryEntryPracticeInput = {
  practiceValues: PracticeValuesInput;
  sleepDiaryEntry: SleepDiaryEntryInput;
};

export type AddSleepDiaryEntryPracticePayload = {
  sleepDiaryEntryPractice: SleepDiaryEntryPractice;
};

export type AddThoughtDiaryEntryPracticeInput = {
  practiceValues: PracticeValuesInput;
  thoughtDiaryEntry: ThoughtDiaryEntryInput;
};

export type AddThoughtDiaryEntryPracticePayload = {
  thoughtDiaryEntryPractice: ThoughtDiaryEntryPractice;
};

export type Address = {
  city: Maybe<Scalars['String']['output']>;
  line: Maybe<Array<Scalars['String']['output']>>;
  postalCode: Maybe<Scalars['String']['output']>;
  state: Maybe<Scalars['String']['output']>;
  use: AddressUse;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  line?: InputMaybe<Array<Scalars['String']['input']>>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  rank?: Scalars['Int']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  use: AddressUse;
};

export const AddressUse = {
  HOME: 'HOME',
  OLD: 'OLD',
  TEMP: 'TEMP',
  WORK: 'WORK'
} as const;

export type AddressUse = typeof AddressUse[keyof typeof AddressUse];
export type AppActivity = {
  name: AppActivityName;
  params: Maybe<Scalars['JSONObject']['output']>;
};

export const AppActivityName = {
  ACTIVITYDIARY: 'ActivityDiary',
  BINGEEATINGMYPLAN: 'BingeEatingMyPlan',
  BREATHHOLDING: 'BreathHolding',
  COPINGCARDS: 'CopingCards',
  CRISISFRAMEWORK: 'CrisisFramework',
  EATINGLOG: 'EatingLog',
  EDITEATINGSCHEDULE: 'EditEatingSchedule',
  HOPEKIT: 'HopeKit',
  HYPERVENTILATION: 'Hyperventilation',
  MYPLANREVIEW: 'MyPlanReview',
  RELAXATION: 'Relaxation',
  RISKCURVE: 'RiskCurve',
  SLEEPDIARY: 'SleepDiary',
  SOLOMYPLAN: 'SoloMyPlan',
  SOLORISKCURVE: 'SoloRiskCurve',
  SOLOSUICIDEMODE: 'SoloSuicideMode',
  STRESSSENSITIVITYASSESSMENT: 'StressSensitivityAssessment',
  SUICIDEMODE: 'SuicideMode',
  THOUGHTDIARY: 'ThoughtDiary',
  WORDPAIRING: 'WordPairing'
} as const;

export type AppActivityName = typeof AppActivityName[keyof typeof AppActivityName];
export type AppVersion = {
  recommendedMinimumVersion: Scalars['String']['output'];
  requiredMinimumVersion: Scalars['String']['output'];
};

export type Artifact = {
  badge: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type Asset = {
  assetID: Scalars['AssetID']['output'];
  context: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  key: Scalars['String']['output'];
  userID: Scalars['String']['output'];
};

export type AvivaProfile = {
  productVariant: ProductVariant;
};

export type BaseError = {
  message: Scalars['String']['output'];
};

export type BingeEatingMyPlanWidget = Widget & {
  ID: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  iconName: Scalars['String']['output'];
  locked: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  practiceCount: Scalars['Int']['output'];
  practiceTileColor: Scalars['String']['output'];
  practiceTileDeeplink: Scalars['String']['output'];
  unlockSession: Maybe<RoleSession>;
};

export type BotHistory = {
  config: Scalars['String']['output'];
  createdAt: Scalars['Int']['output'];
  envelope: Scalars['Map']['output'];
  occurredAt: Scalars['DateTime']['output'];
};

export type BotState = {
  botStateID: Scalars['UUID']['output'];
  config: Scalars['String']['output'];
  createdAt: Scalars['Int']['output'];
  occurredAt: Scalars['DateTime']['output'];
  state: Scalars['String']['output'];
};

export type BreathHoldingAction = Action & {
  actionID: Scalars['UUID']['output'];
  actionValues: ActionValues;
};

export type BreathHoldingPractice = Practice & {
  practiceID: Scalars['UUID']['output'];
  practiceValues: PracticeValues;
  stressBreathingExercise: StressBreathingExercise;
};

export type BreathHoldingWidget = Widget & {
  ID: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  iconName: Scalars['String']['output'];
  locked: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  practiceCount: Scalars['Int']['output'];
  practiceTileColor: Scalars['String']['output'];
  practiceTileDeeplink: Scalars['String']['output'];
  stressBreathingExerciseByID: Maybe<BreathHoldingPractice>;
  stressBreathingExercises: Array<BreathHoldingPractice>;
  unlockSession: Maybe<RoleSession>;
};


export type BreathHoldingWidgetStressBreathingExerciseByIdArgs = {
  practiceID: Scalars['UUID']['input'];
};


export type BreathHoldingWidgetStressBreathingExercisesArgs = {
  input: BreathHoldingWidgetStressBreathingExercisesInput;
};

export type BreathHoldingWidgetStressBreathingExercisesInput = {
  after?: InputMaybe<Scalars['Date']['input']>;
  before?: InputMaybe<Scalars['Date']['input']>;
  /** Return up to this many most recent stressBreathing practices */
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type CmsApp = {
  appID: Scalars['UUID']['output'];
  logo: FileUpload;
  logoID: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  variants: Array<CmsVariant>;
};

export type CmsAppCreateInput = {
  logoID: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
};

export type CmsAppUpdateInput = {
  appID: Scalars['UUID']['input'];
  logoID?: InputMaybe<Scalars['UUID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CmsExchange = {
  content: Scalars['JSONObject']['output'];
  exchangeID: Scalars['UUID']['output'];
  lastPatchVersion: Scalars['Int']['output'];
  name: Maybe<Scalars['String']['output']>;
};

export type CmsSession = {
  agenda: Array<Scalars['String']['output']>;
  completedIllustration: Maybe<FileUpload>;
  content: Maybe<SessionContentForApp>;
  contentType: Scalars['String']['output'];
  description: Scalars['String']['output'];
  group: Maybe<CmsSessionGroup>;
  groupID: Maybe<Scalars['UUID']['output']>;
  illustration: Maybe<FileUpload>;
  locale: Scalars['String']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  /** The ordinal number of this Session within the Variant (1-based) */
  ordinal: Scalars['Int']['output'];
  primaryExchange: CmsExchange;
  sessionID: Scalars['UUID']['output'];
  variant: CmsVariant;
  variantID: Scalars['UUID']['output'];
};


export type CmsSessionContentArgs = {
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type CmsSessionCreateInput = {
  /** Up to 3 agenda items, each up to 30 characters */
  agenda?: InputMaybe<Array<Scalars['String']['input']>>;
  completedIllustrationID?: InputMaybe<Scalars['UUID']['input']>;
  content?: InputMaybe<Scalars['JSONObject']['input']>;
  contentType: Scalars['String']['input'];
  description: Scalars['String']['input'];
  groupID: Scalars['UUID']['input'];
  illustrationID?: InputMaybe<Scalars['UUID']['input']>;
  locale: Scalars['String']['input'];
  name: Scalars['String']['input'];
  order: Scalars['Int']['input'];
  variantID: Scalars['UUID']['input'];
};

export type CmsSessionGroup = {
  groupID: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  sessions: Array<CmsSession>;
  variant: CmsVariant;
  variantID: Scalars['UUID']['output'];
};

export type CmsSessionGroupCreateInput = {
  name: Scalars['String']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  variantID: Scalars['UUID']['input'];
};

export type CmsSessionGroupUpdateInput = {
  groupID: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

export type CmsVariant = {
  app: CmsApp;
  appID: Scalars['UUID']['output'];
  groups: Array<CmsSessionGroup>;
  name: Scalars['String']['output'];
  sessions: Array<CmsSession>;
  variantID: Scalars['UUID']['output'];
  version: Scalars['String']['output'];
};

export type CmsVariantCreateInput = {
  appID: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  version: Scalars['String']['input'];
};

export type CmsVariantUpdateInput = {
  appID?: InputMaybe<Scalars['UUID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  variantID: Scalars['UUID']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};

export type CareTeam = {
  ID: Scalars['UUID']['output'];
  organizations: Array<Organization>;
  participants: Array<CareTeamEntity>;
  patient: Patient;
  practitioners: Array<Practitioner>;
  primaryPractitioner: Maybe<Practitioner>;
};

export type CareTeamEntity = Organization | Practitioner;

export const CaringContactsMedium = {
  EMAIL: 'EMAIL',
  MAIL: 'MAIL',
  NONE: 'NONE',
  SMS: 'SMS'
} as const;

export type CaringContactsMedium = typeof CaringContactsMedium[keyof typeof CaringContactsMedium];
export type ChangeBiteRoleSettings = {
  sessionUnlockDayOfWeek: Maybe<Scalars['Int']['output']>;
};

export type ChangeBiteRoleSettingsInput = {
  sessionUnlockDayOfWeek?: InputMaybe<Scalars['Int']['input']>;
};

export type CompleteQuizSetForPatientInput = {
  patientID: Scalars['UUID']['input'];
  quizSetID: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

export type CompletedPatientQuizSet = {
  createdAt: Scalars['DateTime']['output'];
  quizSetCompletionID: Scalars['UUID']['output'];
  quizSetID: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CompletedPatientQuizSets = {
  completedQuizSets: Array<CompletedPatientQuizSet>;
  patientID: Scalars['UUID']['output'];
};

export type CompletedQuizSetsForPatientInput = {
  patientID: Scalars['UUID']['input'];
  slug: Scalars['String']['input'];
};

export type Composition = {
  ID: Scalars['UUID']['output'];
  author: Person;
  json: Scalars['Map']['output'];
  patient: Patient;
  sections: Array<CompositionSection>;
  title: Maybe<Scalars['String']['output']>;
};

export type CompositionSection = {
  ID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  json: Maybe<Scalars['Any']['output']>;
  text: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export const CompositionTemplate = {
  BINGE_EATING_MY_PLAN: 'BINGE_EATING_MY_PLAN',
  EATING_COMMITMENT: 'EATING_COMMITMENT',
  EATING_SCHEDULE: 'EATING_SCHEDULE',
  MYSTORYMYPLAN: 'MYSTORYMYPLAN',
  MYSTORYMYPLAN_CLINICAL: 'MYSTORYMYPLAN_CLINICAL',
  SCREENER_CSSRS: 'SCREENER_CSSRS',
  SCREENER_NUMERIC: 'SCREENER_NUMERIC',
  STRESS_SENSITIVITY_ASSESSMENT: 'STRESS_SENSITIVITY_ASSESSMENT',
  TEST: 'TEST',
  YST_MENTAL_HEALTH_CONCERNS: 'YST_MENTAL_HEALTH_CONCERNS'
} as const;

export type CompositionTemplate = typeof CompositionTemplate[keyof typeof CompositionTemplate];
export type ContactPoint = {
  ID: Scalars['UUID']['output'];
  system: Maybe<Scalars['String']['output']>;
  use: Maybe<Scalars['String']['output']>;
  value: Maybe<Scalars['String']['output']>;
};

export type ContactPointInput = {
  rank?: Scalars['Int']['input'];
  system: ContactPointSystem;
  use: ContactPointUse;
  value: Scalars['String']['input'];
};

export const ContactPointSystem = {
  EMAIL: 'EMAIL',
  FAX: 'FAX',
  OTHER: 'OTHER',
  PAGER: 'PAGER',
  PHONE: 'PHONE',
  SMS: 'SMS',
  URL: 'URL'
} as const;

export type ContactPointSystem = typeof ContactPointSystem[keyof typeof ContactPointSystem];
export const ContactPointUse = {
  HOME: 'HOME',
  MOBILE: 'MOBILE',
  OLD: 'OLD',
  TEMP: 'TEMP',
  WORK: 'WORK'
} as const;

export type ContactPointUse = typeof ContactPointUse[keyof typeof ContactPointUse];
export const ContentType = {
  ACTIVITY_PLANNING: 'ACTIVITY_PLANNING',
  ANXIETY_CAUSES: 'ANXIETY_CAUSES',
  BODY_IMAGE: 'BODY_IMAGE',
  BREATH_HOLDING: 'BREATH_HOLDING',
  COPING_CARDS: 'COPING_CARDS',
  EATING_THE_RIGHT_FOODS: 'EATING_THE_RIGHT_FOODS',
  ENVIRONMENT_SAFETY: 'ENVIRONMENT_SAFETY',
  EXERCISE_AND_SLEEP: 'EXERCISE_AND_SLEEP',
  HOPE_KIT: 'HOPE_KIT',
  HOW_TO_HELP: 'HOW_TO_HELP',
  HYPERVENTILATION: 'HYPERVENTILATION',
  MOOD_MODULATION: 'MOOD_MODULATION',
  MOOD_TOLERANCE: 'MOOD_TOLERANCE',
  MYPLAN: 'MYPLAN',
  MYPLAN_REVIEW: 'MYPLAN_REVIEW',
  MYSTORYMYPLAN: 'MYSTORYMYPLAN',
  OVEREATING_CYCLE: 'OVEREATING_CYCLE',
  POST_AVIVA: 'POST_AVIVA',
  REDUCE_RISK: 'REDUCE_RISK',
  REGULAR_EATING: 'REGULAR_EATING',
  RELAPSE_PREVENTION: 'RELAPSE_PREVENTION',
  RELAXATION: 'RELAXATION',
  REMOVING_BARRIERS: 'REMOVING_BARRIERS',
  REVIEW_PREVENT_PRACTICE: 'REVIEW_PREVENT_PRACTICE',
  SLEEP: 'SLEEP',
  SOCIAL_SUPPORT: 'SOCIAL_SUPPORT',
  SPOT_IT: 'SPOT_IT',
  STATIC_01: 'STATIC_01',
  STATIC_02: 'STATIC_02',
  STATIC_03: 'STATIC_03',
  STATIC_04: 'STATIC_04',
  STATIC_05: 'STATIC_05',
  STATIC_06: 'STATIC_06',
  STATIC_07: 'STATIC_07',
  STATIC_08: 'STATIC_08',
  STATIC_09: 'STATIC_09',
  STATIC_10: 'STATIC_10',
  STATIC_11: 'STATIC_11',
  STATIC_12: 'STATIC_12',
  SWITCH_IT: 'SWITCH_IT',
  TEST: 'TEST',
  TEST_IT: 'TEST_IT',
  THINK_CLEAR: 'THINK_CLEAR',
  URGE_SURFING: 'URGE_SURFING',
  V1_SESSION_01: 'V1_SESSION_01',
  V1_SESSION_02: 'V1_SESSION_02',
  V1_SESSION_03: 'V1_SESSION_03',
  V1_SESSION_04: 'V1_SESSION_04',
  V1_SESSION_05: 'V1_SESSION_05',
  V1_SESSION_06: 'V1_SESSION_06',
  V1_SESSION_06P: 'V1_SESSION_06P',
  V1_SESSION_07: 'V1_SESSION_07',
  V1_SESSION_07P: 'V1_SESSION_07P',
  V1_SESSION_08: 'V1_SESSION_08',
  V1_SESSION_08P: 'V1_SESSION_08P',
  V1_SESSION_09: 'V1_SESSION_09',
  V1_SESSION_09P: 'V1_SESSION_09P',
  V1_SESSION_10: 'V1_SESSION_10',
  V1_SESSION_10P: 'V1_SESSION_10P',
  V1_WELCOME: 'V1_WELCOME',
  WEIGHT_STIGMA: 'WEIGHT_STIGMA',
  WORD_PAIRING: 'WORD_PAIRING'
} as const;

export type ContentType = typeof ContentType[keyof typeof ContentType];
export type CopingCardAddAction = Action & {
  actionID: Scalars['UUID']['output'];
  actionValues: ActionValues;
};

export type CopingCardReviewAction = Action & {
  actionID: Scalars['UUID']['output'];
  actionValues: ActionValues;
};

export type CopingCardsWidget = Widget & {
  ID: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  iconName: Scalars['String']['output'];
  locked: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  practiceCount: Scalars['Int']['output'];
  practiceTileColor: Scalars['String']['output'];
  practiceTileDeeplink: Scalars['String']['output'];
  unlockSession: Maybe<RoleSession>;
};

export type CopyQuizSetToCollectionInput = {
  quizSetCollectionID: Scalars['UUID']['input'];
  quizSetID: Scalars['UUID']['input'];
};

export type CopySessionInput = {
  destination: CopySessionInputDestination;
  sessionID: Scalars['UUID']['input'];
};

export type CopySessionInputDestination = {
  groupID: Scalars['UUID']['input'];
  variantID: Scalars['UUID']['input'];
};

export type CreateRoleInput = {
  organizationID: Scalars['UUID']['input'];
  /** Only valid for PATIENT roles */
  practitioners?: InputMaybe<Array<RolePractitionerInput>>;
  productConfig?: InputMaybe<RoleProductConfigInput>;
  productID: Scalars['UUID']['input'];
  productStatic: Scalars['Boolean']['input'];
  roleType: RoleType;
  supporteeID?: InputMaybe<Scalars['UUID']['input']>;
  supporteeRelation?: InputMaybe<PatientSupporterRelation>;
};

export type CreateTestScenarioResult = {
  organization: Organization;
  users: Array<User>;
};

export type CreateUserInput = {
  addresses?: InputMaybe<Array<AddressInput>>;
  birthDate?: InputMaybe<Scalars['Date']['input']>;
  email: Scalars['String']['input'];
  familyName?: InputMaybe<Scalars['String']['input']>;
  genderIdentity?: InputMaybe<UserGenderIdentity>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  militaryStatus?: InputMaybe<UserMilitaryStatus>;
  phone?: InputMaybe<Scalars['String']['input']>;
  race?: InputMaybe<UserRace>;
  settings?: InputMaybe<UserSettingsInput>;
  sexualOrientation?: InputMaybe<UserSexualOrientation>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export const CrisisFramework = {
  RISK_CURVE_TIMELINE: 'RISK_CURVE_TIMELINE',
  SUICIDE_MODE: 'SUICIDE_MODE'
} as const;

export type CrisisFramework = typeof CrisisFramework[keyof typeof CrisisFramework];
export type CurrentOuiUser = {
  attributes: OuiUserAttributes;
  createdAt: Scalars['DateTime']['output'];
  person: Person;
  primaryOrganization: Maybe<Organization>;
  pushNotificationsDisabled: Scalars['Boolean']['output'];
  role: OuiUserRoleType;
  roles: Array<OuiUserRole>;
  user: Maybe<OuiUserType>;
  userID: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type DeleteRoleInput = {
  roleID: Scalars['UUID']['input'];
};

export type DeleteSessionGroupInput = {
  groupID: Scalars['UUID']['input'];
};

export type DeleteSessionInput = {
  sessionID: Scalars['UUID']['input'];
};

export type DeletedRole = {
  ID: Scalars['UUID']['output'];
  product: Product;
  productStatic: Scalars['Boolean']['output'];
  roleLabel: Scalars['String']['output'];
  roleType: RoleType;
};

export type Device = {
  installationID: Scalars['UUID']['output'];
  platform: Scalars['String']['output'];
  pushActive: Maybe<Scalars['Boolean']['output']>;
};

export type DeviceInput = {
  expoToken?: InputMaybe<Scalars['String']['input']>;
  installationID: Scalars['UUID']['input'];
  platform: Scalars['String']['input'];
  pushToken?: InputMaybe<Scalars['String']['input']>;
};

export type EatingCheckin = {
  completionButtonClicked: Maybe<EatingCheckinCompletionButton>;
  completionTimestamp: Maybe<Scalars['DateTime']['output']>;
  mood: Maybe<Scalars['Int']['output']>;
  promptTimestamp: Scalars['DateTime']['output'];
  trigger: EatingCheckinTrigger;
  urgeLevel: Maybe<Scalars['Int']['output']>;
};

export const EatingCheckinCompletionButton = {
  DONE: 'DONE',
  SUGGESTION: 'SUGGESTION'
} as const;

export type EatingCheckinCompletionButton = typeof EatingCheckinCompletionButton[keyof typeof EatingCheckinCompletionButton];
export type EatingCheckinInput = {
  completionButtonClicked?: InputMaybe<EatingCheckinCompletionButton>;
  completionTimestamp?: InputMaybe<Scalars['DateTime']['input']>;
  mood?: InputMaybe<Scalars['Int']['input']>;
  promptTimestamp: Scalars['DateTime']['input'];
  /** Widget that prompted User to checkin */
  trigger: EatingCheckinTrigger;
  urgeLevel?: InputMaybe<Scalars['Int']['input']>;
};

export type EatingCheckinPractice = Practice & {
  eatingCheckin: EatingCheckin;
  practiceID: Scalars['UUID']['output'];
  practiceValues: PracticeValues;
};

export const EatingCheckinTrigger = {
  EATING_LOG: 'EATING_LOG',
  SESSION: 'SESSION'
} as const;

export type EatingCheckinTrigger = typeof EatingCheckinTrigger[keyof typeof EatingCheckinTrigger];
export type EatingCommitmentEntry = {
  decreaseCommitment: EatingCommitmentUnhealthyFood;
  decreaseSuccess: Maybe<Scalars['Boolean']['output']>;
  increaseCommitment: EatingCommitmentHealthyFood;
  increaseSuccess: Maybe<Scalars['Boolean']['output']>;
};

export type EatingCommitmentEntryInput = {
  decreaseCommitment: EatingCommitmentUnhealthyFood;
  decreaseSuccess?: InputMaybe<Scalars['Boolean']['input']>;
  increaseCommitment: EatingCommitmentHealthyFood;
  increaseSuccess?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EatingCommitmentEntryPractice = Practice & {
  eatingCommitmentEntry: EatingCommitmentEntry;
  practiceID: Scalars['UUID']['output'];
  practiceValues: PracticeValues;
};

export const EatingCommitmentHealthyFood = {
  FRUITS: 'FRUITS',
  GRAINS: 'GRAINS',
  HEALTHY_FATS: 'HEALTHY_FATS',
  LEAN_MEATS: 'LEAN_MEATS',
  OTHER: 'OTHER',
  VEGETABLES: 'VEGETABLES'
} as const;

export type EatingCommitmentHealthyFood = typeof EatingCommitmentHealthyFood[keyof typeof EatingCommitmentHealthyFood];
export const EatingCommitmentUnhealthyFood = {
  CANDY: 'CANDY',
  DESSERTS: 'DESSERTS',
  JUNK_FOOD: 'JUNK_FOOD',
  OTHER: 'OTHER',
  SODA: 'SODA',
  TAKEOUT: 'TAKEOUT'
} as const;

export type EatingCommitmentUnhealthyFood = typeof EatingCommitmentUnhealthyFood[keyof typeof EatingCommitmentUnhealthyFood];
export type EatingLogAddAction = Action & {
  actionID: Scalars['UUID']['output'];
  actionValues: ActionValues;
};

export type EatingLogEntry = {
  binged: Scalars['Boolean']['output'];
  description: Maybe<Scalars['String']['output']>;
  meal: EatingLogMealType;
  timestamp: Scalars['DateTime']['output'];
};

export type EatingLogEntryInput = {
  binged: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  meal: EatingLogMealType;
  timestamp: Scalars['DateTime']['input'];
};

export type EatingLogEntryPractice = Practice & {
  eatingLogEntry: EatingLogEntry;
  imageUploadUrl: Scalars['String']['output'];
  imageUrl: Maybe<Scalars['String']['output']>;
  practiceID: Scalars['UUID']['output'];
  practiceValues: PracticeValues;
};


export type EatingLogEntryPracticeImageUploadUrlArgs = {
  input: EatingLogEntryPracticeImageUploadUrlInput;
};

export type EatingLogEntryPracticeImageUploadUrlInput = {
  contentType: Scalars['String']['input'];
};

export const EatingLogMealType = {
  BREAKFAST: 'BREAKFAST',
  DINNER: 'DINNER',
  LUNCH: 'LUNCH',
  SNACK: 'SNACK'
} as const;

export type EatingLogMealType = typeof EatingLogMealType[keyof typeof EatingLogMealType];
export type EatingLogWidget = Widget & {
  ID: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  iconName: Scalars['String']['output'];
  locked: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  practiceCount: Scalars['Int']['output'];
  practiceTileColor: Scalars['String']['output'];
  practiceTileDeeplink: Scalars['String']['output'];
  unlockSession: Maybe<RoleSession>;
};

export type EatingProgressEntry = {
  binges: Maybe<Scalars['Int']['output']>;
  fadFixes: Maybe<Scalars['Int']['output']>;
  timestamp: Scalars['DateTime']['output'];
  weight: Maybe<Scalars['Float']['output']>;
  weightUnit: Maybe<EatingProgressWeightUnit>;
};

export type EatingProgressEntryInput = {
  binges?: InputMaybe<Scalars['Int']['input']>;
  fadFixes?: InputMaybe<Scalars['Int']['input']>;
  timestamp: Scalars['DateTime']['input'];
  weight?: InputMaybe<Scalars['Float']['input']>;
  weightUnit?: InputMaybe<EatingProgressWeightUnit>;
};

export type EatingProgressEntryPractice = Practice & {
  eatingProgressEntry: EatingProgressEntry;
  practiceID: Scalars['UUID']['output'];
  practiceValues: PracticeValues;
};

export const EatingProgressWeightUnit = {
  KG: 'KG',
  LBS: 'LBS'
} as const;

export type EatingProgressWeightUnit = typeof EatingProgressWeightUnit[keyof typeof EatingProgressWeightUnit];
export type EntityAccessLogRequestInput = {
  entityID: Scalars['UUID']['input'];
  entityType: EntityType;
};

export const EntityType = {
  ORGANIZATION: 'ORGANIZATION',
  ORGANIZATION_PRODUCT: 'ORGANIZATION_PRODUCT',
  ROLE: 'ROLE',
  USER: 'USER'
} as const;

export type EntityType = typeof EntityType[keyof typeof EntityType];
export const ErrorMessage = {
  CANCELED_REQUEST: 'CANCELED_REQUEST',
  DUPLICATE: 'DUPLICATE',
  EMAIL_IN_USE: 'EMAIL_IN_USE',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  INVALID_EMAIL: 'INVALID_EMAIL',
  NOT_FOUND: 'NOT_FOUND',
  UNAUTHORIZED: 'UNAUTHORIZED'
} as const;

export type ErrorMessage = typeof ErrorMessage[keyof typeof ErrorMessage];
export const ErrorStatus = {
  BAD_REQUEST: 'BAD_REQUEST',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  UNAUTHORIZED: 'UNAUTHORIZED'
} as const;

export type ErrorStatus = typeof ErrorStatus[keyof typeof ErrorStatus];
export type ExchangePatchInput = {
  exchangeID: Scalars['UUID']['input'];
  patches: Array<PatchInput>;
};

export type ExchangePatchResult = {
  exchange: CmsExchange;
  success: Scalars['Boolean']['output'];
};

export type ExchangePreview = {
  exchangeID: Scalars['String']['output'];
  name: Maybe<Scalars['String']['output']>;
};

export type FileUpload = {
  fileName: Scalars['String']['output'];
  fileUploadID: Scalars['UUID']['output'];
  fileUploadType: Scalars['String']['output'];
  gcsPath: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  video: Maybe<Video>;
};

export type FileUploadAction = {
  action: MediaAction;
  completedAt: Maybe<Scalars['String']['output']>;
  fileUploadID: Scalars['UUID']['output'];
  startedAt: Scalars['String']['output'];
};

export type FileUploadActionCompleteInput = {
  fileUploadId: Scalars['UUID']['input'];
  mediaAction: MediaAction;
};

export type FileUploadCreateInput = {
  fileName: Scalars['String']['input'];
  fileUploadType: FileUploadType;
};

export type FileUploadSession = {
  CMSUploadSessionURI: Scalars['String']['output'];
  fileUpload: FileUpload;
};

export const FileUploadType = {
  APP_LOGO: 'APP_LOGO',
  CHAT_IMAGE: 'CHAT_IMAGE',
  ILLUSTRATION: 'ILLUSTRATION',
  QUIZ_SET_VIDEO: 'QUIZ_SET_VIDEO',
  VIDEO: 'VIDEO',
  VIDEO_TEXT_TRACK: 'VIDEO_TEXT_TRACK'
} as const;

export type FileUploadType = typeof FileUploadType[keyof typeof FileUploadType];
export type HopeKitAddAction = Action & {
  actionID: Scalars['UUID']['output'];
  actionValues: ActionValues;
};

export type HopeKitImage = {
  hopeKitItemID: Scalars['UUID']['output'];
  reason: Maybe<Scalars['String']['output']>;
  staticUrl: Maybe<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
};

export type HopeKitImageInput = {
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type HopeKitItem = HopeKitImage | HopeKitQuote | HopeKitVideo;

export type HopeKitQuote = {
  author: Maybe<Scalars['String']['output']>;
  hopeKitItemID: Scalars['UUID']['output'];
  reason: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
};

export type HopeKitQuoteInput = {
  author?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
};

export type HopeKitReviewAction = Action & {
  actionID: Scalars['UUID']['output'];
  actionValues: ActionValues;
};

export type HopeKitVideo = {
  hopeKitItemID: Scalars['UUID']['output'];
  reason: Maybe<Scalars['String']['output']>;
  staticUrl: Maybe<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
};

export type HopeKitVideoInput = {
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type HopeKitWidget = Widget & {
  ID: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  iconName: Scalars['String']['output'];
  items: Array<HopeKitItem>;
  locked: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  practiceCount: Scalars['Int']['output'];
  practiceTileColor: Scalars['String']['output'];
  practiceTileDeeplink: Scalars['String']['output'];
  unlockSession: Maybe<RoleSession>;
};

export type HyperventilationAction = Action & {
  actionID: Scalars['UUID']['output'];
  actionValues: ActionValues;
};

export type HyperventilationPractice = Practice & {
  practiceID: Scalars['UUID']['output'];
  practiceValues: PracticeValues;
  stressBreathingExercise: StressBreathingExercise;
};

export type HyperventilationWidget = Widget & {
  ID: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  iconName: Scalars['String']['output'];
  locked: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  practiceCount: Scalars['Int']['output'];
  practiceTileColor: Scalars['String']['output'];
  practiceTileDeeplink: Scalars['String']['output'];
  stressBreathingExerciseByID: Maybe<HyperventilationPractice>;
  stressBreathingExercises: Array<HyperventilationPractice>;
  unlockSession: Maybe<RoleSession>;
};


export type HyperventilationWidgetStressBreathingExerciseByIdArgs = {
  practiceID: Scalars['UUID']['input'];
};


export type HyperventilationWidgetStressBreathingExercisesArgs = {
  input: HyperventilationWidgetStressBreathingExercisesInput;
};

export type HyperventilationWidgetStressBreathingExercisesInput = {
  after?: InputMaybe<Scalars['Date']['input']>;
  before?: InputMaybe<Scalars['Date']['input']>;
  /** Return up to this many most recent stressBreathing practices */
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IPatientProfile = {
  ID: Scalars['UUID']['output'];
  careTeam: CareTeam;
  devices: Array<Device>;
  emails: Array<UserEmail>;
  lastLogin: Maybe<Scalars['DateTime']['output']>;
  notifications: Array<PatientNotification>;
  patient: Patient;
  progress: Array<OuiProgress>;
  role: Role;
  supportees: Array<PatientSupporter>;
  supporters: Array<PatientSupporter>;
};


export type IPatientProfileSupportersArgs = {
  productVariant?: InputMaybe<ProductVariant>;
};

export type InvalidPasswordError = BaseError & {
  message: Scalars['String']['output'];
};

export type Job = {
  ID: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  result: Scalars['Any']['output'];
  scheduledAt: Maybe<Scalars['DateTime']['output']>;
  status: JobStatus;
  type: JobType;
  updatedAt: Scalars['DateTime']['output'];
};

export const JobStatus = {
  CREATED: 'CREATED',
  ERROR: 'ERROR',
  QUEUED: 'QUEUED',
  RUNNING: 'RUNNING',
  SUCCESS: 'SUCCESS'
} as const;

export type JobStatus = typeof JobStatus[keyof typeof JobStatus];
export const JobType = {
  AMPLITUDE_IMPORT: 'AMPLITUDE_IMPORT',
  CHANGEBITE_NOTIFICATIONS: 'CHANGEBITE_NOTIFICATIONS',
  CHECK_EXPO_PUSH_RECEIPTS: 'CHECK_EXPO_PUSH_RECEIPTS',
  DETOX_USER_CLEANUP: 'DETOX_USER_CLEANUP',
  EMAIL_USERS: 'EMAIL_USERS',
  ENQUEUE_JOBS: 'ENQUEUE_JOBS',
  EXPIRED_USER_SESSION_CLEANUP: 'EXPIRED_USER_SESSION_CLEANUP',
  GROUP_SESSIONS: 'GROUP_SESSIONS',
  INVITE_YST_NOMINEE: 'INVITE_YST_NOMINEE',
  LOG: 'LOG',
  MEAL_REMINDER: 'MEAL_REMINDER',
  MY_PLAN_UPDATED_SUPPORTER_NOTIFICATION: 'MY_PLAN_UPDATED_SUPPORTER_NOTIFICATION',
  NORMALIZE_AMPLITUDE_EVENT_SESSION_START: 'NORMALIZE_AMPLITUDE_EVENT_SESSION_START',
  NORMALIZE_USERS_PHONE: 'NORMALIZE_USERS_PHONE',
  OTX202_DATA_EXPORT: 'OTX202_DATA_EXPORT',
  PATIENTS_PROGRESS_STARTED_AT_FOR_PRODUCT_STATIC_BACKFILL: 'PATIENTS_PROGRESS_STARTED_AT_FOR_PRODUCT_STATIC_BACKFILL',
  PATIENT_CARING_CONTACTS: 'PATIENT_CARING_CONTACTS',
  PATIENT_NOTIFICATIONS: 'PATIENT_NOTIFICATIONS',
  PATIENT_SPOOL: 'PATIENT_SPOOL',
  PROCESS_VIDEO_ASSET: 'PROCESS_VIDEO_ASSET',
  PTSD_NOTIFICATIONS: 'PTSD_NOTIFICATIONS',
  SEND_EMAIL: 'SEND_EMAIL',
  SLEEP: 'SLEEP',
  SYNC_GCS_MEDIA_UPLOAD: 'SYNC_GCS_MEDIA_UPLOAD',
  TEST_RECORD_CLEANUP: 'TEST_RECORD_CLEANUP',
  UNLOCK_SESSIONS: 'UNLOCK_SESSIONS',
  YST_NEW_SIGNUP_EMAIL: 'YST_NEW_SIGNUP_EMAIL',
  YST_NOMINATION_REMINDER: 'YST_NOMINATION_REMINDER',
  YST_REPORTS: 'YST_REPORTS',
  YST_SUPPORTER: 'YST_SUPPORTER'
} as const;

export type JobType = typeof JobType[keyof typeof JobType];
export type KvResponse = {
  context: Scalars['String']['output'];
  key: Scalars['String']['output'];
  value: Maybe<Scalars['Any']['output']>;
};

export type LatLng = {
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
};

export type LatLngInput = {
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
};

export type LegacyQuizSetItem = LegacyQuizSetItemMultipleChoiceQuestion | LegacyQuizSetItemSingleChoiceQuestion | LegacyQuizSetItemVideo;

export type LegacyQuizSetItemMultipleChoiceQuestion = {
  dir: QuizSetItemQuestionDirective;
  props: QuizSetItemChoiceQuestionProps;
};

export type LegacyQuizSetItemSingleChoiceQuestion = {
  dir: QuizSetItemQuestionDirective;
  props: QuizSetItemChoiceQuestionProps;
};

export type LegacyQuizSetItemVideo = {
  props: LegacyQuizSetItemVideoProps;
};

export type LegacyQuizSetItemVideoProps = {
  /** @deprecated do not consume */
  compulsory: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated do not consume */
  title: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type LegacyUser = {
  ID: Scalars['UserID']['output'];
  address: Maybe<UserAddress>;
  demo: Maybe<UserDemo>;
  email: Scalars['String']['output'];
  name: UserName;
  phone: Scalars['String']['output'];
};

export const LegacyUserGender = {
  FEMALE: 'FEMALE',
  MALE: 'MALE',
  NOANSWER: 'NOANSWER',
  OTHER: 'OTHER'
} as const;

export type LegacyUserGender = typeof LegacyUserGender[keyof typeof LegacyUserGender];
export const LegacyUserRace = {
  ASIAN: 'ASIAN',
  BLACK: 'BLACK',
  NATIVE: 'NATIVE',
  NOANSWER: 'NOANSWER',
  OTHER: 'OTHER',
  PACIFIC: 'PACIFIC',
  WHITE: 'WHITE'
} as const;

export type LegacyUserRace = typeof LegacyUserRace[keyof typeof LegacyUserRace];
export const LegacyUserSexuality = {
  BISEXUAL: 'BISEXUAL',
  HETEROSEXUAL: 'HETEROSEXUAL',
  HOMOSEXUAL: 'HOMOSEXUAL',
  NOANSWER: 'NOANSWER'
} as const;

export type LegacyUserSexuality = typeof LegacyUserSexuality[keyof typeof LegacyUserSexuality];
export type LessonLearned = {
  createdAt: Maybe<Scalars['DateTime']['output']>;
  session: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export const MediaAction = {
  FILE_UPLOAD_TO_GCS: 'FILE_UPLOAD_TO_GCS',
  MUX_HOSTING: 'MUX_HOSTING',
  REV_CAPTIONING: 'REV_CAPTIONING'
} as const;

export type MediaAction = typeof MediaAction[keyof typeof MediaAction];
export type MostCompleted = {
  day: Scalars['Date']['output'];
  practiceCount: Scalars['Int']['output'];
  sessionCount: Scalars['Int']['output'];
};

export type Mutation = {
  acceptYstNomination: MutationAcceptYstNominationResult;
  addAction: AddActionPayload;
  addActivityPractice: AddActivityPracticePayload;
  addBreathHoldingPractice: BreathHoldingPractice;
  addEatingCheckin: MutationAddEatingCheckinResult;
  addEatingLogEntry: EatingLogEntryPractice;
  addEatingProgressEntry: MutationAddEatingProgressEntryResult;
  addHopeKitImage: AddHopeKitImagePayload;
  addHopeKitQuote: AddHopeKitQuotePayload;
  addHopeKitVideo: AddHopeKitVideoPayload;
  addHyperventilationPractice: HyperventilationPractice;
  addOrganization: Organization;
  addOrganizationMember: OrganizationMemberResult;
  addOrganizationProduct: OrganizationProduct;
  /** @deprecated Replaced by createUser() */
  addPatient: Patient;
  addPatientSupporter: PatientSupporter;
  addPractitioner: Maybe<Practitioner>;
  addPractitionerToCareTeam: CareTeam;
  addRelaxDiaryEntry: AddRelaxDiaryEntryPracticePayload;
  /** Add a sleep diary entry for a patient, throws error message "Sleep Diary Entry already exists" if an entry already exists for this date. */
  addSleepDiaryEntry: AddSleepDiaryEntryPracticePayload;
  addThoughtDiaryEntry: AddThoughtDiaryEntryPracticePayload;
  addWordPairingPractice: WordPairingPractice;
  addYstSupporterEvent: MutationAddYstSupporterEventResult;
  /** Updates a single fileUploadAction related to a given fileUpload object by setting the completedAt to current DateTime */
  completeFileUploadAction: FileUploadAction;
  completeQuizSetForPatient: PatientQuizSetCompleted;
  contactYstSupport: Scalars['Boolean']['output'];
  copyQuizSetToCollection: QuizSetCollection;
  copySession: CmsSession;
  createApp: CmsApp;
  /** initiates a resumable upload session, creates a fileUpload and returns a fileUpload and a session uri */
  createFileUpload: FileUploadSession;
  createJob: MutationCreateJobResult;
  createQuizSet: QuizSetCreatePayload;
  createQuizSetCollection: QuizSetCollectionCreatePayload;
  createQuizSetItemChoice: QuizSetItemCreatePayload;
  createQuizSetItemVideo: QuizSetItemCreatePayload;
  createSession: CmsSession;
  createSessionGroup: CmsSessionGroup;
  createTestScenario: MutationCreateTestScenarioResult;
  createTestUser: Maybe<Scalars['UserID']['output']>;
  createUser: User;
  createVariant: CmsVariant;
  createVideo: Video;
  deactivateUser: OuiUser;
  declineYstNomination: MutationDeclineYstNominationResult;
  deleteActivityPractice: Scalars['Boolean']['output'];
  deleteEatingLogEntry: Scalars['Boolean']['output'];
  deleteOrganizationProduct: OrganizationProduct;
  deleteQuizSet: QuizSetDeletePayload;
  deleteQuizSetItem: QuizSetItemDeletePayload;
  deleteSession: CmsSession;
  deleteSessionGroup: CmsSessionGroup;
  deleteThoughtDiaryEntry: Scalars['Boolean']['output'];
  inviteYSTGuardian: MutationInviteYstGuardianResult;
  inviteYSTYouth: MutationInviteYstYouthResult;
  issueToken: Token;
  issueTokenByEmail: Scalars['Boolean']['output'];
  /** Only used for e2e testing purposes. disabled in production */
  issueTokenForPractitioner: Token;
  /** Only used for e2e testing purposes. disabled in production */
  issueTokenForRegistrar: Token;
  issueTokenLinkForSupporter: SupporterToken;
  issueYSTYouthInviteLink: YstInvite;
  issueYSTYouthInviteMessage: Scalars['Boolean']['output'];
  kvRespond: KvResponse;
  /** @deprecated Prefer loginWithEmail */
  login: UserSession;
  loginWithEmail: MutationLoginWithEmailResult;
  loginWithToken: MutationLoginWithTokenResult;
  logoutDevice: Scalars['Boolean']['output'];
  newCompositionWithTemplate: Composition;
  /**
   * Manages the YstNominations for a given YST_YOUTH. See the input type comments to understand how
   * statuses are updated for nominations depending on which field they're included in.
   *
   * If after all updates are performed, the YST_YOUTH has all nominations APPROVED, then the nominations
   * are updated to INVITED and invites are sent to the nominees.
   */
  nominateYstSupporters: MutationNominateYstSupportersResult;
  patchExchange: ExchangePatchResult;
  persistBotState: Maybe<MutationPersistBotStateResult>;
  previewYstPatientMessage: MutationPreviewYstPatientMessageResult;
  rateYstSupporterCheckin: MutationRateYstSupporterCheckinResult;
  reauthenticate: Scalars['Boolean']['output'];
  removeHopeKitItem: RemoveHopeKitItemPayload;
  removePatientSupporter: PatientSupporter;
  removePractitionerFromCareTeam: CareTeam;
  replacePrimaryPractitioner: ReplacePrimaryPractitionerResult;
  resendYstNominationInvite: MutationResendYstNominationInviteResult;
  resendYstPatientMessage: MutationResendYstPatientMessageResult;
  /** @deprecated for transition use only, currently used by bot */
  respond: Scalars['Boolean']['output'];
  reviewPatientSupporter: PatientSupporter;
  rewindBotState: Maybe<BotState>;
  setCareTeamPrimaryPractitioner: CareTeam;
  /** Create or Update a singleton Composition for a Patient */
  setComposition: Composition;
  setDevice: Scalars['Boolean']['output'];
  setOuiProgress: OuiProgress;
  setOuiProgressForPatient: OuiProgress;
  setPatientSupporterRelation: PatientSupporter;
  setPushNotificationsDisabled: Maybe<CurrentOuiUser>;
  signupYSTGuardian: MutationSignupYstGuardianResult;
  signupYSTYouth: MutationSignupYstYouthResult;
  snapshotQuizSetCollection: QuizSetCollection;
  snapshotSession: CmsSession;
  softDeleteOutdatedBotHistory: Scalars['Boolean']['output'];
  throwError: Maybe<Scalars['Any']['output']>;
  throwPanic: Maybe<Scalars['Any']['output']>;
  updateActivityPractice: UpdateActivityPracticePayload;
  updateApp: CmsApp;
  updateBreathHoldingPractice: BreathHoldingPractice;
  updateComposition: Composition;
  /** @deprecated Use updateComposition.json instead */
  updateCompositionSection: CompositionSection;
  updateEatingCheckin: MutationUpdateEatingCheckinResult;
  updateEatingLogEntry: EatingLogEntryPractice;
  updateEatingProgressEntry: MutationUpdateEatingProgressEntryResult;
  updateFirstCheckinDate: MutationUpdateFirstCheckinDateResult;
  updateHopeKitImage: UpdateHopeKitImagePayload;
  updateHopeKitQuote: UpdateHopeKitQuotePayload;
  updateHopeKitVideo: UpdateHopeKitVideoPayload;
  updateHyperventilationPractice: HyperventilationPractice;
  /** @deprecated use updateUser() instead */
  updateInfo: Scalars['Boolean']['output'];
  updateOnboardingAvailability: MutationUpdateOnboardingAvailabilityResult;
  updateOrganization: Maybe<Organization>;
  updateOrganizationMember: OrganizationMemberResult;
  updateOrganizationProduct: OrganizationProduct;
  updateOuiUser: MutationUpdateOuiUserResult;
  updateOuiUserWithToken: OuiUser;
  /** @deprecated Replaced by updateUser() */
  updatePatient: PatientProfile;
  updatePractice: MutationUpdatePracticeResult;
  updateQuizSet: QuizSetUpdatePayload;
  updateQuizSetCollection: QuizSetCollectionUpdatePayload;
  updateQuizSetItemChoice: QuizSetItemCreatePayload;
  updateQuizSetItemOrder: QuizSetItemUpdateOrderPayload;
  updateRecurringAvailability: MutationUpdateRecurringAvailabilityResult;
  updateRelaxDiaryEntry: UpdateRelaxDiaryEntryPracticePayload;
  updateRoleSettings: Maybe<RoleSettings>;
  updateSession: CmsSession;
  updateSessionGroup: CmsSessionGroup;
  updateSleepDiaryEntry: UpdateSleepDiaryEntryPracticePayload;
  updateThoughtDiaryEntry: UpdateThoughtDiaryEntryPracticePayload;
  updateUser: User;
  updateVariant: CmsVariant;
  updateWordPairingPractice: WordPairingPractice;
  updateYstMentalHealthConcerns: MutationUpdateYstMentalHealthConcernsResult;
  updateYstSupporterEvent: MutationUpdateYstSupporterEventResult;
  uploadResumableAsset: ResumableUploadAssetSession;
};


export type MutationAddActionArgs = {
  input: AddActionInput;
};


export type MutationAddActivityPracticeArgs = {
  input: AddActivityPracticeInput;
};


export type MutationAddBreathHoldingPracticeArgs = {
  input: MutationAddBreathHoldingPracticeInput;
};


export type MutationAddEatingCheckinArgs = {
  input: MutationAddEatingCheckinInput;
};


export type MutationAddEatingLogEntryArgs = {
  input: AddEatingLogEntryInput;
};


export type MutationAddEatingProgressEntryArgs = {
  input: MutationAddEatingProgressEntryInput;
};


export type MutationAddHopeKitImageArgs = {
  input: AddHopeKitImageInput;
};


export type MutationAddHopeKitQuoteArgs = {
  input: AddHopeKitQuoteInput;
};


export type MutationAddHopeKitVideoArgs = {
  input: AddHopeKitVideoInput;
};


export type MutationAddHyperventilationPracticeArgs = {
  input: MutationAddHyperventilationPracticeInput;
};


export type MutationAddOrganizationArgs = {
  organization: OrganizationInput;
};


export type MutationAddOrganizationMemberArgs = {
  organizationID: Scalars['UUID']['input'];
  person: PersonWithEmailInput;
  roles: Array<OuiUserRoleType>;
};


export type MutationAddOrganizationProductArgs = {
  organizationID: Scalars['UUID']['input'];
  product: OrganizationProductInput;
};


export type MutationAddPatientArgs = {
  organizationID: Scalars['UUID']['input'];
  patient: PatientInput;
  primaryPractitionerID?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationAddPatientSupporterArgs = {
  organizationID?: InputMaybe<Scalars['UUID']['input']>;
  patientID: Scalars['UUID']['input'];
  supporter: PatientSupporterInput;
};


export type MutationAddPractitionerArgs = {
  organizationID: Scalars['UUID']['input'];
  practitioner: PractitionerInput;
};


export type MutationAddPractitionerToCareTeamArgs = {
  careTeamID: Scalars['UUID']['input'];
  practitionerID: Scalars['UUID']['input'];
};


export type MutationAddRelaxDiaryEntryArgs = {
  input: AddRelaxDiaryEntryPracticeInput;
};


export type MutationAddSleepDiaryEntryArgs = {
  input: AddSleepDiaryEntryPracticeInput;
};


export type MutationAddThoughtDiaryEntryArgs = {
  input: AddThoughtDiaryEntryPracticeInput;
};


export type MutationAddWordPairingPracticeArgs = {
  input: MutationAddWordPairingPracticeInput;
};


export type MutationAddYstSupporterEventArgs = {
  input: MutationAddYstSupporterEventInput;
};


export type MutationCompleteFileUploadActionArgs = {
  input: FileUploadActionCompleteInput;
};


export type MutationCompleteQuizSetForPatientArgs = {
  input: CompleteQuizSetForPatientInput;
};


export type MutationContactYstSupportArgs = {
  input: MutationContactYstSupportInput;
};


export type MutationCopyQuizSetToCollectionArgs = {
  input: CopyQuizSetToCollectionInput;
};


export type MutationCopySessionArgs = {
  input: CopySessionInput;
};


export type MutationCreateAppArgs = {
  input: CmsAppCreateInput;
};


export type MutationCreateFileUploadArgs = {
  input: FileUploadCreateInput;
};


export type MutationCreateJobArgs = {
  input: MutationCreateJobInput;
};


export type MutationCreateQuizSetArgs = {
  input: QuizSetCreateInput;
};


export type MutationCreateQuizSetCollectionArgs = {
  input: QuizSetCollectionCreateInput;
};


export type MutationCreateQuizSetItemChoiceArgs = {
  input: QuizSetItemChoiceCreateInput;
};


export type MutationCreateQuizSetItemVideoArgs = {
  input: QuizSetItemVideoCreateInput;
};


export type MutationCreateSessionArgs = {
  input: CmsSessionCreateInput;
};


export type MutationCreateSessionGroupArgs = {
  input: CmsSessionGroupCreateInput;
};


export type MutationCreateTestScenarioArgs = {
  input: MutationCreateTestScenarioInput;
};


export type MutationCreateTestUserArgs = {
  email: Scalars['String']['input'];
  onboardingVariant?: InputMaybe<OnboardingVariant>;
  password?: Scalars['String']['input'];
  productVariant?: InputMaybe<ProductVariant>;
  productVersion?: InputMaybe<ProductVersion>;
  testPatient?: InputMaybe<TestPatientInput>;
};


export type MutationCreateUserArgs = {
  input: MutationCreateUserInput;
};


export type MutationCreateVariantArgs = {
  input: CmsVariantCreateInput;
};


export type MutationCreateVideoArgs = {
  input: MutationCreateVideoInput;
};


export type MutationDeactivateUserArgs = {
  email: Scalars['String']['input'];
};


export type MutationDeclineYstNominationArgs = {
  input: MutationDeclineYstNominationInput;
};


export type MutationDeleteActivityPracticeArgs = {
  practiceID: Scalars['UUID']['input'];
};


export type MutationDeleteEatingLogEntryArgs = {
  practiceID: Scalars['UUID']['input'];
};


export type MutationDeleteOrganizationProductArgs = {
  organizationID: Scalars['UUID']['input'];
  productID: Scalars['UUID']['input'];
};


export type MutationDeleteQuizSetArgs = {
  input: QuizSetDeleteInput;
};


export type MutationDeleteQuizSetItemArgs = {
  input: QuizSetItemDeleteInput;
};


export type MutationDeleteSessionArgs = {
  input: DeleteSessionInput;
};


export type MutationDeleteSessionGroupArgs = {
  input: DeleteSessionGroupInput;
};


export type MutationDeleteThoughtDiaryEntryArgs = {
  practiceID: Scalars['UUID']['input'];
};


export type MutationInviteYstGuardianArgs = {
  input: MutationInviteYstGuardianInput;
};


export type MutationInviteYstYouthArgs = {
  input: MutationInviteYstYouthInput;
};


export type MutationIssueTokenArgs = {
  patientID: Scalars['UUID']['input'];
};


export type MutationIssueTokenByEmailArgs = {
  email: Scalars['String']['input'];
};


export type MutationIssueTokenForPractitionerArgs = {
  practitionerID: Scalars['UUID']['input'];
};


export type MutationIssueTokenForRegistrarArgs = {
  organizationID: Scalars['UUID']['input'];
  userID: Scalars['UUID']['input'];
};


export type MutationIssueTokenLinkForSupporterArgs = {
  supporterID: Scalars['UUID']['input'];
};


export type MutationIssueYstYouthInviteLinkArgs = {
  patientID: Scalars['UUID']['input'];
};


export type MutationIssueYstYouthInviteMessageArgs = {
  patientID: Scalars['UUID']['input'];
};


export type MutationKvRespondArgs = {
  context: Scalars['String']['input'];
  data: Scalars['Any']['input'];
  key: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationLoginWithEmailArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationLoginWithTokenArgs = {
  value: Scalars['String']['input'];
};


export type MutationLogoutDeviceArgs = {
  installationID: Scalars['UUID']['input'];
};


export type MutationNewCompositionWithTemplateArgs = {
  patientID: Scalars['UUID']['input'];
  template: CompositionTemplate;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationNominateYstSupportersArgs = {
  input: MutationNominateYstSupportersInput;
};


export type MutationPatchExchangeArgs = {
  input: ExchangePatchInput;
};


export type MutationPersistBotStateArgs = {
  input: MutationPersistBotStateInput;
};


export type MutationPreviewYstPatientMessageArgs = {
  input: MutationPreviewYstPatientMessageInput;
};


export type MutationRateYstSupporterCheckinArgs = {
  input: MutationRateYstSupporterCheckinInput;
};


export type MutationReauthenticateArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationRemoveHopeKitItemArgs = {
  input: RemoveHopeKitItemInput;
};


export type MutationRemovePatientSupporterArgs = {
  organizationID: Scalars['UUID']['input'];
  patientID: Scalars['UUID']['input'];
  supporterID: Scalars['UUID']['input'];
};


export type MutationRemovePractitionerFromCareTeamArgs = {
  careTeamID: Scalars['UUID']['input'];
  practitionerID: Scalars['UUID']['input'];
};


export type MutationReplacePrimaryPractitionerArgs = {
  currentPrimaryPractitionerID: Scalars['UUID']['input'];
  newPrimaryPractitionerID: Scalars['UUID']['input'];
};


export type MutationResendYstNominationInviteArgs = {
  input: YstNominationUpdateInput;
};


export type MutationResendYstPatientMessageArgs = {
  input: MutationResendYstPatientMessageInput;
};


export type MutationRespondArgs = {
  context: Scalars['String']['input'];
  data: Scalars['Any']['input'];
  key: Scalars['String']['input'];
};


export type MutationReviewPatientSupporterArgs = {
  patientSupporterID: Scalars['UUID']['input'];
};


export type MutationRewindBotStateArgs = {
  configID: Scalars['String']['input'];
};


export type MutationSetCareTeamPrimaryPractitionerArgs = {
  careTeamID: Scalars['UUID']['input'];
  practitionerID?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationSetCompositionArgs = {
  json?: InputMaybe<Scalars['Map']['input']>;
  roleID?: InputMaybe<Scalars['UUID']['input']>;
  template: CompositionTemplate;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSetDeviceArgs = {
  device: DeviceInput;
};


export type MutationSetOuiProgressArgs = {
  content: ContentType;
  value: Scalars['Float']['input'];
};


export type MutationSetOuiProgressForPatientArgs = {
  content: ContentType;
  patientID: Scalars['UUID']['input'];
  value: Scalars['Float']['input'];
};


export type MutationSetPatientSupporterRelationArgs = {
  input: PatientSupporterRelationInput;
};


export type MutationSetPushNotificationsDisabledArgs = {
  pushNotificationsDisabled: Scalars['Boolean']['input'];
};


export type MutationSignupYstGuardianArgs = {
  input: MutationSignupYstGuardianInput;
};


export type MutationSignupYstYouthArgs = {
  input: MutationSignupYstYouthInput;
};


export type MutationSnapshotQuizSetCollectionArgs = {
  input: SnapshotQuizSetCollectionInput;
};


export type MutationSnapshotSessionArgs = {
  input: SnapshotSessionInput;
};


export type MutationSoftDeleteOutdatedBotHistoryArgs = {
  configID: Scalars['String']['input'];
  lastValidOccurredAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastValidTimestamp?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationThrowErrorArgs = {
  message?: Scalars['String']['input'];
};


export type MutationThrowPanicArgs = {
  message?: Scalars['String']['input'];
};


export type MutationUpdateActivityPracticeArgs = {
  input: UpdateActivityPracticeInput;
};


export type MutationUpdateAppArgs = {
  input: CmsAppUpdateInput;
};


export type MutationUpdateBreathHoldingPracticeArgs = {
  input: MutationUpdateBreathHoldingPracticeInput;
};


export type MutationUpdateCompositionArgs = {
  compositionID: Scalars['UUID']['input'];
  json?: InputMaybe<Scalars['Map']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateCompositionSectionArgs = {
  sectionID: Scalars['UUID']['input'];
  text?: InputMaybe<Scalars['Any']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateEatingCheckinArgs = {
  input: MutationUpdateEatingCheckinInput;
};


export type MutationUpdateEatingLogEntryArgs = {
  input: UpdateEatingLogEntryInput;
};


export type MutationUpdateEatingProgressEntryArgs = {
  input: MutationUpdateEatingProgressEntryInput;
};


export type MutationUpdateFirstCheckinDateArgs = {
  input: MutationUpdateFirstCheckinDateInput;
};


export type MutationUpdateHopeKitImageArgs = {
  input: UpdateHopeKitImageInput;
};


export type MutationUpdateHopeKitQuoteArgs = {
  input: UpdateHopeKitQuoteInput;
};


export type MutationUpdateHopeKitVideoArgs = {
  input: UpdateHopeKitVideoInput;
};


export type MutationUpdateHyperventilationPracticeArgs = {
  input: MutationUpdateHyperventilationPracticeInput;
};


export type MutationUpdateInfoArgs = {
  address?: InputMaybe<UserAddressInput>;
  demo?: InputMaybe<UserDemoInput>;
  name?: InputMaybe<UserNameInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateOnboardingAvailabilityArgs = {
  input: MutationUpdateOnboardingAvailabilityInput;
};


export type MutationUpdateOrganizationArgs = {
  organization: OrganizationUpdateInput;
  organizationID: Scalars['UUID']['input'];
};


export type MutationUpdateOrganizationMemberArgs = {
  organizationID: Scalars['UUID']['input'];
  update: OrganizationMemberUpdateInput;
  userID: Scalars['UUID']['input'];
};


export type MutationUpdateOrganizationProductArgs = {
  organizationID: Scalars['UUID']['input'];
  product: OrganizationProductInput;
};


export type MutationUpdateOuiUserArgs = {
  input: UpdateOuiUserInput;
};


export type MutationUpdateOuiUserWithTokenArgs = {
  input: UpdateOuiUserInput;
  value: Scalars['String']['input'];
};


export type MutationUpdatePatientArgs = {
  patientID: Scalars['UUID']['input'];
  update: PatientUpdateInput;
};


export type MutationUpdatePracticeArgs = {
  input: MutationUpdatePracticeInput;
};


export type MutationUpdateQuizSetArgs = {
  input: QuizSetUpdateInput;
};


export type MutationUpdateQuizSetCollectionArgs = {
  input: QuizSetCollectionUpdateInput;
};


export type MutationUpdateQuizSetItemChoiceArgs = {
  input: QuizSetItemChoiceUpdateInput;
};


export type MutationUpdateQuizSetItemOrderArgs = {
  input: QuizSetItemUpdateOrderInput;
};


export type MutationUpdateRecurringAvailabilityArgs = {
  input: MutationUpdateRecurringAvailabilityInput;
};


export type MutationUpdateRelaxDiaryEntryArgs = {
  input: UpdateRelaxDiaryEntryPracticeInput;
};


export type MutationUpdateRoleSettingsArgs = {
  input: MutationUpdateRoleSettingsInput;
};


export type MutationUpdateSessionArgs = {
  input: UpdateSessionInput;
};


export type MutationUpdateSessionGroupArgs = {
  input: CmsSessionGroupUpdateInput;
};


export type MutationUpdateSleepDiaryEntryArgs = {
  input: UpdateSleepDiaryEntryPracticeInput;
};


export type MutationUpdateThoughtDiaryEntryArgs = {
  input: UpdateThoughtDiaryEntryPracticeInput;
};


export type MutationUpdateUserArgs = {
  input: MutationUpdateUserInput;
};


export type MutationUpdateVariantArgs = {
  input: CmsVariantUpdateInput;
};


export type MutationUpdateWordPairingPracticeArgs = {
  input: MutationUpdateWordPairingPracticeInput;
};


export type MutationUpdateYstMentalHealthConcernsArgs = {
  input: MutationUpdateYstMentalHealthConcernsInput;
};


export type MutationUpdateYstSupporterEventArgs = {
  input: MutationUpdateYstSupporterEventInput;
};


export type MutationUploadResumableAssetArgs = {
  input: ResumableAssetCreateInput;
};

export type MutationAcceptYstNominationResult = SimpleError | YstNomination;

export type MutationAddBreathHoldingPracticeInput = {
  practiceValues: PracticeValuesInput;
  stressBreathingExercise: StressBreathingExerciseInput;
};

export type MutationAddEatingCheckinInput = {
  eatingCheckin: EatingCheckinInput;
  practiceValues: PracticeValuesInput;
};

export type MutationAddEatingCheckinResult = EatingCheckinPractice | SimpleError;

export type MutationAddEatingProgressEntryInput = {
  eatingProgressEntry: EatingProgressEntryInput;
  practiceValues: PracticeValuesInput;
};

export type MutationAddEatingProgressEntryResult = EatingProgressEntryPractice | SimpleError;

export type MutationAddHyperventilationPracticeInput = {
  practiceValues: PracticeValuesInput;
  stressBreathingExercise: StressBreathingExerciseInput;
};

export type MutationAddWordPairingPracticeInput = {
  practiceValues: PracticeValuesInput;
  wordPairing: WordPairingInput;
};

export type MutationAddYstSupporterEventInput = {
  patientID: Scalars['UUID']['input'];
  status: Scalars['String']['input'];
  type: Scalars['String']['input'];
  value: Scalars['String']['input'];
  youthID: Scalars['UUID']['input'];
};

export type MutationAddYstSupporterEventResult = SimpleError | YstSupportProfile;

export type MutationContactYstSupportInput = {
  body: Scalars['String']['input'];
  /** Set to true if this message should be sent to the primary clinician rather than the organization's general YST contacts */
  primaryClinician?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateJobInput = {
  payload: Scalars['JSONObject']['input'];
  synchronous?: InputMaybe<Scalars['Boolean']['input']>;
  type: JobType;
};

export type MutationCreateJobResult = Job | SimpleError | ValidationError;

export type MutationCreateTestScenarioInput = {
  organization: OrganizationInput;
  users: Array<TestUserInput>;
};

export type MutationCreateTestScenarioResult = CreateTestScenarioResult | SimpleError | ValidationError;

export type MutationCreateUserInput = {
  roles: Array<CreateRoleInput>;
  user: CreateUserInput;
};

export type MutationCreateVideoInput = {
  fileUploadID: Scalars['UUID']['input'];
};

export type MutationDeclineYstNominationInput = {
  reason: Scalars['String']['input'];
};

export type MutationDeclineYstNominationResult = SimpleError | YstNomination;

export type MutationInviteYstGuardianInput = {
  guardian: YstPatientInput;
  youthID: Scalars['UUID']['input'];
};

export type MutationInviteYstGuardianResult = Patient | SimpleError | ValidationError;

export type MutationInviteYstYouthInput = {
  youth: YstPatientInput;
};

export type MutationInviteYstYouthResult = Patient | SimpleError | ValidationError;

export type MutationLoginWithEmailResult = InvalidPasswordError | SimpleError | UserDisabledError | UserSession;

export type MutationLoginWithTokenResult = InvalidPasswordError | SimpleError | UserDisabledError | UserSession;

export type MutationNominateYstSupportersInput = {
  /**
   * - if teen is 18+ these are APPROVED
   * - if teen is <18 these are PENDING
   * - if parent, these are SUGGESTED
   */
  create?: InputMaybe<Array<YstNominationCreateInput>>;
  /**
   * - if youth is current user or youth 18+ -> throw
   * - if parent, these are REJECTED w/reason is set
   */
  reject?: InputMaybe<Array<YstNominationRejectInput>>;
  /**
   * - if parent, these are updated and no status change occurs
   * - if parent, these are updated & APPROVED
   * - if after update all are APPROVED, invites are sent and status is INVITED
   */
  update?: InputMaybe<Array<YstNominationUpdateInput>>;
  youthID: Scalars['UUID']['input'];
};

export type MutationNominateYstSupportersResult = MutationNominateYstSupportersSuccess | SimpleError | ValidationError;

export type MutationNominateYstSupportersSuccess = {
  data: Array<YstNomination>;
};

export type MutationPersistBotStateInput = {
  configID: Scalars['String']['input'];
  envelopes: Array<Scalars['Map']['input']>;
  previousBotStateID?: InputMaybe<Scalars['UUID']['input']>;
  state: Scalars['String']['input'];
};

export type MutationPersistBotStateResult = BotState | OutdatedBotStateError | SimpleError;

export type MutationPreviewYstPatientMessageInput = {
  ystPatientMessageID: Scalars['UUID']['input'];
};

export type MutationPreviewYstPatientMessageResult = SimpleError | YstPatientMessagePreview;

export type MutationRateYstSupporterCheckinInput = {
  rating: Scalars['Int']['input'];
  ystSupporterCheckinID: Scalars['UUID']['input'];
};

export type MutationRateYstSupporterCheckinResult = SimpleError | YstSupporterCheckin;

export type MutationResendYstNominationInviteResult = SimpleError | ValidationError | YstNomination;

export type MutationResendYstPatientMessageInput = {
  ystPatientMessageID: Scalars['UUID']['input'];
};

export type MutationResendYstPatientMessageResult = MutationResendYstPatientMessageSuccess | SimpleError;

export type MutationResendYstPatientMessageSuccess = {
  data: Array<YstPatientMessage>;
};

export type MutationSignupYstGuardianInput = {
  organizationID: Scalars['UUID']['input'];
  password: Scalars['String']['input'];
  patient: YstPatientInput;
};

export type MutationSignupYstGuardianResult = Patient | SimpleError | ValidationError;

export type MutationSignupYstYouthInput = {
  organizationID: Scalars['UUID']['input'];
  password: Scalars['String']['input'];
  patient: YstPatientInput;
};

export type MutationSignupYstYouthResult = Patient | SimpleError | ValidationError;

export type MutationUpdateBreathHoldingPracticeInput = {
  practiceID: Scalars['UUID']['input'];
  practiceValues: PracticeValuesInput;
  stressBreathingExercise: StressBreathingExerciseInput;
};

export type MutationUpdateEatingCheckinInput = {
  eatingCheckin: EatingCheckinInput;
  practiceID: Scalars['UUID']['input'];
  practiceValues: PracticeValuesInput;
};

export type MutationUpdateEatingCheckinResult = EatingCheckinPractice | SimpleError;

export type MutationUpdateEatingProgressEntryInput = {
  eatingProgressEntry: EatingProgressEntryInput;
  practiceID: Scalars['UUID']['input'];
  practiceValues: PracticeValuesInput;
};

export type MutationUpdateEatingProgressEntryResult = EatingProgressEntryPractice | SimpleError;

export type MutationUpdateFirstCheckinDateInput = {
  date: Scalars['Date']['input'];
  patientID: Scalars['UUID']['input'];
};

export type MutationUpdateFirstCheckinDateResult = MutationUpdateFirstCheckinDateSuccess | SimpleError | ValidationError;

export type MutationUpdateFirstCheckinDateSuccess = {
  data: YstSupportProfile;
};

export type MutationUpdateHyperventilationPracticeInput = {
  practiceID: Scalars['UUID']['input'];
  practiceValues: PracticeValuesInput;
  stressBreathingExercise: StressBreathingExerciseInput;
};

export type MutationUpdateOnboardingAvailabilityInput = {
  onboardingAvailability: Scalars['YstOnboardingAvailability']['input'];
};

export type MutationUpdateOnboardingAvailabilityResult = MutationUpdateOnboardingAvailabilitySuccess | SimpleError;

export type MutationUpdateOnboardingAvailabilitySuccess = {
  data: YstSupportProfile;
};

export type MutationUpdateOuiUserResult = CurrentOuiUser | InvalidPasswordError | SimpleError | ValidationError;

export type MutationUpdatePracticeInput = {
  content?: InputMaybe<PracticeContentInput>;
  date?: InputMaybe<Scalars['Date']['input']>;
  practiceID: Scalars['UUID']['input'];
  ratings?: InputMaybe<Array<PracticeRatingInput>>;
};

export type MutationUpdatePracticeResult = SimpleError | UpdatePracticeResult;

export type MutationUpdateRecurringAvailabilityInput = {
  patientID?: InputMaybe<Scalars['UUID']['input']>;
  recurringAvailability: Scalars['YstRecurringAvailability']['input'];
};

export type MutationUpdateRecurringAvailabilityResult = MutationUpdateRecurringAvailabilitySuccess | SimpleError;

export type MutationUpdateRecurringAvailabilitySuccess = {
  data: YstSupportProfile;
};

export type MutationUpdateRoleSettingsInput = {
  roleID: Scalars['UUID']['input'];
  settings: RoleSettingsInput;
};

export type MutationUpdateUserInput = {
  roles?: InputMaybe<Array<RoleInput>>;
  user?: InputMaybe<UpdateUserInput>;
  userID: Scalars['UUID']['input'];
};

export type MutationUpdateWordPairingPracticeInput = {
  practiceID: Scalars['UUID']['input'];
  practiceValues: PracticeValuesInput;
  wordPairing: WordPairingInput;
};

export type MutationUpdateYstMentalHealthConcernsInput = {
  patientID: Scalars['UUID']['input'];
  ystMentalHealthConcerns: Scalars['YstMentalHealthConcerns']['input'];
};

export type MutationUpdateYstMentalHealthConcernsResult = MutationUpdateYstMentalHealthConcernsSuccess | SimpleError;

export type MutationUpdateYstMentalHealthConcernsSuccess = {
  data: YstProfile;
};

export type MutationUpdateYstSupporterEventInput = {
  status: Scalars['String']['input'];
  type: Scalars['String']['input'];
  value: Scalars['String']['input'];
  ystSupporterEventID: Scalars['UUID']['input'];
};

export type MutationUpdateYstSupporterEventResult = SimpleError | YstSupportProfile;

export const MuxFitMode = {
  CROP: 'crop',
  PAD: 'pad',
  PRESERVE: 'preserve',
  SMARTCROP: 'smartcrop'
} as const;

export type MuxFitMode = typeof MuxFitMode[keyof typeof MuxFitMode];
export type MyPlanReviewAction = Action & {
  actionID: Scalars['UUID']['output'];
  actionValues: ActionValues;
};

export type MyPlanWidget = Widget & {
  ID: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  iconName: Scalars['String']['output'];
  locked: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  practiceCount: Scalars['Int']['output'];
  practiceTileColor: Scalars['String']['output'];
  practiceTileDeeplink: Scalars['String']['output'];
  suicideMode: Scalars['Boolean']['output'];
  unlockSession: Maybe<RoleSession>;
};

export const OnboardingVariant = {
  COLLABORATIVE: 'COLLABORATIVE',
  INDEPENDENT: 'INDEPENDENT'
} as const;

export type OnboardingVariant = typeof OnboardingVariant[keyof typeof OnboardingVariant];
export type Organization = {
  ID: Scalars['UUID']['output'];
  /** @deprecated Moved to Organization Products */
  caringContactsEnabled: Scalars['Boolean']['output'];
  children: Array<Organization>;
  isTrialOrganization: Scalars['Boolean']['output'];
  lastPatientCreatedAt: Maybe<Scalars['DateTime']['output']>;
  members: Array<OrganizationMember>;
  name: Scalars['String']['output'];
  /** @deprecated Moved to Organization Products */
  onboardingVariant: OnboardingVariant;
  parent: Maybe<Organization>;
  practitioners: Maybe<Array<Practitioner>>;
  /** @deprecated Replaced with Products */
  productVariants: Array<ProductVariant>;
  /** @deprecated Organizations can now have different versions for different products */
  productVersion: ProductVersion;
  products: Array<OrganizationProduct>;
  registrars: Maybe<Array<Registrar>>;
  stats: OrganizationStats;
};


export type OrganizationMembersArgs = {
  recurse?: InputMaybe<Scalars['Boolean']['input']>;
};


export type OrganizationPractitionersArgs = {
  recurse?: InputMaybe<Scalars['Boolean']['input']>;
};


export type OrganizationRegistrarsArgs = {
  recurse?: InputMaybe<Scalars['Boolean']['input']>;
};


export type OrganizationStatsArgs = {
  recurse?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrganizationAvivaConfig = {
  caringContactsEnabled: Scalars['Boolean']['output'];
  crisisFramework: CrisisFramework;
  onboardingVariant: OnboardingVariant;
  productVersion: ProductVersion;
};

export type OrganizationAvivaConfigInput = {
  caringContactsEnabled: Scalars['Boolean']['input'];
  crisisFramework: CrisisFramework;
  onboardingVariant: OnboardingVariant;
  productVersion: ProductVersion;
};

export type OrganizationInput = {
  isTrialOrganization?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  parent?: InputMaybe<Scalars['UUID']['input']>;
  products?: InputMaybe<Array<OrganizationProductInput>>;
};

export type OrganizationMember = {
  organizationID: Scalars['UUID']['output'];
  person: Person;
  roles: Array<OuiUserRoleType>;
  userID: Scalars['UUID']['output'];
  userRoles: Array<OuiUserRole>;
};

export type OrganizationMemberResult = {
  member: OrganizationMember;
};

export type OrganizationMemberUpdateInput = {
  person?: InputMaybe<PersonUpdateInput>;
  roles: Array<OuiUserRoleType>;
};

export type OrganizationProduct = {
  name: Scalars['String']['output'];
  nameStatic: Scalars['String']['output'];
  product: Product;
  productConfig: Maybe<OrganizationProductConfig>;
};

export type OrganizationProductConfig = OrganizationAvivaConfig | OrganizationYstConfig;

export type OrganizationProductConfigInput = {
  avivaConfig?: InputMaybe<OrganizationAvivaConfigInput>;
  ystConfig?: InputMaybe<OrganizationYstConfigInput>;
};

export type OrganizationProductInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameStatic?: InputMaybe<Scalars['String']['input']>;
  /** Required if Product has configuration */
  productConfig?: InputMaybe<OrganizationProductConfigInput>;
  productID: Scalars['UUID']['input'];
};

export type OrganizationStats = {
  patientCount: Scalars['Int']['output'];
  practitionerCount: Scalars['Int']['output'];
  registrarCount: Scalars['Int']['output'];
};

export type OrganizationUpdateInput = {
  isTrialOrganization?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  products?: InputMaybe<Array<OrganizationProductInput>>;
};

export type OrganizationYstConfig = {
  contacts: Array<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  unlisted: Scalars['Boolean']['output'];
};

export type OrganizationYstConfigInput = {
  contacts: Array<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  unlisted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OuiAdmin = {
  ID: Scalars['UUID']['output'];
  person: Person;
};

export type OuiProgress = {
  completed: Scalars['Boolean']['output'];
  completion: Scalars['Float']['output'];
  content: ContentType;
  session: Maybe<CmsSession>;
  updatedAt: Scalars['DateTime']['output'];
};

export type OuiUser = {
  createdAt: Scalars['DateTime']['output'];
  devices: Array<Device>;
  disabled: Scalars['Boolean']['output'];
  person: Person;
  products: Array<OuiUserProduct>;
  roles: Array<OuiUserRole>;
  roles2: Array<Role>;
  userID: Scalars['UUID']['output'];
  username: Scalars['String']['output'];
};

export type OuiUserAttributes = {
  admin: Scalars['Boolean']['output'];
  testUser: Scalars['Boolean']['output'];
  /** @deprecated Unused */
  trialAuditor: Scalars['Boolean']['output'];
};

export type OuiUserProduct = {
  product: Product;
  roles: Array<OuiUserRole>;
};

export type OuiUserRole = {
  ID: Scalars['UUID']['output'];
  organization: Maybe<Organization>;
  role: OuiUserRoleType;
  roleLabel: Scalars['String']['output'];
  user: OuiUserType;
};

export const OuiUserRoleType = {
  OUI_ADMIN: 'OUI_ADMIN',
  PATIENT: 'PATIENT',
  PRACTITIONER: 'PRACTITIONER',
  REGISTRAR: 'REGISTRAR',
  UNKNOWN: 'UNKNOWN'
} as const;

export type OuiUserRoleType = typeof OuiUserRoleType[keyof typeof OuiUserRoleType];
export type OuiUserType = OuiAdmin | Patient | Practitioner | Registrar;

export type OutdatedBotStateError = BaseError & {
  message: Scalars['String']['output'];
};

export type PatchInput = {
  inverse: Scalars['JSONObject']['input'];
  payload: Scalars['JSONObject']['input'];
};

export type Patient = IPatientProfile & {
  ID: Scalars['UUID']['output'];
  /** @deprecated Prefer person.address */
  address: Array<Address>;
  avivaProfile: Maybe<AvivaProfile>;
  /** @deprecated Prefer person.birthDate */
  birthDate: Maybe<Scalars['Date']['output']>;
  careTeam: CareTeam;
  caringContactsMedium: CaringContactsMedium;
  createdAt: Scalars['DateTime']['output'];
  devices: Array<Device>;
  emails: Array<UserEmail>;
  /** @deprecated Prefer person.genderIdentity */
  genderIdentity: Maybe<PatientGenderIdentity>;
  lastLogin: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Prefer person.militaryStatus */
  militaryStatus: Maybe<PatientMilitaryStatus>;
  notifications: Array<PatientNotification>;
  onboardingVariant: OnboardingVariant;
  patient: Patient;
  person: Person;
  productStatic: Scalars['Boolean']['output'];
  productVariant: ProductVariant;
  productVersion: ProductVersion;
  profile: PatientProfile;
  progress: Array<OuiProgress>;
  /** @deprecated Prefer person.race */
  race: Maybe<PatientRace>;
  role: Role;
  /** @deprecated Prefer person.sexualOrientation */
  sexualOrientation: Maybe<PatientSexualOrientation>;
  supportees: Array<PatientSupporter>;
  supporters: Array<PatientSupporter>;
  ystGuardianProfile: Maybe<YstGuardianProfile>;
  ystProfile: Maybe<YstProfile>;
  ystSupportProfile: Maybe<YstSupportProfile>;
  ystYouthProfile: Maybe<YstYouthProfile>;
};


export type PatientSupportersArgs = {
  productVariant?: InputMaybe<ProductVariant>;
};

export type PatientCaringContact = {
  caringContactID: Scalars['UUID']['output'];
  caringContactsMedium: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  templateKey: Scalars['String']['output'];
  thirdPartyServiceID: Scalars['String']['output'];
};

export const PatientGenderIdentity = {
  FEMALE: 'FEMALE',
  MALE: 'MALE',
  NON_BINARY: 'NON_BINARY',
  NON_DISCLOSE: 'NON_DISCLOSE',
  TRANSGENDER_FEMALE: 'TRANSGENDER_FEMALE',
  TRANSGENDER_MALE: 'TRANSGENDER_MALE',
  UNKNOWN: 'UNKNOWN'
} as const;

export type PatientGenderIdentity = typeof PatientGenderIdentity[keyof typeof PatientGenderIdentity];
export type PatientInput = {
  caringContactsMedium?: InputMaybe<CaringContactsMedium>;
  onboardingVariant?: InputMaybe<OnboardingVariant>;
  person: PersonInput;
  productID?: InputMaybe<Scalars['UUID']['input']>;
  productStatic?: InputMaybe<Scalars['Boolean']['input']>;
  productVersion?: InputMaybe<ProductVersion>;
  roleType?: InputMaybe<RoleType>;
};

export const PatientMilitaryStatus = {
  ACTIVE_MILITARY_DUTY: 'ACTIVE_MILITARY_DUTY',
  CIVILIAN: 'CIVILIAN',
  DISABLED_VETERAN: 'DISABLED_VETERAN',
  FAMILY_MEMBER_OF_ACTIVE_DUTY: 'FAMILY_MEMBER_OF_ACTIVE_DUTY',
  FAMILY_MEMBER_OF_RETIRED_MILITARY: 'FAMILY_MEMBER_OF_RETIRED_MILITARY',
  INACTIVE_READY_RESERVE: 'INACTIVE_READY_RESERVE',
  NATIONAL_GUARD: 'NATIONAL_GUARD',
  NON_DISCLOSE: 'NON_DISCLOSE',
  RESERVES: 'RESERVES',
  RETIRED_MILITARY: 'RETIRED_MILITARY',
  UNKNOWN: 'UNKNOWN',
  VETERAN: 'VETERAN'
} as const;

export type PatientMilitaryStatus = typeof PatientMilitaryStatus[keyof typeof PatientMilitaryStatus];
export type PatientNotification = {
  createdAt: Scalars['DateTime']['output'];
  notificationID: Scalars['UUID']['output'];
  rule: Scalars['String']['output'];
  variation: Scalars['Int']['output'];
};

export type PatientProfile = IPatientProfile & {
  ID: Scalars['UUID']['output'];
  careTeam: CareTeam;
  caringContacts: Array<PatientCaringContact>;
  devices: Array<Device>;
  emails: Array<UserEmail>;
  lastLogin: Maybe<Scalars['DateTime']['output']>;
  myPlanComposition: Maybe<Composition>;
  notifications: Array<PatientNotification>;
  patient: Patient;
  progress: Array<OuiProgress>;
  role: Role;
  supportees: Array<PatientSupporter>;
  supporters: Array<PatientSupporter>;
};


export type PatientProfileSupportersArgs = {
  productVariant?: InputMaybe<ProductVariant>;
};

export type PatientQuizSetCompleted = {
  completedQuizSet: CompletedPatientQuizSet;
  patientID: Scalars['UUID']['output'];
};

export const PatientRace = {
  AMERICAN_INDIAN_OR_ALASKA_NATIVE: 'AMERICAN_INDIAN_OR_ALASKA_NATIVE',
  ASIAN: 'ASIAN',
  BLACK_OR_AFRICAN_AMERICAN: 'BLACK_OR_AFRICAN_AMERICAN',
  NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER: 'NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER',
  NON_DISCLOSE: 'NON_DISCLOSE',
  UNKNOWN: 'UNKNOWN',
  WHITE: 'WHITE'
} as const;

export type PatientRace = typeof PatientRace[keyof typeof PatientRace];
export const PatientSexualOrientation = {
  BISEXUAL: 'BISEXUAL',
  HETEROSEXUAL: 'HETEROSEXUAL',
  HOMOSEXUAL: 'HOMOSEXUAL',
  NON_DISCLOSE: 'NON_DISCLOSE',
  OTHER: 'OTHER',
  UNKNOWN: 'UNKNOWN'
} as const;

export type PatientSexualOrientation = typeof PatientSexualOrientation[keyof typeof PatientSexualOrientation];
export type PatientSupportLinkDetails = {
  patientName: Scalars['String']['output'];
  relation: PatientSupporterRelation;
};

export type PatientSupporter = {
  ID: Scalars['String']['output'];
  claimed: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Prefer roleSupportee */
  patient: Patient;
  patientID: Scalars['UUID']['output'];
  relation: PatientSupporterRelation;
  reviewed: Maybe<Scalars['Boolean']['output']>;
  roleSupportee: Supportee;
  roleSupporter: Supporter;
  /** @deprecated Prefer roleSupporter */
  supporter: Patient;
  supporterID: Scalars['UUID']['output'];
};

export type PatientSupporterInput = {
  person: PersonInput;
  productStatic?: InputMaybe<Scalars['Boolean']['input']>;
  productVariant?: InputMaybe<ProductVariant>;
  relation: PatientSupporterRelation;
};

export const PatientSupporterRelation = {
  CLINICIAN: 'CLINICIAN',
  OTHER: 'OTHER',
  PARENT_GUARDIAN: 'PARENT_GUARDIAN',
  RELATIVE: 'RELATIVE',
  SOCIAL_SERVICES: 'SOCIAL_SERVICES'
} as const;

export type PatientSupporterRelation = typeof PatientSupporterRelation[keyof typeof PatientSupporterRelation];
export type PatientSupporterRelationInput = {
  patientID: Scalars['UUID']['input'];
  relation: PatientSupporterRelation;
};

export type PatientUpdateInput = {
  caringContactsMedium?: InputMaybe<CaringContactsMedium>;
  person?: InputMaybe<PersonUpdateInput>;
};

export type PendingAction = {
  pendingActionID: Scalars['ID']['output'];
};

export type PendingActivityAddAction = PendingAction & {
  pendingActionID: Scalars['ID']['output'];
};

export type PendingActivityRateAction = PendingAction & {
  activityPractice: ActivityPractice;
  pendingActionID: Scalars['ID']['output'];
};

export type PendingBreathHoldingAction = PendingAction & {
  pendingActionID: Scalars['ID']['output'];
};

export type PendingCopingCardAddAction = PendingAction & {
  pendingActionID: Scalars['ID']['output'];
};

export type PendingCopingCardReviewAction = PendingAction & {
  pendingActionID: Scalars['ID']['output'];
};

export type PendingEatingLogAddAction = PendingAction & {
  pendingActionID: Scalars['ID']['output'];
};

export type PendingHopeKitAddAction = PendingAction & {
  pendingActionID: Scalars['ID']['output'];
};

export type PendingHopeKitReviewAction = PendingAction & {
  pendingActionID: Scalars['ID']['output'];
};

export type PendingHyperventilationAction = PendingAction & {
  pendingActionID: Scalars['ID']['output'];
};

export type PendingMyPlanReviewAction = PendingAction & {
  pendingActionID: Scalars['ID']['output'];
};

export type PendingRelaxAction = PendingAction & {
  pendingActionID: Scalars['ID']['output'];
};

export type PendingSleepDiaryEntryMorningAction = PendingAction & {
  date: Scalars['Date']['output'];
  pendingActionID: Scalars['ID']['output'];
};

export type PendingSleepDiaryEntryNightAction = PendingAction & {
  date: Scalars['Date']['output'];
  pendingActionID: Scalars['ID']['output'];
};

export type PendingThoughtDiaryEntrySpotAction = PendingAction & {
  pendingActionID: Scalars['ID']['output'];
};

export type PendingThoughtDiaryEntrySwitchAction = PendingAction & {
  pendingActionID: Scalars['ID']['output'];
  thoughtDiaryEntryPractice: ThoughtDiaryEntryPractice;
};

export type PendingThoughtDiaryEntryTestAction = PendingAction & {
  pendingActionID: Scalars['ID']['output'];
  thoughtDiaryEntryPractice: ThoughtDiaryEntryPractice;
};

export type PendingWordPairingAction = PendingAction & {
  pendingActionID: Scalars['ID']['output'];
};

export type Person = {
  address: Array<Address>;
  birthDate: Maybe<Scalars['Date']['output']>;
  /** @deprecated Prefer email/phone */
  contactPoint: Array<ContactPoint>;
  email: Maybe<Scalars['String']['output']>;
  familyName: Maybe<Scalars['String']['output']>;
  genderIdentity: UserGenderIdentity;
  givenName: Maybe<Scalars['String']['output']>;
  lastLogin: Maybe<Scalars['DateTime']['output']>;
  militaryStatus: UserMilitaryStatus;
  ouiUser: Maybe<OuiUser>;
  passwordSetAt: Maybe<Scalars['DateTime']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  race: UserRace;
  settings: UserSettings;
  sexualOrientation: UserSexualOrientation;
  timezone: Maybe<Scalars['String']['output']>;
  userID: Scalars['UUID']['output'];
};

export type PersonInput = {
  birthDate?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  familyName: Scalars['String']['input'];
  genderIdentity?: InputMaybe<UserGenderIdentity>;
  givenName: Scalars['String']['input'];
  militaryStatus?: InputMaybe<UserMilitaryStatus>;
  phone?: InputMaybe<Scalars['String']['input']>;
  race?: InputMaybe<UserRace>;
  settings?: InputMaybe<UserSettingsInput>;
  sexualOrientation?: InputMaybe<UserSexualOrientation>;
};

export type PersonUpdateInput = {
  birthDate?: InputMaybe<Scalars['Date']['input']>;
  contactPoint?: InputMaybe<Array<ContactPointInput>>;
  email?: InputMaybe<Scalars['String']['input']>;
  familyName?: InputMaybe<Scalars['String']['input']>;
  genderIdentity?: InputMaybe<UserGenderIdentity>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  militaryStatus?: InputMaybe<UserMilitaryStatus>;
  phone?: InputMaybe<Scalars['String']['input']>;
  race?: InputMaybe<UserRace>;
  settings?: InputMaybe<UserSettingsInput>;
  sexualOrientation?: InputMaybe<UserSexualOrientation>;
};

export type PersonWithEmailInput = {
  birthDate?: InputMaybe<Scalars['Date']['input']>;
  email: Scalars['String']['input'];
  familyName: Scalars['String']['input'];
  genderIdentity?: InputMaybe<UserGenderIdentity>;
  givenName: Scalars['String']['input'];
  militaryStatus?: InputMaybe<UserMilitaryStatus>;
  phone?: InputMaybe<Scalars['String']['input']>;
  race?: InputMaybe<UserRace>;
  settings?: InputMaybe<UserSettingsInput>;
  sexualOrientation?: InputMaybe<UserSexualOrientation>;
};

export type PlaceAutocomplete = {
  description: Scalars['String']['output'];
  matchedSubstrings: Scalars['String']['output'];
  placeId: Scalars['String']['output'];
  structuredFormatting: PlaceStructuredFormatting;
};

export type PlaceDetails = {
  formattedAddress: Scalars['String']['output'];
  geometry: PlaceGeometry;
  name: Scalars['String']['output'];
  placeId: Scalars['String']['output'];
};

export type PlaceGeometry = {
  location: LatLng;
};

export type PlaceStructuredFormatting = {
  mainText: Scalars['String']['output'];
  secondaryText: Maybe<Scalars['String']['output']>;
};

export type Practice = {
  practiceID: Scalars['UUID']['output'];
  practiceValues: PracticeValues;
};

export type PracticeContentInput = {
  eatingCommitmentEntry?: InputMaybe<EatingCommitmentEntryInput>;
};

export type PracticeRating = {
  type: RatingType;
  value: Scalars['Float']['output'];
};

export type PracticeRatingInput = {
  type: RatingType;
  value: Scalars['Float']['input'];
};

export type PracticeRatingInterval = {
  practiceRating: Maybe<PracticeRating>;
  startDate: Scalars['Date']['output'];
};

export const PracticeType = {
  ACTIVITY: 'ACTIVITY',
  BREATH_HOLDING: 'BREATH_HOLDING',
  EATING_CHECKIN: 'EATING_CHECKIN',
  EATING_COMMITMENT_ENTRY: 'EATING_COMMITMENT_ENTRY',
  EATING_LOG_ENTRY: 'EATING_LOG_ENTRY',
  EATING_PROGRESS_ENTRY: 'EATING_PROGRESS_ENTRY',
  HYPERVENTILATION: 'HYPERVENTILATION',
  RELAX_DIARY_ENTRY: 'RELAX_DIARY_ENTRY',
  SLEEP_DIARY_ENTRY: 'SLEEP_DIARY_ENTRY',
  THOUGHT_DIARY_ENTRY: 'THOUGHT_DIARY_ENTRY',
  WORD_PAIRING: 'WORD_PAIRING'
} as const;

export type PracticeType = typeof PracticeType[keyof typeof PracticeType];
export type PracticeValues = {
  date: Scalars['Date']['output'];
  /** @deprecated Prefer role */
  patient: Patient;
  ratings: Array<PracticeRating>;
  role: Role;
};

export type PracticeValuesInput = {
  date?: InputMaybe<Scalars['Date']['input']>;
  patientID: Scalars['UUID']['input'];
  ratings: Array<PracticeRatingInput>;
};

export type Practitioner = {
  ID: Scalars['UUID']['output'];
  /** @deprecated No longer used */
  npi: Maybe<Scalars['Int']['output']>;
  person: Person;
};

export type PractitionerInput = {
  /** @deprecated unused */
  npi?: InputMaybe<Scalars['Int']['input']>;
  person: PersonInput;
};

export type Presence = {
  connected: Scalars['Boolean']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type Product = {
  ID: Scalars['UUID']['output'];
  iconUrl: Maybe<Scalars['String']['output']>;
  logoUrl: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  roleTypes: Array<ProductRoleType>;
  slug: Scalars['String']['output'];
  staticEnabled: Scalars['Boolean']['output'];
};

export type ProductRoleType = {
  cmsVariant: Maybe<CmsVariant>;
  name: Scalars['String']['output'];
  roleType: RoleType;
};

export const ProductVariant = {
  AVIVA_ADOLESCENT: 'AVIVA_ADOLESCENT',
  AVIVA_ADULT: 'AVIVA_ADULT',
  AVIVA_INPATIENT: 'AVIVA_INPATIENT',
  AVIVA_MILITARY: 'AVIVA_MILITARY',
  AVIVA_STATIC: 'AVIVA_STATIC',
  AVIVA_SUPPORT: 'AVIVA_SUPPORT',
  OUI_PLATFORM: 'OUI_PLATFORM',
  YST_GUARDIAN: 'YST_GUARDIAN',
  YST_SUPPORT: 'YST_SUPPORT',
  YST_YOUTH: 'YST_YOUTH'
} as const;

export type ProductVariant = typeof ProductVariant[keyof typeof ProductVariant];
export const ProductVersion = {
  AVIVA_1_1: 'AVIVA_1_1',
  AVIVA_2_0: 'AVIVA_2_0'
} as const;

export type ProductVersion = typeof ProductVersion[keyof typeof ProductVersion];
/** Deprecated - use productStatic instead */
export const PtsdDosageVersion = {
  V1: 'V1',
  V2: 'V2'
} as const;

export type PtsdDosageVersion = typeof PtsdDosageVersion[keyof typeof PtsdDosageVersion];
export type Query = {
  /** Returns a list of access logs for a given user */
  accessLogsByUser: Array<AccessLog>;
  /** Returns a list of access logs from a given entity type and entity ID */
  accessLogsForEntity: Array<AccessLog>;
  actionCounts: Array<ActionCount>;
  actionProgresses: Array<ActionProgress>;
  actionTodos: ActionTodos;
  activityPracticeByID: Maybe<ActivityPractice>;
  /** Returns a single fileUpload object from the provided ID */
  appByID: Maybe<CmsApp>;
  /** Returns a list of all apps */
  apps: Array<CmsApp>;
  asset: Maybe<Scalars['String']['output']>;
  botConfigState: Maybe<BotState>;
  botHistory: Array<BotHistory>;
  careTeamByPatientID: CareTeam;
  completedQuizSetsForPatient: CompletedPatientQuizSets;
  compositionByID: Maybe<Composition>;
  compositions: Array<Composition>;
  /** @deprecated for transition use only */
  currentUser: LegacyUser;
  currentYstPatient: YstProfile;
  /** Returns a single fileUpload object from the provided ID */
  fileUploadByID: Maybe<FileUpload>;
  /**
   * Returns a list of FileUpload objects, optionally filter based on input file type:
   *         QUIZ_SET_VIDEO, CHAT_IMAGE, ILLUSTRATION, etc
   */
  fileUploads: Array<FileUpload>;
  hopeKitItems: Array<HopeKitItem>;
  jobByID: Job;
  /** Returns all Jobs */
  jobs: Array<Job>;
  kvResponse: KvResponse;
  lessonLearned: Maybe<Array<LessonLearned>>;
  minimumAppVersion: AppVersion;
  organizationByID: Maybe<Organization>;
  organizations: Maybe<Array<Organization>>;
  ouiUser: Maybe<CurrentOuiUser>;
  ouiUserEmailWithToken: Scalars['String']['output'];
  patientByPatientID: Maybe<PatientProfile>;
  patientSupporterLinkDetails: PatientSupportLinkDetails;
  patients: Array<PatientProfile>;
  placeAutocomplete: Array<PlaceAutocomplete>;
  placeDetails: PlaceDetails;
  practiceRatings: Array<PracticeRatingInterval>;
  practices: Array<Practice>;
  products: Array<Product>;
  quizSetCollectionByID: QuizSetCollection;
  quizSetCollections: Array<QuizSetCollection>;
  /** @deprecated Replaced by Role.relaxDiaryWidget() */
  relaxDiaryConfig: RelaxDiaryConfig;
  relaxDiaryEntryCounts: Scalars['Map']['output'];
  sessionByID: Maybe<CmsSession>;
  sessionGroupByID: Maybe<CmsSessionGroup>;
  sleepDiaryEntryPracticeByDate: Maybe<SleepDiaryEntryPractice>;
  sleepDiaryEntryPracticeByID: Maybe<SleepDiaryEntryPractice>;
  thoughtDiaryEntryPracticeByID: Maybe<ThoughtDiaryEntryPractice>;
  treatmentV2: Maybe<Array<Session>>;
  user: Maybe<User>;
  userByID: Maybe<User>;
  users: Array<User>;
  /** Returns a single variant object from the provided ID */
  variantByID: Maybe<CmsVariant>;
  ystOrganizationBySlug: Maybe<YstOrganization>;
  ystOrganizations: Array<YstOrganization>;
};


export type QueryAccessLogsByUserArgs = {
  input: UserAccessLogRequestInput;
};


export type QueryAccessLogsForEntityArgs = {
  input: EntityAccessLogRequestInput;
};


export type QueryActionCountsArgs = {
  actionTypes?: InputMaybe<Array<ActionType>>;
  after?: InputMaybe<Scalars['DateTime']['input']>;
  before?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryActionProgressesArgs = {
  after: Scalars['Date']['input'];
  before: Scalars['Date']['input'];
};


export type QueryActivityPracticeByIdArgs = {
  practiceID: Scalars['UUID']['input'];
};


export type QueryAppByIdArgs = {
  appID: Scalars['UUID']['input'];
};


export type QueryAssetArgs = {
  context: Scalars['String']['input'];
  key: Scalars['String']['input'];
};


export type QueryBotConfigStateArgs = {
  configID: Scalars['String']['input'];
  hash?: InputMaybe<Scalars['String']['input']>;
};


export type QueryBotHistoryArgs = {
  configID: Scalars['String']['input'];
  lastValidOccurredAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastValidTimestamp?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCareTeamByPatientIdArgs = {
  patientID: Scalars['UUID']['input'];
};


export type QueryCompletedQuizSetsForPatientArgs = {
  input: CompletedQuizSetsForPatientInput;
};


export type QueryCompositionByIdArgs = {
  compositionID: Scalars['UUID']['input'];
};


export type QueryCompositionsArgs = {
  patientID?: InputMaybe<Scalars['UUID']['input']>;
  template?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFileUploadByIdArgs = {
  fileUploadID: Scalars['UUID']['input'];
};


export type QueryFileUploadsArgs = {
  fileUploadType?: InputMaybe<FileUploadType>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
};


export type QueryJobByIdArgs = {
  jobID: Scalars['UUID']['input'];
};


export type QueryJobsArgs = {
  jobType?: InputMaybe<JobType>;
  status?: InputMaybe<JobStatus>;
};


export type QueryKvResponseArgs = {
  context: Scalars['String']['input'];
  key: Scalars['String']['input'];
};


export type QueryOrganizationByIdArgs = {
  organizationID: Scalars['UUID']['input'];
};


export type QueryOrganizationsArgs = {
  parentOnly?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryOuiUserEmailWithTokenArgs = {
  value: Scalars['String']['input'];
};


export type QueryPatientByPatientIdArgs = {
  patientID: Scalars['UUID']['input'];
};


export type QueryPatientSupporterLinkDetailsArgs = {
  token: Scalars['String']['input'];
};


export type QueryPatientsArgs = {
  searchPattern?: InputMaybe<Scalars['String']['input']>;
  withOrganizationID?: InputMaybe<Scalars['UUID']['input']>;
  withPrimaryPractitionerID?: InputMaybe<Scalars['UUID']['input']>;
  withoutProductVariant?: InputMaybe<Array<ProductVariant>>;
};


export type QueryPlaceAutocompleteArgs = {
  latlng?: InputMaybe<LatLngInput>;
  query: Scalars['String']['input'];
  sessionToken: Scalars['String']['input'];
};


export type QueryPlaceDetailsArgs = {
  placeId: Scalars['String']['input'];
  sessionToken: Scalars['String']['input'];
};


export type QueryPracticeRatingsArgs = {
  after?: InputMaybe<Scalars['Date']['input']>;
  before?: InputMaybe<Scalars['Date']['input']>;
  duration: RatingDuration;
  practiceType: PracticeType;
  ratingType: RatingType;
};


export type QueryPracticesArgs = {
  after?: InputMaybe<Scalars['Date']['input']>;
  before?: InputMaybe<Scalars['Date']['input']>;
  practiceTypes: Array<PracticeType>;
};


export type QueryQuizSetCollectionByIdArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  quizSetCollectionID: Scalars['UUID']['input'];
};


export type QueryQuizSetCollectionsArgs = {
  sessionID?: InputMaybe<Scalars['UUID']['input']>;
};


export type QuerySessionByIdArgs = {
  sessionID: Scalars['UUID']['input'];
};


export type QuerySessionGroupByIdArgs = {
  groupID: Scalars['UUID']['input'];
};


export type QuerySleepDiaryEntryPracticeByDateArgs = {
  date: Scalars['Date']['input'];
  patientID?: InputMaybe<Scalars['UUID']['input']>;
};


export type QuerySleepDiaryEntryPracticeByIdArgs = {
  practiceID: Scalars['UUID']['input'];
};


export type QueryThoughtDiaryEntryPracticeByIdArgs = {
  practiceID: Scalars['UUID']['input'];
};


export type QueryUserByIdArgs = {
  userID: Scalars['UUID']['input'];
};


export type QueryUsersArgs = {
  input: QueryUsersInput;
};


export type QueryVariantByIdArgs = {
  variantID: Scalars['UUID']['input'];
};


export type QueryYstOrganizationBySlugArgs = {
  slug: Scalars['String']['input'];
};

export type QueryUsersInput = {
  /** Optional filter by Organization or Child Organization */
  organizationID?: InputMaybe<Scalars['UUID']['input']>;
  /** Optional filter by Primary Practitioners */
  primaryPractitionerIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Optional filter by Product */
  productID?: InputMaybe<Scalars['UUID']['input']>;
  /** Optional search query matched as a substring of name or email */
  query?: InputMaybe<Scalars['String']['input']>;
  /** Optional filter by Role Types */
  roleTypes?: InputMaybe<Array<RoleType>>;
  /** Optional filter by Sessions completed */
  sessions?: InputMaybe<Array<ContentType>>;
};

export type QuizSet = {
  /** Used by app as key/ID for tracking completion. Stable across snapshots. */
  ID: Scalars['String']['output'];
  description: Scalars['String']['output'];
  items: Array<QuizSetItem>;
  legacyItems: Array<LegacyQuizSetItem>;
  name: Scalars['String']['output'];
  previewOptions: Maybe<QuizSetPreviewOptions>;
  previewTime: Maybe<Scalars['Int']['output']>;
  /** Used by CMS as primary key for mutations */
  quizSetID: Scalars['String']['output'];
};

export type QuizSetCollection = {
  name: Scalars['String']['output'];
  quizSetCollectionID: Scalars['UUID']['output'];
  session: CmsSession;
  sets: Array<QuizSet>;
};

export type QuizSetCollectionCreateInput = {
  name: Scalars['String']['input'];
  nodeID: Scalars['UUID']['input'];
  quizSetCollectionID: Scalars['UUID']['input'];
  sessionID: Scalars['UUID']['input'];
};

export type QuizSetCollectionCreatePayload = {
  quizSetCollection: QuizSetCollection;
};

export type QuizSetCollectionUpdateInput = {
  name: Scalars['String']['input'];
  quizSetCollectionID: Scalars['String']['input'];
};

export type QuizSetCollectionUpdatePayload = {
  quizSetCollection: QuizSetCollection;
};

export type QuizSetCreateInput = {
  quizSet: QuizSetInput;
  quizSetCollectionID: Scalars['UUID']['input'];
};

export type QuizSetCreatePayload = {
  quizSet: QuizSet;
};

export type QuizSetDeleteInput = {
  quizSetID: Scalars['UUID']['input'];
};

export type QuizSetDeletePayload = {
  success: Scalars['Boolean']['output'];
};

export type QuizSetInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type QuizSetItem = {
  quizSetItemID: Scalars['UUID']['output'];
};

export type QuizSetItemChoice = QuizSetItem & {
  afterAllFeedback: Scalars['String']['output'];
  correctFeedback: Scalars['String']['output'];
  incorrectFeedback: Scalars['String']['output'];
  options: Array<QuizSetItemChoiceOption>;
  prompt: Scalars['String']['output'];
  quizSetItemID: Scalars['UUID']['output'];
  type: QuizSetItemType;
};

export type QuizSetItemChoiceCreateInput = {
  quizSetID: Scalars['UUID']['input'];
  quizSetItemChoice: QuizSetItemChoiceInput;
};

export type QuizSetItemChoiceInput = {
  afterAllFeedback: Scalars['String']['input'];
  correctFeedback: Scalars['String']['input'];
  incorrectFeedback: Scalars['String']['input'];
  options: Array<QuizSetItemChoiceOptionInput>;
  prompt: Scalars['String']['input'];
  type: QuizSetItemType;
};

export type QuizSetItemChoiceOption = {
  correct: Scalars['Boolean']['output'];
  feedback: Scalars['String']['output'];
  text: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type QuizSetItemChoiceOptionInput = {
  correct: Scalars['Boolean']['input'];
  feedback: Scalars['String']['input'];
  text: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type QuizSetItemChoiceQuestionProps = {
  label: Scalars['QuizSetItemQuestionLabel']['output'];
};

export type QuizSetItemChoiceUpdateInput = {
  quizSetItemChoice: QuizSetItemChoiceInput;
  quizSetItemID: Scalars['UUID']['input'];
};

export type QuizSetItemCreatePayload = {
  legacyQuizSetItem: LegacyQuizSetItem;
  quizSetItem: QuizSetItem;
};

export type QuizSetItemDeleteInput = {
  quizSetItemID: Scalars['UUID']['input'];
};

export type QuizSetItemDeletePayload = {
  success: Scalars['Boolean']['output'];
};

export type QuizSetItemQuestionDirective = {
  afterAll: Maybe<Scalars['String']['output']>;
  feedback: Scalars['QuizSetItemQuestionDirectiveFeedback']['output'];
  prompt: Scalars['String']['output'];
};

export const QuizSetItemType = {
  MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
  SINGLE_CHOICE: 'SINGLE_CHOICE',
  VIDEO: 'VIDEO'
} as const;

export type QuizSetItemType = typeof QuizSetItemType[keyof typeof QuizSetItemType];
export type QuizSetItemUpdateOrderInput = {
  order: Scalars['Int']['input'];
  quizSetItemID: Scalars['UUID']['input'];
};

export type QuizSetItemUpdateOrderPayload = {
  success: Scalars['Boolean']['output'];
};

export type QuizSetItemVideo = QuizSetItem & {
  fileUpload: FileUpload;
  quizSetItemID: Scalars['UUID']['output'];
  url: Maybe<Scalars['String']['output']>;
};

export type QuizSetItemVideoCreateInput = {
  quizSetID: Scalars['UUID']['input'];
  quizSetItemVideo: QuizSetItemVideoInput;
};

export type QuizSetItemVideoInput = {
  fileUploadID: Scalars['String']['input'];
};

export type QuizSetPreviewOptions = {
  fitMode: Maybe<MuxFitMode>;
  height: Maybe<Scalars['Int']['output']>;
  time: Maybe<Scalars['Int']['output']>;
};

export type QuizSetUpdateInput = {
  quizSet: QuizSetInput;
  quizSetID: Scalars['UUID']['input'];
};

export type QuizSetUpdatePayload = {
  quizSet: QuizSet;
};

export const RatingDuration = {
  ALL: 'ALL',
  DAY: 'DAY',
  MONTH: 'MONTH',
  WEEK: 'WEEK',
  YEAR: 'YEAR'
} as const;

export type RatingDuration = typeof RatingDuration[keyof typeof RatingDuration];
export const RatingType = {
  QUALITY: 'QUALITY',
  RATING: 'RATING',
  RATING_AFTER: 'RATING_AFTER',
  RATING_BEFORE: 'RATING_BEFORE'
} as const;

export type RatingType = typeof RatingType[keyof typeof RatingType];
export type Registrar = {
  ID: Scalars['UUID']['output'];
  person: Person;
};

export type RegistrarInput = {
  person: PersonInput;
};

export type RelaxAction = Action & {
  actionID: Scalars['UUID']['output'];
  actionValues: ActionValues;
};

export type RelaxDiaryConfig = {
  /** Cache key for the config based on requesting client */
  ID: Scalars['String']['output'];
  breathingCircleExercise: RelaxExercise;
  mindfulnessExercises: Array<RelaxExercise>;
  mindfulnessTitle: Scalars['String']['output'];
  /** Display name for the widget */
  name: Scalars['String']['output'];
};

export type RelaxDiaryEntry = {
  completion: Scalars['Float']['output'];
  slug: Scalars['String']['output'];
};

export type RelaxDiaryEntryInput = {
  completion: Scalars['Float']['input'];
  slug: Scalars['String']['input'];
};

export type RelaxDiaryEntryPractice = Practice & {
  practiceID: Scalars['UUID']['output'];
  practiceValues: PracticeValues;
  relaxDiaryEntry: RelaxDiaryEntry;
};

export type RelaxDiaryWidget = Widget & {
  ID: Scalars['ID']['output'];
  breathingCircleExercise: RelaxExercise;
  description: Scalars['String']['output'];
  iconName: Scalars['String']['output'];
  locked: Scalars['Boolean']['output'];
  mindfulnessExercises: Array<RelaxExercise>;
  mindfulnessTitle: Scalars['String']['output'];
  name: Scalars['String']['output'];
  practiceCount: Scalars['Int']['output'];
  practiceTileColor: Scalars['String']['output'];
  practiceTileDeeplink: Scalars['String']['output'];
  unlockSession: Maybe<RoleSession>;
};

export type RelaxExercise = {
  audioUrl: Scalars['String']['output'];
  illustrationUrl: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  subtitleUrl: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type RemoveHopeKitItemInput = {
  hopeKitItemID: Scalars['UUID']['input'];
};

export type RemoveHopeKitItemPayload = {
  hopeKitItemID: Scalars['UUID']['output'];
};

export type ReplacePrimaryPractitionerResult = {
  careTeams: Array<CareTeam>;
  success: Scalars['Boolean']['output'];
};

export type ResumableAssetCreateInput = {
  context: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  key: Scalars['String']['input'];
  uploadType: UploadType;
};

export type ResumableUploadAssetSession = {
  asset: Asset;
  resumableUploadAssetURI: Scalars['String']['output'];
};

export type Role = {
  ID: Scalars['UUID']['output'];
  activities: Array<YstSupporterActivity>;
  activityDiaryWidget: Maybe<ActivityDiaryWidget>;
  bingeEatingMyPlanWidget: Maybe<BingeEatingMyPlanWidget>;
  breathHoldingWidget: Maybe<BreathHoldingWidget>;
  checkinAvailability: Scalars['YstRecurringAvailability']['output'];
  checkins: Array<YstSupporterCheckin>;
  cmsVariant: Maybe<CmsVariant>;
  composition: Maybe<Composition>;
  copingCardsWidget: Maybe<CopingCardsWidget>;
  /** @deprecated Replaced by composition(template) */
  eatingCommitmentComposition: Maybe<Composition>;
  eatingCommitmentEntries: Array<EatingCommitmentEntryPractice>;
  eatingLogEntries: Array<EatingLogEntryPractice>;
  eatingLogEntryByID: Maybe<EatingLogEntryPractice>;
  eatingLogWidget: Maybe<EatingLogWidget>;
  eatingProgressEntries: Array<EatingProgressEntryPractice>;
  eatingProgressEntryByID: Maybe<EatingProgressEntryPractice>;
  /** @deprecated Replaced by composition(template) */
  eatingScheduleComposition: Maybe<Composition>;
  hopeKitWidget: Maybe<HopeKitWidget>;
  hyperventilationWidget: Maybe<HyperventilationWidget>;
  mentalHealthConcerns: Maybe<Scalars['YstMentalHealthConcerns']['output']>;
  messages: Array<YstPatientMessage>;
  mostCompleted: Maybe<MostCompleted>;
  myPlanWidget: Maybe<MyPlanWidget>;
  nomination: Maybe<YstNomination>;
  nominations: Array<YstNomination>;
  onboardingAvailability: Maybe<Scalars['YstOnboardingAvailability']['output']>;
  onboardingAvailabilitySaved: Maybe<Scalars['Boolean']['output']>;
  organization: Organization;
  practitioners: Array<Role>;
  primaryPractitioner: Maybe<Role>;
  product: Product;
  /** Product-specific configuration for this Role, or null if none */
  productConfig: Maybe<RoleProductConfig>;
  productStatic: Scalars['Boolean']['output'];
  progress: Array<OuiProgress>;
  relaxDiaryWidget: Maybe<RelaxDiaryWidget>;
  roleLabel: Scalars['String']['output'];
  roleType: RoleType;
  sessions: Array<RoleSession>;
  /** Product-specific settings for this Role, or null if none */
  settings: Maybe<RoleSettings>;
  sleepDiaryWidget: Maybe<SleepDiaryWidget>;
  status: Scalars['String']['output'];
  /** Get length of current "streak" of activity. */
  streak: Scalars['Int']['output'];
  /** Get days which are part of a "streak" of activity. */
  streaks: Array<Scalars['Date']['output']>;
  supportee: Maybe<Supportee>;
  supporters: Array<Supporter>;
  thoughtDiaryWidget: Maybe<ThoughtDiaryWidget>;
  user: User;
  widgets: Array<Widget>;
  wordPairingWidget: Maybe<WordPairingWidget>;
};


export type RoleCompositionArgs = {
  template: Scalars['String']['input'];
};


export type RoleEatingLogEntriesArgs = {
  after?: InputMaybe<Scalars['Date']['input']>;
  before?: InputMaybe<Scalars['Date']['input']>;
};


export type RoleEatingLogEntryByIdArgs = {
  practiceID: Scalars['UUID']['input'];
};


export type RoleEatingProgressEntryByIdArgs = {
  practiceID: Scalars['UUID']['input'];
};


export type RoleSessionsArgs = {
  contentType?: InputMaybe<Scalars['String']['input']>;
};


export type RoleSupportersArgs = {
  roleType?: InputMaybe<RoleType>;
};

export type RoleAvivaConfig = {
  caringContactsMedium: CaringContactsMedium;
  crisisFramework: CrisisFramework;
  lastCaringContactAt: Maybe<Scalars['DateTime']['output']>;
  onboardingVariant: Scalars['String']['output'];
  productVersion: ProductVersion;
};

export type RoleAvivaConfigInput = {
  caringContactsMedium: CaringContactsMedium;
  crisisFramework: CrisisFramework;
  onboardingVariant: OnboardingVariant;
  productVersion: ProductVersion;
};

export type RoleInput = {
  createRole?: InputMaybe<CreateRoleInput>;
  deleteRole?: InputMaybe<DeleteRoleInput>;
  updateRole?: InputMaybe<UpdateRoleInput>;
};

export type RolePractitionerInput = {
  practitionerID: Scalars['UUID']['input'];
  primaryPractitioner: Scalars['Boolean']['input'];
};

export type RoleProductConfig = RoleAvivaConfig | RolePtsdConfig;

export type RoleProductConfigInput = {
  avivaConfig?: InputMaybe<RoleAvivaConfigInput>;
};

export type RolePtsdConfig = {
  /** @deprecated Use productStatic instead */
  dosageVersion: PtsdDosageVersion;
};

export type RoleSession = {
  /** Get Patient's Progress for this Session, or null if not started yet. */
  progress: Maybe<OuiProgress>;
  role: Role;
  session: CmsSession;
  status: SessionState;
  /** Get the time the Session was unlocked, or will be unlocked in the future, or null if unknown. */
  unlockedAt: Maybe<Scalars['DateTime']['output']>;
};

export type RoleSettings = ChangeBiteRoleSettings;

export type RoleSettingsInput = {
  changeBiteSettings?: InputMaybe<ChangeBiteRoleSettingsInput>;
};

export const RoleType = {
  GUARDIAN: 'GUARDIAN',
  PATIENT: 'PATIENT',
  PRACTITIONER: 'PRACTITIONER',
  REGISTRAR: 'REGISTRAR',
  SUPPORTER: 'SUPPORTER'
} as const;

export type RoleType = typeof RoleType[keyof typeof RoleType];
export type Session = {
  artifact: Maybe<Artifact>;
  description: Scalars['String']['output'];
  ordinal: Scalars['Int']['output'];
  progress: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  subtitle: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type SessionContentForApp = {
  activities: Array<AppActivity>;
  contentTypes: Array<Scalars['String']['output']>;
  exchange: CmsExchange;
  quizSetCollections: Array<QuizSetCollection>;
};

export const SessionState = {
  COMPLETED: 'COMPLETED',
  IN_PROGRESS: 'IN_PROGRESS',
  LOCKED: 'LOCKED',
  UNLOCKED: 'UNLOCKED'
} as const;

export type SessionState = typeof SessionState[keyof typeof SessionState];
export type SimpleError = BaseError & {
  message: Scalars['String']['output'];
};

export type SleepDiaryEntry = {
  alcohol: Maybe<SleepDiaryEntryInfluencer>;
  caffeine: Maybe<SleepDiaryEntryInfluencer>;
  deviceInBed: Maybe<SleepDiaryEntryInfluencer>;
  endTime: Scalars['DateTime']['output'];
  exercise: Maybe<SleepDiaryEntryInfluencer>;
  medicine: Maybe<SleepDiaryEntryInfluencer>;
  napping: Maybe<SleepDiaryEntryInfluencer>;
  sleepDelay: Maybe<SleepDiaryEntryDuration>;
  startTime: Scalars['DateTime']['output'];
  tobacco: Maybe<SleepDiaryEntryInfluencer>;
  wakeupCount: Scalars['Int']['output'];
  wakeupDuration: Maybe<SleepDiaryEntryDuration>;
};

export const SleepDiaryEntryDuration = {
  DURATION_1_TO_5: 'DURATION_1_TO_5',
  DURATION_5_TO_10: 'DURATION_5_TO_10',
  DURATION_15_TO_30: 'DURATION_15_TO_30',
  DURATION_20_TO_45: 'DURATION_20_TO_45',
  DURATION_30_TO_60: 'DURATION_30_TO_60',
  DURATION_45_TO_60: 'DURATION_45_TO_60',
  DURATION_60_TO_120: 'DURATION_60_TO_120',
  DURATION_120_TO_180: 'DURATION_120_TO_180',
  DURATION_LESS_THAN_15: 'DURATION_LESS_THAN_15',
  DURATION_MORE_THAN_60: 'DURATION_MORE_THAN_60',
  DURATION_MORE_THAN_120: 'DURATION_MORE_THAN_120',
  DURATION_MORE_THAN_180: 'DURATION_MORE_THAN_180'
} as const;

export type SleepDiaryEntryDuration = typeof SleepDiaryEntryDuration[keyof typeof SleepDiaryEntryDuration];
export type SleepDiaryEntryInfluencer = {
  count: Maybe<Scalars['Int']['output']>;
  duration: Maybe<SleepDiaryEntryDuration>;
  kinds: Maybe<Array<Scalars['String']['output']>>;
  occurred: Scalars['Boolean']['output'];
  timesOfDay: Array<SleepDiaryEntryTimeOfDay>;
};

export type SleepDiaryEntryInfluencerInput = {
  count?: InputMaybe<Scalars['Int']['input']>;
  duration?: InputMaybe<SleepDiaryEntryDuration>;
  kinds?: InputMaybe<Array<Scalars['String']['input']>>;
  occurred: Scalars['Boolean']['input'];
  timesOfDay: Array<SleepDiaryEntryTimeOfDay>;
};

export type SleepDiaryEntryInput = {
  alcohol?: InputMaybe<SleepDiaryEntryInfluencerInput>;
  caffeine?: InputMaybe<SleepDiaryEntryInfluencerInput>;
  deviceInBed?: InputMaybe<SleepDiaryEntryInfluencerInput>;
  endTime: Scalars['DateTime']['input'];
  exercise?: InputMaybe<SleepDiaryEntryInfluencerInput>;
  medicine?: InputMaybe<SleepDiaryEntryInfluencerInput>;
  napping?: InputMaybe<SleepDiaryEntryInfluencerInput>;
  sleepDelay?: InputMaybe<SleepDiaryEntryDuration>;
  startTime: Scalars['DateTime']['input'];
  tobacco?: InputMaybe<SleepDiaryEntryInfluencerInput>;
  wakeupCount: Scalars['Int']['input'];
  wakeupDuration?: InputMaybe<SleepDiaryEntryDuration>;
};

export type SleepDiaryEntryMorningAction = Action & {
  actionID: Scalars['UUID']['output'];
  actionValues: ActionValues;
  sleepDiaryEntryPractice: SleepDiaryEntryPractice;
};

export type SleepDiaryEntryNightAction = Action & {
  actionID: Scalars['UUID']['output'];
  actionValues: ActionValues;
  sleepDiaryEntryPractice: SleepDiaryEntryPractice;
};

export type SleepDiaryEntryPractice = Practice & {
  practiceID: Scalars['UUID']['output'];
  practiceValues: PracticeValues;
  sleepDiaryEntry: SleepDiaryEntry;
};

export const SleepDiaryEntryTimeOfDay = {
  AFTERNOON: 'AFTERNOON',
  EVENING: 'EVENING',
  MORNING: 'MORNING',
  NIGHT: 'NIGHT'
} as const;

export type SleepDiaryEntryTimeOfDay = typeof SleepDiaryEntryTimeOfDay[keyof typeof SleepDiaryEntryTimeOfDay];
export type SleepDiaryWidget = Widget & {
  ID: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  iconName: Scalars['String']['output'];
  locked: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  practiceCount: Scalars['Int']['output'];
  practiceTileColor: Scalars['String']['output'];
  practiceTileDeeplink: Scalars['String']['output'];
  unlockSession: Maybe<RoleSession>;
};

export type SnapshotQuizSetCollectionInput = {
  quizSetCollectionID: Scalars['UUID']['input'];
};

export type SnapshotSessionInput = {
  sessionID: Scalars['UUID']['input'];
};

export type StaticAction = Action & {
  actionID: Scalars['UUID']['output'];
  actionValues: ActionValues;
};

export type StressBreathingExercise = {
  isComplete: Scalars['Boolean']['output'];
  rounds: Array<StressBreathingRound>;
};

export type StressBreathingExerciseInput = {
  rounds: Array<StressBreathingRoundInput>;
};

export type StressBreathingRound = {
  /** Distress rating, between 1(no distress) and 10(max distress), or null if not rated */
  distressRating: Maybe<Scalars['Int']['output']>;
  isComplete: Scalars['Boolean']['output'];
};

export type StressBreathingRoundInput = {
  /** Distress rating between 1(no distress) and 10(max distress), omit if not rated */
  distressRating?: InputMaybe<Scalars['Int']['input']>;
};

export type Subscription = {
  compositionByID: Composition;
  patientPresence: Presence;
};


export type SubscriptionCompositionByIdArgs = {
  compositionID: Scalars['UUID']['input'];
};


export type SubscriptionPatientPresenceArgs = {
  patientID: Scalars['UUID']['input'];
};

export type Supportee = {
  ID: Scalars['String']['output'];
  claimed: Maybe<Scalars['Boolean']['output']>;
  relation: PatientSupporterRelation;
  reviewed: Maybe<Scalars['Boolean']['output']>;
  supportee: Role;
};

export type Supporter = {
  ID: Scalars['String']['output'];
  claimed: Maybe<Scalars['Boolean']['output']>;
  relation: PatientSupporterRelation;
  reviewed: Maybe<Scalars['Boolean']['output']>;
  supporter: Role;
};

export type SupporterToken = {
  url: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type TestPatientInput = {
  caringContactsMedium?: InputMaybe<CaringContactsMedium>;
  crisisFramework?: InputMaybe<CrisisFramework>;
  onboardingVariant?: InputMaybe<OnboardingVariant>;
  productSlug?: InputMaybe<Scalars['String']['input']>;
  productStatic?: InputMaybe<Scalars['Boolean']['input']>;
  productVersion?: InputMaybe<ProductVersion>;
  roleType?: InputMaybe<RoleType>;
};

export type TestRoleInput = {
  practitionerEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  productConfig?: InputMaybe<RoleProductConfigInput>;
  productID: Scalars['UUID']['input'];
  productStatic: Scalars['Boolean']['input'];
  roleType: RoleType;
  supporteeEmail?: InputMaybe<Scalars['String']['input']>;
  ystNominationStatus?: InputMaybe<Scalars['String']['input']>;
};

export type TestUserInput = {
  /** Provide a value to set a user password. If not provided, the user will still have to claim their account via claim code / invite link */
  password?: InputMaybe<Scalars['String']['input']>;
  roles: Array<TestRoleInput>;
  user: CreateUserInput;
};

export type TextItem = {
  ID: Scalars['UUID']['output'];
  text: Scalars['String']['output'];
};

export type TextItemInput = {
  ID: Scalars['UUID']['input'];
  text: Scalars['String']['input'];
};

export type ThoughtDiaryEntry = {
  behavior: Scalars['String']['output'];
  event: Scalars['String']['output'];
  evidenceAgainst: Array<TextItem>;
  evidenceFor: Array<TextItem>;
  feelingsAfter: Array<TextItem>;
  feelingsBefore: Array<TextItem>;
  thoughtAfter: Scalars['String']['output'];
  thoughtBefore: Scalars['String']['output'];
};

export type ThoughtDiaryEntryInput = {
  behavior: Scalars['String']['input'];
  event: Scalars['String']['input'];
  evidenceAgainst: Array<TextItemInput>;
  evidenceFor: Array<TextItemInput>;
  feelingsAfter: Array<TextItemInput>;
  feelingsBefore: Array<TextItemInput>;
  thoughtAfter: Scalars['String']['input'];
  thoughtBefore: Scalars['String']['input'];
};

export type ThoughtDiaryEntryPractice = Practice & {
  practiceID: Scalars['UUID']['output'];
  practiceValues: PracticeValues;
  thoughtDiaryEntry: ThoughtDiaryEntry;
};

export type ThoughtDiaryEntrySpotAction = Action & {
  actionID: Scalars['UUID']['output'];
  actionValues: ActionValues;
  thoughtDiaryEntryPractice: ThoughtDiaryEntryPractice;
};

export type ThoughtDiaryEntrySwitchAction = Action & {
  actionID: Scalars['UUID']['output'];
  actionValues: ActionValues;
  thoughtDiaryEntryPractice: ThoughtDiaryEntryPractice;
};

export type ThoughtDiaryEntryTestAction = Action & {
  actionID: Scalars['UUID']['output'];
  actionValues: ActionValues;
  thoughtDiaryEntryPractice: ThoughtDiaryEntryPractice;
};

export type ThoughtDiaryWidget = Widget & {
  ID: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  iconName: Scalars['String']['output'];
  locked: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  practiceCount: Scalars['Int']['output'];
  practiceTileColor: Scalars['String']['output'];
  practiceTileDeeplink: Scalars['String']['output'];
  unlockSession: Maybe<RoleSession>;
};

export type Token = {
  expiresAt: Scalars['DateTime']['output'];
  ouiUser: OuiUser;
  value: Scalars['String']['output'];
};

export type UpdateActivityPracticeInput = {
  activity: ActivityInput;
  practiceID: Scalars['UUID']['input'];
  practiceValues: PracticeValuesInput;
};

export type UpdateActivityPracticePayload = {
  activityPractice: ActivityPractice;
};

export type UpdateEatingLogEntryInput = {
  deleteImage?: InputMaybe<Scalars['Boolean']['input']>;
  eatingLogEntry: EatingLogEntryInput;
  practiceID: Scalars['UUID']['input'];
  practiceValues: PracticeValuesInput;
};

export type UpdateHopeKitImageInput = {
  hopeKitItemID: Scalars['UUID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateHopeKitImagePayload = {
  hopeKitImage: HopeKitImage;
};

export type UpdateHopeKitQuoteInput = {
  author?: InputMaybe<Scalars['String']['input']>;
  hopeKitItemID: Scalars['UUID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
};

export type UpdateHopeKitQuotePayload = {
  hopeKitQuote: HopeKitQuote;
};

export type UpdateHopeKitVideoInput = {
  hopeKitItemID: Scalars['UUID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateHopeKitVideoPayload = {
  hopeKitVideo: HopeKitVideo;
};

export type UpdateOuiUserInput = {
  currentPassword?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  person?: InputMaybe<PersonUpdateInput>;
};

export type UpdatePracticeResult = {
  practice: Practice;
};

export type UpdateRelaxDiaryEntryPracticeInput = {
  practiceID: Scalars['UUID']['input'];
  practiceValues: PracticeValuesInput;
  relaxDiaryEntry: RelaxDiaryEntryInput;
};

export type UpdateRelaxDiaryEntryPracticePayload = {
  relaxDiaryEntryPractice: RelaxDiaryEntryPractice;
};

export type UpdateRoleInput = {
  organizationID?: InputMaybe<Scalars['UUID']['input']>;
  /** Only valid for PATIENT roles */
  practitioners?: InputMaybe<Array<RolePractitionerInput>>;
  productConfig?: InputMaybe<RoleProductConfigInput>;
  productID?: InputMaybe<Scalars['UUID']['input']>;
  productStatic?: InputMaybe<Scalars['Boolean']['input']>;
  roleID: Scalars['UUID']['input'];
  roleType?: InputMaybe<RoleType>;
  supporteeID?: InputMaybe<Scalars['UUID']['input']>;
  supporteeRelation?: InputMaybe<PatientSupporterRelation>;
};

export type UpdateSessionInput = {
  /** Up to 3 agenda items, each up to 30 characters */
  agenda?: InputMaybe<Array<Scalars['String']['input']>>;
  completedIllustrationID?: InputMaybe<Scalars['UUID']['input']>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  groupID: Scalars['UUID']['input'];
  illustrationID?: InputMaybe<Scalars['UUID']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  sessionID: Scalars['UUID']['input'];
  statusID?: InputMaybe<Scalars['Int']['input']>;
  variantID: Scalars['UUID']['input'];
};

export type UpdateSleepDiaryEntryPracticeInput = {
  practiceID: Scalars['UUID']['input'];
  practiceValues: PracticeValuesInput;
  sleepDiaryEntry: SleepDiaryEntryInput;
};

export type UpdateSleepDiaryEntryPracticePayload = {
  sleepDiaryEntryPractice: SleepDiaryEntryPractice;
};

export type UpdateThoughtDiaryEntryPracticeInput = {
  practiceID: Scalars['UUID']['input'];
  practiceValues: PracticeValuesInput;
  thoughtDiaryEntry: ThoughtDiaryEntryInput;
};

export type UpdateThoughtDiaryEntryPracticePayload = {
  thoughtDiaryEntryPractice: ThoughtDiaryEntryPractice;
};

export type UpdateUserInput = {
  addresses?: InputMaybe<Array<AddressInput>>;
  birthDate?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  familyName?: InputMaybe<Scalars['String']['input']>;
  genderIdentity?: InputMaybe<UserGenderIdentity>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  militaryStatus?: InputMaybe<UserMilitaryStatus>;
  phone?: InputMaybe<Scalars['String']['input']>;
  race?: InputMaybe<UserRace>;
  settings?: InputMaybe<UserSettingsInput>;
  sexualOrientation?: InputMaybe<UserSexualOrientation>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type UploadSession = {
  url: Scalars['String']['output'];
};

export const UploadType = {
  AUDIO: 'AUDIO',
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO'
} as const;

export type UploadType = typeof UploadType[keyof typeof UploadType];
export const UsageDuration = {
  DAY: 'DAY',
  MONTH: 'MONTH',
  WEEK: 'WEEK'
} as const;

export type UsageDuration = typeof UsageDuration[keyof typeof UsageDuration];
export type UsageInterval = {
  sessionsCount: Scalars['Int']['output'];
  /** Total duration of all sessions in milliseconds */
  sessionsDuration: Scalars['Int']['output'];
  startAt: Scalars['Date']['output'];
};

export type UsageSummary = {
  sessionsCount: Scalars['Int']['output'];
  /** Total duration of all sessions in milliseconds */
  sessionsDuration: Scalars['Int']['output'];
};

export type User = {
  ID: Scalars['UUID']['output'];
  addresses: Array<Address>;
  admin: Scalars['Boolean']['output'];
  attributes: UserAttributes;
  birthDate: Maybe<Scalars['Date']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedRoles: Array<DeletedRole>;
  email: Maybe<Scalars['String']['output']>;
  familyName: Maybe<Scalars['String']['output']>;
  genderIdentity: UserGenderIdentity;
  givenName: Maybe<Scalars['String']['output']>;
  lastLogin: Maybe<Scalars['DateTime']['output']>;
  locale: Maybe<Scalars['String']['output']>;
  militaryStatus: UserMilitaryStatus;
  passwordSetAt: Maybe<Scalars['DateTime']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  race: UserRace;
  /** Get Role for User, or null if not found. If multiple Roles are found and arguments are not provided they are inferred from the client. If multiple Roles still match a warning is logged and the first Role is returned. */
  role: Maybe<Role>;
  roles: Array<Role>;
  settings: UserSettings;
  sexualOrientation: UserSexualOrientation;
  timezone: Maybe<Scalars['String']['output']>;
  usage: Array<UsageInterval>;
  usageSummary: UsageSummary;
  username: Scalars['String']['output'];
};


export type UserRoleArgs = {
  productSlug?: InputMaybe<Scalars['String']['input']>;
  roleType?: InputMaybe<RoleType>;
};


export type UserUsageArgs = {
  input: UserUsageInput;
};


export type UserUsageSummaryArgs = {
  input: UserUsageSummaryInput;
};

export type UserAccessLogRequestInput = {
  userID: Scalars['UUID']['input'];
};

export type UserAddress = {
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  line1: Scalars['String']['output'];
  line2: Scalars['String']['output'];
  state: Maybe<Scalars['String']['output']>;
  zip: Maybe<Scalars['String']['output']>;
};

export type UserAddressInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  line1: Scalars['String']['input'];
  line2: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type UserAttributes = {
  admin: Scalars['Boolean']['output'];
  testUser: Scalars['Boolean']['output'];
  /** @deprecated Unused */
  trialAuditor: Scalars['Boolean']['output'];
};

export type UserDemo = {
  DOB: Maybe<Scalars['Date']['output']>;
  gender: Maybe<LegacyUserGender>;
  isVeteran: Maybe<Scalars['Boolean']['output']>;
  race: Maybe<LegacyUserRace>;
  sexuality: Maybe<LegacyUserSexuality>;
};

export type UserDemoInput = {
  DOB?: InputMaybe<Scalars['Date']['input']>;
  gender?: InputMaybe<LegacyUserGender>;
  isVeteran?: InputMaybe<Scalars['Boolean']['input']>;
  race?: InputMaybe<LegacyUserRace>;
  sexuality?: InputMaybe<LegacyUserSexuality>;
};

export type UserDisabledError = BaseError & {
  message: Scalars['String']['output'];
};

export type UserEmail = {
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  thirdPartyServiceID: Scalars['String']['output'];
  userEmailID: Scalars['UUID']['output'];
};

export const UserGenderIdentity = {
  FEMALE: 'FEMALE',
  MALE: 'MALE',
  NON_BINARY: 'NON_BINARY',
  NON_DISCLOSE: 'NON_DISCLOSE',
  TRANSGENDER_FEMALE: 'TRANSGENDER_FEMALE',
  TRANSGENDER_MALE: 'TRANSGENDER_MALE',
  UNKNOWN: 'UNKNOWN'
} as const;

export type UserGenderIdentity = typeof UserGenderIdentity[keyof typeof UserGenderIdentity];
export const UserMilitaryStatus = {
  ACTIVE_MILITARY_DUTY: 'ACTIVE_MILITARY_DUTY',
  CIVILIAN: 'CIVILIAN',
  DISABLED_VETERAN: 'DISABLED_VETERAN',
  FAMILY_MEMBER_OF_ACTIVE_DUTY: 'FAMILY_MEMBER_OF_ACTIVE_DUTY',
  FAMILY_MEMBER_OF_RETIRED_MILITARY: 'FAMILY_MEMBER_OF_RETIRED_MILITARY',
  INACTIVE_READY_RESERVE: 'INACTIVE_READY_RESERVE',
  NATIONAL_GUARD: 'NATIONAL_GUARD',
  NON_DISCLOSE: 'NON_DISCLOSE',
  RESERVES: 'RESERVES',
  RETIRED_MILITARY: 'RETIRED_MILITARY',
  UNKNOWN: 'UNKNOWN',
  VETERAN: 'VETERAN'
} as const;

export type UserMilitaryStatus = typeof UserMilitaryStatus[keyof typeof UserMilitaryStatus];
export type UserName = {
  first: Scalars['String']['output'];
  last: Scalars['String']['output'];
  pfx: Maybe<Scalars['String']['output']>;
  preferred: Maybe<Scalars['String']['output']>;
  sfx: Maybe<Scalars['String']['output']>;
};

export type UserNameInput = {
  first: Scalars['String']['input'];
  last: Scalars['String']['input'];
  pfx?: InputMaybe<Scalars['String']['input']>;
  preferred?: InputMaybe<Scalars['String']['input']>;
  sfx?: InputMaybe<Scalars['String']['input']>;
};

export const UserRace = {
  AMERICAN_INDIAN_OR_ALASKA_NATIVE: 'AMERICAN_INDIAN_OR_ALASKA_NATIVE',
  ASIAN: 'ASIAN',
  BLACK_OR_AFRICAN_AMERICAN: 'BLACK_OR_AFRICAN_AMERICAN',
  NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER: 'NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER',
  NON_DISCLOSE: 'NON_DISCLOSE',
  UNKNOWN: 'UNKNOWN',
  WHITE: 'WHITE'
} as const;

export type UserRace = typeof UserRace[keyof typeof UserRace];
export type UserSession = {
  /** @deprecated Prefer user */
  currentOuiUser: CurrentOuiUser;
  token: UserSessionToken;
  user: User;
};

export type UserSessionToken = {
  expiresAt: Scalars['DateTime']['output'];
  value: Scalars['String']['output'];
};

export type UserSettings = {
  emailPreferred: Maybe<Scalars['Boolean']['output']>;
  phonePreferred: Maybe<Scalars['Boolean']['output']>;
};

export type UserSettingsInput = {
  /** True if this is a preferred contact method */
  emailPreferred?: InputMaybe<Scalars['Boolean']['input']>;
  /** True if this is a preferred contact method */
  phonePreferred?: InputMaybe<Scalars['Boolean']['input']>;
};

export const UserSexualOrientation = {
  BISEXUAL: 'BISEXUAL',
  HETEROSEXUAL: 'HETEROSEXUAL',
  HOMOSEXUAL: 'HOMOSEXUAL',
  NON_DISCLOSE: 'NON_DISCLOSE',
  OTHER: 'OTHER',
  UNKNOWN: 'UNKNOWN'
} as const;

export type UserSexualOrientation = typeof UserSexualOrientation[keyof typeof UserSexualOrientation];
export type UserUsageInput = {
  after?: InputMaybe<Scalars['Date']['input']>;
  before?: InputMaybe<Scalars['Date']['input']>;
  duration: UsageDuration;
  productSlug?: InputMaybe<Scalars['String']['input']>;
  roleID?: InputMaybe<Scalars['UUID']['input']>;
};

export type UserUsageSummaryInput = {
  after?: InputMaybe<Scalars['Date']['input']>;
  before?: InputMaybe<Scalars['Date']['input']>;
  productSlug?: InputMaybe<Scalars['String']['input']>;
  roleID?: InputMaybe<Scalars['UUID']['input']>;
};

export type ValidationError = BaseError & {
  fieldErrors: Array<ValidationFieldError>;
  message: Scalars['String']['output'];
};

export type ValidationFieldError = {
  message: Scalars['String']['output'];
  path: Array<Scalars['String']['output']>;
};

export type Video = {
  ID: Scalars['UUID']['output'];
  fileUpload: FileUpload;
  /** The playback URL of the Video, or null if not processed yet */
  playbackUrl: Maybe<Scalars['String']['output']>;
};

export type Widget = {
  ID: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  iconName: Scalars['String']['output'];
  locked: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  practiceCount: Scalars['Int']['output'];
  practiceTileColor: Scalars['String']['output'];
  practiceTileDeeplink: Scalars['String']['output'];
  unlockSession: Maybe<RoleSession>;
};

export type WordPairing = {
  correctAnswersIncreased: Scalars['Boolean']['output'];
  isComplete: Scalars['Boolean']['output'];
  /** Total number of rounds to be completed */
  numRounds: Scalars['Int']['output'];
  rounds: Array<WordPairingRound>;
  timeToCompleteDecreased: Scalars['Boolean']['output'];
};

export type WordPairingAction = Action & {
  actionID: Scalars['UUID']['output'];
  actionValues: ActionValues;
};

export type WordPairingInput = {
  rounds: Array<WordPairingRoundInput>;
};

export type WordPairingLevel = {
  badgeUrl: Maybe<Scalars['String']['output']>;
  level: Scalars['Int']['output'];
  name: Maybe<Scalars['String']['output']>;
  nextLevelProgress: Maybe<Scalars['Float']['output']>;
  nextLevelProgressMessage: Maybe<Scalars['String']['output']>;
};

export type WordPairingPractice = Practice & {
  practiceID: Scalars['UUID']['output'];
  practiceValues: PracticeValues;
  wordPairing: WordPairing;
};

export type WordPairingRound = {
  isComplete: Scalars['Boolean']['output'];
  /** Number of questions answered, normally the same as numQuestions but can be less if round is only partially completed */
  numAnswers: Scalars['Int']['output'];
  /** Number of correct answers, between 0 and numAnswers */
  numCorrectAnswers: Scalars['Int']['output'];
  /** Total number of questions to be asked */
  numQuestions: Scalars['Int']['output'];
  /** Time taken in milliseconds */
  time: Scalars['Int']['output'];
};

export type WordPairingRoundInput = {
  /** Number of questions answered */
  numAnswers: Scalars['Int']['input'];
  numCorrectAnswers: Scalars['Int']['input'];
  /** Time taken in milliseconds */
  time: Scalars['Int']['input'];
};

export type WordPairingWidget = Widget & {
  ID: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  iconName: Scalars['String']['output'];
  locked: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  /** Number of questions in a round of a new Word Pairing practice */
  numQuestionsPerRound: Scalars['Int']['output'];
  /** Number of rounds in a new Word Pairing practice */
  numRounds: Scalars['Int']['output'];
  practiceCount: Scalars['Int']['output'];
  practiceTileColor: Scalars['String']['output'];
  practiceTileDeeplink: Scalars['String']['output'];
  unlockSession: Maybe<RoleSession>;
  wordPairingByID: Maybe<WordPairingPractice>;
  wordPairingLevel: WordPairingLevel;
  wordPairings: Array<WordPairingPractice>;
};


export type WordPairingWidgetWordPairingByIdArgs = {
  practiceID: Scalars['UUID']['input'];
};


export type WordPairingWidgetWordPairingsArgs = {
  input: WordPairingWidgetWordPairingsInput;
};

export type WordPairingWidgetWordPairingsInput = {
  after?: InputMaybe<Scalars['Date']['input']>;
  before?: InputMaybe<Scalars['Date']['input']>;
  /** Return up to this many most recent word pairing practices */
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type YstPatientInput = {
  /** Is required for Youth patients */
  birthDate?: InputMaybe<Scalars['Date']['input']>;
  person: PersonWithEmailInput;
};

export type YstGuardianProfile = YstProfile & {
  ID: Scalars['UUID']['output'];
  mentalHealthConcerns: Maybe<Scalars['YstMentalHealthConcerns']['output']>;
  nominations: Array<YstNomination>;
  patient: Patient;
  productVariant: ProductVariant;
};

export type YstInvite = {
  url: Scalars['String']['output'];
};

export type YstNomination = {
  email: Maybe<Scalars['String']['output']>;
  familyName: Scalars['String']['output'];
  givenName: Scalars['String']['output'];
  hasBeenInvited: Scalars['Boolean']['output'];
  isNominating: Scalars['Boolean']['output'];
  phone: Maybe<Scalars['String']['output']>;
  relation: Scalars['String']['output'];
  status: YstNominationStatus;
  ystNominationID: Scalars['String']['output'];
};

export type YstNominationCreateInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  familyName: Scalars['String']['input'];
  givenName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  relation: Scalars['String']['input'];
};

export type YstNominationRejectInput = {
  reason: Scalars['String']['input'];
  ystNominationID: Scalars['String']['input'];
};

export type YstNominationStatus = {
  reason: Maybe<Scalars['String']['output']>;
  status: YstNominationStatusValue;
  updatedAt: Scalars['DateTime']['output'];
};

export const YstNominationStatusValue = {
  ACCEPTED: 'ACCEPTED',
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
  INVITED: 'INVITED',
  INVITE_FAILED: 'INVITE_FAILED',
  INVITE_SCHEDULED: 'INVITE_SCHEDULED',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  REJECTED: 'REJECTED',
  SUGGESTED: 'SUGGESTED'
} as const;

export type YstNominationStatusValue = typeof YstNominationStatusValue[keyof typeof YstNominationStatusValue];
export type YstNominationUpdateInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  familyName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  ystNominationID: Scalars['String']['input'];
};

export type YstOrganization = {
  name: Scalars['String']['output'];
  organizationID: Scalars['UUID']['output'];
  slug: Scalars['String']['output'];
};

export type YstPatientMessage = {
  channel: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** The patient to whom the message was sent. This is NULL if the patient is the sender which is possible for INBOUND messages */
  recipient: Maybe<Patient>;
  type: YstPatientMessageValue;
  ystPatientMessageID: Scalars['UUID']['output'];
};

export type YstPatientMessagePreview = {
  body: Scalars['String']['output'];
  message: YstPatientMessage;
  subject: Maybe<Scalars['String']['output']>;
};

export const YstPatientMessageValue = {
  ADMIN_CONTACT_RESPONSE: 'ADMIN_CONTACT_RESPONSE',
  CHECKIN_CALL_REMINDER: 'CHECKIN_CALL_REMINDER',
  CHECKIN_END: 'CHECKIN_END',
  CHECKIN_MIDDLE: 'CHECKIN_MIDDLE',
  CHECKIN_REMINDER: 'CHECKIN_REMINDER',
  CHECKIN_START: 'CHECKIN_START',
  GUARDIAN_INVITE: 'GUARDIAN_INVITE',
  INBOUND: 'INBOUND',
  NOMINATION: 'NOMINATION',
  NOMINATION_18PLUS: 'NOMINATION_18PLUS',
  NOMINATION_APPROVED: 'NOMINATION_APPROVED',
  NOMINATION_RESPONSE: 'NOMINATION_RESPONSE',
  NOMINATION_RESPONSE_WITHOUT_SUGGESTION: 'NOMINATION_RESPONSE_WITHOUT_SUGGESTION',
  NOMINATION_SUGGESTION_ACCEPTED: 'NOMINATION_SUGGESTION_ACCEPTED',
  ORIENTATION_REMINDER: 'ORIENTATION_REMINDER',
  RESOURCES_ACTIVATED: 'RESOURCES_ACTIVATED',
  SUPPORTER_ACCEPTED: 'SUPPORTER_ACCEPTED',
  SUPPORTER_CONFIRMATION: 'SUPPORTER_CONFIRMATION',
  SUPPORTER_DECLINED: 'SUPPORTER_DECLINED',
  SUPPORTER_GENERAL_RESOURCES_REMINDER: 'SUPPORTER_GENERAL_RESOURCES_REMINDER',
  SUPPORTER_INVITE: 'SUPPORTER_INVITE',
  SUPPORTER_INVITE_REMINDER: 'SUPPORTER_INVITE_REMINDER',
  YOUTH_INVITE: 'YOUTH_INVITE'
} as const;

export type YstPatientMessageValue = typeof YstPatientMessageValue[keyof typeof YstPatientMessageValue];
export type YstProfile = {
  ID: Scalars['UUID']['output'];
  patient: Patient;
  productVariant: ProductVariant;
};

export type YstSupportProfile = YstProfile & {
  ID: Scalars['UUID']['output'];
  activities: Array<YstSupporterActivity>;
  checkinAvailability: Scalars['YstRecurringAvailability']['output'];
  checkins: Array<YstSupporterCheckin>;
  /** null if the supporter does not yet have any resources activated */
  mentalHealthConcerns: Maybe<Scalars['YstMentalHealthConcerns']['output']>;
  nomination: YstNomination;
  onboardingAvailability: Scalars['YstOnboardingAvailability']['output'];
  onboardingAvailabilitySaved: Scalars['Boolean']['output'];
  patient: Patient;
  productVariant: ProductVariant;
};

export type YstSupporterActivity = YstSupporterCheckin | YstSupporterEvent;

export type YstSupporterCheckin = {
  ratedAt: Maybe<Scalars['DateTime']['output']>;
  rating: Maybe<Scalars['Int']['output']>;
  scheduledAt: Scalars['Date']['output'];
  ystSupporterCheckinID: Scalars['UUID']['output'];
};

export type YstSupporterEvent = {
  createdAt: Scalars['DateTime']['output'];
  status: Scalars['String']['output'];
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
  ystSupporterEventID: Scalars['UUID']['output'];
};

export type YstYouthProfile = YstProfile & {
  ID: Scalars['UUID']['output'];
  mentalHealthConcerns: Scalars['YstMentalHealthConcerns']['output'];
  messages: Array<YstPatientMessage>;
  nominations: Array<YstNomination>;
  patient: Patient;
  productVariant: ProductVariant;
  status: Scalars['String']['output'];
};

// @ts-ignore

import { z } from 'zod';



export const AnySchema = z.unknown();
export const ArtifactIDSchema = z.unknown();
export const AssetIDSchema = z.unknown();
export const DateSchema = z.string().regex(/^(\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01]))$/).transform(v => v as Scalars['Date']['output']);
export const DateTimeSchema = z.string().regex(/^(\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])T([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9]|60))(\.\d{1,})?(([Z])|([+|-]([01][0-9]|2[0-3]):[0-5][0-9]))$/);
export const JSONObjectSchema = z.unknown();
export const MapSchema = z.unknown();
export const QuizSetItemQuestionDirectiveFeedbackSchema = z.unknown();
export const QuizSetItemQuestionLabelSchema = z.unknown();
export const UUIDSchema = z.string({ required_error: 'Required' }).uuid({ message: 'Invalid value' }).transform((v) => v as Scalars['UUID']['output']);
export const UserIDSchema = z.unknown();
export const YstMentalHealthConcernsSchema = CustomScalars.YstMentalHealthConcerns;
export const YstOnboardingAvailabilitySchema = CustomScalars.YstOnboardingAvailability;
export const YstRecurringAvailabilitySchema = CustomScalars.YstRecurringAvailability;

export const ActionTypeSchema = z.enum(["ACTIVITY_ADD","ACTIVITY_RATE","BREATH_HOLDING","COPING_CARD_ADD","COPING_CARD_REVIEW","EATING_LOG_ADD","HOPE_KIT_ADD","HOPE_KIT_REVIEW","HYPERVENTILATION","MY_PLAN_REVIEW","RELAX","SLEEP_DIARY_ENTRY_MORNING","SLEEP_DIARY_ENTRY_NIGHT","STATIC_01","STATIC_02","STATIC_03","STATIC_04","STATIC_05","STATIC_06","STATIC_07","STATIC_08","STATIC_09","STATIC_10","STATIC_11","STATIC_12","THOUGHT_DIARY_SPOT","THOUGHT_DIARY_SWITCH","THOUGHT_DIARY_TEST","WORD_PAIRING"]);
export const AddressUseSchema = z.enum(["HOME","OLD","TEMP","WORK"]);
export const AppActivityNameSchema = z.enum(["ActivityDiary","BingeEatingMyPlan","BreathHolding","CopingCards","CrisisFramework","EatingLog","EditEatingSchedule","HopeKit","Hyperventilation","MyPlanReview","Relaxation","RiskCurve","SleepDiary","SoloMyPlan","SoloRiskCurve","SoloSuicideMode","StressSensitivityAssessment","SuicideMode","ThoughtDiary","WordPairing"]);
export const CaringContactsMediumSchema = z.enum(["EMAIL","MAIL","NONE","SMS"]);
export const CompositionTemplateSchema = z.enum(["BINGE_EATING_MY_PLAN","EATING_COMMITMENT","EATING_SCHEDULE","MYSTORYMYPLAN","MYSTORYMYPLAN_CLINICAL","SCREENER_CSSRS","SCREENER_NUMERIC","STRESS_SENSITIVITY_ASSESSMENT","TEST","YST_MENTAL_HEALTH_CONCERNS"]);
export const ContactPointSystemSchema = z.enum(["EMAIL","FAX","OTHER","PAGER","PHONE","SMS","URL"]);
export const ContactPointUseSchema = z.enum(["HOME","MOBILE","OLD","TEMP","WORK"]);
export const ContentTypeSchema = z.enum(["ACTIVITY_PLANNING","ANXIETY_CAUSES","BODY_IMAGE","BREATH_HOLDING","COPING_CARDS","EATING_THE_RIGHT_FOODS","ENVIRONMENT_SAFETY","EXERCISE_AND_SLEEP","HOPE_KIT","HOW_TO_HELP","HYPERVENTILATION","MOOD_MODULATION","MOOD_TOLERANCE","MYPLAN","MYPLAN_REVIEW","MYSTORYMYPLAN","OVEREATING_CYCLE","POST_AVIVA","REDUCE_RISK","REGULAR_EATING","RELAPSE_PREVENTION","RELAXATION","REMOVING_BARRIERS","REVIEW_PREVENT_PRACTICE","SLEEP","SOCIAL_SUPPORT","SPOT_IT","STATIC_01","STATIC_02","STATIC_03","STATIC_04","STATIC_05","STATIC_06","STATIC_07","STATIC_08","STATIC_09","STATIC_10","STATIC_11","STATIC_12","SWITCH_IT","TEST","TEST_IT","THINK_CLEAR","URGE_SURFING","V1_SESSION_01","V1_SESSION_02","V1_SESSION_03","V1_SESSION_04","V1_SESSION_05","V1_SESSION_06","V1_SESSION_06P","V1_SESSION_07","V1_SESSION_07P","V1_SESSION_08","V1_SESSION_08P","V1_SESSION_09","V1_SESSION_09P","V1_SESSION_10","V1_SESSION_10P","V1_WELCOME","WEIGHT_STIGMA","WORD_PAIRING"]);
export const CrisisFrameworkSchema = z.enum(["RISK_CURVE_TIMELINE","SUICIDE_MODE"]);
export const EatingCheckinCompletionButtonSchema = z.enum(["DONE","SUGGESTION"]);
export const EatingCheckinTriggerSchema = z.enum(["EATING_LOG","SESSION"]);
export const EatingCommitmentHealthyFoodSchema = z.enum(["FRUITS","GRAINS","HEALTHY_FATS","LEAN_MEATS","OTHER","VEGETABLES"]);
export const EatingCommitmentUnhealthyFoodSchema = z.enum(["CANDY","DESSERTS","JUNK_FOOD","OTHER","SODA","TAKEOUT"]);
export const EatingLogMealTypeSchema = z.enum(["BREAKFAST","DINNER","LUNCH","SNACK"]);
export const EatingProgressWeightUnitSchema = z.enum(["KG","LBS"]);
export const EntityTypeSchema = z.enum(["ORGANIZATION","ORGANIZATION_PRODUCT","ROLE","USER"]);
export const ErrorMessageSchema = z.enum(["CANCELED_REQUEST","DUPLICATE","EMAIL_IN_USE","INTERNAL_SERVER_ERROR","INVALID_EMAIL","NOT_FOUND","UNAUTHORIZED"]);
export const ErrorStatusSchema = z.enum(["BAD_REQUEST","INTERNAL_SERVER_ERROR","UNAUTHORIZED"]);
export const FileUploadTypeSchema = z.enum(["APP_LOGO","CHAT_IMAGE","ILLUSTRATION","QUIZ_SET_VIDEO","VIDEO","VIDEO_TEXT_TRACK"]);
export const JobStatusSchema = z.enum(["CREATED","ERROR","QUEUED","RUNNING","SUCCESS"]);
export const JobTypeSchema = z.enum(["AMPLITUDE_IMPORT","CHANGEBITE_NOTIFICATIONS","CHECK_EXPO_PUSH_RECEIPTS","DETOX_USER_CLEANUP","EMAIL_USERS","ENQUEUE_JOBS","EXPIRED_USER_SESSION_CLEANUP","GROUP_SESSIONS","INVITE_YST_NOMINEE","LOG","MEAL_REMINDER","MY_PLAN_UPDATED_SUPPORTER_NOTIFICATION","NORMALIZE_AMPLITUDE_EVENT_SESSION_START","NORMALIZE_USERS_PHONE","OTX202_DATA_EXPORT","PATIENTS_PROGRESS_STARTED_AT_FOR_PRODUCT_STATIC_BACKFILL","PATIENT_CARING_CONTACTS","PATIENT_NOTIFICATIONS","PATIENT_SPOOL","PROCESS_VIDEO_ASSET","PTSD_NOTIFICATIONS","SEND_EMAIL","SLEEP","SYNC_GCS_MEDIA_UPLOAD","TEST_RECORD_CLEANUP","UNLOCK_SESSIONS","YST_NEW_SIGNUP_EMAIL","YST_NOMINATION_REMINDER","YST_REPORTS","YST_SUPPORTER"]);
export const LegacyUserGenderSchema = z.enum(["FEMALE","MALE","NOANSWER","OTHER"]);
export const LegacyUserRaceSchema = z.enum(["ASIAN","BLACK","NATIVE","NOANSWER","OTHER","PACIFIC","WHITE"]);
export const LegacyUserSexualitySchema = z.enum(["BISEXUAL","HETEROSEXUAL","HOMOSEXUAL","NOANSWER"]);
export const MediaActionSchema = z.enum(["FILE_UPLOAD_TO_GCS","MUX_HOSTING","REV_CAPTIONING"]);
export const MuxFitModeSchema = z.enum(["crop","pad","preserve","smartcrop"]);
export const OnboardingVariantSchema = z.enum(["COLLABORATIVE","INDEPENDENT"]);
export const OuiUserRoleTypeSchema = z.enum(["OUI_ADMIN","PATIENT","PRACTITIONER","REGISTRAR","UNKNOWN"]);
export const PatientGenderIdentitySchema = z.enum(["FEMALE","MALE","NON_BINARY","NON_DISCLOSE","TRANSGENDER_FEMALE","TRANSGENDER_MALE","UNKNOWN"]);
export const PatientMilitaryStatusSchema = z.enum(["ACTIVE_MILITARY_DUTY","CIVILIAN","DISABLED_VETERAN","FAMILY_MEMBER_OF_ACTIVE_DUTY","FAMILY_MEMBER_OF_RETIRED_MILITARY","INACTIVE_READY_RESERVE","NATIONAL_GUARD","NON_DISCLOSE","RESERVES","RETIRED_MILITARY","UNKNOWN","VETERAN"]);
export const PatientRaceSchema = z.enum(["AMERICAN_INDIAN_OR_ALASKA_NATIVE","ASIAN","BLACK_OR_AFRICAN_AMERICAN","NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER","NON_DISCLOSE","UNKNOWN","WHITE"]);
export const PatientSexualOrientationSchema = z.enum(["BISEXUAL","HETEROSEXUAL","HOMOSEXUAL","NON_DISCLOSE","OTHER","UNKNOWN"]);
export const PatientSupporterRelationSchema = z.enum(["CLINICIAN","OTHER","PARENT_GUARDIAN","RELATIVE","SOCIAL_SERVICES"]);
export const PracticeTypeSchema = z.enum(["ACTIVITY","BREATH_HOLDING","EATING_CHECKIN","EATING_COMMITMENT_ENTRY","EATING_LOG_ENTRY","EATING_PROGRESS_ENTRY","HYPERVENTILATION","RELAX_DIARY_ENTRY","SLEEP_DIARY_ENTRY","THOUGHT_DIARY_ENTRY","WORD_PAIRING"]);
export const ProductVariantSchema = z.enum(["AVIVA_ADOLESCENT","AVIVA_ADULT","AVIVA_INPATIENT","AVIVA_MILITARY","AVIVA_STATIC","AVIVA_SUPPORT","OUI_PLATFORM","YST_GUARDIAN","YST_SUPPORT","YST_YOUTH"]);
export const ProductVersionSchema = z.enum(["AVIVA_1_1","AVIVA_2_0"]);
export const PtsdDosageVersionSchema = z.enum(["V1","V2"]);
export const QuizSetItemTypeSchema = z.enum(["MULTIPLE_CHOICE","SINGLE_CHOICE","VIDEO"]);
export const RatingDurationSchema = z.enum(["ALL","DAY","MONTH","WEEK","YEAR"]);
export const RatingTypeSchema = z.enum(["QUALITY","RATING","RATING_AFTER","RATING_BEFORE"]);
export const RoleTypeSchema = z.enum(["GUARDIAN","PATIENT","PRACTITIONER","REGISTRAR","SUPPORTER"]);
export const SessionStateSchema = z.enum(["COMPLETED","IN_PROGRESS","LOCKED","UNLOCKED"]);
export const SleepDiaryEntryDurationSchema = z.enum(["DURATION_1_TO_5","DURATION_5_TO_10","DURATION_15_TO_30","DURATION_20_TO_45","DURATION_30_TO_60","DURATION_45_TO_60","DURATION_60_TO_120","DURATION_120_TO_180","DURATION_LESS_THAN_15","DURATION_MORE_THAN_60","DURATION_MORE_THAN_120","DURATION_MORE_THAN_180"]);
export const SleepDiaryEntryTimeOfDaySchema = z.enum(["AFTERNOON","EVENING","MORNING","NIGHT"]);
export const UploadTypeSchema = z.enum(["AUDIO","IMAGE","VIDEO"]);
export const UsageDurationSchema = z.enum(["DAY","MONTH","WEEK"]);
export const UserGenderIdentitySchema = z.enum(["FEMALE","MALE","NON_BINARY","NON_DISCLOSE","TRANSGENDER_FEMALE","TRANSGENDER_MALE","UNKNOWN"]);
export const UserMilitaryStatusSchema = z.enum(["ACTIVE_MILITARY_DUTY","CIVILIAN","DISABLED_VETERAN","FAMILY_MEMBER_OF_ACTIVE_DUTY","FAMILY_MEMBER_OF_RETIRED_MILITARY","INACTIVE_READY_RESERVE","NATIONAL_GUARD","NON_DISCLOSE","RESERVES","RETIRED_MILITARY","UNKNOWN","VETERAN"]);
export const UserRaceSchema = z.enum(["AMERICAN_INDIAN_OR_ALASKA_NATIVE","ASIAN","BLACK_OR_AFRICAN_AMERICAN","NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER","NON_DISCLOSE","UNKNOWN","WHITE"]);
export const UserSexualOrientationSchema = z.enum(["BISEXUAL","HETEROSEXUAL","HOMOSEXUAL","NON_DISCLOSE","OTHER","UNKNOWN"]);
export const YstNominationStatusValueSchema = z.enum(["ACCEPTED","APPROVED","DECLINED","INVITED","INVITE_FAILED","INVITE_SCHEDULED","PENDING_APPROVAL","REJECTED","SUGGESTED"]);
export const YstPatientMessageValueSchema = z.enum(["ADMIN_CONTACT_RESPONSE","CHECKIN_CALL_REMINDER","CHECKIN_END","CHECKIN_MIDDLE","CHECKIN_REMINDER","CHECKIN_START","GUARDIAN_INVITE","INBOUND","NOMINATION","NOMINATION_18PLUS","NOMINATION_APPROVED","NOMINATION_RESPONSE","NOMINATION_RESPONSE_WITHOUT_SUGGESTION","NOMINATION_SUGGESTION_ACCEPTED","ORIENTATION_REMINDER","RESOURCES_ACTIVATED","SUPPORTER_ACCEPTED","SUPPORTER_CONFIRMATION","SUPPORTER_DECLINED","SUPPORTER_GENERAL_RESOURCES_REMINDER","SUPPORTER_INVITE","SUPPORTER_INVITE_REMINDER","YOUTH_INVITE"]);

export const ActionValuesInputSchema = z.lazy(() => z.object({
patientID: UUIDSchema
}));


export const ActivityInputSchema = z.lazy(() => z.object({
attendee: z.string().nullish(),
endTime: DateTimeSchema,
location: z.string().nullish(),
notes: z.string().nullish(),
startTime: DateTimeSchema,
title: z.string()
}));


export const AddActionInputSchema = z.lazy(() => z.object({
actionType: ActionTypeSchema,
actionValues: ActionValuesInputSchema,
practiceID: UUIDSchema.nullish()
}));


export const AddActivityPracticeInputSchema = z.lazy(() => z.object({
activity: ActivityInputSchema,
practiceValues: PracticeValuesInputSchema
}));


export const AddEatingLogEntryInputSchema = z.lazy(() => z.object({
eatingLogEntry: EatingLogEntryInputSchema,
practiceValues: PracticeValuesInputSchema
}));


export const AddHopeKitImageInputSchema = z.lazy(() => z.object({
hopeKitImage: HopeKitImageInputSchema,
uploadContentType: z.string()
}));


export const AddHopeKitQuoteInputSchema = z.lazy(() => z.object({
hopeKitQuote: HopeKitQuoteInputSchema
}));


export const AddHopeKitVideoInputSchema = z.lazy(() => z.object({
hopeKitVideo: HopeKitVideoInputSchema,
uploadContentType: z.string()
}));


export const AddRelaxDiaryEntryPracticeInputSchema = z.lazy(() => z.object({
practiceValues: PracticeValuesInputSchema,
relaxDiaryEntry: RelaxDiaryEntryInputSchema
}));


export const AddSleepDiaryEntryPracticeInputSchema = z.lazy(() => z.object({
practiceValues: PracticeValuesInputSchema,
sleepDiaryEntry: SleepDiaryEntryInputSchema
}));


export const AddThoughtDiaryEntryPracticeInputSchema = z.lazy(() => z.object({
practiceValues: PracticeValuesInputSchema,
thoughtDiaryEntry: ThoughtDiaryEntryInputSchema
}));


export const AddressInputSchema = z.lazy(() => z.object({
city: z.string().nullish(),
line: z.array(z.string()).nullish(),
postalCode: z.string().nullish(),
rank: z.number(),
state: z.string().nullish(),
use: AddressUseSchema
}));


export const BreathHoldingWidgetStressBreathingExercisesInputSchema = z.lazy(() => z.object({
after: DateSchema.nullish(),
before: DateSchema.nullish(),
limit: z.number().nullish()
}));


export const CMSAppCreateInputSchema = z.lazy(() => z.object({
logoID: UUIDSchema,
name: z.string()
}));


export const CMSAppUpdateInputSchema = z.lazy(() => z.object({
appID: UUIDSchema,
logoID: UUIDSchema.nullish(),
name: z.string().nullish()
}));


export const CMSSessionCreateInputSchema = z.lazy(() => z.object({
agenda: z.array(z.string()).nullish(),
completedIllustrationID: UUIDSchema.nullish(),
content: JSONObjectSchema.nullish(),
contentType: z.string(),
description: z.string(),
groupID: UUIDSchema,
illustrationID: UUIDSchema.nullish(),
locale: z.string(),
name: z.string(),
order: z.number(),
variantID: UUIDSchema
}));


export const CMSSessionGroupCreateInputSchema = z.lazy(() => z.object({
name: z.string(),
order: z.number().nullish(),
variantID: UUIDSchema
}));


export const CMSSessionGroupUpdateInputSchema = z.lazy(() => z.object({
groupID: UUIDSchema,
name: z.string(),
order: z.number()
}));


export const CMSVariantCreateInputSchema = z.lazy(() => z.object({
appID: UUIDSchema,
name: z.string(),
version: z.string()
}));


export const CMSVariantUpdateInputSchema = z.lazy(() => z.object({
appID: UUIDSchema.nullish(),
name: z.string().nullish(),
variantID: UUIDSchema,
version: z.string().nullish()
}));


export const ChangeBiteRoleSettingsInputSchema = z.lazy(() => z.object({
sessionUnlockDayOfWeek: z.number().nullish()
}));


export const CompleteQuizSetForPatientInputSchema = z.lazy(() => z.object({
patientID: UUIDSchema,
quizSetID: z.string(),
slug: z.string()
}));


export const CompletedQuizSetsForPatientInputSchema = z.lazy(() => z.object({
patientID: UUIDSchema,
slug: z.string()
}));


export const ContactPointInputSchema = z.lazy(() => z.object({
rank: z.number(),
system: ContactPointSystemSchema,
use: ContactPointUseSchema,
value: z.string()
}));


export const CopyQuizSetToCollectionInputSchema = z.lazy(() => z.object({
quizSetCollectionID: UUIDSchema,
quizSetID: UUIDSchema
}));


export const CopySessionInputSchema = z.lazy(() => z.object({
destination: CopySessionInputDestinationSchema,
sessionID: UUIDSchema
}));


export const CopySessionInputDestinationSchema = z.lazy(() => z.object({
groupID: UUIDSchema,
variantID: UUIDSchema
}));


export const CreateRoleInputSchema = z.lazy(() => z.object({
organizationID: UUIDSchema,
practitioners: z.array(RolePractitionerInputSchema).nullish(),
productConfig: RoleProductConfigInputSchema.nullish(),
productID: UUIDSchema,
productStatic: z.boolean(),
roleType: RoleTypeSchema,
supporteeID: UUIDSchema.nullish(),
supporteeRelation: PatientSupporterRelationSchema.nullish()
}));


export const CreateUserInputSchema = z.lazy(() => z.object({
addresses: z.array(AddressInputSchema).nullish(),
birthDate: DateSchema.nullish(),
email: z.string().email(),
familyName: z.string().nullish(),
genderIdentity: UserGenderIdentitySchema.nullish(),
givenName: z.string().nullish(),
locale: z.string().nullish(),
militaryStatus: UserMilitaryStatusSchema.nullish(),
phone: z.string().nullish(),
race: UserRaceSchema.nullish(),
settings: UserSettingsInputSchema.nullish(),
sexualOrientation: UserSexualOrientationSchema.nullish(),
timezone: z.string().nullish()
}));


export const DeleteRoleInputSchema = z.lazy(() => z.object({
roleID: UUIDSchema
}));


export const DeleteSessionGroupInputSchema = z.lazy(() => z.object({
groupID: UUIDSchema
}));


export const DeleteSessionInputSchema = z.lazy(() => z.object({
sessionID: UUIDSchema
}));


export const DeviceInputSchema = z.lazy(() => z.object({
expoToken: z.string().nullish(),
installationID: UUIDSchema,
platform: z.string(),
pushToken: z.string().nullish()
}));


export const EatingCheckinInputSchema = z.lazy(() => z.object({
completionButtonClicked: EatingCheckinCompletionButtonSchema.nullish(),
completionTimestamp: DateTimeSchema.nullish(),
mood: z.number().nullish(),
promptTimestamp: DateTimeSchema,
trigger: EatingCheckinTriggerSchema,
urgeLevel: z.number().nullish()
}));


export const EatingCommitmentEntryInputSchema = z.lazy(() => z.object({
decreaseCommitment: EatingCommitmentUnhealthyFoodSchema,
decreaseSuccess: z.boolean().nullish(),
increaseCommitment: EatingCommitmentHealthyFoodSchema,
increaseSuccess: z.boolean().nullish()
}));


export const EatingLogEntryInputSchema = z.lazy(() => z.object({
binged: z.boolean(),
description: z.string().nullish(),
meal: EatingLogMealTypeSchema,
timestamp: DateTimeSchema
}));


export const EatingLogEntryPracticeImageUploadUrlInputSchema = z.lazy(() => z.object({
contentType: z.string()
}));


export const EatingProgressEntryInputSchema = z.lazy(() => z.object({
binges: z.number().nullish(),
fadFixes: z.number().nullish(),
timestamp: DateTimeSchema,
weight: z.number().nullish(),
weightUnit: EatingProgressWeightUnitSchema.nullish()
}));


export const EntityAccessLogRequestInputSchema = z.lazy(() => z.object({
entityID: UUIDSchema,
entityType: EntityTypeSchema
}));


export const ExchangePatchInputSchema = z.lazy(() => z.object({
exchangeID: UUIDSchema,
patches: z.array(PatchInputSchema)
}));


export const FileUploadActionCompleteInputSchema = z.lazy(() => z.object({
fileUploadId: UUIDSchema,
mediaAction: MediaActionSchema
}));


export const FileUploadCreateInputSchema = z.lazy(() => z.object({
fileName: z.string(),
fileUploadType: FileUploadTypeSchema
}));


export const HopeKitImageInputSchema = z.lazy(() => z.object({
reason: z.string().nullish()
}));


export const HopeKitQuoteInputSchema = z.lazy(() => z.object({
author: z.string().nullish(),
reason: z.string().nullish(),
text: z.string()
}));


export const HopeKitVideoInputSchema = z.lazy(() => z.object({
reason: z.string().nullish()
}));


export const HyperventilationWidgetStressBreathingExercisesInputSchema = z.lazy(() => z.object({
after: DateSchema.nullish(),
before: DateSchema.nullish(),
limit: z.number().nullish()
}));


export const LatLngInputSchema = z.lazy(() => z.object({
lat: z.number(),
lng: z.number()
}));


export const MutationAddBreathHoldingPracticeInputSchema = z.lazy(() => z.object({
practiceValues: PracticeValuesInputSchema,
stressBreathingExercise: StressBreathingExerciseInputSchema
}));


export const MutationAddEatingCheckinInputSchema = z.lazy(() => z.object({
eatingCheckin: EatingCheckinInputSchema,
practiceValues: PracticeValuesInputSchema
}));


export const MutationAddEatingProgressEntryInputSchema = z.lazy(() => z.object({
eatingProgressEntry: EatingProgressEntryInputSchema,
practiceValues: PracticeValuesInputSchema
}));


export const MutationAddHyperventilationPracticeInputSchema = z.lazy(() => z.object({
practiceValues: PracticeValuesInputSchema,
stressBreathingExercise: StressBreathingExerciseInputSchema
}));


export const MutationAddWordPairingPracticeInputSchema = z.lazy(() => z.object({
practiceValues: PracticeValuesInputSchema,
wordPairing: WordPairingInputSchema
}));


export const MutationAddYstSupporterEventInputSchema = z.lazy(() => z.object({
patientID: UUIDSchema,
status: z.string(),
type: z.string(),
value: z.string(),
youthID: UUIDSchema
}));


export const MutationContactYstSupportInputSchema = z.lazy(() => z.object({
body: z.string(),
primaryClinician: z.boolean().nullish()
}));


export const MutationCreateJobInputSchema = z.lazy(() => z.object({
payload: JSONObjectSchema,
synchronous: z.boolean().nullish(),
type: JobTypeSchema
}));


export const MutationCreateTestScenarioInputSchema = z.lazy(() => z.object({
organization: OrganizationInputSchema,
users: z.array(TestUserInputSchema)
}));


export const MutationCreateUserInputSchema = z.lazy(() => z.object({
roles: z.array(CreateRoleInputSchema),
user: CreateUserInputSchema
}));


export const MutationCreateVideoInputSchema = z.lazy(() => z.object({
fileUploadID: UUIDSchema
}));


export const MutationDeclineYstNominationInputSchema = z.lazy(() => z.object({
reason: z.string()
}));


export const MutationInviteYSTGuardianInputSchema = z.lazy(() => z.object({
guardian: YSTPatientInputSchema,
youthID: UUIDSchema
}));


export const MutationInviteYSTYouthInputSchema = z.lazy(() => z.object({
youth: YSTPatientInputSchema
}));


export const MutationNominateYstSupportersInputSchema = z.lazy(() => z.object({
create: z.array(YstNominationCreateInputSchema).nullish(),
reject: z.array(YstNominationRejectInputSchema).nullish(),
update: z.array(YstNominationUpdateInputSchema).nullish(),
youthID: UUIDSchema
}));


export const MutationPersistBotStateInputSchema = z.lazy(() => z.object({
configID: z.string(),
envelopes: z.array(MapSchema),
previousBotStateID: UUIDSchema.nullish(),
state: z.string()
}));


export const MutationPreviewYstPatientMessageInputSchema = z.lazy(() => z.object({
ystPatientMessageID: UUIDSchema
}));


export const MutationRateYstSupporterCheckinInputSchema = z.lazy(() => z.object({
rating: z.number().int().max(6).min(1),
ystSupporterCheckinID: UUIDSchema
}));


export const MutationResendYstPatientMessageInputSchema = z.lazy(() => z.object({
ystPatientMessageID: UUIDSchema
}));


export const MutationSignupYSTGuardianInputSchema = z.lazy(() => z.object({
organizationID: UUIDSchema,
password: z.string().min(8),
patient: YSTPatientInputSchema
}));


export const MutationSignupYSTYouthInputSchema = z.lazy(() => z.object({
organizationID: UUIDSchema,
password: z.string().min(8),
patient: YSTPatientInputSchema
}));


export const MutationUpdateBreathHoldingPracticeInputSchema = z.lazy(() => z.object({
practiceID: UUIDSchema,
practiceValues: PracticeValuesInputSchema,
stressBreathingExercise: StressBreathingExerciseInputSchema
}));


export const MutationUpdateEatingCheckinInputSchema = z.lazy(() => z.object({
eatingCheckin: EatingCheckinInputSchema,
practiceID: UUIDSchema,
practiceValues: PracticeValuesInputSchema
}));


export const MutationUpdateEatingProgressEntryInputSchema = z.lazy(() => z.object({
eatingProgressEntry: EatingProgressEntryInputSchema,
practiceID: UUIDSchema,
practiceValues: PracticeValuesInputSchema
}));


export const MutationUpdateFirstCheckinDateInputSchema = z.lazy(() => z.object({
date: DateSchema,
patientID: UUIDSchema
}));


export const MutationUpdateHyperventilationPracticeInputSchema = z.lazy(() => z.object({
practiceID: UUIDSchema,
practiceValues: PracticeValuesInputSchema,
stressBreathingExercise: StressBreathingExerciseInputSchema
}));


export const MutationUpdateOnboardingAvailabilityInputSchema = z.lazy(() => z.object({
onboardingAvailability: YstOnboardingAvailabilitySchema
}));


export const MutationUpdatePracticeInputSchema = z.lazy(() => z.object({
content: PracticeContentInputSchema.nullish(),
date: DateSchema.nullish(),
practiceID: UUIDSchema,
ratings: z.array(PracticeRatingInputSchema).nullish()
}));


export const MutationUpdateRecurringAvailabilityInputSchema = z.lazy(() => z.object({
patientID: UUIDSchema.nullish(),
recurringAvailability: YstRecurringAvailabilitySchema
}));


export const MutationUpdateRoleSettingsInputSchema = z.lazy(() => z.object({
roleID: UUIDSchema,
settings: RoleSettingsInputSchema
}));


export const MutationUpdateUserInputSchema = z.lazy(() => z.object({
roles: z.array(RoleInputSchema).nullish(),
user: UpdateUserInputSchema.nullish(),
userID: UUIDSchema
}));


export const MutationUpdateWordPairingPracticeInputSchema = z.lazy(() => z.object({
practiceID: UUIDSchema,
practiceValues: PracticeValuesInputSchema,
wordPairing: WordPairingInputSchema
}));


export const MutationUpdateYstMentalHealthConcernsInputSchema = z.lazy(() => z.object({
patientID: UUIDSchema,
ystMentalHealthConcerns: YstMentalHealthConcernsSchema
}));


export const MutationUpdateYstSupporterEventInputSchema = z.lazy(() => z.object({
status: z.string(),
type: z.string(),
value: z.string(),
ystSupporterEventID: UUIDSchema
}));


export const OrganizationAvivaConfigInputSchema = z.lazy(() => z.object({
caringContactsEnabled: z.boolean(),
crisisFramework: CrisisFrameworkSchema,
onboardingVariant: OnboardingVariantSchema,
productVersion: ProductVersionSchema
}).merge(z.object({"caringContactsEnabled":z.boolean(),"crisisFramework":z.enum(["SUICIDE_MODE","RISK_CURVE_TIMELINE"]),"onboardingVariant":z.enum(["COLLABORATIVE","INDEPENDENT"]),"productVersion":z.enum(["AVIVA_1_1","AVIVA_2_0"])})));


export const OrganizationInputSchema = z.lazy(() => z.object({
isTrialOrganization: z.boolean().nullish(),
name: z.string(),
parent: UUIDSchema.nullish(),
products: z.array(OrganizationProductInputSchema).nullish()
}));


export const OrganizationMemberUpdateInputSchema = z.lazy(() => z.object({
person: PersonUpdateInputSchema.nullish(),
roles: z.array(OuiUserRoleTypeSchema)
}));


export const OrganizationProductConfigInputSchema = z.lazy(() => z.object({
avivaConfig: OrganizationAvivaConfigInputSchema.nullish(),
ystConfig: OrganizationYstConfigInputSchema.nullish()
}));


export const OrganizationProductInputSchema = z.lazy(() => z.object({
name: z.string().nullish(),
nameStatic: z.string().nullish(),
productConfig: OrganizationProductConfigInputSchema.nullish(),
productID: UUIDSchema
}));


export const OrganizationUpdateInputSchema = z.lazy(() => z.object({
isTrialOrganization: z.boolean().nullish(),
name: z.string().nullish(),
products: z.array(OrganizationProductInputSchema).nullish()
}));


export const OrganizationYstConfigInputSchema = z.lazy(() => z.object({
contacts: z.array(z.string()),
slug: z.string(),
unlisted: z.boolean().nullish()
}).merge(z.object({"slug":z.string().regex(new RegExp("^[\\w-]+$"), "Can only contain letters and dashes"),"contacts":z.array(z.string().email()).min(1, "At least one contact email must be provided"),"unlisted":z.boolean().default(false)})));


export const PatchInputSchema = z.lazy(() => z.object({
inverse: JSONObjectSchema,
payload: JSONObjectSchema
}));


export const PatientInputSchema = z.lazy(() => z.object({
caringContactsMedium: CaringContactsMediumSchema.nullish(),
onboardingVariant: OnboardingVariantSchema.nullish(),
person: PersonInputSchema,
productID: UUIDSchema.nullish(),
productStatic: z.boolean().nullish(),
productVersion: ProductVersionSchema.nullish(),
roleType: RoleTypeSchema.nullish()
}));


export const PatientSupporterInputSchema = z.lazy(() => z.object({
person: PersonInputSchema,
productStatic: z.boolean().nullish(),
productVariant: ProductVariantSchema.nullish(),
relation: PatientSupporterRelationSchema
}));


export const PatientSupporterRelationInputSchema = z.lazy(() => z.object({
patientID: UUIDSchema,
relation: PatientSupporterRelationSchema
}));


export const PatientUpdateInputSchema = z.lazy(() => z.object({
caringContactsMedium: CaringContactsMediumSchema.nullish(),
person: PersonUpdateInputSchema.nullish()
}));


export const PersonInputSchema = z.lazy(() => z.object({
birthDate: DateSchema.nullish(),
email: z.string().email().nullish(),
familyName: z.string(),
genderIdentity: UserGenderIdentitySchema.nullish(),
givenName: z.string(),
militaryStatus: UserMilitaryStatusSchema.nullish(),
phone: z.string().nullish(),
race: UserRaceSchema.nullish(),
settings: UserSettingsInputSchema.nullish(),
sexualOrientation: UserSexualOrientationSchema.nullish()
}));


export const PersonUpdateInputSchema = z.lazy(() => z.object({
birthDate: DateSchema.nullish(),
contactPoint: z.array(ContactPointInputSchema).nullish(),
email: z.string().email().nullish(),
familyName: z.string().nullish(),
genderIdentity: UserGenderIdentitySchema.nullish(),
givenName: z.string().nullish(),
militaryStatus: UserMilitaryStatusSchema.nullish(),
phone: z.string().nullish(),
race: UserRaceSchema.nullish(),
settings: UserSettingsInputSchema.nullish(),
sexualOrientation: UserSexualOrientationSchema.nullish()
}));


export const PersonWithEmailInputSchema = z.lazy(() => z.object({
birthDate: DateSchema.nullish(),
email: z.string().email(),
familyName: z.string(),
genderIdentity: UserGenderIdentitySchema.nullish(),
givenName: z.string(),
militaryStatus: UserMilitaryStatusSchema.nullish(),
phone: z.string().nullish(),
race: UserRaceSchema.nullish(),
settings: UserSettingsInputSchema.nullish(),
sexualOrientation: UserSexualOrientationSchema.nullish()
}));


export const PracticeContentInputSchema = z.lazy(() => z.object({
eatingCommitmentEntry: EatingCommitmentEntryInputSchema.nullish()
}));


export const PracticeRatingInputSchema = z.lazy(() => z.object({
type: RatingTypeSchema,
value: z.number()
}));


export const PracticeValuesInputSchema = z.lazy(() => z.object({
date: DateSchema.nullish(),
patientID: UUIDSchema,
ratings: z.array(PracticeRatingInputSchema)
}));


export const PractitionerInputSchema = z.lazy(() => z.object({
npi: z.number().nullish(),
person: PersonInputSchema
}));


export const QueryUsersInputSchema = z.lazy(() => z.object({
organizationID: UUIDSchema.nullish(),
primaryPractitionerIDs: z.array(UUIDSchema).nullish(),
productID: UUIDSchema.nullish(),
query: z.string().nullish(),
roleTypes: z.array(RoleTypeSchema).nullish(),
sessions: z.array(ContentTypeSchema).nullish()
}));


export const QuizSetCollectionCreateInputSchema = z.lazy(() => z.object({
name: z.string(),
nodeID: UUIDSchema,
quizSetCollectionID: UUIDSchema,
sessionID: UUIDSchema
}));


export const QuizSetCollectionUpdateInputSchema = z.lazy(() => z.object({
name: z.string(),
quizSetCollectionID: z.string()
}));


export const QuizSetCreateInputSchema = z.lazy(() => z.object({
quizSet: QuizSetInputSchema,
quizSetCollectionID: UUIDSchema
}));


export const QuizSetDeleteInputSchema = z.lazy(() => z.object({
quizSetID: UUIDSchema
}));


export const QuizSetInputSchema = z.lazy(() => z.object({
description: z.string(),
name: z.string()
}));


export const QuizSetItemChoiceCreateInputSchema = z.lazy(() => z.object({
quizSetID: UUIDSchema,
quizSetItemChoice: QuizSetItemChoiceInputSchema
}));


export const QuizSetItemChoiceInputSchema = z.lazy(() => z.object({
afterAllFeedback: z.string(),
correctFeedback: z.string(),
incorrectFeedback: z.string(),
options: z.array(QuizSetItemChoiceOptionInputSchema),
prompt: z.string(),
type: QuizSetItemTypeSchema
}));


export const QuizSetItemChoiceOptionInputSchema = z.lazy(() => z.object({
correct: z.boolean(),
feedback: z.string(),
text: z.string(),
value: z.string()
}));


export const QuizSetItemChoiceUpdateInputSchema = z.lazy(() => z.object({
quizSetItemChoice: QuizSetItemChoiceInputSchema,
quizSetItemID: UUIDSchema
}));


export const QuizSetItemDeleteInputSchema = z.lazy(() => z.object({
quizSetItemID: UUIDSchema
}));


export const QuizSetItemUpdateOrderInputSchema = z.lazy(() => z.object({
order: z.number(),
quizSetItemID: UUIDSchema
}));


export const QuizSetItemVideoCreateInputSchema = z.lazy(() => z.object({
quizSetID: UUIDSchema,
quizSetItemVideo: QuizSetItemVideoInputSchema
}));


export const QuizSetItemVideoInputSchema = z.lazy(() => z.object({
fileUploadID: z.string()
}));


export const QuizSetUpdateInputSchema = z.lazy(() => z.object({
quizSet: QuizSetInputSchema,
quizSetID: UUIDSchema
}));


export const RegistrarInputSchema = z.lazy(() => z.object({
person: PersonInputSchema
}));


export const RelaxDiaryEntryInputSchema = z.lazy(() => z.object({
completion: z.number(),
slug: z.string()
}));


export const RemoveHopeKitItemInputSchema = z.lazy(() => z.object({
hopeKitItemID: UUIDSchema
}));


export const ResumableAssetCreateInputSchema = z.lazy(() => z.object({
context: z.string(),
fileName: z.string(),
key: z.string(),
uploadType: UploadTypeSchema
}));


export const RoleAvivaConfigInputSchema = z.lazy(() => z.object({
caringContactsMedium: CaringContactsMediumSchema,
crisisFramework: CrisisFrameworkSchema,
onboardingVariant: OnboardingVariantSchema,
productVersion: ProductVersionSchema
}));


export const RoleInputSchema = z.lazy(() => z.object({
createRole: CreateRoleInputSchema.nullish(),
deleteRole: DeleteRoleInputSchema.nullish(),
updateRole: UpdateRoleInputSchema.nullish()
}));


export const RolePractitionerInputSchema = z.lazy(() => z.object({
practitionerID: UUIDSchema,
primaryPractitioner: z.boolean()
}));


export const RoleProductConfigInputSchema = z.lazy(() => z.object({
avivaConfig: RoleAvivaConfigInputSchema.nullish()
}));


export const RoleSettingsInputSchema = z.lazy(() => z.object({
changeBiteSettings: ChangeBiteRoleSettingsInputSchema.nullish()
}));


export const SleepDiaryEntryInfluencerInputSchema = z.lazy(() => z.object({
count: z.number().nullish(),
duration: SleepDiaryEntryDurationSchema.nullish(),
kinds: z.array(z.string()).nullish(),
occurred: z.boolean(),
timesOfDay: z.array(SleepDiaryEntryTimeOfDaySchema)
}));


export const SleepDiaryEntryInputSchema = z.lazy(() => z.object({
alcohol: SleepDiaryEntryInfluencerInputSchema.nullish(),
caffeine: SleepDiaryEntryInfluencerInputSchema.nullish(),
deviceInBed: SleepDiaryEntryInfluencerInputSchema.nullish(),
endTime: DateTimeSchema,
exercise: SleepDiaryEntryInfluencerInputSchema.nullish(),
medicine: SleepDiaryEntryInfluencerInputSchema.nullish(),
napping: SleepDiaryEntryInfluencerInputSchema.nullish(),
sleepDelay: SleepDiaryEntryDurationSchema.nullish(),
startTime: DateTimeSchema,
tobacco: SleepDiaryEntryInfluencerInputSchema.nullish(),
wakeupCount: z.number(),
wakeupDuration: SleepDiaryEntryDurationSchema.nullish()
}));


export const SnapshotQuizSetCollectionInputSchema = z.lazy(() => z.object({
quizSetCollectionID: UUIDSchema
}));


export const SnapshotSessionInputSchema = z.lazy(() => z.object({
sessionID: UUIDSchema
}));


export const StressBreathingExerciseInputSchema = z.lazy(() => z.object({
rounds: z.array(StressBreathingRoundInputSchema)
}));


export const StressBreathingRoundInputSchema = z.lazy(() => z.object({
distressRating: z.number().max(10).min(1).nullish()
}));


export const TestPatientInputSchema = z.lazy(() => z.object({
caringContactsMedium: CaringContactsMediumSchema.nullish(),
crisisFramework: CrisisFrameworkSchema.nullish(),
onboardingVariant: OnboardingVariantSchema.nullish(),
productSlug: z.string().nullish(),
productStatic: z.boolean().nullish(),
productVersion: ProductVersionSchema.nullish(),
roleType: RoleTypeSchema.nullish()
}));


export const TestRoleInputSchema = z.lazy(() => z.object({
practitionerEmails: z.array(z.string()).nullish(),
productConfig: RoleProductConfigInputSchema.nullish(),
productID: UUIDSchema,
productStatic: z.boolean(),
roleType: RoleTypeSchema,
supporteeEmail: z.string().nullish(),
ystNominationStatus: z.string().nullish()
}));


export const TestUserInputSchema = z.lazy(() => z.object({
password: z.string().nullish(),
roles: z.array(TestRoleInputSchema),
user: CreateUserInputSchema
}));


export const TextItemInputSchema = z.lazy(() => z.object({
ID: UUIDSchema,
text: z.string()
}));


export const ThoughtDiaryEntryInputSchema = z.lazy(() => z.object({
behavior: z.string(),
event: z.string(),
evidenceAgainst: z.array(TextItemInputSchema),
evidenceFor: z.array(TextItemInputSchema),
feelingsAfter: z.array(TextItemInputSchema),
feelingsBefore: z.array(TextItemInputSchema),
thoughtAfter: z.string(),
thoughtBefore: z.string()
}));


export const UpdateActivityPracticeInputSchema = z.lazy(() => z.object({
activity: ActivityInputSchema,
practiceID: UUIDSchema,
practiceValues: PracticeValuesInputSchema
}));


export const UpdateEatingLogEntryInputSchema = z.lazy(() => z.object({
deleteImage: z.boolean().nullish(),
eatingLogEntry: EatingLogEntryInputSchema,
practiceID: UUIDSchema,
practiceValues: PracticeValuesInputSchema
}));


export const UpdateHopeKitImageInputSchema = z.lazy(() => z.object({
hopeKitItemID: UUIDSchema,
reason: z.string().nullish()
}));


export const UpdateHopeKitQuoteInputSchema = z.lazy(() => z.object({
author: z.string().nullish(),
hopeKitItemID: UUIDSchema,
reason: z.string().nullish(),
text: z.string()
}));


export const UpdateHopeKitVideoInputSchema = z.lazy(() => z.object({
hopeKitItemID: UUIDSchema,
reason: z.string().nullish()
}));


export const UpdateOuiUserInputSchema = z.lazy(() => z.object({
currentPassword: z.string().nullish(),
email: z.string().nullish(),
password: z.string().nullish(),
person: PersonUpdateInputSchema.nullish()
}));


export const UpdateRelaxDiaryEntryPracticeInputSchema = z.lazy(() => z.object({
practiceID: UUIDSchema,
practiceValues: PracticeValuesInputSchema,
relaxDiaryEntry: RelaxDiaryEntryInputSchema
}));


export const UpdateRoleInputSchema = z.lazy(() => z.object({
organizationID: UUIDSchema.nullish(),
practitioners: z.array(RolePractitionerInputSchema).nullish(),
productConfig: RoleProductConfigInputSchema.nullish(),
productID: UUIDSchema.nullish(),
productStatic: z.boolean().nullish(),
roleID: UUIDSchema,
roleType: RoleTypeSchema.nullish(),
supporteeID: UUIDSchema.nullish(),
supporteeRelation: PatientSupporterRelationSchema.nullish()
}));


export const UpdateSessionInputSchema = z.lazy(() => z.object({
agenda: z.array(z.string()).nullish(),
completedIllustrationID: UUIDSchema.nullish(),
contentType: z.string().nullish(),
description: z.string().nullish(),
groupID: UUIDSchema,
illustrationID: UUIDSchema.nullish(),
locale: z.string().nullish(),
name: z.string().nullish(),
order: z.number().nullish(),
sessionID: UUIDSchema,
statusID: z.number().nullish(),
variantID: UUIDSchema
}));


export const UpdateSleepDiaryEntryPracticeInputSchema = z.lazy(() => z.object({
practiceID: UUIDSchema,
practiceValues: PracticeValuesInputSchema,
sleepDiaryEntry: SleepDiaryEntryInputSchema
}));


export const UpdateThoughtDiaryEntryPracticeInputSchema = z.lazy(() => z.object({
practiceID: UUIDSchema,
practiceValues: PracticeValuesInputSchema,
thoughtDiaryEntry: ThoughtDiaryEntryInputSchema
}));


export const UpdateUserInputSchema = z.lazy(() => z.object({
addresses: z.array(AddressInputSchema).nullish(),
birthDate: DateSchema.nullish(),
email: z.string().email().nullish(),
familyName: z.string().nullish(),
genderIdentity: UserGenderIdentitySchema.nullish(),
givenName: z.string().nullish(),
locale: z.string().nullish(),
militaryStatus: UserMilitaryStatusSchema.nullish(),
phone: z.string().nullish(),
race: UserRaceSchema.nullish(),
settings: UserSettingsInputSchema.nullish(),
sexualOrientation: UserSexualOrientationSchema.nullish(),
timezone: z.string().nullish()
}));


export const UserAccessLogRequestInputSchema = z.lazy(() => z.object({
userID: UUIDSchema
}));


export const UserAddressInputSchema = z.lazy(() => z.object({
city: z.string(),
country: z.string(),
line1: z.string(),
line2: z.string(),
state: z.string().nullish(),
zip: z.string().nullish()
}));


export const UserDemoInputSchema = z.lazy(() => z.object({
DOB: DateSchema.nullish(),
gender: LegacyUserGenderSchema.nullish(),
isVeteran: z.boolean().nullish(),
race: LegacyUserRaceSchema.nullish(),
sexuality: LegacyUserSexualitySchema.nullish()
}));


export const UserNameInputSchema = z.lazy(() => z.object({
first: z.string(),
last: z.string(),
pfx: z.string().nullish(),
preferred: z.string().nullish(),
sfx: z.string().nullish()
}));


export const UserSettingsInputSchema = z.lazy(() => z.object({
emailPreferred: z.boolean().nullish(),
phonePreferred: z.boolean().nullish()
}));


export const UserUsageInputSchema = z.lazy(() => z.object({
after: DateSchema.nullish(),
before: DateSchema.nullish(),
duration: UsageDurationSchema,
productSlug: z.string().nullish(),
roleID: UUIDSchema.nullish()
}));


export const UserUsageSummaryInputSchema = z.lazy(() => z.object({
after: DateSchema.nullish(),
before: DateSchema.nullish(),
productSlug: z.string().nullish(),
roleID: UUIDSchema.nullish()
}));


export const WordPairingInputSchema = z.lazy(() => z.object({
rounds: z.array(WordPairingRoundInputSchema)
}));


export const WordPairingRoundInputSchema = z.lazy(() => z.object({
numAnswers: z.number().max(40).nonnegative(),
numCorrectAnswers: z.number().max(40).nonnegative(),
time: z.number().nonnegative()
}));


export const WordPairingWidgetWordPairingsInputSchema = z.lazy(() => z.object({
after: DateSchema.nullish(),
before: DateSchema.nullish(),
limit: z.number().nullish()
}));


export const YSTPatientInputSchema = z.lazy(() => z.object({
birthDate: DateSchema.nullish(),
person: PersonWithEmailInputSchema
}));


export const YstNominationCreateInputSchema = z.lazy(() => z.object({
email: z.string().email().nullish(),
familyName: z.string(),
givenName: z.string(),
phone: z.string().nullish(),
relation: z.string()
}));


export const YstNominationRejectInputSchema = z.lazy(() => z.object({
reason: z.string(),
ystNominationID: z.string()
}));


export const YstNominationUpdateInputSchema = z.lazy(() => z.object({
email: z.string().nullish(),
familyName: z.string().nullish(),
givenName: z.string().nullish(),
phone: z.string().nullish(),
ystNominationID: z.string()
}))