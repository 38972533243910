import type { ApolloClient } from '@apollo/client';
import { captureMessage } from '@sentry/core';

import { CMSPrimaryExchange } from './cmsEditor';
import { cmsToConvo } from './cmsToStateMachine';
import { graphql } from './graphql/tada';
import e2eTest from './metadata/content/e2e-test.json';
import ENVIRONMENT_SAFETY from './metadata/content/ENVIRONMENT_SAFETY.json';
import v2session1es from './metadata/content/v2-session-01-es.json';
import v2session1Inpatient from './metadata/content/v2-session-01-inpatient.json';
import v2session1 from './metadata/content/v2-session-01.json';
import v2session2 from './metadata/content/v2-session-02.json';
import v2session3 from './metadata/content/v2-session-03.json';
import v2session4 from './metadata/content/v2-session-04.json';
import v2session5 from './metadata/content/v2-session-05.json';
import v2session6 from './metadata/content/v2-session-06.json';
import v2session7 from './metadata/content/v2-session-07.json';
import v2session8 from './metadata/content/v2-session-08.json';
import v2session9Inpatient from './metadata/content/v2-session-09-inpatient.json';
import v2session9 from './metadata/content/v2-session-09.json';
import v2session10 from './metadata/content/v2-session-10.json';
import v2session11 from './metadata/content/v2-session-11.json';
import v2session12 from './metadata/content/v2-session-12.json';
import { Config, ConvoNode, getMachineConfigFromConversation } from './stateMachine';
import { ProductVariant } from './types/graphql.generated';

export const BotContentQuery = graphql(`
  query BotContent($contentType: String!, $version: Int) {
    user {
      ID
      role {
        ID
        sessions(contentType: $contentType) {
          session {
            sessionID
            content(version: $version) {
              exchange {
                exchangeID
                content
                lastPatchVersion
              }
            }
          }
        }
      }
    }
  }
`);

const CONFIGS: Record<string, Config | undefined> = {};

const SHARED_CONVO_DATAS: Record<string, ConvoNode | undefined> = {
  'e2e-test': e2eTest as ConvoNode,
};

const V2_ADULT: Record<string, ConvoNode | undefined> = {
  MYPLAN: v2session1 as ConvoNode,
  HOPE_KIT: v2session2 as ConvoNode,
  ACTIVITY_PLANNING: v2session3 as ConvoNode,
  RELAXATION: v2session4 as ConvoNode,
  SLEEP: v2session5 as ConvoNode,
  SPOT_IT: v2session6 as ConvoNode,
  TEST_IT: v2session7 as ConvoNode,
  SWITCH_IT: v2session8 as ConvoNode,
  COPING_CARDS: v2session9 as ConvoNode,
  MYPLAN_REVIEW: v2session10 as ConvoNode,
  REDUCE_RISK: v2session11 as ConvoNode,
  POST_AVIVA: v2session12 as ConvoNode,
  DEMO_MYPLAN__ES: v2session1es as ConvoNode,
};

const V2_MILITARY: Record<string, ConvoNode | undefined> = {
  MYPLAN: v2session1 as ConvoNode,
  ENVIRONMENT_SAFETY: ENVIRONMENT_SAFETY as ConvoNode,
  HOPE_KIT: v2session2 as ConvoNode,
  ACTIVITY_PLANNING: v2session3 as ConvoNode,
  RELAXATION: v2session4 as ConvoNode,
  SLEEP: v2session5 as ConvoNode,
  SPOT_IT: v2session6 as ConvoNode,
  TEST_IT: v2session7 as ConvoNode,
  SWITCH_IT: v2session8 as ConvoNode,
  COPING_CARDS: v2session9 as ConvoNode,
  REDUCE_RISK: v2session11 as ConvoNode,
  POST_AVIVA: v2session12 as ConvoNode,
};

const V2_INPATIENT: Record<string, ConvoNode | undefined> = {
  MYPLAN: v2session1Inpatient as ConvoNode,
  HOPE_KIT: v2session2 as ConvoNode,
  COPING_CARDS: v2session9Inpatient as ConvoNode,
};

const V2_CONVO_DATAS: Record<string, Record<string, ConvoNode | undefined>> = {
  [ProductVariant.AVIVA_ADOLESCENT]: V2_ADULT,
  [ProductVariant.AVIVA_ADULT]: V2_ADULT,
  [ProductVariant.AVIVA_MILITARY]: V2_MILITARY,
  [ProductVariant.AVIVA_STATIC]: {}, // deprecated, no bot sesssions
  [ProductVariant.AVIVA_SUPPORT]: {}, // no bot sesssions
  [ProductVariant.AVIVA_INPATIENT]: V2_INPATIENT,
  [ProductVariant.OUI_PLATFORM]: {}, // no bot sesssions
  [ProductVariant.YST_YOUTH]: {}, // no bot sesssions
  [ProductVariant.YST_SUPPORT]: {}, // no bot sesssions
  [ProductVariant.YST_GUARDIAN]: {}, // no bot sesssions
};

// Allows for lazy loading of CONFIGS
export async function loadConfig(
  apolloClient: ApolloClient<unknown>,
  options: {
    useLegacyStaticContent: boolean;
    content: string;
    version?: number;
    productVariant?: string;
    productStatic: boolean;
  },
): Promise<Config | undefined> {
  // console.log('loadConfig', options);
  if (options.productStatic) {
    throw new Error('bot is not applicable to productStatic patients');
  }

  const cacheKey = JSON.stringify(options);
  if (CONFIGS[cacheKey]) return CONFIGS[cacheKey];

  const { useLegacyStaticContent, version, content, productVariant } = options;

  if (SHARED_CONVO_DATAS[content]) {
    CONFIGS[cacheKey] = getMachineConfigFromConversation(SHARED_CONVO_DATAS[content]!);
    return CONFIGS[cacheKey];
  }

  if (!useLegacyStaticContent && content !== 'e2e-test') {
    const result = await apolloClient.query({
      query: BotContentQuery,
      variables: { contentType: content, version },
      // A version is immutable so we can cache that without worrying. If version is not specified
      // we want to make sure we're loading the newest content/version
      fetchPolicy: typeof version === 'number' ? 'cache-first' : 'no-cache',
    });

    const exchange = result.data?.user?.role?.sessions[0].session?.content?.exchange;
    if (exchange?.content) {
      const convo = cmsToConvo({
        sessionID: content,
        primaryExchange: exchange.content as CMSPrimaryExchange,
        contentType: content,
      });
      return getMachineConfigFromConversation(convo, {
        context: { __CONTENT_VERSION: exchange.lastPatchVersion },
      });
      // TODO how to bust cache for new content? Maybe a TTL? Do we need to cache if no version present?
      // until we figure this out, we should not cache and just pay the network cost
      // CONFIGS[cacheKey] = getMachineConfigFromConversation(convo, {
      //   context: { __CONTENT_VERSION: result.data.sessionContentForApp.exchange.lastPatchVersion },
      // });

      // return CONFIGS[cacheKey];
    }

    // Until all variants have CMS content published we want to fallback to static content
    // if we don't find a match
    captureMessage(`No sessionContentForApp found for ${content}`, {
      tags: { content, productVariant, version },
    });
  }

  if (!productVariant) {
    captureMessage('Should have a productVariant if useLegacyStaticContent is false', {
      tags: { content, productVariant, version },
    });
  }

  const convoData = V2_CONVO_DATAS[productVariant ?? 'AVIVA_ADULT'][content];
  if (convoData) {
    CONFIGS[cacheKey] = getMachineConfigFromConversation(convoData);
  }
  return CONFIGS[cacheKey];
}

export function registerConvo(content: string, convo: ConvoNode) {
  // bust cache since convo may be changing since previous caching
  for (let key of Object.keys(CONFIGS)) {
    if (key.startsWith(content)) {
      delete CONFIGS[key];
    }
  }
  SHARED_CONVO_DATAS[content] = convo;
}
