/* This file is autogenerated by @oui/cli */
import Svg, { Line, Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={16} height={18} viewBox="0 0 16 18" fill="none" {...props}>
    <Path
      d="M8 11c3 0 6.36 1.95 6.65 2.63.24.55.35 2.24.35 2.24 0 .63-.47 1.13-1.05 1.13H2.05C1.47 17 1 16.5 1 15.87c0 0 .11-1.7.35-2.24C1.64 12.95 5 11 8 11Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M8 7.15A3.08 3.08 0 1 1 8 1a3.08 3.08 0 0 1 0 6.15Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Line
      x1={6}
      y1={3}
      x2={13}
      y2={3}
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
