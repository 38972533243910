/* This file is autogenerated by @oui/cli */
import Svg, { Line, Path, Rect, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={16} height={18} viewBox="0 0 16 18" fill="none" {...props}>
    <Path
      d="M1.74 2a1 1 0 0 1 1-1h5.65a1 1 0 0 1 1 1v14.3H1.74V2Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M14.26 5.48a1 1 0 0 0-1-1H10.4a1 1 0 0 0-1 1V16.3h4.87V5.48Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Rect x={3.13} y={2.74} width={2.09} height={2.09} rx={1} fill="currentColor" />
    <Rect x={3.13} y={5.87} width={2.09} height={2.09} rx={1} fill="currentColor" />
    <Rect x={3.13} y={9} width={2.09} height={2.09} rx={1} fill="currentColor" />
    <Rect x={3.13} y={12.13} width={2.09} height={2.09} rx={1} fill="currentColor" />
    <Rect x={5.91} y={2.74} width={2.09} height={2.09} rx={1} fill="currentColor" />
    <Rect x={10.78} y={6.22} width={2.09} height={2.09} rx={1} fill="currentColor" />
    <Rect x={5.91} y={5.87} width={2.09} height={2.09} rx={1} fill="currentColor" />
    <Rect x={10.78} y={9.35} width={2.09} height={2.09} rx={1} fill="currentColor" />
    <Rect x={5.91} y={9} width={2.09} height={2.09} rx={1} fill="currentColor" />
    <Rect x={10.78} y={12.13} width={2.09} height={2.09} rx={1} fill="currentColor" />
    <Rect x={5.91} y={12.13} width={2.09} height={2.09} rx={1} fill="currentColor" />
    <Line
      x1={1}
      y1={16}
      x2={14.65}
      y2={16}
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
