/* This file is autogenerated by @oui/cli */
import Svg, { Line, Path, Rect, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Rect
      x={-0.41}
      y={12.39}
      width={18.11}
      height={8.52}
      rx={4.26}
      transform="rotate(-45 -0.414214 12.3898)"
      stroke="currentColor"
      strokeWidth={2}
      vectorEffect="non-scaling-stroke"
    />
    <Line
      x1={7.13}
      y1={6.26}
      x2={11.74}
      y2={10.87}
      stroke="currentColor"
      strokeWidth={2}
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M10.52 5.65s.96-1.15 1.73-1.15c.42 0 .76.38.76.38"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
