/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={17} viewBox="0 0 18 17" fill="none" {...props}>
    <Path
      d="M9.93 11.42v2.46a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3c0-1.1.9-2 2-2h4.93a2 2 0 0 1 2 2v.8"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.47 14.4a1.12 1.12 0 1 0 0-2.24 1.12 1.12 0 0 0 0 2.24Z"
      fill="currentColor"
    />
    <Path
      d="M4.72 3.23h1.49"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M16.11 9.99A3 3 0 0 1 15.37 8V5.88a3.02 3.02 0 1 0-6.04 0v2.13A3 3 0 0 1 8.58 10L7.7 11H17l-.89-1.01Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.42 10.77a1.16 1.16 0 1 0 1.86 0h-1.86Z"
      fill="currentColor"
    />
  </Svg>
);
export default SvgComponent;
