/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={17} height={18} viewBox="0 0 17 18" fill="none" {...props}>
    <Path
      d="M5.01 17s.1-2.31-1.88-4.63A8.93 8.93 0 0 1 1.06 8h13.25c.25.78 1.44 1.48.96 2.17-.22.33-.79.27-.92.69-.25.73-.05 1.56-.16 2.36-.12.93-.18.98-.34 1.39-.22.58-.74 1.02-1.68.94-.58-.05-1.09.1-1.19.43-.04.17-.12.75-.12 1.03"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M13.17 7.47c0-.32-.03-.63-.1-.93l.74-.41a.96.96 0 0 0 .36-1.33l-.66-1.12a1 1 0 0 0-1.35-.36l-.75.43a4.94 4.94 0 0 0-1.62-.89v-.89A.98.98 0 0 0 8.81 1H7.49a.98.98 0 0 0-.98.97v.96c-.56.2-1.07.5-1.5.88l-.87-.49a1 1 0 0 0-1.35.36L2.13 4.8a.96.96 0 0 0 .36 1.33l.9.5c-.05.28-.08.56-.08.84"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M9.77 7.47a1.62 1.62 0 1 0-3.24 0"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
