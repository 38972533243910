/* This file is autogenerated by @oui/cli */
import Svg, { Circle, Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={16} height={18} viewBox="0 0 16 18" fill="none" {...props}>
    <Circle
      cx={10.59}
      cy={2.48}
      r={1.48}
      stroke="currentColor"
      strokeWidth={2}
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M2 14.78s3.2-.38 4.3-1.93l1.4-3.1m6.9-5.5s-.94 2.89-2.38 2.82C11.34 7.04 11.17 6 10.3 5.9c-.88-.1-2.6 3.85-2.6 3.85m0 0s2.64.63 3.34 2.22c.7 1.6.44 5.04.44 5.04M7.93 5.6s-2.31.11-2.67.29c-.89.43-1.63 1.92-1.63 1.92"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
