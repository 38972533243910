/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 2.24C1 1.56 1.55 1 2.24 1h4.12c.69 0 1.64.4 2.12.88l8.16 8.16c.48.48.48 1.26 0 1.75l-4.85 4.85c-.49.48-1.27.48-1.76 0L1.88 8.48A3.43 3.43 0 0 1 1 6.36V2.24Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.64 6.1a1.45 1.45 0 1 0 0-2.92 1.45 1.45 0 0 0 0 2.91Z"
      fill="currentColor"
    />
  </Svg>
);
export default SvgComponent;
