/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={16} height={17} viewBox="0 0 16 17" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.36 16.06A8.03 8.03 0 1 0 5 0a1 1 0 0 0-1 1v.88h.88a1 1 0 0 0 .48.12 6.03 6.03 0 1 1 0 12.06 1 1 0 0 0-.48.12H4v.88a1 1 0 0 0 1.18.98l.18.02ZM6 12.42v-1.76H4v1.76h2ZM6 5.4V3.64H4v1.75h2Zm0 3.52V7.15H4v1.76h2Z"
      fill="currentColor"
    />
  </Svg>
);
export default SvgComponent;
