/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={16} height={17} viewBox="0 0 16 17" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.03 0a8.03 8.03 0 1 0 .36 16.06 1 1 0 0 0 1-1v-.88h-.88a1 1 0 0 0-.48-.12 6.03 6.03 0 1 1 0-12.06 1 1 0 0 0 .48-.12h.88V1A1 1 0 0 0 11.2.02a1 1 0 0 0-.18-.02Zm-.64 3.64v1.75h2V3.64h-2Zm0 7.02v1.76h2v-1.76h-2Zm0-3.51v1.76h2V7.15h-2Z"
      fill="currentColor"
    />
  </Svg>
);
export default SvgComponent;
