/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      d="M3.56 5.8h11.2l-.6 8.42a3 3 0 0 1-3 2.78h-4a3 3 0 0 1-3-2.78l-.6-8.42Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="m11.16 9-.4 4.8"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="m7.16 9 .4 4.8"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M3.16 2.6c-.66 0-1.2.54-1.2 1.2v.8c0 .66.54 1.2 1.2 1.2h12c.66 0 1.2-.54 1.2-1.2v-.8c0-.66-.54-1.2-1.2-1.2l-3.2-.4c0-.66-.54-1.2-1.2-1.2h-3.2c-.66 0-1.2.54-1.2 1.2l-3.2.4Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
