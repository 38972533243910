/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={19} height={17} viewBox="0 0 19 17" fill="none" {...props}>
    <Path
      d="M7.57 4.37a1 1 0 0 1 .8-1.15l.33-.06c.54-.1 1.08.26 1.2.79l.77 3.13c.43.02.97.07 1.63.16 1.97.27 3.08.73 3.08.73.32.12.63.48.7.82l.93 5.26a.99.99 0 0 1-.8 1.15l-4.23.75c-.53.1-1.04-.37-1.3-.76a3.55 3.55 0 0 0-2.04-1.5l-3.27-.95-.16-.91a.93.93 0 0 1 .76-1.08l2.76.14-1.16-6.52Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M6 1 2.56 4 1 2.64"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
