/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 1.7c.41 0 .82.04 1.22.11-.23.23-.43.48-.63.77a.36.36 0 0 0-.02.36c.06.12.19.2.32.2.52 0 .75.1.83.2.19.36 0 .78-.1 1.14-.08.3-.1.66.08.86.14.14.32.16.45.15l-.27.21c-.4.33-.88.79-.99 1.46 0 .46 0 1 .22 1.34.2.3.53.44.83.5.56.1 1.05.06 1.35.26.27.36.45.96.63 1.57.18.63.34 1.28.84 1.66.2.14.52.02.56-.22.08-.38.21-.58.4-.83.54-.64.72-1.37 1.22-2.05.1-.14.22-.26.34-.35a7.28 7.28 0 1 1-14.4-1.55c.36.23.47.72.55 1.06.02.07.05.12.1.17.84.84 1.14 1.6 1.14 2.6 0 .08.03.16.08.22.48.58 1.05 1.02 1.48 1.55.43.54.75 1.15.75 2.14 0 .14.1.28.24.33l.53.18c.16.05.36-.03.43-.18a5 5 0 0 1 1.6-3.11c.48-.5.93-1.04.93-2.02 0-.16-.12-.31-.27-.35-.57-.14-1.1-.69-1.83-1.08-.68-.37-1.6-.48-2.78.1-.12-.16-.1-.43-.1-.63a.36.36 0 0 0-.15-.3c-.24-.16-.39-.3-.48-.43.16.05.34-.03.42-.18.4-.8 1.13-1.33 1.87-1.8.74-.45 1.48-.76 1.85-1.38.15-.5 0-1.1-.16-1.5a8.76 8.76 0 0 0-.54-1.02C8 1.76 8.5 1.7 9 1.7Z"
      fill="currentColor"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 1A8 8 0 1 0 9 17.01 8 8 0 0 0 9 1Z"
      stroke="currentColor"
      strokeWidth={2}
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
