/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={16} viewBox="0 0 18 16" fill="none" {...props}>
    <Path
      d="M1.32 9.56c-.03.38-.23.7-.27 1.1-.08.9.15 1.25 1.04 1.35.16.02.34 0 .5 0h12.54c1.4 0 1.83-.59 1.33-1.85-.08-.2-.1-.42-.23-.6"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M1.4 6.97c.3.1.63.06.94.06h12.62c.63 0 1.3.1 1.6-.5.28-.55-.08-1.02-.44-1.4a6.95 6.95 0 0 0-3.29-1.65 16.4 16.4 0 0 0-7.28-.14c-1.4.32-2.78.69-3.8 1.53-.78.65-.9 1.38-.38 2.1"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="M1.32 7.54c-.49.74-.46.86.35 1.09.36.1.6.35.84.6.65.68 1.1.7 1.7.08.87-.9 1.08-.9 1.94 0 .56.59 1 .59 1.61 0 .95-.9 1.06-.93 2.02.01.53.52 1.2.59 1.72-.08.66-.84 1.1-.8 1.85-.01.66.7 1.1.78 1.67.06.45-.58 1.33-.45 1.64-1.15 0-.28-.2-.42-.35-.6"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
