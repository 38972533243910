/* This file is autogenerated by @oui/cli */
import Svg, { Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={19} height={19} viewBox="0 0 19 19" fill="none" {...props}>
    <Path
      d="M6.93 11v7H1V12.5c0-2.98.33-5.14 1-6.47A8.61 8.61 0 0 1 6.11 2l1.34 2.34A5.11 5.11 0 0 0 4.94 6.6c-.54 1-.84 2.46-.9 4.38h2.89Zm9.53 0v7h-5.92V12.5c0-2.98.33-5.14.99-6.47A8.6 8.6 0 0 1 15.65 2L17 4.34a5.12 5.12 0 0 0-2.52 2.27c-.54 1-.84 2.46-.9 4.38h2.88Z"
      stroke="currentColor"
      strokeWidth={2}
      vectorEffect="non-scaling-stroke"
    />
  </Svg>
);
export default SvgComponent;
