/* This file is autogenerated by @oui/cli */
import Svg, { Circle, Path, type SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={16} viewBox="0 0 18 16" fill="none" {...props}>
    <Path
      d="M14.91 12.74c0-4.7-3.11-8-6.95-8C4.1 4.74 1 8.54 1 12.39"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Path
      d="m12.83 11.35 2.08 2.08L17 11.35"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <Circle cx={7.96} cy={4.74} r={1.74} fill="currentColor" />
  </Svg>
);
export default SvgComponent;
